import moment, { Moment } from 'moment';

export function formatDate(year: number, month?: number, day?: number): string {
  let date = moment({ year: year, month: month !== undefined ? month - 1 : undefined, day: day });

  if (month === undefined) {
    return date.format('YYYY');
  } else if (day === undefined) {
    return date.format('MMMM, YYYY');
  } else {
    return date.format('D MMMM, YYYY');
  }
}

export function getCurrentDate(): Moment {
  return moment();
}