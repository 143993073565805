import { ApplicationState } from './types';
import { ArtistState } from './Artist/types';
import { ItemState } from './Item/types';
import { AuthState } from './Auth/types';
import { PortfolioState } from './Portfolio/types';
import { WishlistState } from './Wishlist/types';
import { UserState } from './User/types';
import { PopupState } from './Popup/types';
import { ListingState } from './Listing/types';
import { MaterialState } from './Material/types';
import { ItemDataSuggestionState } from './ItemDataSuggestion/types';
import { ItemSaleSuggestionState } from './ItemSaleSuggestion/types';
import { SalesVenueState } from './SalesVenue/types';
import { ItemListingGroupingState } from './ItemListingGrouping/types';
import { ListingDataSuggestionState } from './ListingDataSuggestion/types';
import { HomeState } from './Home/types';

export class RootSelectors {
  public static getArtistState(state: ApplicationState): ArtistState {
    return state.artists;
  }

  public static getItemState(state: ApplicationState): ItemState {
    return state.items;
  }

  public static getAuthState(state: ApplicationState): AuthState {
    return state.auth;
  }

  public static getPortfolioState(state: ApplicationState): PortfolioState {
    return state.portfolios;
  }

  public static getWishlistState(state: ApplicationState): WishlistState {
    return state.wishlists;
  }

  public static getUserState(state: ApplicationState): UserState {
    return state.users;
  }

  public static getPopupState(state: ApplicationState): PopupState {
    return state.popups;
  }

  public static getListingState(state: ApplicationState): ListingState {
    return state.listings;
  }

  public static getMaterialState(state: ApplicationState): MaterialState {
    return state.materials;
  }

  public static getItemDataSuggestionState(state: ApplicationState): ItemDataSuggestionState {
    return state.itemDataSuggestions;
  }

  public static getItemSaleSuggestionState(state: ApplicationState): ItemSaleSuggestionState {
    return state.itemSaleSuggestions;
  }

  public static getListingDataSuggestionState(state: ApplicationState): ListingDataSuggestionState {
    return state.listingDataSuggestions;
  }

  public static getItemListingGroupingState(state: ApplicationState): ItemListingGroupingState {
    return state.itemListingGroupings;
  }

  public static getSalesVenueState(state: ApplicationState): SalesVenueState {
    return state.salesVenues;
  }

  public static getHomeState(state: ApplicationState): HomeState {
    return state.home;
  }
}
