import { MeasurementData, MeasurementUnit } from 'models/types';

export function convertDimensions(dimensions: MeasurementData, unit: MeasurementUnit): MeasurementData {
  const multiplier = unit === MeasurementUnit.IN ? 0.393701 : (1 / 0.393701);
  const newDimensions: MeasurementData = {}

  if (dimensions.h) {
    newDimensions.h = dimensions.h * multiplier;
  }

  if (dimensions.l) {
    newDimensions.l = dimensions.l * multiplier;
  }

  if (dimensions.w) {
    newDimensions.w = dimensions.w * multiplier;
  }

  return newDimensions;
}
