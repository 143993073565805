import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { ListingModel, PaginationModel } from 'models';

export class ListingSelectors {
  public static getPaginatedListings(state: ApplicationState): PaginationModel<ListingModel> {
    return RootSelectors.getListingState(state).paginatedListings;
  }

  public static getListingSearchLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getListingState(state).loading.search;
  }
}
