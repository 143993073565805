import {
  ChakraProvider,
  ColorModeScript,
  extendTheme
} from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from 'chakra-ui-steps';
import { AppView } from './views';

const theme = extendTheme({
  components: {
    Steps,
  },
});

export const App = () => (
  <ChakraProvider theme={theme}>
    <ColorModeScript />
    <AppView />
  </ChakraProvider>
);
