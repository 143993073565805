import React, { useState, useEffect } from 'react';
import {
  Table,
  Tbody,
  Tr,
  Td,
  Checkbox,
  Flex,
  Box
} from '@chakra-ui/react';
import { SalesVenueModel } from 'models/SalesVenue/SalesVenue';
import SearchBar from '../../SearchBar/SearchBar';
import PaginationFooter from '../../Pagination/PaginationFooter/PaginationFooter';

export interface SalesVenueTableProps {
  venues: SalesVenueModel[];
  onSelect: (venueIds: string[]) => void;
  selectedVenueIds?: string[];
  disabled?: boolean;
  multiSelect?: boolean;
  paginate?: boolean;
  perPage?: number
}

export default function SalesVenueTable({
  venues,
  onSelect,
  disabled = false,
  selectedVenueIds = [],
  multiSelect = false,
  paginate = false,
  perPage = 40
}: SalesVenueTableProps) {
  const [filter, setFilter] = useState('');
  const [selectedVenues, setSelectedVenues] = useState<string[]>(selectedVenueIds);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(Math.ceil(venues.length / perPage));
  const startIndex = (currentPage - 1) * perPage;


  useEffect(() => {
    setLastPage(Math.ceil(venues.length / perPage));
  }, [venues, perPage]);

  const filteredVenues = venues.filter(venue => 
    venue.name.toLowerCase().includes(filter.toLowerCase())
  ).sort((a, b) => 
    Number(selectedVenues.includes(b.id)) - Number(selectedVenues.includes(a.id))
  );

  const displayedVenues = paginate 
    ? filteredVenues.slice(startIndex, startIndex + perPage)
    : filteredVenues;

  const toggleVenueSelection = (venueId: string) => {
    if (disabled) return;

    let updatedVenues;
    if (selectedVenues.includes(venueId)) {
      updatedVenues = selectedVenues.filter(id => id !== venueId);
    } else {
      if (multiSelect) {
        updatedVenues = [...selectedVenues, venueId];
      } else {
        updatedVenues = [venueId];
      }
    }

    setSelectedVenues(updatedVenues);
    onSelect(updatedVenues);
    setCurrentPage(1);
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <Flex direction="column" width="100%">
      <Flex alignItems="center" marginBottom="4">
        <Box marginBottom={2}>
          <SearchBar onChange={setFilter} showResultBox={false} disabled={disabled} />
        </Box>
      </Flex>
      <Table size="sm" width="100%">
        <Tbody height="200px" overflowY="scroll" display="block" width="100%">
          {displayedVenues.map((venue) => (
            <Tr key={venue.id} onClick={() => toggleVenueSelection(venue.id)} cursor={disabled ? 'not-allowed' : 'pointer'}>
              <Td onClick={(e) => e.stopPropagation()}>
                <Checkbox 
                  isChecked={selectedVenues.includes(venue.id)}
                  onChange={() => toggleVenueSelection(venue.id)}
                  isDisabled={disabled}
                  required={false}
                />
              </Td>
              <Td width={'100%'}>{venue.name}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      {paginate && <PaginationFooter currentPage={currentPage} lastPage={lastPage} siblingsCount={2} onPageChange={onPageChange} />}
    </Flex>
  );
}
