import {
  Box,
  Button,
  Flex,
  Image,
  Heading,
  Stack,
  Text
} from "@chakra-ui/react";
import { FiArrowRight } from 'react-icons/fi';

export interface HeroProps {
  title: string;
  subtitle: string;
  subtext?: string;
  image: string;
  ctaText?: string;
  onCtaClick?: () => void;
  titleImageUrl?: string;
}

export default function Hero({
  title,
  subtitle,
  subtext,
  image,
  onCtaClick,
  ctaText,
  titleImageUrl
}: HeroProps) {
  function renderTitleImage() {
    if (titleImageUrl) {
      return <Image src={titleImageUrl} w={22} h={22} mr={2} />;
    }
    return null;
}

  return (
    <Flex
      align="center"
      justify={{ base: "center", md: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      wrap="nowrap"
      mb={16}
    >
      <Stack
        spacing={4}
        w={{ base: "80%", md: "40%" }}
        align={["center", "center", "flex-start", "flex-start"]}
      >
        <Heading
          as="h1"
          size="xl"
          fontWeight="bold"
          color="primary.800"
          textAlign={["center", "center", "left", "left"]}
          display="flex"
          alignItems="center"
        >
          {renderTitleImage()}
          {title}
        </Heading>
        <Heading
          as="h2"
          size="md"
          color="primary.800"
          opacity="0.8"
          fontWeight="normal"
          lineHeight={1.5}
          textAlign={["center", "center", "left", "left"]}
        >
          {subtitle}
        </Heading>
        {!!ctaText && 
          <Button
            colorScheme={'orange'}
            borderRadius="8px"
            py="4"
            px="4"
            lineHeight="1"
            size="lg"
            onClick={onCtaClick}
            rightIcon={<FiArrowRight />}
          >
            {ctaText}
          </Button>
        }
        <Text
          fontSize="xs"
          mt={2}
          textAlign="center"
          color="primary.800"
          opacity="0.6"
        >
          {subtext}
        </Text>
      </Stack>
      <Flex w={{ base: "80%", sm: "60%", md: "50%" }} mb={{ base: 12, md: 0 }} justifyContent={'center'}>
        <Image src={image} rounded="1rem" shadow="2xl" maxH={{base: '200px', md: '400px'}}/>
      </Flex>
    </Flex>
  );
}
