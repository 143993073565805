import moment from 'moment';
import {
	Box,
	Stack,
	StackDivider,
	Heading,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
import { MetricModel } from 'models/Market/Metric';
import { toDollarFormat } from 'utils/format';
import { MarketTooltipData } from '../types';

interface MarketVolumeTooltipProps extends MarketTooltipData {};

export default function MarketVolumeTooltip({ payload, label, active = false }: MarketVolumeTooltipProps) {
	if (!active || !payload || !payload.length) return null;
	const data: MetricModel = payload[0].payload;
	if (!data.totalSales) return null;

	return (
		<Box borderWidth="1px" borderRadius="lg" p={4} shadow="md" backgroundColor={useColorModeValue("white", "black")} zIndex={10}>
			<Box mb={4}>
				<Heading size='md'>{label}</Heading>
			</Box>

			<Stack divider={<StackDivider />} spacing='4' textAlign={'left'}>
				<Box>
					<Heading size='xs' textTransform='uppercase'>
						Total Volume
					</Heading>
					<Text pt='2' fontSize='sm'>
						{toDollarFormat(data.totalSales, false)}
					</Text>
				</Box>
				<Box>
					<Heading size='xs' textTransform='uppercase'>
						Total Sales
					</Heading>
					<Text pt='2' fontSize='sm'>
						{data.totalListings}
					</Text>
				</Box>
			</Stack>
		</Box>
	);
}
