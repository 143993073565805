import {
  Modal as ChakraModal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  ModalBody,
  Button,
} from '@chakra-ui/react';

export interface ModalProps {
  title: string | JSX.Element;
  content: JSX.Element;
  isOpen: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
  showSubmitButton?: boolean;
  submitText?: string;
  closeText?: string;
  onSubmit?: () => void;
  scrollEnabled?: boolean;
}

export default function Modal({ title, content, isOpen, showSubmitButton = true, showCloseButton = true, closeText = 'Close', submitText = 'Submit', onClose = () => { }, onSubmit = () => { }, scrollEnabled = true }: ModalProps) {
  function renderCloseButton() {
    if (showCloseButton) {
      return (
        <Button variant='ghost' mr={3} onClick={onClose}>
          {closeText}
        </Button>
      );
    }
  }

  function renderSubmitButton() {
    if (showSubmitButton) {
      return (
        <Button colorScheme='blue' onClick={onSubmit}>{submitText}</Button>
      )
    }
  }
  return (
    <ChakraModal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent marginTop={'auto'} marginBottom={{ base: 0, md: 'auto' }}>
        <ModalHeader textAlign='center'>
          {title}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody maxHeight={{ base: '400px', md: '600px' }} overflow={scrollEnabled ? "scroll" : 'hidden'}>
          {content}
        </ModalBody>
        <ModalFooter>
          {renderCloseButton()}
          {renderSubmitButton()}
        </ModalFooter>
      </ModalContent>
    </ChakraModal>
  );
}
