import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ApplicationState } from 'reducers/types';
import { UserSelectors } from 'reducers/User/selectors';
import { Box } from '@chakra-ui/react';
import { RouterProps, withRouter } from "utils/route";
import { NavigationService } from 'services';
import { UserModel } from 'models';


export interface AuthenticatedContentUnconnectedProps {
  children: JSX.Element;
  onContentClick?: () => void;
}

export interface AuthenticatedContentProps extends RouterProps, AuthenticatedContentUnconnectedProps {
  user: UserModel;
}

function AuthenticatedContentUnconnected({ user, children, onContentClick, navigate }: AuthenticatedContentProps) {
  const onClick = () => {
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      )
    } else {
      onContentClick && onContentClick();
    }
  }

  return (
    <Box display={'contents'} onClick={onClick}>
      {children}
    </Box>
  )
}

function mapStateToProps(state: ApplicationState, ownProps: AuthenticatedContentUnconnectedProps) {
  return {
    user: UserSelectors.getUser(state),
    children: ownProps.children,
    onContentClick: ownProps.onContentClick
  };
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {},
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AuthenticatedContentUnconnected));
