import { Moment } from 'moment';

export enum ItemSaleSuggestionActionTypes {
  CREATE_ITEM_SALE_SUGGESTION = 'CREATE_ITEM_SALE_SUGGESTION',
  CREATE_ITEM_SALE_SUGGESTION_SUCCESS = 'CREATE_ITEM_SALE_SUGGESTION_SUCCESS',
  CREATE_ITEM_SALE_SUGGESTION_FAILURE = 'CREATE_ITEM_SALE_SUGGESTION_FAILURE',
};

export interface ItemSaleSuggestionActionPayload {
  id?: string;
  itemId?: string;
  price?: number;
  link?: string;
  date?: Moment;
  sourceId?: string;
};

export interface ItemSaleSuggestionAction {
  type: ItemSaleSuggestionActionTypes;
  payload?: ItemSaleSuggestionActionPayload;
};

export interface ItemSaleSuggestionStateLoading {
  create: boolean
};

export interface ItemSaleSuggestionState {
  error: Error | undefined;
  loading: ItemSaleSuggestionStateLoading;
};
