import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { ArtistModel } from 'models';

export class ArtistSelectors {
  public static getArtistList(state: ApplicationState): ArtistModel[] {
    return RootSelectors.getArtistState(state).artistList;
  }

  public static getArtist(state: ApplicationState): ArtistModel {
    return RootSelectors.getArtistState(state).artist;
  }

  public static getError(state: ApplicationState): Error | undefined {
    return RootSelectors.getArtistState(state).error;
  }

  public static getArtistLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getArtistState(state).loading.item;
  }

  public static getArtistListLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getArtistState(state).loading.list;
  }

  public static getArtistItemLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getArtistState(state).loading.itemList;
  }

  public static getArtistListingLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getArtistState(state).loading.listingList;
  }

  public static getArtistEditionIndexLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getArtistState(state).loading.editionIndex;
  }
}
