import { Dot } from 'recharts';
import { MetricModel } from 'models/Market/Metric';
import { ExtendedActiveDotProps } from '../types';

interface ActiveDotComponentProps extends ExtendedActiveDotProps {
	onClickHandler: (payload: MetricModel | undefined) => void;
}

export default function ActiveDot({ onClickHandler, payload, ...dotProps }: ActiveDotComponentProps) {
	return <Dot {...dotProps} r={8} onClick={() => onClickHandler(payload)} />;
}
