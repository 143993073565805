export enum FeatureToggleKeys {
  ITEM_DETAILS_DESCRIPTION = 'ITEM_DETAILS_DESCRIPTION',
  ITEM_DETAILS_ARTIST_BANNER = 'ITEM_DETAILS_ARTIST_BANNER',
  ITEM_DETAILS_MARKET_BUTTONS = 'ITEM_DETAILS_MARKET_BUTTONS',
  AUTH_OAUTH_BUTTONS = 'AUTH_OAUTH_BUTTONS',
  NAV_BAR_SEARCH_LISTINGS = 'NAV_BAR_SEARCH_LISTINGS',
  ARTIST_DETAILS_STATS = 'ARTIST_DETAILS_STATS',
  ITEM_CARD_LAST_SALE = 'ITEM_CARD_LAST_SALE'
}

export const FeatureToggleMap: Record<string, boolean> = {
  [FeatureToggleKeys.ITEM_DETAILS_DESCRIPTION]: false,
  [FeatureToggleKeys.ITEM_DETAILS_ARTIST_BANNER]: false,
  [FeatureToggleKeys.ITEM_DETAILS_MARKET_BUTTONS]: false,
  [FeatureToggleKeys.AUTH_OAUTH_BUTTONS]: false,
  [FeatureToggleKeys.NAV_BAR_SEARCH_LISTINGS]: false,
  [FeatureToggleKeys.ARTIST_DETAILS_STATS]: true,
  [FeatureToggleKeys.ITEM_CARD_LAST_SALE]: true
};
