import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { UserModel } from 'models';

export class AuthSelectors {
  public static getAuthenticatedUser(state: ApplicationState): UserModel {
    return RootSelectors.getAuthState(state).authUser;
  }

  public static getError(state: ApplicationState): Error | undefined {
    return RootSelectors.getAuthState(state).error;
  }

  public static getUserLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getAuthState(state).loading.user;
  }

  public static getUserLoginLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getAuthState(state).loading.login;
  }

  public static getUserAuthRefreshLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getAuthState(state).loading.refreshAuth;
  }

  public static getUserLogoutLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getAuthState(state).loading.logout;
  }

  public static getUserRegisterLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getAuthState(state).loading.register;
  }
}
