import { HomeFeedData } from 'models/Content/types';
import { HomeActionTypes, HomeAction } from './types';

export class HomeActions {
  public static getFeedContent(): HomeAction {
    return {
      type: HomeActionTypes.GET_FEED_CONTENT,
    };
  }
}

export class HomeInternalActions {
  public static getFeedContentSuccess(homeFeedData: HomeFeedData): HomeAction {
    return {
      type: HomeActionTypes.GET_FEED_CONTENT_SUCCESS,
      payload: { homeFeedData }
    };
  }

  public static getFeedContentFailure(): HomeAction {
    return {
      type: HomeActionTypes.GET_FEED_CONTENT_FAILURE,
    };
  }
}
