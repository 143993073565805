import { ItemModel, ListingModel, MarketModel, ArtistModel, PaginationModel } from 'models';
import { JSONObject } from 'models/Api/types';

export enum ItemActionTypes {
  GET_ITEM_BY_ID = 'GET_ITEM_BY_ID',
  GET_ITEM_BY_ID_SUCCESS = 'GET_ITEM_BY_ID_SUCCESS',
  GET_ITEM_BY_ID_FAILURE = 'GET_ITEM_BY_ID_FAILURE',
  GET_ALL_ITEMS = 'GET_ALL_ITEMS',
  GET_ALL_ITEMS_SUCCESS = 'GET_ALL_ITEMS_SUCCESS',
  GET_ALL_ITEMS_FAILURE = 'GET_ALL_ITEMS_FAILURE',
  GET_ITEM_LISTINGS = 'GET_ITEM_LISTINGS',
  GET_ITEM_LISTINGS_SUCCESS = 'GET_ITEM_LISTINGS_SUCCESS',
  GET_ITEM_LISTINGS_FAILURE = 'GET_ITEM_LISTINGS_FAILURE',
  GET_ITEM_MARKET = 'GET_ITEM_MARKET',
  GET_ITEM_MARKET_SUCCESS = 'GET_ITEM_MARKET_SUCCESS',
  GET_ITEM_MARKET_FAILURE = 'GET_ITEM_MARKET_FAILURE',
  GET_ITEM_ARTIST = 'GET_ITEM_ARTIST',
  GET_ITEM_ARTIST_SUCCESS = 'GET_ITEM_ARTIST_SUCCESS',
  GET_ITEM_ARTIST_FAILURE = 'GET_ITEM_ARTIST_FAILURE',
  SEARCH_ITEM = 'SEARCH_ITEM',
  SEARCH_ITEM_SUCCESS = 'SEARCH_ITEM_SUCCESS',
  SEARCH_ITEM_FAILURE = 'SEARCH_ITEM_FAILURE',
  SEARCH_ITEM_BY_QUERY = 'SEARCH_ITEM_BY_QUERY',
  SEARCH_ITEM_BY_QUERY_SUCCESS = 'SEARCH_ITEM_BY_QUERY_SUCCESS',
  SEARCH_ITEM_BY_QUERY_FAILURE = 'SEARCH_ITEM_BY_QUERY_FAILURE',
  GET_USER_FOLLOWING_ARTISTS_ITEMS = 'GET_USER_FOLLOWING_ARTISTS_ITEMS',
  GET_USER_FOLLOWING_ARTISTS_ITEMS_SUCCESS = 'GET_USER_FOLLOWING_ARTISTS_ITEMS_SUCCESS',
  GET_USER_FOLLOWING_ARTISTS_ITEMS_FAILURE = 'GET_USER_FOLLOWING_ARTISTS_ITEMS_FAILURE',
  GET_ITEM_SUGGESTIONS = 'GET_ITEM_SUGGESTIONS',
  GET_ITEM_SUGGESTIONS_SUCCESS = 'GET_ITEM_SUGGESTIONS_SUCCESS',
  GET_ITEM_SUGGESTIONS_FAILURE = 'GET_ITEM_SUGGESTIONS_FAILURE',

};

export interface ItemActionPayload {
  id?: string;
  searchQuery?: JSONObject;
  query?: string;
  page?: number;
  item?: ItemModel;
  market?: MarketModel;
  listings?: ListingModel[];
  artist?: ArtistModel;
  paginatedItems?: PaginationModel<ItemModel>;
  items?: ItemModel[];
};

export interface ItemAction {
  type: ItemActionTypes;
  payload?: ItemActionPayload;
};

export interface ItemStateLoading {
  item: boolean;
  market: boolean;
  listings: boolean;
  artist: boolean;
  search: boolean;
  items: boolean;
};

export interface ItemState {
  item: ItemModel;
  paginatedItems: PaginationModel<ItemModel>;
  items: ItemModel[];
  error: Error | undefined;
  loading: ItemStateLoading;
};
