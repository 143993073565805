import { useState } from 'react';
import {
  Text,
  Flex,
  Box,
  useToken
} from '@chakra-ui/react';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DetailedPortfolioItemModel, ArtistModel, ItemModel } from 'models';
import { ArtistDistributionTooltip } from './Tooltips';
import { DistributionActiveShape } from './Shapes';
import { ArtistLegend } from './Legends';

interface ArtistDistributionPieChartProps {
	items: (DetailedPortfolioItemModel | ItemModel)[];
  artists: ArtistModel[];
  showLegend?: boolean;
}

function ArtistDistributionPieChart({ items, artists, showLegend = false }: ArtistDistributionPieChartProps) {
  const [activeIndex, setActiveIndex] = useState(0);
  const brandColors = useToken(
    'colors',
    ['pink.200', 'purple.200', 'blue.200', 'green.200', 'yellow.200', 'orange.200', 'red.200']
  );

	const onPieEnter = (_: any, index: number) => {
    setActiveIndex(index);
  };

	const aggregateItemsByArtist = (itemList: (DetailedPortfolioItemModel | ItemModel)[]) => {
    const artistIdCounts: { [artistId: string]: number } = {};

    itemList.forEach((item: DetailedPortfolioItemModel | ItemModel) => {
			if (item instanceof ItemModel) {
				const artistId = item.artistId;
				artistIdCounts[artistId] = (artistIdCounts[artistId] || 0) + 1;
			} else {
        const artistId = item.item.artistId;
				const purchaseCount = item.getTotalPurchaseCount();
        artistIdCounts[artistId] = (artistIdCounts[artistId] || 0) + purchaseCount;
			}
    });

    return artistIdCounts;
};

const getTotalCount = (artistIdCounts: { [artistId: string]: number }) => {
    return Object.values(artistIdCounts).reduce((total, count) => total + count, 0);
};

const mapArtistIdsToNames = (artistIdCounts: { [artistId: string]: number }, artistModels: ArtistModel[], totalCount: number) => {
    return Object.keys(artistIdCounts).map((artistId) => {
        const artist = artistModels.find((artistModel) => artistModel.id === artistId);
        const count = artistIdCounts[artistId];
        const percentage = (count / totalCount) * 100;
        return {
            data: artist,
            value: count,
            percentage: percentage.toFixed(2)
        };
    });
};

const artistIdCounts = aggregateItemsByArtist(items);
const totalCount = getTotalCount(artistIdCounts);
const pieChartData = mapArtistIdsToNames(artistIdCounts, artists, totalCount);

  const colors = pieChartData.map((_, index) => brandColors[index % brandColors.length]);

  return (
    <Card p='28px 0px 0px 0px' borderRadius='8px'>
      <CardHeader mb='20px'>
        <Flex direction='column' alignSelf='flex-start'>
          <Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
            Artist Distribution
          </Text>
        </Flex>
      </CardHeader>
      <Box w='100%' justifyContent="center" alignItems="center">
        <ResponsiveContainer width="100%" height={400}>
          <PieChart>
            <Pie
              activeIndex={activeIndex}
              activeShape={DistributionActiveShape}
              data={pieChartData}
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={150}
              fill="#8884d8"
              dataKey="value"
							nameKey="name"
              onMouseEnter={onPieEnter}
            >
              {pieChartData.map((entry, index) => (
                <Cell name={entry.data?.name} key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
            <Tooltip content={<ArtistDistributionTooltip />} />
						{showLegend && <Legend content={ArtistLegend} />}
          </PieChart>
        </ResponsiveContainer>
      </Box>
    </Card>
  );
};

export default ArtistDistributionPieChart;