import {
	Box,
	Stack,
	StackDivider,
	Heading,
	Text,
	useColorModeValue
} from '@chakra-ui/react';
import { MetricModel } from 'models/Market/Metric';
import { toDollarFormat } from 'utils/format';
import { MarketTooltipData } from '../types';

interface MarketPriceTooltipProps extends MarketTooltipData {};

export default function MarketPriceTooltip({ payload, label, active = false }: MarketPriceTooltipProps) {
	if (!active || !payload || !payload.length) return null;
	const data: MetricModel = payload[0].payload;
	if (!data.totalSales) return null;

	return (
		<Box borderWidth="1px" borderRadius="lg" p={4} shadow="md" backgroundColor={useColorModeValue("white", "black")} zIndex={10}>
			<Box mb={4}>
				<Heading fontSize='sm'>{label}</Heading>
			</Box>
			<Stack divider={<StackDivider />} spacing='4' textAlign={'left'}>
			<Box>
					<Text fontSize='xs' textTransform='uppercase'>
						Total Sales
					</Text>
					<Heading pt='2' fontSize='sm'>
						{data.totalListings}
					</Heading>
				</Box>
				<Box>
					<Text fontSize='xs' textTransform='uppercase'>
						Mean
					</Text>
					<Heading pt='2' fontSize='sm'>
						{toDollarFormat(data.mean, false)}
					</Heading>
				</Box>
				<Box>
					<Text fontSize='xs' textTransform='uppercase'>
						Open / Close
					</Text>
					<Heading pt='2' fontSize='sm'>
						{toDollarFormat(data.open, false)} / {toDollarFormat(data.close, false)}
					</Heading>
				</Box>
			</Stack>
		</Box>
	);
}
