import { Box, Heading, useColorModeValue, Stack, StackDivider, Text } from '@chakra-ui/react';
import BasicArtistCard from '../../Artist/BasicArtistCard/BasicArtistCard';
import { ArtistModel } from 'models';
import { ArtistTooltipData } from '../types';

interface ArtistDistributionTooltipProps extends ArtistTooltipData { };

export default function ArtistDistributionTooltip(props: ArtistDistributionTooltipProps) {
  const { payload, active, label } = props;
  if (!active || !payload || payload.length === 0) return null;
  
  const artistData: ArtistModel = payload?.[0]?.payload?.data;
  const value = payload?.[0]?.value;
  const percentage = payload?.[0].payload?.percentage;

  if (artistData) {
    return (
      <Box borderWidth="1px" borderRadius="lg" p={4} shadow="md" backgroundColor={useColorModeValue("white", "black")} zIndex={10}>
        {!!label && <Box mb={4}>
          <Heading size='md'>{label}</Heading>
        </Box>}
        <Stack divider={<StackDivider />} spacing='4' textAlign={'left'}>
          <BasicArtistCard
            artist={artistData}
            showBorder={false}
            showButton={false}
            showAdditionalInfo={true} />
          {!!percentage && (
            <Box>
              <Text fontSize='xs' textTransform='uppercase'>
                Percentage
              </Text>
              <Heading pt='2' fontSize='sm'>
                {percentage}%
              </Heading>
            </Box>
          )}
          <Box>
            <Text fontSize='xs' textTransform='uppercase'>
              Total Items
            </Text>
            <Heading pt='2' fontSize='sm'>
              {value}
            </Heading>
          </Box>
        </Stack>
      </Box>
    );
  } else {
    return null;
  }
};