import { Box, Heading, Stack, Text, Button, Icon, Avatar, useColorModeValue } from '@chakra-ui/react';
import ActionButton from '../Button/ActionButton';
import emptyStateGif from '../../assets/gif/emptystate.gif';

export interface EmptyStateProps {
  header?: string;
  description?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  showButton?: boolean;
  showAvatar?: boolean;
}

export default function EmptyState({ header = 'No Data', description = '', buttonText = '', onButtonClick = () => {}, showButton = false, showAvatar = true }: EmptyStateProps) {
  function renderAvatar() {
    if (showAvatar) {
      return (
        <Avatar src={emptyStateGif} size="xl" marginBottom="24px" />
      );
    }
  }

  function renderDescription() {
    if (description) {
      return (
        <Text>{description}</Text>
      );
    }
  }

  function renderButton() {
    if (showButton) {
      return <ActionButton text={buttonText} onClick={onButtonClick} />;
    }
  }


  return (
    <Box
      backgroundColor={useColorModeValue("white","black")}
      ml={0}
      mr={0}
      borderRadius={8}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
    >
      <Box
        height="40px"
      />
      <Stack alignItems="center" justifyContent="center" paddingLeft={16} paddingRight={16} paddingBottom={16} spacing={6}>
        <Stack
          spacing={2}
          borderRadius={8}
          alignItems="center"
          justifyContent="center"
        >
          {renderAvatar()}
          <Heading size="lg">{header}</Heading>
          {renderDescription()}
        </Stack>
        {renderButton()}
      </Stack>
    </Box>
  )
}
