import { ItemSaleSuggestionActionTypes, ItemSaleSuggestionAction } from './types';
import { Moment } from 'moment'

export class ItemSaleSuggestionActions {
  public static createItemSaleSuggestion(itemId: string, price: number, link: string, date: Moment, sourceId: string): ItemSaleSuggestionAction {
    return {
      type: ItemSaleSuggestionActionTypes.CREATE_ITEM_SALE_SUGGESTION,
      payload: { itemId, price, link, date, sourceId },
    };
  }
}

export class ItemSaleSuggestionInternalActions {
  public static createItemSaleSuggestionSuccess(id: string): ItemSaleSuggestionAction {
    return {
      type: ItemSaleSuggestionActionTypes.CREATE_ITEM_SALE_SUGGESTION_SUCCESS,
      payload: { id },
    };
  }

  public static createItemSaleSuggestionFailure(): ItemSaleSuggestionAction {
    return { type: ItemSaleSuggestionActionTypes.CREATE_ITEM_SALE_SUGGESTION_FAILURE };
  }
}
