import { UrlConfig } from 'config';
import { ApiResponse } from 'models/Api/ApiResponse';
import { AjaxService } from 'services';
import { Moment } from 'moment';

export class ItemSaleSuggestionApiClient {
  public static async createItemSaleSuggestion(itemId: string, price: number, link: string, date: Moment, sourceId: string): Promise<string> {
    const url = UrlConfig.ITEM_SALE_SUGGESTION.ITEM_SALE_SUGGESTION_CREATE;
    const response: ApiResponse<string> = await AjaxService.postData(url, {
      item_id: itemId,
      raw_price: Number(price),
      link: link,
      date: date.format(),
      source_id: sourceId
    });
    return response.data;
  }
}
