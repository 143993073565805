import { UrlConfig } from 'config';
import { UserModel } from 'models';
import { RawUserData } from 'models/User/User';
import { AuthData } from 'models/Auth/types';
import { ApiResponse } from 'models/Api/ApiResponse';
import { AjaxService, CredentialService } from 'services';

export class AuthApiClient {
  public static async getAuthenticatedUser(): Promise<UserModel> {
    const url = UrlConfig.USER.BASE;
    const response: ApiResponse<RawUserData> = await AjaxService.getData(url);
    const user = new UserModel(response.data);
    return user;
  }

  public static async registerUser(email: string, username: string, password: string): Promise<AuthData> {
    const url = UrlConfig.AUTH.REGISTER;
    const response: ApiResponse<AuthData> = await AjaxService.postData(url, {
      email: email,
      user_name: username,
      password: password
    });

    CredentialService.setAuthToken(response.data.token);

    if (response.data.refresh) {
      CredentialService.setRefreshToken(response.data.refresh);
    }

    return response.data;
  }

  public static async loginByEmail(email: string, password: string): Promise<AuthData> {
    const url = UrlConfig.AUTH.LOGIN;
    const response: ApiResponse<AuthData> = await AjaxService.postData(url, {
      email,
      password
    });

    CredentialService.setAuthToken(response.data.token);

    if (response.data.refresh) {
      CredentialService.setRefreshToken(response.data.refresh);
    }

    return response.data;
  }

  public static async refreshAuthToken(): Promise<AuthData> {
    const url = UrlConfig.AUTH.REFRESH;
    const response: ApiResponse<AuthData> = await AjaxService.postData(url, {}, true, false, true);
    CredentialService.setAuthToken(response.data.token);
    if (response.data.refresh) {
      CredentialService.setRefreshToken(response.data.refresh);
    }
    return response.data;
  }

  public static async logout(): Promise<void> {
    CredentialService.deleteAuthToken();
    CredentialService.deleteRefreshToken();
  }

  public static async isEmailAvailable(email: string): Promise<boolean> {
    const url = UrlConfig.AUTH.EMAIL_AVAILABLE;
    const response: ApiResponse<boolean> = await AjaxService.postData(url, {
      email: email,
    });
    return response.data;
  }

  public static async isEmailWhitelisted(email: string): Promise<boolean> {
    const url = UrlConfig.AUTH.EMAIL_WHITELISTED;
    const response: ApiResponse<boolean> = await AjaxService.postData(url, {
      email: email,
    });
    return response.data;
  }

  public static async isUsernameAvailable(username: string): Promise<boolean> {
    const url = UrlConfig.AUTH.USERNAME_AVAILABLE;
    const response: ApiResponse<boolean> = await AjaxService.postData(url, {
      username: username,
    });
    return response.data;
  }
}
