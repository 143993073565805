import { UserModel, PortfolioModel, WishlistModel, ArtistModel } from 'models';
import { JSONObject } from 'models/Api/types';

export enum UserActionTypes {
  SET_USER = 'SET_USER',
  CLEAR_USER = 'CLEAR_USER',

  GET_USER_FOLLOWING_ARTISTS = 'GET_USER_FOLLOWING_ARTISTS',
  GET_USER_FOLLOWING_ARTISTS_SUCCESS = 'GET_USER_FOLLOWING_ARTISTS_SUCCESS',
  GET_USER_FOLLOWING_ARTISTS_FAILURE = 'GET_USER_FOLLOWING_ARTISTS_FAILURE',
  FOLLOW_ARTIST = 'FOLLOW_ARTIST',
  FOLLOW_ARTIST_SUCCESS = 'FOLLOW_ARTIST_SUCCESS',
  FOLLOW_ARTIST_FAILURE = 'FOLLOW_ARTIST_FAILURE',
  UNFOLLOW_ARTIST = 'UNFOLLOW_ARTIST',
  UNFOLLOW_ARTIST_SUCCESS = 'UNFOLLOW_ARTIST_SUCCESS',
  UNFOLLOW_ARTIST_FAILURE = 'UNFOLLOW_ARTIST_FAILURE',

  GET_USER_PORTFOLIO = 'GET_USER_PORTFOLIO',
  GET_USER_PORTFOLIO_SUCCESS = 'GET_USER_PORTFOLIO_SUCCESS',
  GET_USER_PORTFOLIO_FAILURE = 'GET_USER_PORTFOLIO_FAILURE',

  GET_USER_WISHLIST = 'GET_USER_WISHLIST',
  GET_USER_WISHLIST_SUCCESS = 'GET_USER_WISHLIST_SUCCESS',
  GET_USER_WISHLIST_FAILURE = 'GET_USER_WISHLIST_FAILURE',

  MIGRATE_USER_IMAGE = 'MIGRATE_USER_IMAGE',
  MIGRATE_USER_IMAGE_SUCCESS = 'MIGRATE_USER_IMAGE_SUCCESS',
  MIGRATE_USER_IMAGE_FAILURE = 'MIGRATE_USER_IMAGE_FAILURE',

  CHANGE_USER_PASSWORD = 'CHANGE_USER_PASSWORD',
  CHANGE_USER_PASSWORD_SUCCESS = 'CHANGE_USER_PASSWORD_SUCCESS',
  CHANGE_USER_PASSWORD_FAILURE = 'CHANGE_USER_PASSWORD_FAILURE',

  SEND_CONFIRMATION_EMAIL = 'SEND_CONFIRMATION_EMAIL',
  SEND_CONFIRMATION_EMAIL_SUCCESS = 'SEND_CONFIRMATION_EMAIL_SUCCESS',
  SEND_CONFIRMATION_EMAIL_FAILURE = 'SEND_CONFIRMATION_EMAIL_FAILURE',

  SEND_FORGOT_PASSWORD_EMAIL = 'SEND_FORGOT_PASSWORD_EMAIL',
  SEND_FORGOT_PASSWORD_EMAIL_SUCCESS = 'SEND_FORGOT_PASSWORD_EMAIL_SUCCESS',
  SEND_FORGOT_PASSWORD_EMAIL_FAILURE = 'SEND_FORGOT_PASSWORD_EMAIL_FAILURE',

  CONFIRM_EMAIL = 'CONFIRM_EMAIL',
  CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS',
  CONFIRM_EMAIL_FAILURE = 'CONFIRM_EMAIL_FAILURE',

  CHANGE_FORGOTTEN_PASSWORD = 'CHANGE_FORGOTTEN_PASSWORD',
  CHANGE_FORGOTTEN_PASSWORD_SUCCESS = 'CHANGE_FORGOTTEN_PASSWORD_SUCCESS',
  CHANGE_FORGOTTEN_PASSWORD_FAILURE = 'CHANGE_FORGOTTEN_PASSWORD_FAILURE',

  JOIN_USER_WAITLIST = 'JOIN_USER_WAITLIST',
  JOIN_USER_WAITLIST_SUCCESS = 'JOIN_USER_WAITLIST_SUCCESS',
  JOIN_USER_WAITLIST_FAILURE = 'JOIN_USER_WAITLIST_FAILURE',

  SEND_INBOUND_CONTACT_REQUEST = 'SEND_INBOUND_CONTACT_REQUEST',
  SEND_INBOUND_CONTACT_REQUEST_SUCCESS = 'SEND_INBOUND_CONTACT_REQUEST_SUCCESS',
  SEND_INBOUND_CONTACT_REQUEST_FAILURE = 'SEND_INBOUND_CONTACT_REQUEST_FAILURE',

  UPDATE_USER_DATA = 'UPDATE_USER_DATA',
  UPDATE_USER_DATA_SUCCESS = 'UPDATE_USER_DATA_SUCCESS',
  UPDATE_USER_DATA_FAILURE = 'UPDATE_USER_DATA_FAILURE'
};

export interface UserActionPayload {
  user?: UserModel;
  id?: string;
  itemId?: string;
  artistId?: string;
  email?: string;
  name?: string;
  message?: string;
  portfolio?: PortfolioModel;
  wishlist?: WishlistModel;
  artistList?: ArtistModel[];
  imageUrl?: string;
  oldPassword?: string;
  newPassword?: string;
  token?: string;
  updatedData?: JSONObject;
};

export interface UserAction {
  type: UserActionTypes;
  payload?: UserActionPayload;
};

export interface UserStateLoading {
  user: boolean;
  portfolio: boolean;
  wishlist: boolean;
  followingArtists: boolean;
  wishlistAction: boolean;
  artistAction: boolean;
  imageAction: boolean;
  passwordAction: boolean;
  confirmationAction: boolean;
  mailAction: boolean;
  waitlistAction: boolean;
  updateAction: boolean;
};

export interface UserState {
  user: UserModel;
  error: Error | undefined;
  loading: UserStateLoading;
};
