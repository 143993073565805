import { UrlConfig } from 'config';
import { AjaxService } from 'services';

export class WaitlistApiClient {
  public static async joinUserWaitlist(email: string): Promise<void> {
    const url = UrlConfig.WAITLIST.CREATE;
    await AjaxService.postData(url, {
      email
    });
  }
}
