import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { WishlistModel } from 'models';

export class WishlistSelectors {
  public static getWishlist(state: ApplicationState): WishlistModel {
    return RootSelectors.getWishlistState(state).wishlist;
  }

  public static getError(state: ApplicationState): Error | undefined {
    return RootSelectors.getWishlistState(state).error;
  }

  public static getWishlistLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getWishlistState(state).loading.item;
  }

  public static getWishlistActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getWishlistState(state).loading.action;
  }

  public static getWishlistArtistsLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getWishlistState(state).loading.artists;
  }

  public static getWishlistMarketLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getWishlistState(state).loading.market;
  }

  public static getWishlistListingsLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getWishlistState(state).loading.listings;
  }
}
