import { Cloneable } from '../Utils/Cloneable';

export interface RawPaginationData<T> {
  current_page?: number;
  per_page?: number;
  total_count?: number;
  total_pages?: number;
  data?: T[];
  fetched?: boolean;
}

export class PaginationModel<T> extends Cloneable {
  currentPage: number;
  perPage: number;
  totalCount: number;
  totalPages: number;
  data: T[];
  fetchedData: boolean;

  constructor(rawPaginationData?: RawPaginationData<T>) {
    super();
    this.currentPage = rawPaginationData?.current_page || 0;
    this.perPage = rawPaginationData?.per_page || 0;
    this.totalCount = rawPaginationData?.total_count || 0;
    this.totalPages = rawPaginationData?.total_pages || 0;
    this.data = rawPaginationData?.data || [];
    this.fetchedData = rawPaginationData?.fetched || false;
  }

  public setData(data: T[]): void {
    this.data = data;
  }

  public addToData(data: T[]): void {
    this.data = [...this.data, ...data];
  }

  public hasMorePages(): boolean {
    return this.currentPage !== this.totalPages;
  }

  public hasData(): boolean {
    return this.data.length > 0;
  }

  public hasFetchedData(): boolean {
    return this.fetchedData;
  }
}
