import {
  Flex,
  Heading,
  Button,
  Box
} from '@chakra-ui/react';
import { Step, Steps, useSteps } from "chakra-ui-steps";
import Loader from '../Loader/Loader';
import { AiFillCheckCircle } from "react-icons/ai";

export interface WizardStep {
  label: string;
  description?: string;
  content: JSX.Element;
  requiresValidation?: boolean;
  isValidated?: boolean;
}

export interface WizardProps {
  steps: WizardStep[];
  finishStep?: JSX.Element;
  showButtons?: boolean;
  onSubmit?: () => void;
  loading?: boolean;
}

export default function Wizard({ steps, finishStep, showButtons = true, onSubmit = () => {}, loading = false }: WizardProps) {
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const isLastStep = activeStep === steps.length - 1;
  const hasCompletedAllSteps = activeStep === steps.length;

  const isNextButtonDisabled = () => {
    const currentStep = steps[activeStep];
    return currentStep.requiresValidation && !currentStep.isValidated;
  };

  function renderFinishingStep() {
    if (finishStep) {
      return finishStep;
    } else {
      return (
        <Box sx={{ my: 8, p: 8, rounded: "md" }}>
          <Heading fontSize="xl" textAlign="center">
            All steps completed!
          </Heading>
        </Box>
      )
    }
  }

  function renderStep(content: JSX.Element) {
    if (loading) {
      return (
        <Flex justifyContent={'center'} marginTop={{base: 0, md: 4}} marginBottom={4}>
          <Loader />
        </Flex>
      );
    } else {
      return (
        <Box rounded={'md'} marginTop={{base: 0, md: 4}} marginBottom={4}>
          {content}
        </Box>
      );
    }
  }

  function renderButtonContainer() {
    if (showButtons) {
      return (
        <Flex width="100%" justify="flex-end" gap={4}>
          <Button
            isDisabled={activeStep === 0}
            onClick={prevStep}
            size="sm"
            variant="ghost"
          >
            Prev
          </Button>
          <Button size="sm" onClick={isLastStep ? onSubmit : nextStep} isDisabled={isNextButtonDisabled()}>
            {isLastStep ? "Finish" : "Next"}
          </Button>
        </Flex>
      );
    }
  }

  return (
    <Flex flexDir="column" width="100%">
      <Steps
        checkIcon={AiFillCheckCircle}
        variant="circles"
        colorScheme="blue"
        activeStep={activeStep}
        labelOrientation={'vertical'}
        size={'sm'}
      >
        {steps.map(({ label, description, content }: WizardStep) => (
          <Step label={label} key={label} description={description}>
            {renderStep(content)}
          </Step>
        ))}
      </Steps>
      {hasCompletedAllSteps ? renderFinishingStep() : renderButtonContainer()}
    </Flex>
  );
}
