import { SalesVenueModel } from 'models';
import { SalesVenueState } from './types';

export const defaultState: SalesVenueState = {
  salesVenue: new SalesVenueModel(),
  salesVenueList: [],
  error: undefined,
  loading: {
    item: false,
    list: false
  }
};
