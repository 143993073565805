export class AppConfig {
  static API = {
    CORE_URL: 'https://api.artcore.tech',
    LOCAL_URL: 'http://0.0.0.0:4000'
  };

  static APP_NAME: string = 'Artcore';
  static CONTACT_EMAIL: string = 'contact@artcore.tech';
  static CONTACT_INSTAGRAM: string = 'artcore.tech';
  static DEFAULT_COUNTRY_CODE: string = 'us';
}
