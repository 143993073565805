import { Badge, Flex, Spinner } from '@chakra-ui/react';

export interface RefreshBadge {
  label?: string;
}

export default function RefreshBadge({ label }: RefreshBadge) {
  return (
    <Badge padding="2px 4px" borderRadius={4} width={'fit-content'} height={'fit-content'} colorScheme={'orange'}>
      <Flex direction="row" gap={2} alignItems="center">
        <Spinner w={3} h={3} />
        {label || 'Refreshing'}
      </Flex>
    </Badge>
  );
};
