import { Cmd, loop, Loop } from 'redux-loop';
import { UserState, UserAction } from './types';
import { UserActions, UserInternalActions } from './actions';
import { defaultState } from './consts';
import { UserModel, PortfolioModel, WishlistModel, ArtistModel } from 'models';
import { PortfolioApiClient, WishlistApiClient, UserApiClient, ArtistApiClient, WaitlistApiClient } from 'api';

export enum UserStateErrors {
  PORTFOLIO_FETCH = 'Unable to fetch user portfolio',
  WISHLIST_FETCH = 'Unable to fetch user wishlist',
  FOLLOWING_ARTIST_FETCH = 'Unable to fetch user following artists',
  ADD_ITEM_TO_PORTFOLIO = 'Unable to add item to user portfolio',
  REPLACE_ITEM_IN_PORTFOLIO = 'Unable to replace item in user portfolio',
  REMOVE_ITEM_FROM_PORTFOLIO = 'Unable to remove item from user portfolio',
  ADD_ITEM_TO_WISHLIST = 'Unable to add item to user wishlist',
  REMOVE_ITEM_FROM_WISHLIST = 'Unable to remove item from user wishlist',
  FOLLOW_ARTIST = 'Unable to follow artist',
  UNFOLLOW_ARTIST = 'Unable to unfollow artist',
  MIGRATE_IMAGE = 'Unable to migrate user image',
  CHANGE_PASSWORD = 'Unable to change user password',
  SEND_CONFIRMATION_EMAIL = 'Unable to send confirmation email',
  SEND_FORGOT_PASSWORD_EMAIL = 'Unable to process forgot password request',
  CONFIRM_EMAIL = 'Unable to confirm user email',
  JOIN_WAITLIST = 'Unable to join waitlist',
  SEND_CONTACT_REQUEST = 'Unable to send contact request',
  UPDATE_USER = 'Unable to update user data'
}

export class UserHandlers {
  public static handleSetUser(state: UserState, action: UserAction): Loop<UserState, UserAction> {
    const user: UserModel = action.payload?.user || defaultState.user;
    const newState = {
      ...state,
      user
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(UserActions.getUserPortfolio(user.getPortfolioId())),
        Cmd.action(UserActions.getUserWishlist(user.getWishlistId())),
        Cmd.action(UserActions.getUserFollowingArtists())
      ])
    );
  }

  public static handleClearUser(state: UserState): UserState {
    return {
      ...state,
      user: defaultState.user
    };
  }

  public static handleGetUserPortfolio(state: UserState): Loop<UserState, UserAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        portfolio: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.getPortfolio, {
        args: [],
        successActionCreator: UserInternalActions.getUserPortfolioSuccess,
        failActionCreator: UserInternalActions.getUserPortfolioFailure,
      })
    );
  }

  public static handleGetUserPortfolioSuccess(state: UserState, action: UserAction): UserState {
    const { user } = state;
    const portfolio: PortfolioModel = action.payload?.portfolio || state.user.portfolio;
    const newUser = UserModel.deepCopy(user);
    newUser.setPortfolio(portfolio);

    return {
      ...state,
      user: newUser,
      loading: {
        ...state.loading,
        portfolio: false
      }
    };
  }

  public static handleGetUserPortfolioFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.PORTFOLIO_FETCH),
      loading: {
        ...state.loading,
        portfolio: false
      }
    };
  }

  public static handleGetUserWishlist(state: UserState): Loop<UserState, UserAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        wishlist: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.getWishlist, {
        args: [],
        successActionCreator: UserInternalActions.getUserWishlistSuccess,
        failActionCreator: UserInternalActions.getUserWishlistFailure,
      })
    );
  }

  public static handleGetUserWishlistSuccess(state: UserState, action: UserAction): UserState {
    const { user } = state;
    const wishlist: WishlistModel = action.payload?.wishlist || state.user.wishlist;
    const newUser = UserModel.deepCopy(user);
    newUser.setWishlist(wishlist);

    return {
      ...state,
      user: newUser,
      loading: {
        ...state.loading,
        wishlist: false
      }
    };
  }

  public static handleGetUserWishlistFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.WISHLIST_FETCH),
      loading: {
        ...state.loading,
        wishlist: false
      }
    };
  }


  public static handleGetUserFollowingArtists(state: UserState): Loop<UserState, UserAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        followingArtists: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.getFollowingArtists, {
        args: [],
        successActionCreator: UserInternalActions.getUserFollowingArtistsSuccess,
        failActionCreator: UserInternalActions.getUserFollowingArtistsFailure,
      })
    );
  }

  public static handleGetUserFollowingArtistsSuccess(state: UserState, action: UserAction): UserState {
    const { user } = state;
    const artists: ArtistModel[] = action.payload?.artistList || [];
    const newUser = UserModel.deepCopy(user);
    newUser.setFollowingArtists(artists);

    return {
      ...state,
      user: newUser,
      loading: {
        ...state.loading,
        followingArtists: false
      }
    };
  }

  public static handleGetUserFollowingArtistsFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.FOLLOWING_ARTIST_FETCH),
      loading: {
        ...state.loading,
        followingArtists: false
      }
    };
  }

  public static handleFollowArtist(state: UserState, action: UserAction): Loop<UserState, UserAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        artistAction: true
      }
    };

    return loop(
      newState,
      Cmd.run(ArtistApiClient.followArtist, {
        args: [payload?.artistId || ''],
        successActionCreator: UserInternalActions.followArtistSuccess,
        failActionCreator: UserInternalActions.followArtistFailure,
      })
    );
  }

  public static handleFollowArtistSuccess(state: UserState): Loop<UserState, UserAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        artistAction: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(UserActions.getUserFollowingArtists())
      ])
    );
  }

  public static handleFollowArtistFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.FOLLOW_ARTIST),
      loading: {
        ...state.loading,
        artistAction: false
      }
    };
  }

  public static handleUnfollowArtist(state: UserState, action: UserAction): Loop<UserState, UserAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        artistAction: true
      }
    };

    return loop(
      newState,
      Cmd.run(ArtistApiClient.unfollowArtist, {
        args: [payload?.artistId || ''],
        successActionCreator: UserInternalActions.unfollowArtistSuccess,
        failActionCreator: UserInternalActions.unfollowArtistFailure,
      })
    );
  }

  public static handleUnfollowArtistSuccess(state: UserState): Loop<UserState, UserAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        artistAction: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(UserActions.getUserFollowingArtists())
      ])
    );
  }

  public static handleUnfollowArtistFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.UNFOLLOW_ARTIST),
      loading: {
        ...state.loading,
        artistAction: false
      }
    };
  }

  public static handleMigrateImage(state: UserState, action: UserAction): Loop<UserState, UserAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        imageAction: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.migrateImage, {
        args: [payload?.imageUrl || ''],
        successActionCreator: UserInternalActions.migrateImageSuccess,
        failActionCreator: UserInternalActions.migrateImageFailure,
      })
    );
  }

  public static handleMigrateImageSuccess(state: UserState, action: UserAction): UserState {
    const { user } = state;
    const newImageUrl: string = action.payload?.imageUrl || '';
    const newUser = UserModel.deepCopy(user);
    newUser.setImage(newImageUrl);

    return {
      ...state,
      user: newUser,
      loading: {
        ...state.loading,
        imageAction: false
      }
    };
  }

  public static handleMigrateImageFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.MIGRATE_IMAGE),
      loading: {
        ...state.loading,
        imageAction: false
      }
    };
  }

  public static handleChangePassword(state: UserState, action: UserAction): Loop<UserState, UserAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        passwordAction: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.changePassword, {
        args: [payload?.oldPassword || '', payload?.newPassword || ''],
        successActionCreator: UserInternalActions.changePasswordSuccess,
        failActionCreator: UserInternalActions.changePasswordFailure,
      })
    );
  }

  public static handleChangePasswordSuccess(state: UserState): UserState {
    return {
      ...state,
      loading: {
        ...state.loading,
        passwordAction: false
      }
    };
  }

  public static handleChangePasswordFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.CHANGE_PASSWORD),
      loading: {
        ...state.loading,
        passwordAction: false
      }
    };
  }


  public static handleSendConfirmationEmail(state: UserState): Loop<UserState, UserAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        mailAction: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.sendConfirmationEmail, {
        args: [],
        successActionCreator: UserInternalActions.sendConfirmationEmailSuccess,
        failActionCreator: UserInternalActions.sendConfirmationEmailFailure,
      })
    );
  }

  public static handleSendConfirmationEmailSuccess(state: UserState): UserState {
    return {
      ...state,
      loading: {
        ...state.loading,
        mailAction: false
      }
    };
  }

  public static handleSendConfirmationEmailFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.SEND_CONFIRMATION_EMAIL),
      loading: {
        ...state.loading,
        mailAction: false
      }
    };
  }

  public static handleSendForgotPasswordEmail(state: UserState, action: UserAction): Loop<UserState, UserAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        mailAction: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.sendUserForgotPasswordEmail, {
        args: [payload?.email || ''],
        successActionCreator: UserInternalActions.sendForgotPasswordEmailSuccess,
        failActionCreator: UserInternalActions.sendForgotPasswordEmailFailure,
      })
    );
  }

  public static handleSendForgotPasswordEmailSuccess(state: UserState): UserState {
    return {
      ...state,
      loading: {
        ...state.loading,
        mailAction: false
      }
    };
  }

  public static handleSendForgotPasswordEmailFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.SEND_FORGOT_PASSWORD_EMAIL),
      loading: {
        ...state.loading,
        mailAction: false
      }
    };
  }

  public static handleConfirmEmail(state: UserState, action: UserAction): Loop<UserState, UserAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        confirmationAction: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.confirmUserEmail, {
        args: [payload?.token || ''],
        successActionCreator: UserInternalActions.confirmEmailSuccess,
        failActionCreator: UserInternalActions.confirmEmailFailure,
      })
    );
  }

  public static handleConfirmEmailSuccess(state: UserState, action: UserAction): Loop<UserState, UserAction> | UserState {
    const { payload } = action;
    const user: UserModel | undefined = payload?.user;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        confirmationAction: false
      }
    };

    if (user) {
      return loop(
        newState,
        Cmd.list([
          Cmd.action(UserActions.setUser(user))
        ])
      );
    } else {
      return newState;
    }
  }

  public static handleConfirmEmailFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.CONFIRM_EMAIL),
      loading: {
        ...state.loading,
        confirmationAction: false
      }
    };
  }

  public static handleChangeForgottenPassword(state: UserState, action: UserAction): Loop<UserState, UserAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        passwordAction: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.changeUserForgottenPassword, {
        args: [payload?.token || '', payload?.newPassword || ''],
        successActionCreator: UserInternalActions.changeForgottenPasswordSuccess,
        failActionCreator: UserInternalActions.changeForgottenPasswordFailure,
      })
    );
  }

  public static handleChangeForgottenPasswordSuccess(state: UserState): UserState {
    return {
      ...state,
      loading: {
        ...state.loading,
        passwordAction: false
      }
    };
  }

  public static handleChangeForgottenPasswordFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.CHANGE_PASSWORD),
      loading: {
        ...state.loading,
        passwordAction: false
      }
    };
  }


  public static handleJoinUserWaitlist(state: UserState, action: UserAction): Loop<UserState, UserAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        waitlistAction: true
      }
    };

    return loop(
      newState,
      Cmd.run(WaitlistApiClient.joinUserWaitlist, {
        args: [payload?.email || ''],
        successActionCreator: UserInternalActions.joinUserWaitlistSuccess,
        failActionCreator: UserInternalActions.joinUserWaitlistFailure,
      })
    );
  }

  public static handleJoinUserWaitlistSuccess(state: UserState): UserState {
    return {
      ...state,
      loading: {
        ...state.loading,
        waitlistAction: false
      }
    };
  }

  public static handleJoinUserWaitlistFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.JOIN_WAITLIST),
      loading: {
        ...state.loading,
        waitlistAction: false
      }
    };
  }

  public static handleSendInboundContactRequest(state: UserState, action: UserAction): Loop<UserState, UserAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        mailAction: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.sendInboundContactRequest, {
        args: [payload?.email || '', payload?.name || '', payload?.message || ''],
        successActionCreator: UserInternalActions.sendInboundContactRequestSuccess,
        failActionCreator: UserInternalActions.sendInboundContactRequestFailure,
      })
    );
  }

  public static handleSendInboundContactRequestSuccess(state: UserState): UserState {
    return {
      ...state,
      loading: {
        ...state.loading,
        mailAction: false
      }
    };
  }

  public static handleSendInboundContactRequestFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.SEND_CONTACT_REQUEST),
      loading: {
        ...state.loading,
        mailAction: false
      }
    };
  }

  public static handleUpdateUserData(state: UserState, action: UserAction): Loop<UserState, UserAction> | UserState {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        updateAction: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.updateUser, {
        args: [payload?.updatedData || {}],
        successActionCreator: UserInternalActions.updateUserDataSuccess,
        failActionCreator: UserInternalActions.updateUserDataFailure,
      })
    );
  }


  public static handleUpdateUserDataSuccess(state: UserState, action: UserAction): Loop<UserState, UserAction> | UserState {
    const { payload } = action;
    const user: UserModel | undefined = payload?.user;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        updateAction: false
      }
    };

    if (user) {
      return loop(
        newState,
        Cmd.list([
          Cmd.action(UserActions.setUser(user))
        ])
      );
    } else {
      return newState;
    }
  }

  public static handleUpdateUserDataFailure(state: UserState): UserState {
    return {
      ...state,
      error: new Error(UserStateErrors.UPDATE_USER),
      loading: {
        ...state.loading,
        updateAction: false
      }
    };
  }
}
