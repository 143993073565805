import { Moment } from 'moment';

export enum ItemListingGroupingActionTypes {
  CREATE_ITEM_LISTING_GROUPING = 'CREATE_ITEM_LISTING_GROUPING',
  CREATE_ITEM_LISTING_GROUPING_SUCCESS = 'CREATE_ITEM_LISTING_GROUPING_SUCCESS',
  CREATE_ITEM_LISTING_GROUPING_FAILURE = 'CREATE_ITEM_LISTING_GROUPING_FAILURE',
};

export interface ItemListingGroupingActionPayload {
  id?: string;
  itemId?: string;
  listingIds?: string[];
  listingId?: string;
};

export interface ItemListingGroupingAction {
  type: ItemListingGroupingActionTypes;
  payload?: ItemListingGroupingActionPayload;
};

export interface ItemListingGroupingStateLoading {
  create: boolean
};

export interface ItemListingGroupingState {
  error: Error | undefined;
  loading: ItemListingGroupingStateLoading;
};
