import { Box, useStyleConfig } from "@chakra-ui/react";
import { CardVariants } from './Card';

export default function CardHeader(props: any) {
  const { variant, children, ...rest } = props;
  const styles = useStyleConfig("CardHeader", { variant });
  let colorStyles = {}
  if (props.styleVariant === CardVariants.COLORED) {
    colorStyles = {
      color: '#fff'
    };
  }
  return (
    <Box __css={styles} {...rest}>
      {children}
    </Box>
  );
}
