import { HomeState } from './types';

export const defaultState: HomeState = {
	homeFeedData: undefined,
	error: {
		feedContentFetch: undefined
	},
	loading: {
		feedContentFetch: false,
	}
};
