import { Component } from 'react';
import moment, { Moment } from 'moment';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { UserSelectors } from 'reducers/User/selectors';
import { ItemSelectors } from 'reducers/Item/selectors';
import { MaterialSelectors } from 'reducers/Material/selectors';
import { SalesVenueSelectors } from 'reducers/SalesVenue/selectors';
import { ItemDataSuggestionSelectors } from 'reducers/ItemDataSuggestion/selectors';
import { ItemSaleSuggestionSelectors } from 'reducers/ItemSaleSuggestion/selectors';
import { PortfolioActions } from 'reducers/Portfolio/actions';
import { PortfolioSelectors } from 'reducers/Portfolio/selectors';
import { WishlistActions } from 'reducers/Wishlist/actions';
import { WishlistSelectors } from 'reducers/Wishlist/selectors';
import { UserActions } from 'reducers/User/actions';
import { ItemActions } from 'reducers/Item/actions';
import { ItemDataSuggestionActions } from 'reducers/ItemDataSuggestion/actions';
import { ItemSaleSuggestionActions } from 'reducers/ItemSaleSuggestion/actions';
import {
  Box,
  Icon,
  Image,
  SimpleGrid,
  Flex,
  Stack,
  Text,
  Container,
  Heading,
  StackDivider,
  Spacer,
  Tooltip,
  Spinner,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import {
  BiRuler,
  BiDollar,
  BiCalendar
} from 'react-icons/bi';
import {
  BsFolder,
  BsFolderFill,
  BsBell,
  BsBellFill
} from 'react-icons/bs';
import {
  AiFillPrinter,
  AiFillHeart,
  AiOutlineHeart,
  AiOutlineLineChart,
  AiOutlineVerticalAlignTop,
  AiOutlinePlusCircle
} from 'react-icons/ai';
import {
  VscEdit
} from 'react-icons/vsc';
import {
  GiThorHammer,
  GiMaterialsScience
} from 'react-icons/gi';
import {
  FaRegClone
} from 'react-icons/fa';
import {
  ImStatsDots
} from 'react-icons/im';
import {
  IoMdStats,
} from 'react-icons/io';
import { IoFlagOutline } from "react-icons/io5";
import {
  RiAuctionLine,
  RiScalesLine
} from "react-icons/ri";
import { ArtistBanner, ItemInfoBlock, InfoAccordion, ListingGrid, Feature, TabGroup, Slider, StatsCard, EmptyState, ActionButton, Modal, ItemSaleSuggestionForm, ItemDataSuggestionForm, PortfolioItemManagementForm, BasicArtistCard, ItemCard, MarketPriceChart, MarketVolumeChart, Loader, ItemFlagWizard, GrowthBadge } from 'components';
import { ItemDataSuggestionFormData, ItemDataSuggestionFormOption } from 'components/Form/ItemDataSuggestionForm/ItemDataSuggestionForm';
import { ItemSaleSuggestionFormData } from 'components/Form/ItemSaleSuggestionForm/ItemSaleSuggestionForm';
import { PortfolioItemManagementFormData, PortfolioItemPurchaseInfo } from 'components/Form/PortfolioItemManagementForm/PortfolioItemManagementForm';
import { ItemModel, ArtistModel, UserModel, MaterialModel, PortfolioModel, SalesVenueModel, WishlistModel } from 'models';
import { ItemPurchaseInfo } from 'models/Portfolio/types';
import { JSONObject } from 'models/Api/types';
import { FeatureToggleService, NavigationService } from 'services';
import { AppConstants } from '../../constants';
import { FeatureToggleKeys } from '../../constants/toggles';
import { convertJSONObjectToSnakecase } from 'utils/object';

interface ItemDetailsViewProps extends RouterProps {
  item: ItemModel;
  user: UserModel;
  portfolio: PortfolioModel;
  portfolioLoading: boolean;
  portfolioActionLoading: boolean;
  wishlist: WishlistModel;
  wishlistLoading: boolean;
  wishlistActionLoading: boolean;
  itemLoading: boolean;
  itemListingsLoading: boolean;
  itemMarketLoading: boolean;
  userPortfolioLoading: boolean;
  userWishlistLoading: boolean;
  userFollowingArtistsLoading: boolean;
  userArtistActionLoading: boolean;
  itemDataSuggestionLoading: boolean;
  itemSaleSuggestionLoading: boolean;
  getItem: (id: string) => void;
  getItemListings: (id: string) => void;
  getItemArtist: (artistId: string) => void;
  addItemToPortfolio: (itemId: string, purchaseInfo: ItemPurchaseInfo[]) => void;
  replaceItemInPortfolio: (itemId: string, purchaseInfo: ItemPurchaseInfo[]) => void;
  addItemToWishlist: (itemId: string) => void;
  removeItemFromWishlist: (itemId: string) => void;
  followArtist: (artistId: string) => void;
  unfollowArtist: (artistId: string) => void;
  itemSuggestionLoading: boolean;
  itemSuggestions: ItemModel[];
  getItemSuggestions: (id: string) => void;
  materialList: MaterialModel[];
  salesVenueList: SalesVenueModel[];
  submitItemDataSuggestion: (itemId: string, data: JSONObject) => void;
  submitItemSaleSuggestion: (itemId: string, price: number, link: string, date: Moment, sourceId: string) => void;
}

interface ItemDetailsViewModalState {
  reportResultModal: boolean;
  dataSuggestionModal: boolean;
  portfolioAdditionModal: boolean;
  dataItemSuggestionModal: ItemDataSuggestionFormOption | undefined;
  flagItem: boolean;
}

interface ItemDetailsViewState {
  fetchedAdditionalData: boolean;
  modals: ItemDetailsViewModalState;
}

export enum ItemDetailsSizingConfig {
  SECTION_SPACING = '40px',
  CONTENT_SPACING = '20px',
  MARKET_BUTTON_HEIGHT = '48px',
  MARKET_BUTTON_WIDTH = '40%',
  ITEM_IMAGE_MAX_HEIGHT = '480px'
}

class ItemDetails extends Component<ItemDetailsViewProps, ItemDetailsViewState> {
  state = {
    fetchedAdditionalData: false,
    modals: {
      reportResultModal: false,
      dataSuggestionModal: false,
      portfolioAdditionModal: false,
      dataItemSuggestionModal: undefined,
      flagItem: false
    }
  };

  componentDidMount() {
    const { params } = this.props;
    if (params && params.id) {
      this.props.getItem(params.id);
    }
  }

  componentDidUpdate(prevProps: ItemDetailsViewProps) {
    const { item, params } = this.props;
    if (params.id && this.hasRouteChanged(prevProps)) {
      this.props.getItem(params.id);
      this.setState({ fetchedAdditionalData: false });
    }

    if (!this.state.fetchedAdditionalData) {
      this.props.getItemArtist(params.id || item.id);
      this.props.getItemListings(params.id || item.id);
      this.props.getItemSuggestions(params.id || item.id);
      this.setState({ fetchedAdditionalData: true });
    }
  }

  hasRouteChanged = (prevProps: ItemDetailsViewProps): boolean => {
    const { params } = this.props;
    return !!params.id && (params.id !== prevProps.params.id);
  }

  getPortfolioData = (): PortfolioModel | null => {
    const { portfolio, user } = this.props;
    if (portfolio && portfolio.id) {
      return portfolio;
    } else if (user && user.hasFetchedPortfolio()) {
      return user.portfolio;
    }
    return null;
  }

  getWishlistData = (): WishlistModel | null => {
    const { wishlist, user } = this.props;
    if (wishlist && wishlist.id) {
      return wishlist;
    } else if (user && user.hasFetchedWishlist()) {
      return user.wishlist;
    }
    return null;
  }

  onItemArtistClick = () => {
    const { item, navigate } = this.props;
    navigate(
      NavigationService.getArtistDetailsPath(item.artistId)
    );
  }

  onItemSuggestionGridClick = (item: ItemModel) => {
    this.props.navigate(NavigationService.getItemDetailsPath(item.id));
  };

  getPortfolioItemManagementFormData = (): PortfolioItemManagementFormData | undefined => {
    const { item } = this.props;
    const portfolio = this.getPortfolioData();
    const purchaseData = portfolio?.getPortfolioItemPurchaseInfo(item.id) || [];
    if (purchaseData.length) {
      return {
        numPurchases: purchaseData.length || 1,
        purchaseInfo: purchaseData.map(purchase => ({
          price: purchase.price,
          quantity: purchase.quantity,
          date: purchase.date.format('YYYY-MM-DD')
        }))
      }
    } else {
      return undefined;
    }
  }


  getSuggestionFormData = (): ItemDataSuggestionFormData => {
    const { item } = this.props;
    return {
      releasePrice: item.getReleaseConvertedPrice() || undefined,
      publisher: item.publisher,
      material: item.material,
      materialIds: item.getMaterialIds() || [],
      edition: item.getEditionSize() || undefined,
      date: item.getReleaseDate()?.format('YYYY-MM-DD'),
      dimensions: item.dimensions || undefined
    };
  }

  getSuggestionFormDisabledSettings = (): ItemDataSuggestionFormOption[] => {
    const { item } = this.props;
    const settings: ItemDataSuggestionFormOption[] = [];
    if (!!item.getReleaseConvertedPrice()) {
      settings.push('releasePrice');
    }

    if (!!item.publisher) {
      settings.push('publisher');
    }

    if (!!item.materialIds.length) {
      settings.push('materialIds');
    }

    if (!!item.getEditionSize()) {
      settings.push('edition');
    }

    if (!!item.getReleaseDate()) {
      settings.push('date');
    }

    if (!!item.measurements) {
      settings.push('dimensions');
    }

    return settings;
  }

  getItemSuggestionsData = (): ItemModel[] => {
    const { itemSuggestions } = this.props;
    return itemSuggestions.slice(0, 4);
  }

  getItemMaterialNames = (): string => {
    const { item, materialList } = this.props;
    const itemMaterialIds = item.getMaterialIds();
    const itemMaterialNames = materialList.filter(material => itemMaterialIds.includes(material.id)).map(material => material.name);
    return itemMaterialNames.join(', ')
  }

  onReportResultModalOpen = () => {
    const { user, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          reportResultModal: true
        }
      });
    }
  }

  onReportResultModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        reportResultModal: false
      }
    });
  }

  onDataSuggestionModalOpen = () => {
    const { user, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          dataSuggestionModal: true
        }
      });
    }
  }

  onDataSuggestionModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        dataSuggestionModal: false
      }
    });
  }

  onFlagItemModalOpen = () => {
    const { user, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          flagItem: true
        }
      });
    }
  }

  onFlagItemModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        flagItem: false
      }
    });
  }


  onPortfolioAdditionModalOpen = () => {
    const { user, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          portfolioAdditionModal: true
        }
      });
    }
  }

  onPortfolioAdditionModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        portfolioAdditionModal: false
      }
    });
  }

  onDataItemSuggestionModalOpen = (dataItem: ItemDataSuggestionFormOption) => {
    const { user, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          dataItemSuggestionModal: dataItem
        }
      });
    }
  }

  onDataItemSuggestionModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        dataItemSuggestionModal: undefined
      }
    });
  }

  onArtistContainerButtonClick = () => {
    const { user, item, followArtist, unfollowArtist, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else if (user.followsArtist(item.artistId)) {
      unfollowArtist(item.artistId);
    } else if (!user.followsArtist(item.artistId)) {
      followArtist(item.artistId);
    }
  }

  onPortfolioIconButtonClick = () => {
    this.onPortfolioAdditionModalOpen();
  }

  onPortfolioManagementFormSubmit = (data: PortfolioItemManagementFormData) => {
    const { item, addItemToPortfolio, replaceItemInPortfolio } = this.props;
    const portfolio = this.getPortfolioData();
    const purchaseInfo: ItemPurchaseInfo[] = data.purchaseInfo.map((item: PortfolioItemPurchaseInfo) => ({
      date: moment(item.date),
      quantity: item.quantity,
      price: item.price
    }));

    if (portfolio?.containsItem(item.id)) {
      replaceItemInPortfolio(item.id, purchaseInfo);
    } else {
      addItemToPortfolio(item.id, purchaseInfo);
    }

    this.onPortfolioAdditionModalClose();
  }

  onItemDataSuggestionFormSubmit = (data: ItemDataSuggestionFormData) => {
    const { item, submitItemDataSuggestion } = this.props;
    const snakeCasedData: JSONObject = convertJSONObjectToSnakecase(data as JSONObject);
    submitItemDataSuggestion(item.id, snakeCasedData);
    this.onDataItemSuggestionModalClose();
    this.onDataSuggestionModalClose();
  }

  onItemSaleSuggestionFormSubmit = (data: ItemSaleSuggestionFormData) => {
    const { item, submitItemSaleSuggestion } = this.props;
    submitItemSaleSuggestion(item.id, data.rawPrice, data.link, moment(data.date.getDate()), data.sourceId);
    this.onReportResultModalClose();
  }

  onWishlistIconButtonClick = () => {
    const { user, item, addItemToWishlist, removeItemFromWishlist, navigate } = this.props;
    const wishlist = this.getWishlistData();
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else if (wishlist?.containsItem(item.id)) {
      removeItemFromWishlist(item.id);
    } else {
      addItemToWishlist(item.id);
    }
  }

  renderDescription() {
    const { item } = this.props;
    if (FeatureToggleService.isToggleEnabled(FeatureToggleKeys.ITEM_DETAILS_DESCRIPTION)) {
      return (
        <Box marginTop={ItemDetailsSizingConfig.SECTION_SPACING}>
          <InfoAccordion header={'DESCRIPTION'} content={item.description} />
        </Box>
      );
    }
  }

  renderItemInfoSlider() {
    const { item } = this.props;

    const content = [
      { label: 'Size', value: item.getDimensionsForDisplay(), icon: BiRuler, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('dimensions') },
      { label: 'Edition Size', value: item.edition, icon: FaRegClone, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('edition') },
      { label: 'Release Date', value: item.date?.format('MM/DD/YYYY'), icon: BiCalendar, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('date') },
      { label: 'Retail Price', value: item.releasePrice, icon: BiDollar, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('releasePrice') },
      { label: 'Publisher', value: item.publisher, icon: AiFillPrinter, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('publisher') },
      { label: 'Material', value: this.getItemMaterialNames(), icon: GiMaterialsScience, onFallbackContentClick: () => this.onDataItemSuggestionModalOpen('materialIds') },
    ].map((block, index: number) => (
      <ItemInfoBlock key={`item_info_block_${index}`} label={block.label} value={block.value} icon={block.icon} fallbackIcon={AiOutlinePlusCircle} onFallbackContentClick={block.onFallbackContentClick} />
    ));

    return (
      <Box marginTop={ItemDetailsSizingConfig.SECTION_SPACING}>
        <Slider content={content} slidesPerView={4} />
      </Box>
    );
  }

  renderArtistBanner() {
    const { item } = this.props;
    const artist: ArtistModel | null = item.getArtist();
    if (artist && FeatureToggleService.isToggleEnabled(FeatureToggleKeys.ITEM_DETAILS_ARTIST_BANNER)) {
      return (
        <Box marginTop={ItemDetailsSizingConfig.SECTION_SPACING}>
          <ArtistBanner artist={artist} />
        </Box>
      );
    }
  }

  renderContainerMarketButtons() {
    if (FeatureToggleService.isToggleEnabled(FeatureToggleKeys.ITEM_DETAILS_MARKET_BUTTONS)) {
      return (
        <Box paddingTop={ItemDetailsSizingConfig.CONTENT_SPACING}>
          <Flex>
            <ActionButton text={'Bids'} />
            <Spacer />
            <ActionButton text={'Asks'} />
          </Flex>
        </Box>
      );
    }
  }

  renderContainerUserButton(icon: IconType, onClick: () => void, isLoading: boolean) {
    return (
      <Flex
        w={8}
        h={8}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        cursor={'pointer'}
        border="1px"
        onClick={onClick}
        _hover={{ color: 'blue.500' }}>
        {isLoading ? <Spinner size='sm' /> : <Icon as={icon} />}
      </Flex>
    );
  }

  renderContainerUserButtons() {
    const {
      item,
      userPortfolioLoading,
      portfolioLoading,
      portfolioActionLoading,
      userWishlistLoading,
      wishlistLoading,
      wishlistActionLoading
    } = this.props;
    const portfolio = this.getPortfolioData();
    const wishlist = this.getWishlistData();

    const portfolioTooltipLabel = portfolio?.containsItem(item.id) ? 'Remove from Collection' : 'Add to Collection';
    const portfolioIconType = portfolio?.containsItem(item.id) ? BsFolderFill : BsFolder;
    const portfolioButtonLoading = userPortfolioLoading || portfolioLoading || portfolioActionLoading;

    const wishlistTooltipLabel = wishlist?.containsItem(item.id) ? 'Unfavorite' : 'Favorite';
    const wishlistIconType = wishlist?.containsItem(item.id) ? AiFillHeart : AiOutlineHeart;
    const wishlistButtonLoading = userWishlistLoading || wishlistLoading || wishlistActionLoading;

    return (
      <Flex direction="row" justifyContent="center">
        <Flex width="40%">
          <Tooltip label={portfolioTooltipLabel}>
            {this.renderContainerUserButton(portfolioIconType, this.onPortfolioIconButtonClick, portfolioButtonLoading)}
          </Tooltip>
          <Spacer />
          <Tooltip label={wishlistTooltipLabel}>
            {this.renderContainerUserButton(wishlistIconType, this.onWishlistIconButtonClick, wishlistButtonLoading)}
          </Tooltip>
          <Spacer />
          <Tooltip label={'Suggest an Edit'}>
            {this.renderContainerUserButton(VscEdit, this.onDataSuggestionModalOpen, false)}
          </Tooltip>
          <Spacer />
          <Tooltip label={'Flag this item'}>
            {this.renderContainerUserButton(IoFlagOutline, this.onFlagItemModalOpen, false)}
          </Tooltip>
        </Flex>
      </Flex>
    );
  }

  getClosePriceColor = () => {
    const { item } = this.props;
    let iconColor = 'gray';
    if (item.hasMarketData()) {
      if (item.getEstimatedMarketPrice() > item.getClosePrice()) {
        iconColor = 'red';
      }

      if (item.getEstimatedMarketPrice() < item.getClosePrice()) {
        iconColor = 'green';
      }
    }

    return iconColor;
  }


  renderContainerFeatures() {
    const { item } = this.props;
    const lastSaleIconColor = this.getClosePriceColor();
    const containerDivider = <StackDivider borderColor={'gray.100'} />;
    let secondaryMarketPremiumText = 'No Market Data';
    if (item.hasMarketData() && item.hasReleasePriceData()) {
      secondaryMarketPremiumText = item.getSecondaryMarketPremiumForDisplay();
    } else if (item.hasMarketData() && !item.hasReleasePriceData()) {
      secondaryMarketPremiumText = 'Retail Price Unknown';
    }
    return (
      <Stack
        paddingTop={ItemDetailsSizingConfig.CONTENT_SPACING}
        spacing={4}
        divider={containerDivider}>
        <Feature
          icon={<Icon as={BiDollar} color={'purple.500'} w={5} h={5} />}
          iconBg={'purple.100'}
          text={item.hasMarketData() ? item.getEstimatedMarketPriceForDisplay() : 'No Market Data'}
          subText={item.hasMarketData() ? 'Est. market price' : ''}
        />
        <Feature
          icon={
            <Icon as={GiThorHammer} color={`${lastSaleIconColor}.500`} w={5} h={5} />
          }
          iconBg={`${lastSaleIconColor}.100`}
          text={item.hasMarketData() ? (
            <Flex alignItems={'center'} gap={2}>
              {item.getClosePriceForDisplay()}
              {item.hasMultipleSales() && <GrowthBadge percentage={item.getLastSalePercentageChange()} />}
            </Flex>
          ): 'No Recorded Sales'}
          subText={item.hasMarketData() ? 'Last Sale' : ''}
        />
        <Feature
          icon={
            <Icon as={AiOutlineLineChart} color={'yellow.500'} w={5} h={5} />
          }
          iconBg={'yellow.100'}
          text={secondaryMarketPremiumText}
          subText={item.hasMarketData() ? 'Secondary Market Premium' : ''}
        />
      </Stack>
    );
  }


  renderArtistContainerSection() {
    const { item, user, userFollowingArtistsLoading, userArtistActionLoading } = this.props;
    const artist = item.getArtist();
    if (artist) {
      const isFollowing = user.followsArtist(item.artistId);
      const followActionLoading = userFollowingArtistsLoading || userArtistActionLoading;
      const buttonText = isFollowing ? 'Unfollow' : 'Follow';
      const buttonActionIcon = isFollowing ? <BsBellFill /> : <BsBell />;
      return (
        <BasicArtistCard artist={artist} avatarSize='md' titleSize='lg' showAdditionalInfo={true} showButton={true} showBorder={false} onArtistTextClick={this.onItemArtistClick} buttonText={buttonText} buttonIcon={buttonActionIcon} buttonLoading={followActionLoading} onButtonClick={this.onArtistContainerButtonClick} />
      )
    }
  }

  renderContainer() {
    const { item } = this.props;
    return (
      <Container width={{ base: 'auto', md: `${AppConstants.GRIDPAGE_WIDTH}px` }} maxWidth={'unset'}>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Flex justifyContent="center">
            <Image
              rounded={'md'}
              alt={item.name}
              src={item.image}
              objectFit={'contain'}
              maxHeight={'500px'}
            />
          </Flex>
          <Stack spacing={4}>
            <Heading>{item.name}</Heading>
            {this.renderContainerUserButtons()}
            {this.renderArtistContainerSection()}
            {this.renderContainerFeatures()}
            {this.renderContainerMarketButtons()}
          </Stack>
        </SimpleGrid>
      </Container>
    );
  }

  renderSoldListings() {
    const { item, itemListingsLoading } = this.props;
    if (item.hasSoldListings()) {
      return (
        <Box marginTop={ItemDetailsSizingConfig.SECTION_SPACING}>
          <ListingGrid gridListings={item.getSoldListings()} isLoading={itemListingsLoading} showFilters={true} />
        </Box>
      );
    } else {
      return (
        <EmptyState header="No Recorded Sales" description="This Item Has No Recorded Sales" buttonText="Report Sale" showButton={true} onButtonClick={this.onReportResultModalOpen} />
      );
    }
  }

  renderMarketOverview() {
    return (
      <Box padding={{ base: "10px", md: "0px" }}>
        {this.renderMarketStats()}
        {this.renderCharts()}
      </Box>
    )
  }

  renderCharts() {
    const { item, itemLoading, itemMarketLoading } = this.props;
    const market = item.getMarket();
    if (itemLoading || itemMarketLoading) {
      return <Loader />
    } else if (market && item.hasMarketData()) {
      const content = [
        <MarketPriceChart market={market} listings={item.getSoldListings()} startingPrice={item.hasReleasePriceData() ? item.getReleaseConvertedPrice() : null} />,
        <MarketVolumeChart market={market} listings={item.getSoldListings()} />
      ];
      return (
        <SimpleGrid columns={[1, 2, 2]} spacing={ItemDetailsSizingConfig.CONTENT_SPACING} marginTop={ItemDetailsSizingConfig.SECTION_SPACING}>
          {content}
        </SimpleGrid>
      );
    } else {
      return (
        <EmptyState header="No Market Data" description="This Item Has No Recorded Sales" buttonText="Report Sale" showButton={true} onButtonClick={this.onReportResultModalOpen} />
      );
    }
  }

  renderMarketStats() {
    const { item } = this.props;
    const market = item.getMarket();
    if (market && item.hasMarketData()) {
      const content = [
        { title: 'Total Listings', stat: `${market.overview.totalListings}`, icon: <IoMdStats size={'2em'} /> },
        { title: 'Mean Price', stat: item.getOverviewMeanPriceForDisplay(), icon: <RiScalesLine size={'2em'} /> },
        { title: 'Max Price', stat: item.getOverviewMaxPriceForDisplay(), icon: <AiOutlineVerticalAlignTop size={'2em'} /> },
        { title: 'Growth', stat: item.getOverviewGrowthForDisplay(), icon: <AiOutlineLineChart size={'2em'} /> },
      ].map((block, index: number) => (
        <StatsCard key={`item_market_statcard_${index}`} title={block.title} stat={block.stat} icon={block.icon} />
      ));

      return (
        <SimpleGrid columns={[2, 2, 4]} spacing={ItemDetailsSizingConfig.CONTENT_SPACING}>
          {content}
        </SimpleGrid>
      );
    }
  }

  renderTabs() {
    const labels = [
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={ImStatsDots} />
        <Text> Market </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={RiAuctionLine} />
        <Text> Past Sales </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={AiOutlinePlusCircle} />
        <Text> Report Sale </Text>
      </Flex>

    ];
    const content = [
      this.renderMarketOverview(),
      this.renderSoldListings(),
      this.renderReportSaleContent()
    ];

    return (
      <TabGroup labels={labels} content={content} />
    );
  }

  renderReportSaleContent() {
    const { item, salesVenueList, itemSaleSuggestionLoading } = this.props;
    return (
      <Box padding={{ base: "10px", md: "0px" }}>
        <ItemSaleSuggestionForm item={item} salesVenues={salesVenueList} onSubmit={this.onItemSaleSuggestionFormSubmit} submitLoading={itemSaleSuggestionLoading} />
      </Box>
    );
  }

  renderFlagItemModalContent() {
    const { item } = this.props;

    return (
      <ItemFlagWizard item={item} />
    );
  }

  renderReportModalContent() {
    const { item, salesVenueList, itemSaleSuggestionLoading } = this.props;
    return (
      <ItemSaleSuggestionForm item={item} salesVenues={salesVenueList} onSubmit={this.onItemSaleSuggestionFormSubmit} submitLoading={itemSaleSuggestionLoading} />
    );
  }

  renderDataSuggestionModalContent() {
    const { item, materialList, itemDataSuggestionLoading } = this.props;
    return (
      <ItemDataSuggestionForm materialList={materialList} item={item} defaultFormData={this.getSuggestionFormData()} disabledOptions={this.getSuggestionFormDisabledSettings()} onSubmit={this.onItemDataSuggestionFormSubmit} submitLoading={itemDataSuggestionLoading} />
    );
  }

  renderPortfolioAdditionModalContent() {
    const { item, portfolioActionLoading } = this.props;
    return (
      <PortfolioItemManagementForm item={item} defaultFormData={this.getPortfolioItemManagementFormData()} onSubmit={this.onPortfolioManagementFormSubmit} submitLoading={portfolioActionLoading} />
    );
  }

  renderDataItemSuggestionModalContent() {
    const { item, materialList, itemDataSuggestionLoading } = this.props;
    const { modals } = this.state;
    if (modals.dataItemSuggestionModal) {
      return (
        <ItemDataSuggestionForm materialList={materialList} item={item} displayOptions={[modals.dataItemSuggestionModal]} requiredOptions={[modals.dataItemSuggestionModal]} onSubmit={this.onItemDataSuggestionFormSubmit} submitLoading={itemDataSuggestionLoading} />
      );
    } else {
      return <Box />;
    }
  }

  renderItemSuggestionGrid() {
    const { itemSuggestionLoading, itemSuggestions } = this.props;
    const content = itemSuggestions.map((item: ItemModel, index: number) => <ItemCard onClick={() => this.onItemSuggestionGridClick(item)} key={`item_suggestions_card_${index}`} item={item} clickOnImage />);
    if (itemSuggestions.length || itemSuggestionLoading) {
      return (
        <Flex direction="column" gap={4} textAlign={'left'} marginY={8}>
          <Heading fontSize={{ base: 'xl', md: '2xl' }} padding={{ base: 3, md: 0 }}> Related Works </Heading>
          <Slider content={content} slidesPerView={4} slidesPerMobileView={2} />
        </Flex>
      );
    } else {
      return <Box />;
    }
  }

  renderModals() {
    const { item } = this.props;
    const { modals } = this.state;
    const portfolio = this.getPortfolioData();
    const portfolioModalTitle = portfolio?.containsItem(item.id) ? 'Update Portfolio' : 'Add to Portfolio';
    return (
      <Stack>
        <Modal title={'Report Sale'} isOpen={modals.reportResultModal} onClose={this.onReportResultModalClose} content={this.renderReportModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Suggest Data'} isOpen={modals.dataSuggestionModal} onClose={this.onDataSuggestionModalClose} content={this.renderDataSuggestionModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Suggest Data'} isOpen={!!modals.dataItemSuggestionModal} onClose={this.onDataItemSuggestionModalClose} content={this.renderDataItemSuggestionModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={portfolioModalTitle} isOpen={!!modals.portfolioAdditionModal} onClose={this.onPortfolioAdditionModalClose} content={this.renderPortfolioAdditionModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Flag item'} isOpen={!!modals.flagItem} onClose={this.onFlagItemModalClose} content={this.renderFlagItemModalContent()} showCloseButton={false} showSubmitButton={false} />
      </Stack>
    );
  }

  render() {
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center">
        {this.renderContainer()}
        {this.renderDescription()}
        {this.renderItemInfoSlider()}
        {this.renderItemSuggestionGrid()}
        {this.renderTabs()}
        {this.renderArtistBanner()}
        {this.renderModals()}
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    item: ItemSelectors.getItem(state),
    user: UserSelectors.getUser(state),
    userPortfolioLoading: UserSelectors.getUserPortfolioLoading(state),
    userWishlistLoading: UserSelectors.getUserWishlistLoading(state),
    itemListingsLoading: ItemSelectors.getItemListingsLoading(state),
    itemMarketLoading: ItemSelectors.getItemMarketLoading(state),
    itemLoading: ItemSelectors.getItemLoading(state),
    itemDataSuggestionLoading: ItemDataSuggestionSelectors.getItemDataSuggestionCreateLoading(state),
    itemSaleSuggestionLoading: ItemSaleSuggestionSelectors.getItemSaleSuggestionCreateLoading(state),
    userArtistActionLoading: UserSelectors.getUserArtistActionLoading(state),
    userFollowingArtistsLoading: UserSelectors.getUserFollowingArtistsLoading(state),
    itemSuggestionLoading: ItemSelectors.getItemsLoading(state),
    itemSuggestions: ItemSelectors.getItems(state),
    materialList: MaterialSelectors.getMaterialList(state),
    salesVenueList: SalesVenueSelectors.getSalesVenueList(state),
    portfolio: PortfolioSelectors.getPortfolio(state),
    portfolioLoading: PortfolioSelectors.getPortfolioLoading(state),
    portfolioActionLoading: PortfolioSelectors.getPortfolioActionLoading(state),
    wishlist: WishlistSelectors.getWishlist(state),
    wishlistLoading: WishlistSelectors.getWishlistLoading(state),
    wishlistActionLoading: WishlistSelectors.getWishlistActionLoading(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      getItem: (id: string) => ItemActions.getItemById(id),
      getItemArtist: (id: string) => ItemActions.getItemArtist(id),
      getItemListings: (id: string) => ItemActions.getItemListings(id),
      addItemToPortfolio: (itemId: string, purchaseInfo: ItemPurchaseInfo[]) => PortfolioActions.addItemToPortfolio(itemId, purchaseInfo),
      replaceItemInPortfolio: (itemId: string, purchaseInfo: ItemPurchaseInfo[]) => PortfolioActions.replaceItemInPortfolio(itemId, purchaseInfo),
      addItemToWishlist: (itemId: string) => WishlistActions.addItemToWishlist(itemId),
      removeItemFromWishlist: (itemId: string) => WishlistActions.removeItemFromWishlist(itemId),
      followArtist: (id: string) => UserActions.followArtist(id),
      unfollowArtist: (id: string) => UserActions.unfollowArtist(id),
      getItemSuggestions: (id: string) => ItemActions.getItemSuggestions(id),
      submitItemDataSuggestion: (itemId: string, data: JSONObject) => ItemDataSuggestionActions.createItemDataSuggestion(itemId, data),
      submitItemSaleSuggestion: (itemId: string, price: number, link: string, date: Moment, sourceId: string) => ItemSaleSuggestionActions.createItemSaleSuggestion(itemId, price, link, date, sourceId)
    },
    dispatch
  );
}

export const ItemDetailsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ItemDetails));
