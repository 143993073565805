import { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  Input,
  Spinner,
  InputRightElement,
} from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';
import { PasswordInput } from '../../Input/PasswordInput/PasswordInput';
import ActionButton from '../../Button/ActionButton';
import { isValidEmail, isValidUsername, hasNumbers, hasSpecialCharacters, hasLowerCase, hasUpperCase } from 'utils/string';
import { delay } from 'utils/time';
import { AuthApiClient } from 'api/auth';

export interface RegisterFormData {
  email: string;
  username: string;
  password: string;
  confirmPassword: string;
}

export interface RegisterFormProps {
  onSubmit?: (data: RegisterFormData) => void;
  submitText?: string;
  submitLoading?: boolean;
}

export default function RegisterForm({ onSubmit, submitLoading, submitText = 'Register' }: RegisterFormProps) {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<RegisterFormData>({
    mode: 'onBlur'
  });
  const [emailValidationLoading, setEmailValidationLoading] = useState<boolean>(false);
  const [usernameValidationLoading, setUsernameValidationLoading] = useState<boolean>(false);
  const [emailValidated, setEmailValidated] = useState<boolean>(false);
  const [usernameValidated, setUsernameValidated] = useState<boolean>(false);

  const onFormSubmit = (data: RegisterFormData) => {
    onSubmit?.(data);
  }

  function renderEmailInput() {
    return (
      <Input {...register('email', {
        required: 'Email must be specified',
        validate: {
          isDefined: (v: string) => v.length > 0 || 'Email must be specified',
          isValidEmail: (v: string) => isValidEmail(v) || 'Invalid Email Format Provided',
          emailValidation: async (v: string) => {
            setEmailValidationLoading(true);
            await delay(1000);
            const whitelistResult = await AuthApiClient.isEmailWhitelisted(v);
            const availabilityResult = await AuthApiClient.isEmailAvailable(v);
            setEmailValidationLoading(false);
            if (!whitelistResult) {
              setEmailValidated(false);
              return 'Email Address Not Enrolled. Please Join Our Waitlist';
            } else if (!availabilityResult) {
              setEmailValidated(false);
              return 'Email Address Already Taken';
            }
            setEmailValidated(true);
            return true;
          }
        }
      })} />
    );
  }

  function renderUsernameInput() {
    return (
      <Input {...register('username', {
        required: 'Username must be specified',
        validate: {
          isDefined: (v: string) => v.length > 0 || 'User must be specified',
          isValidUsername: (v: string) => isValidUsername(v) || 'Invalid Username Format Provided',
          isAvailable: async (v: string) => {
            setUsernameValidationLoading(true);
            await delay(1000);
            const result = await AuthApiClient.isUsernameAvailable(v);
            setUsernameValidationLoading(false);
            if (!result) {
              setUsernameValidated(false);
              return 'Username Already Taken';
            }
            setUsernameValidated(true);
            return true;
          }
        }
      })} />
    );
  }

  function renderPasswordInput() {
    return (
      <PasswordInput {...register('password', {
        required: 'Password must be specified',
        validate: {
          minLength: (v: string) => v && v.length >= 8 || 'Password Length Must Be Atleast 8',
          containsNumber: (v: string) => hasNumbers(v) || 'Password Must Contain Number',
          containsSpecialCharacter: (v: string) => hasSpecialCharacters(v) || 'Password Must Contain Special Character',
          hasUpperCase: (v: string) => hasUpperCase(v) || 'Password Must Contain Upper Case Character',
          hasLowerCase: (v: string) => hasLowerCase(v) || 'Password Must Contain Lower Case Character'
        }
      })} />
    );
  }

  function renderConfirmPasswordInput() {
    return (
      <PasswordInput {...register('confirmPassword', {
        required: 'Confirm Password',
        validate: {
          isEqualToNextPassword: (v: string) => v.length > 0 && v === watch('password') || 'Passwords Do Not Match'
        }
      })} />
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={8}>
        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel htmlFor="email" fontSize="sm">Email</FormLabel>
          <InputGroup>
            {renderEmailInput()}
            <InputRightElement children={emailValidationLoading ? <Spinner /> : (emailValidated && <CheckIcon color="green.500" />)} />
          </InputGroup>
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.username}>
          <FormLabel htmlFor="username" fontSize="sm">Username</FormLabel>
          <InputGroup>
            {renderUsernameInput()}
            <InputRightElement children={usernameValidationLoading ? <Spinner /> : (usernameValidated && <CheckIcon color="green.500" />)} />
          </InputGroup>
          <FormErrorMessage>
            {errors.username && errors.username.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.password}>
          <FormLabel htmlFor="password" fontSize="sm">Password</FormLabel>
          <InputGroup>
            {renderPasswordInput()}
          </InputGroup>
          <FormErrorMessage>
            {errors.password && errors.password.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.confirmPassword}>
          <FormLabel htmlFor="confirmPassword" fontSize="sm">Confirm Password</FormLabel>
          <InputGroup>
            {renderConfirmPasswordInput()}
          </InputGroup>
          <FormErrorMessage>
            {errors.confirmPassword && errors.confirmPassword.message}
          </FormErrorMessage>
        </FormControl>
        <Stack alignItems="center">
          <ActionButton type='submit' text={submitText} loading={submitLoading} disabled={emailValidationLoading || usernameValidationLoading || submitLoading} />
        </Stack>
      </Stack>
    </form>
  );
}
