import {
  Heading,
  Avatar,
  Flex,
  SimpleGrid,
  useBreakpointValue
} from '@chakra-ui/react';
import { ArtistModel } from 'models/Artist/Artist';

export interface ArtistAvatarGridProps {
  artists: ArtistModel[];
  onClick?: (artist: ArtistModel) => void;
  showArtistName?: boolean;
  rows?: number
}

export default function ArtistAvatarGrid({ artists, onClick, rows = 2, showArtistName = true }: ArtistAvatarGridProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const avatarSize = isMobile ? 'lg' : 'xl';

  function renderArtistAvatar(artist: ArtistModel, index: number) {
    return (
      <Flex key={`artistAvatarGrid_${index}`} onClick={() => onClick && onClick(artist)} direction='column' alignItems='center' gap='16px' cursor={onClick ? 'pointer' : 'text'} _hover={onClick ? { color: 'blue.500', transform: 'scale(1.1)' } : {}}>
        <Avatar size={avatarSize} src={artist.image} loading='lazy' />
        {showArtistName && <Heading size='sm'>{artist.name}</Heading>}
      </Flex>
    );
  }

  const getArtists = () => {
    if (rows) {
      const limit = Math.min(4 * rows, artists.length);
      return artists.slice(0, limit);
    } else {
      return artists;
    }
  }

  return (
    <SimpleGrid columns={4} spacing={{ base: "10px", md: "20px" }}>
      {getArtists().map((artist: ArtistModel, index: number) => renderArtistAvatar(artist, index))}
    </SimpleGrid>
  );
}
