import moment, { Moment } from 'moment';
import { ItemModel }  from '../Item/Item';
import { RawItemData }  from '../Item/types';
import { PaginationModel, RawPaginationData } from '../Pagination/Pagination';
import { ListingModel } from '../Listing/Listing';
import { RawListingData } from '../Listing/types';
import { Cloneable } from '../Utils/Cloneable';
import { CountryData, RawTimestampData, TimestampData } from '../types';
import { ArtistIndexModel } from './ArtistIndex';

export interface RawArtistContactInfo {
  artsy_link: string;
  instagram_link: string;
  representative_gallery: string;
  website: string;
}

export interface ArtistContactInfo {
  instagramLink: string;
  artsyLink: string;
  representativeGallery: string;
  website: string;
}

export interface RawArtistData {
  id: string;
  name: string;
  description: string;
  image: string;
  aliases: string[];
  birth_year?: number;
  items?: RawItemData[];
  listings?: RawPaginationData<RawListingData>[];
  country?: CountryData;
  contact_info?: RawArtistContactInfo;
  timestamps?: RawTimestampData;
}

export interface ArtistIndexes {
  edition?: ArtistIndexModel;
}

export class ArtistModel extends Cloneable {
  id: string;
  name: string;
  description: string;
  image: string;
  aliases: string[];
  birthYear: number;
  items: ItemModel[];
  soldListings: PaginationModel<ListingModel>;
  indexes: ArtistIndexes;
  country: CountryData;
  contactInfo: ArtistContactInfo;
  timestamps: TimestampData;

  constructor(artistData?: RawArtistData) {
    super();
    this.id = artistData?.id || '';
    this.name = artistData?.name || '';
    this.description = artistData?.description || '';
    this.image = artistData?.image || '';
    this.aliases = artistData?.aliases || [];
    this.birthYear = artistData?.birth_year || 0;
    this.items = (artistData?.items || []).map((item: RawItemData) => new ItemModel(item));
    this.soldListings = new PaginationModel<ListingModel>();
    this.country = artistData?.country || { code: '', name: '' };
    this.contactInfo = {
      instagramLink: artistData?.contact_info?.instagram_link || '',
      artsyLink: artistData?.contact_info?.artsy_link || '',
      representativeGallery: artistData?.contact_info?.representative_gallery || '',
      website: artistData?.contact_info?.website || ''
    };
    this.timestamps = {
      createdAt: artistData?.timestamps? moment(artistData.timestamps.created_at): null,
      updatedAt: artistData?.timestamps? moment(artistData.timestamps.updated_at): null,
    };
    this.indexes = {};
  }

  public setItems(items: ItemModel[]): void {
    this.items = items;
  }

  public setSoldListings(listings: PaginationModel<ListingModel> | undefined): void {
    if (listings) {
      this.soldListings = listings;
    }
  }

  public setEditionIndex(artistIndexData: ArtistIndexModel): void {
    this.indexes.edition = artistIndexData;
  }

  public getItems(): ItemModel[] {
    return this.items;
  }

  public getBirthYear(): number {
    return this.birthYear;
  }

  public getBirthCountryName(): string {
    return this.country.name;
  }

  public getSoldListings(): PaginationModel<ListingModel> {
    return this.soldListings;
  }

  public hasSoldListings(): boolean {
    return this.soldListings.data.length > 0;
  }

  public getImageUrl(): string {
    return this.image;
  }

  public getEditionIndex(): ArtistIndexModel | undefined {
    return this.indexes.edition;
  }

  public hasIndexData(): boolean {
    return !!this.indexes;
  }

}
