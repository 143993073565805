import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { ItemSelectors } from 'reducers/Item/selectors';
import { ArtistSelectors } from 'reducers/Artist/selectors';
import { ListingSelectors } from 'reducers/Listing/selectors';
import { MaterialSelectors } from 'reducers/Material/selectors';
import { ItemActions } from 'reducers/Item/actions';
import { ListingActions } from 'reducers/Listing/actions';
import {
	Box,
	Circle,
	Icon,
	Stack,
	Flex,
	Text,
} from '@chakra-ui/react';
import { PaginationModel, ItemModel, ArtistModel, MaterialModel, ListingModel } from 'models';
import { PaginatedItemGrid, PaginatedListingGrid, Modal, ItemSearchFiltersForm, ListingSearchFiltersForm, TabGroup, Hero, ArtistGrid } from 'components';
import { ItemSearchFiltersFormData } from 'components/Form/ItemSearchFiltersForm/ItemSearchFiltersForm';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';
import { JSONObject } from 'models/Api/types';
import { BsSearch } from 'react-icons/bs';
import { IoGridOutline } from 'react-icons/io5';
import { RiAuctionLine } from 'react-icons/ri';
import { AiFillFormatPainter } from 'react-icons/ai';
import { convertSearchFilterDataToQuery, convertQueryToSearchFilterData, convertListingQueryToSearchFilterData, convertListingSearchFilterDataToQuery } from 'utils/search';
import { ListingSearchFiltersFormData, ListingSortFilter } from 'components/Form/ListingSearchFiltersForm/ListingSearchFiltersForm';

interface ExploreViewProps extends RouterProps {
	itemSearchLoading: boolean;
	itemSearchResults: PaginationModel<ItemModel>;
	listingSearchLoading: boolean;
	listingSearchResults: PaginationModel<ListingModel>;
	artistList: ArtistModel[];
	artistListLoading: boolean;
	materialList: MaterialModel[];
	materialListLoading: boolean;
	onItemSearch: (query: JSONObject) => void;
	onListingSearch: (qeury: JSONObject) => void;
	getAllItems: (page?: number) => void;
}

interface ExploreViewState {
	modals: ExploreViewModalState;
	itemSearchFilters: ItemSearchFiltersFormData | undefined;
	listingSearchFilters: ListingSearchFiltersFormData | undefined;
}

interface ExploreViewModalState {
	itemSearchFiltersModal: boolean;
	listingSearchFiltersModal: boolean;
}


class Explore extends Component<ExploreViewProps, ExploreViewState> {
	state = {
		itemSearchFilters: undefined,
		listingSearchFilters: undefined,
		modals: {
			itemSearchFiltersModal: false,
			listingSearchFiltersModal: false
		}
	};

	componentDidMount(): void {
		const { location, onItemSearch, onListingSearch, getAllItems } = this.props;
		const queryParams: JSONObject = {};
		const searchParams = new URLSearchParams(location.search);
		searchParams.forEach((value, key) => {
			if (key == 'sortBy' || key == 'sortType') {
			  queryParams[key] = value;
			}
		});

		if (!!Object.keys(queryParams).length) {
			const convertedQueryParams = convertQueryToSearchFilterData(queryParams);
			const convertedListingQueryParams = convertListingQueryToSearchFilterData(queryParams);
      		this.setState({listingSearchFilters: convertedListingQueryParams, itemSearchFilters: convertedQueryParams});
      		onItemSearch(queryParams);
			onListingSearch({
				...queryParams,
				associate_items: true
			})
    } else {
			const listingQueryParams = {
				sortBy: ListingSortFilter.DATE,
				sortType: 'desc'
			};
			this.setState({listingSearchFilters: convertListingQueryToSearchFilterData(listingQueryParams)})
			getAllItems();
			onListingSearch({
				...listingQueryParams,
				associate_items: true,
			});
    }
	}

	onItemSearchQueryModalOpen = () => {
		this.setState({
			modals: {
				...this.state.modals,
				itemSearchFiltersModal: true
			}
		});
	}

	onItemSearchQueryModalClose = () => {
		this.setState({
			modals: {
				...this.state.modals,
				itemSearchFiltersModal: false
			}
		});
	}

	onListingSearchQueryModalOpen = () => {
		this.setState({
			modals: {
				...this.state.modals,
				listingSearchFiltersModal: true
			}
		});
	}

	onListingSearchQueryModalClose = () => {
		this.setState({
			modals: {
				...this.state.modals,
				listingSearchFiltersModal: false
			}
		});
	}

	onItemSearchFiltersFormSubmit = (searchQuery: ItemSearchFiltersFormData) => {
		this.setState({ itemSearchFilters: searchQuery });
		this.props.onItemSearch(convertSearchFilterDataToQuery(searchQuery));
		this.onItemSearchQueryModalClose();
	}

	onListingSearchFiltersFormSubmit = (searchQuery: ListingSearchFiltersFormData) => {
		this.setState({ listingSearchFilters: searchQuery });
		this.props.onListingSearch({
			...convertListingSearchFilterDataToQuery(searchQuery),
			associate_items: true
		});
		this.onListingSearchQueryModalClose();
	}

	onListingSearchFiltersFormReset = () => {
		this.setState({ listingSearchFilters: undefined });
	}

	onListingPageSelect = (page: number) => {
		const { listingSearchFilters } = this.state;
		if (listingSearchFilters) {
			this.props.onListingSearch({
				...convertListingSearchFilterDataToQuery(listingSearchFilters),
				page: page,
				associate_items: true,

			});
		} else {
			this.props.onListingSearch({
				page: page,
				associate_items: true
			});
		}
	}

	onItemsPageSelect = (page: number) => {
		const { itemSearchFilters } = this.state;
		if (itemSearchFilters) {
			this.props.onItemSearch({
				...convertSearchFilterDataToQuery(itemSearchFilters),
				page: page
			});
		} else {
			this.props.getAllItems(page);
		}
	}


	onItemClick = (item: ItemModel) => {
		this.props.navigate(NavigationService.getItemDetailsPath(item.id));
	};

	onListingItemClick = (itemId: string) => {
		this.props.navigate(NavigationService.getItemDetailsPath(itemId));
	}

	onArtistCardClick = (artist: ArtistModel) => {
		this.props.navigate(NavigationService.getArtistDetailsPath(artist.id));
	};
	

	getViewStartingTab = (): number => {
		const searchParams = new URLSearchParams(this.props.location.search);
		const tabQueryParam = searchParams.get('tab');
		let startingTab = 0;
		if (tabQueryParam?.toLowerCase().includes('item')) {
			startingTab = 1;
		} else if (tabQueryParam?.toLowerCase().includes('listing')) {
			startingTab = 2;
		}

		return startingTab;
	}

	renderItemSearchResults() {
		const { itemSearchLoading, itemSearchResults } = this.props;
		return (
			<Stack>
				<PaginatedItemGrid
					onItemCardClick={this.onItemClick}
					paginatedItems={itemSearchResults}
					onPageSelect={this.onItemsPageSelect}
					scrollMode={false}
					gridMaxHeight={1200}
					isLoading={itemSearchLoading} />
				{this.renderItemSearchButton()}
			</Stack>
		);
	}

	renderListingSearchResults() {
		const { listingSearchLoading, listingSearchResults } = this.props;
		return (
			<Stack>
				<PaginatedListingGrid
					paginatedListings={listingSearchResults}
					onPageSelect={this.onListingPageSelect}
					scrollMode={false}
					isLoading={listingSearchLoading}
					onListingItemClick={this.onListingItemClick}
					showAssociatedItems={true}
					showAssociationAction={false}
				/>
				{this.renderListingSearchButton()}
			</Stack>

		);
	}

	renderArtistGrid() {
		const { artistList, artistListLoading } = this.props;
		return (
		  <ArtistGrid artists={artistList} isLoading={artistListLoading} onArtistCardClick={this.onArtistCardClick} />
		);
	  }
	

	renderItemSearchButton() {
		return (
			<Circle size='40px' bg='gray.300' left={'50%'} transform={'translateX(-50%)'} position={'fixed'} bottom={100} cursor={'pointer'} onClick={this.onItemSearchQueryModalOpen}>
				<Icon as={BsSearch} color={'white.100'} />
			</Circle>
		);
	}

	renderListingSearchButton() {
		return (
			<Circle size='40px' bg='gray.300' left={'50%'} transform={'translateX(-50%)'} position={'fixed'} bottom={100} cursor={'pointer'} onClick={this.onListingSearchQueryModalOpen}>
				<Icon as={BsSearch} color={'white.100'} />
			</Circle>
		);
	}

	renderItemSearchFiltersModalContent() {
		const { artistList, materialList, itemSearchLoading } = this.props;
		const { itemSearchFilters } = this.state;
		return (
			<ItemSearchFiltersForm artistList={artistList} materialList={materialList} submitLoading={itemSearchLoading} defaultFormData={itemSearchFilters} onSubmit={this.onItemSearchFiltersFormSubmit} />
		);
	}

	renderListingSearchFiltersModalContent() {
		const { artistList, listingSearchLoading } = this.props;
		const { listingSearchFilters } = this.state;
		return (
			<ListingSearchFiltersForm artistList={artistList} submitLoading={listingSearchLoading} defaultFormData={listingSearchFilters} onSubmit={this.onListingSearchFiltersFormSubmit} onReset={this.onListingSearchFiltersFormReset} />
		);
	}


	renderModals() {
		const { modals } = this.state;
		return (
			<Stack>
				<Modal title={'Search Filters'} isOpen={!!modals.itemSearchFiltersModal} onClose={this.onItemSearchQueryModalClose} content={this.renderItemSearchFiltersModalContent()} showCloseButton={false} showSubmitButton={false} />
				<Modal title={'Search Filters'} isOpen={!!modals.listingSearchFiltersModal} onClose={this.onListingSearchQueryModalClose} content={this.renderListingSearchFiltersModalContent()} showCloseButton={false} showSubmitButton={false} />
			</Stack>
		);
	}

	renderTabs() {
		const labels = [
			<Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
				<Icon as={AiFillFormatPainter} />
				<Text> Artists </Text>
			</Flex>,
			<Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
				<Icon as={IoGridOutline} />
				<Text> Items </Text>
			</Flex>,
			<Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
				<Icon as={RiAuctionLine} />
				<Text> Past Sales </Text>
			</Flex>
		];

		const content = [
			this.renderArtistGrid(),
			this.renderItemSearchResults(),
			this.renderListingSearchResults()
		];

		return (
			<TabGroup labels={labels} content={content} startingTab={this.getViewStartingTab()}/>
		);
	}

	renderHero() {
		return (
		<Hero
			title="The Artworld at your Fingertips"
			subtitle='Explore the catalogue of your favorite artists with ease and complete transparency.'
			image="https://storage.googleapis.com/artcore-image-set/content-images/Beige%20Artist%208%20Grid%20Collage.png" />
		);
	}


	render() {
		return (
			<Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
				{this.renderHero()}
				{this.renderTabs()}
				{this.renderModals()}
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		itemSearchLoading: ItemSelectors.getItemSearchLoading(state),
		itemSearchResults: ItemSelectors.getPaginatedItems(state),
		artistList: ArtistSelectors.getArtistList(state),
		artistListLoading: ArtistSelectors.getArtistListLoading(state),
		materialList: MaterialSelectors.getMaterialList(state),
		materialListLoading: MaterialSelectors.getMaterialListLoading(state),
		listingSearchLoading: ListingSelectors.getListingSearchLoading(state),
		listingSearchResults: ListingSelectors.getPaginatedListings(state)
	}
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
	return bindActionCreators(
		{
			onItemSearch: ItemActions.searchItem,
			getAllItems: ItemActions.getAllItems,
			onListingSearch: ListingActions.searchListings
		},
		dispatch
	);
}

export const ExploreView = connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(Explore));
