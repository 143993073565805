import {
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
  Flex,
  Text,
  useDisclosure,
  useMergeRefs,
} from '@chakra-ui/react';
import * as React from 'react';
import { HiEye, HiEyeOff } from 'react-icons/hi';

export interface PasswordInputProps extends InputProps {
  hideInputLabel?: boolean;
  showHelperText?: boolean;
}

export const PasswordInput = React.forwardRef<HTMLInputElement, PasswordInputProps>((props, ref) => {
  const { isOpen, onToggle } = useDisclosure();
  const inputRef = React.useRef<HTMLInputElement>(null);

  const mergeRef = useMergeRefs(inputRef, ref);
  const onClickReveal = () => {
    onToggle();
    if (inputRef.current) {
      inputRef.current.focus({ preventScroll: true });
    }
  }

  function renderHelperText() {
    if (props.showHelperText) {
      return (
        <FormHelperText textAlign='left'>
          <Flex direction={'column'}>
            <Text>Requirements:</Text>
            <Text>1 Upper Case Character</Text>
            <Text>1 Lower Case Character</Text>
            <Text>1 Number</Text>
            <Text>1 Special Character</Text>
          </Flex>
        </FormHelperText>
      );
    }
  }

  return (
    <FormControl>
      <InputGroup>
        <InputRightElement>
          <IconButton
            variant="link"
            aria-label={isOpen ? 'Mask password' : 'Reveal password'}
            icon={isOpen ? <HiEyeOff /> : <HiEye />}
            onClick={onClickReveal}
          />
        </InputRightElement>
        <Input
          id={props.name || 'password'}
          ref={mergeRef}
          name={props.name || 'password'}
          type={isOpen ? 'text' : 'password'}
          autoComplete="current-password"
          isInvalid={props.isInvalid}
          required
          {...props}
        />
      </InputGroup>
      {renderHelperText()}
    </FormControl>
  );
})

PasswordInput.displayName = 'PasswordInput'
