import React, { useState, useEffect } from 'react';
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Box,
  Text,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

export interface NumberSliderProps {
    onNumberRangeSelect?: (minNumber: number, maxNumber: number) => void;
    rangeMin?: number;
    rangeMax?: number;
    startingMin?: number;
    startingMax?: number
    step?: number;
    dropdownDisplay?: boolean;
    dropdownLabel?: string;
    isDollarValue?: boolean;
}

export default function NumberSlider({ rangeMin = 0, rangeMax = 50000, startingMin = 0, startingMax = 50000, step = 5000, onNumberRangeSelect = () => {}, dropdownDisplay = true, dropdownLabel = 'Number', isDollarValue = true }: NumberSliderProps) {
  const [number, setNumber] = useState(startingMax);
  const [minNumber, setMinNumber] = useState(startingMin);
  const [hasFilteredRange, setHasFilteredRange] = useState(false);

  const handleRangeSliderChange = (value: number[]) => {
    setHasFilteredRange(true);
    setMinNumber(value[0]);
    setNumber(value[1]);
  }

	const getRangeText = () => {
    const numberPrefix = isDollarValue ? '$' : ''
		if (number === rangeMax) {
			return `${numberPrefix}${minNumber} - ${numberPrefix}${number}+`
		} else {
			return `${numberPrefix}${minNumber} - ${numberPrefix}${number}`
		}
	}

  useEffect(() => {
    setMinNumber(startingMin);
    setNumber(startingMax);
  }, [startingMin, startingMax]);
  
  useEffect(() => {
    if (hasFilteredRange) {
        onNumberRangeSelect(minNumber, number)
    }
  }, [number, minNumber]);

  function renderRangeSlider() {
    return (
        <Box width={dropdownDisplay ? "200px" : "100%"} paddingX={2}>
          <RangeSlider
            min={rangeMin}
            max={rangeMax}
            defaultValue={[rangeMin, rangeMax]}
            value={[minNumber, number]}
            step={step}
            onChange={handleRangeSliderChange}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb boxSize={6} index={0} />
            <RangeSliderThumb boxSize={6} index={1} />
          </RangeSlider>
          <Text mt={2} textAlign="center" fontSize={'sm'}>
              {getRangeText()}
          </Text>
        </Box>
    );
  }

  if (dropdownDisplay) {
    return (
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} variant="outline" rightIcon={<ChevronDownIcon />}>
          {dropdownLabel}
        </MenuButton>
        <MenuList>
          <MenuItem>
            {renderRangeSlider()}
          </MenuItem>
        </MenuList>
      </Menu>
    );
  } else {
    return renderRangeSlider();
  }
}