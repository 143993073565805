import {
  Box,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton,
  useDisclosure,
  SlideFade
} from '@chakra-ui/react';
import { Toast } from 'models/types';

export interface AlertPopupProps {
  toast: Toast
}

export default function AlertPopup({ toast }: AlertPopupProps) {
  const {
    isOpen: isVisible,
    onClose
  } = useDisclosure({ defaultIsOpen: true });

  return isVisible ? (
    <SlideFade in={isVisible} unmountOnExit>
      <Alert status={toast.status} maxWidth="400px" borderRadius={4}>
        <AlertIcon />
        <Box>
          <AlertTitle>{toast.title}</AlertTitle>
          <AlertDescription>
            {toast.description}
          </AlertDescription>
        </Box>
        <CloseButton
          marginLeft='auto'
          onClick={onClose}
        />
      </Alert>
    </SlideFade>
  ) : <Box />;
}
