import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { Box } from '@chakra-ui/react';
import { ArtistDataSuggestionForm } from 'components';
import { AppConstants } from '../../constants';

interface ArtistSuggestionViewProps extends RouterProps {}

export class ArtistSuggestion extends Component<ArtistSuggestionViewProps> {
  renderArtistSuggestionForm() {
    return (
      <Box p={{base: '20px', md: '0px'}}>
        <ArtistDataSuggestionForm requiredOptions={['name', 'image']} />
      </Box>
    );
  }

  render() {
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
        {this.renderArtistSuggestionForm()}
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {}
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {},
    dispatch
  );
}

export const ArtistSuggestionView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArtistSuggestion));
