import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { PortfolioModel } from 'models';

export class PortfolioSelectors {
  public static getPortfolio(state: ApplicationState): PortfolioModel {
    return RootSelectors.getPortfolioState(state).portfolio;
  }

  public static getError(state: ApplicationState): Error | undefined {
    return RootSelectors.getPortfolioState(state).error;
  }

  public static getPortfolioLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getPortfolioState(state).loading.item;
  }

  public static getPortfolioActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getPortfolioState(state).loading.action;
  }

  public static getPortfolioArtistsLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getPortfolioState(state).loading.artists;
  }

  public static getPortfolioItemsLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getPortfolioState(state).loading.items;
  }

  public static getPortfolioHistoryLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getPortfolioState(state).loading.history;
  }

  public static getPortfolioListingsLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getPortfolioState(state).loading.listings;
  }
}
