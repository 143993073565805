import { Cmd, loop, Loop } from 'redux-loop';
import { PortfolioInternalActions } from './actions';
import { PortfolioApiClient, UserApiClient } from 'api';
import { PortfolioModel } from 'models';
import { PortfolioActions } from './actions';
import { PortfolioState, PortfolioAction } from './types';
import { defaultState } from './consts';

export enum PortfolioStateErrors {
  USER_FETCH = 'Unable to fetch user portfolio',
  ID_FETCH = 'Unable to fetch portfolio',
  ARTISTS_FETCH = 'Unable to fetch portfolio artists',
  HISTORY_FETCH = 'Unable to fetch portfolio history',
  ITEMS_FETCH = 'Unable to fetch portfolio items',
  LISTINGS_FETCH = 'Unable to fetch portfolio listings',
  ADD_ITEM_TO_PORTFOLIO = 'Unable to add item to portfolio',
  REPLACE_ITEM_IN_PORTFOLIO = 'Unable to replace item in portfolio',
  REMOVE_ITEM_FROM_PORTFOLIO = 'Unable to remove item from portfolio',
}

export class PortfolioHandlers {
  public static handleGetUserPortfolio(state: PortfolioState): Loop<PortfolioState, PortfolioAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        item: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.getPortfolio, {
        args: [],
        successActionCreator: PortfolioInternalActions.getUserPortfolioSuccess,
        failActionCreator: PortfolioInternalActions.getUserPortfolioFailure,
      })
    );
  }

  public static handleGetUserPortfolioSuccess(state: PortfolioState, action: PortfolioAction): PortfolioState | Loop<PortfolioState, PortfolioAction> {
    const portfolio: PortfolioModel = action.payload?.portfolio || defaultState.portfolio;

    return {
      ...state,
      portfolio,
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetUserPortfolioFailure(state: PortfolioState): PortfolioState {
    return {
      ...state,
      error: new Error(PortfolioStateErrors.USER_FETCH),
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetPortfolioById(state: PortfolioState, action: PortfolioAction): Loop<PortfolioState, PortfolioAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        item: true
      }
    };

    return loop(
      newState,
      Cmd.run(PortfolioApiClient.getPortfolioById, {
        args: [payload?.id || ''],
        successActionCreator: PortfolioInternalActions.getPortfolioByIdSuccess,
        failActionCreator: PortfolioInternalActions.getPortfolioByIdFailure,
      })
    );
  }

  public static handleGetPortfolioByIdSuccess(state: PortfolioState, action: PortfolioAction): PortfolioState | Loop<PortfolioState, PortfolioAction> {
    const portfolio: PortfolioModel = action.payload?.portfolio || defaultState.portfolio;

    const newState = {
      ...state,
      portfolio,
      loading: {
        ...state.loading,
        item: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(PortfolioActions.getPortfolioListings(portfolio.id)),
        Cmd.action(PortfolioActions.getPortfolioHistory(portfolio.id))
      ])
    );
  }

  public static handleGetPortfolioByIdFailure(state: PortfolioState): PortfolioState {
    return {
      ...state,
      error: new Error(PortfolioStateErrors.ID_FETCH),
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetPortfolioArtists(state: PortfolioState, action: PortfolioAction): Loop<PortfolioState, PortfolioAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        artists: true
      }
    };

    return loop(
      newState,
      Cmd.run(PortfolioApiClient.getPortfolioArtists, {
        args: [payload?.id || ''],
        successActionCreator: PortfolioInternalActions.getPortfolioArtistsSuccess,
        failActionCreator: PortfolioInternalActions.getPortfolioArtistsFailure,
      })
    );
  }

  public static handleGetPortfolioArtistsSuccess(state: PortfolioState, action: PortfolioAction): PortfolioState | Loop<PortfolioState, PortfolioAction> {
    const { portfolio } = state;
    const artistList = action.payload?.artistList || [];
    const newPortfolio = PortfolioModel.deepCopy(portfolio);
    newPortfolio.setArtists(artistList);

    return {
      ...state,
      portfolio: newPortfolio,
      loading: {
        ...state.loading,
        artists: false
      }
    };
  }

  public static handleGetPortfolioArtistsFailure(state: PortfolioState): PortfolioState {
    return {
      ...state,
      error: new Error(PortfolioStateErrors.ARTISTS_FETCH),
      loading: {
        ...state.loading,
        artists: false
      }
    };
  }


  public static handleGetPortfolioItems(state: PortfolioState, action: PortfolioAction): Loop<PortfolioState, PortfolioAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        items: true
      }
    };

    return loop(
      newState,
      Cmd.run(PortfolioApiClient.getPortfolioItems, {
        args: [payload?.id || ''],
        successActionCreator: PortfolioInternalActions.getPortfolioItemsSuccess,
        failActionCreator: PortfolioInternalActions.getPortfolioItemsFailure,
      })
    );
  }

  public static handleGetPortfolioItemsSuccess(state: PortfolioState, action: PortfolioAction): PortfolioState | Loop<PortfolioState, PortfolioAction> {
    const { portfolio } = state;
    const items = action.payload?.items || [];
    const newPortfolio = PortfolioModel.deepCopy(portfolio);
    newPortfolio.setDetailedPortfolioItems(items);

    return {
      ...state,
      portfolio: newPortfolio,
      loading: {
        ...state.loading,
        items: false
      }
    };
  }

  public static handleGetPortfolioItemsFailure(state: PortfolioState): PortfolioState {
    return {
      ...state,
      error: new Error(PortfolioStateErrors.ITEMS_FETCH),
      loading: {
        ...state.loading,
        items: false
      }
    };
  }

  public static handleGetPortfolioHistory(state: PortfolioState, action: PortfolioAction): Loop<PortfolioState, PortfolioAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        history: true
      }
    };

    return loop(
      newState,
      Cmd.run(PortfolioApiClient.getPortfolioHistory, {
        args: [payload?.id || ''],
        successActionCreator: PortfolioInternalActions.getPortfolioHistorySuccess,
        failActionCreator: PortfolioInternalActions.getPortfolioHistoryFailure,
      })
    );
  }

  public static handleGetPortfolioHistorySuccess(state: PortfolioState, action: PortfolioAction): PortfolioState | Loop<PortfolioState, PortfolioAction> {
    const { portfolio } = state;
    const portfolioHistory = action.payload?.portfolioHistory;
    const newPortfolio = PortfolioModel.deepCopy(portfolio);
    newPortfolio.setHistory(portfolioHistory);

    return {
      ...state,
      portfolio: newPortfolio,
      loading: {
        ...state.loading,
        history: false
      }
    };
  }

  public static handleGetPortfolioHistoryFailure(state: PortfolioState): PortfolioState {
    return {
      ...state,
      error: new Error(PortfolioStateErrors.HISTORY_FETCH),
      loading: {
        ...state.loading,
        history: false
      }
    };
  }

  public static handleGetPortfolioListings(state: PortfolioState, action: PortfolioAction): Loop<PortfolioState, PortfolioAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        listings: true
      }
    };

    return loop(
      newState,
      Cmd.run(PortfolioApiClient.getPortfolioListings, {
        args: [payload?.id || '', payload?.page || 1],
        successActionCreator: PortfolioInternalActions.getPortfolioListingsSuccess,
        failActionCreator: PortfolioInternalActions.getPortfolioListingsFailure,
      })
    );
  }

  public static handleGetPortfolioListingsSuccess(state: PortfolioState, action: PortfolioAction): PortfolioState | Loop<PortfolioState, PortfolioAction> {
    const { portfolio } = state;
    const listings = action.payload?.listings;
    const newPortfolio = PortfolioModel.deepCopy(portfolio);
    newPortfolio.setListings(listings);

    return {
      ...state,
      portfolio: newPortfolio,
      loading: {
        ...state.loading,
        listings: false
      }
    };
  }

  public static handleGetPortfolioListingsFailure(state: PortfolioState): PortfolioState {
    return {
      ...state,
      error: new Error(PortfolioStateErrors.LISTINGS_FETCH),
      loading: {
        ...state.loading,
        listings: false
      }
    };
  }

  public static handleAddItemToPortfolio(state: PortfolioState, action: PortfolioAction): Loop<PortfolioState, PortfolioAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        action: true
      }
    };

    return loop(
      newState,
      Cmd.run(PortfolioApiClient.addItemToPortfolio, {
        args: [payload?.itemId || '', payload?.purchaseInfo || []],
        successActionCreator: PortfolioInternalActions.addItemToPortfolioSuccess,
        failActionCreator: PortfolioInternalActions.addItemToPortfolioFailure,
      })
    );
  }

  public static handleAddItemToPortfolioSuccess(state: PortfolioState, action: PortfolioAction): Loop<PortfolioState, PortfolioAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        action: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(PortfolioActions.getPortfolioById(payload?.portfolioId || '')),
      ])
    );
  }

  public static handleAddItemToPortfolioFailure(state: PortfolioState): PortfolioState {
    return {
      ...state,
      error: new Error(PortfolioStateErrors.ADD_ITEM_TO_PORTFOLIO),
      loading: {
        ...state.loading,
        action: false
      }
    };
  }

  public static handleRemoveItemFromPortfolio(state: PortfolioState, action: PortfolioAction): Loop<PortfolioState, PortfolioAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        action: true
      }
    };

    return loop(
      newState,
      Cmd.run(PortfolioApiClient.removeItemFromPortfolio, {
        args: [payload?.itemId || '', payload?.purchaseInfo || []],
        successActionCreator: PortfolioInternalActions.removeItemFromPortfolioSuccess,
        failActionCreator: PortfolioInternalActions.removeItemFromPortfolioFailure,
      })
    );
  }

  public static handleRemoveItemFromPortfolioSuccess(state: PortfolioState, action: PortfolioAction): Loop<PortfolioState, PortfolioAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        action: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(PortfolioActions.getPortfolioById(payload?.portfolioId || '')),
      ])
    );
  }

  public static handleRemoveItemFromPortfolioFailure(state: PortfolioState): PortfolioState {
    return {
      ...state,
      error: new Error(PortfolioStateErrors.REMOVE_ITEM_FROM_PORTFOLIO),
      loading: {
        ...state.loading,
        action: false
      }
    };
  }

  public static handleReplaceItemInPortfolio(state: PortfolioState, action: PortfolioAction): Loop<PortfolioState, PortfolioAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        action: true
      }
    };

    return loop(
      newState,
      Cmd.run(PortfolioApiClient.replaceItemInPortfolio, {
        args: [payload?.itemId || '', payload?.purchaseInfo || []],
        successActionCreator: PortfolioInternalActions.replaceItemInPortfolioSuccess,
        failActionCreator: PortfolioInternalActions.replaceItemInPortfolioFailure,
      })
    );
  }

  public static handleReplaceItemInPortfolioSuccess(state: PortfolioState, action: PortfolioAction): Loop<PortfolioState, PortfolioAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        action: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(PortfolioActions.getPortfolioById(payload?.portfolioId || ''))
      ])
    );
  }

  public static handleReplaceItemInPortfolioFailure(state: PortfolioState): PortfolioState {
    return {
      ...state,
      error: new Error(PortfolioStateErrors.REPLACE_ITEM_IN_PORTFOLIO),
      loading: {
        ...state.loading,
        action: false
      }
    };
  }

  public static handleClearPortfolio(state: PortfolioState): PortfolioState {
    return {
      ...state,
      portfolio: defaultState.portfolio
    };
  }
}
