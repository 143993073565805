import { ListingModel, PaginationModel } from 'models';
import { JSONObject } from 'models/Api/types';

export enum ListingActionTypes {
  SEARCH_LISTING_BY_QUERY = 'SEARCH_LISTING_BY_QUERY',
  SEARCH_LISTING_BY_QUERY_SUCCESS = 'SEARCH_LISTING_BY_QUERY_SUCCESS',
  SEARCH_LISTING_BY_QUERY_FAILURE = 'SEARCH_LISTING_BY_QUERY_FAILURE',
  SEARCH_LISTINGS = 'SEARCH_LISTINGS',
  SEARCH_LISTINGS_SUCCESS = 'SEARCH_LISTINGS_SUCCESS',
  SEARCH_LISTINGS_FAILURE = 'SEARCH_LISTINGS_FAILURE',
  GET_USER_FOLLOWING_ARTISTS_LISTINGS = 'GET_USER_FOLLOWING_ARTISTS_LISTINGS',
  GET_USER_FOLLOWING_ARTISTS_LISTINGS_SUCCESS = 'GET_USER_FOLLOWING_ARTISTS_LISTINGS_SUCCESS',
  GET_USER_FOLLOWING_ARTISTS_LISTINGS_FAILURE = 'GET_USER_FOLLOWING_ARTISTS_LISTINGS_FAILURE',
};

export interface ListingActionPayload {
  query?: string;
  page?: number;
  searchQuery?: JSONObject;
  paginatedListings?: PaginationModel<ListingModel>;
};

export interface ListingAction {
  type: ListingActionTypes;
  payload?: ListingActionPayload;
};

export interface ListingStateLoading {
  search: boolean;
};

export interface ListingState {
  paginatedListings: PaginationModel<ListingModel>;
  error: Error | undefined;
  loading: ListingStateLoading;
};
