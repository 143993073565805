import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export interface ScrollToTopProps {
  children: JSX.Element;
}

export default function ScrollToTop({ children }: ScrollToTopProps): JSX.Element | null {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return children || null;
};
