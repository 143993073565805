import {
  Avatar,
  Flex,
  Button,
  Icon,
  Text,
  Spacer,
  useColorModeValue
} from '@chakra-ui/react';
import {
  IconType
} from 'react-icons';
import {
  FaCube
} from 'react-icons/fa';
import { UserModel } from 'models';

export interface UserProfileBadgeProps {
  user: UserModel;
  showButton?: boolean;
  buttonText?: string;
  buttonIcon?: IconType;
  isButtonSelected?: boolean;
}

export default function UserProfileBadge({ user, showButton = true, buttonText = 'OVERVIEW', buttonIcon = FaCube, isButtonSelected = true }: UserProfileBadgeProps) {
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue("gray.100", "black");
  const borderProfileColor = useColorModeValue("white", "transparent");
  const emailColor = useColorModeValue("gray.400", "gray.300");

  function renderButton(title: string, icon: IconType, isButtonSelected: boolean = false) {
    if (showButton) {
      return (
        <Button p='0px' bg='transparent' variant='no-effects'>
          <Flex
            align='center'
            textAlign='left'
            bg={isButtonSelected ? useColorModeValue('#fff', 'navy.900') : ''}
            w={{ lg: "135px" }}
            borderRadius='15px'
            justifyContent='center'
            p='10px'
            boxShadow={isButtonSelected ? '0px 2px 5.5px rgba(0, 0, 0, 0.06)' : ''}
            cursor='pointer'>
            <Icon color={textColor} as={icon} me='6px' />
            <Text fontSize='xs' color={textColor} fontWeight='bold'>
              {title}
            </Text>
          </Flex>
        </Button>
      );
    }
  }

  function renderAvatar() {
    return (
      <Avatar
        src={user.getImage()}
        me={{ md: "22px" }}
        w='80px'
        h='80px'
      />
    );
  }

  function renderUserInfo() {
    return (
      <Flex direction='column' maxWidth='100%' my={{ sm: "14px" }} textAlign={'left'}>
        <Text
          fontSize={{ base: "md", md: "xl" }}
          color={textColor}
          fontWeight='bold'
          ms={{ sm: "8px", md: "0px" }}>
          {user.name}
        </Text>
        <Text
          fontSize={{ base: "sm", md: "md" }}
          color={emailColor}
          fontWeight='semibold'>
          {user.userName}
        </Text>
      </Flex>
    );
  }

  return (
    <Flex
      direction={{ sm: "column", md: "row" }}
      mb='24px'
      maxH='330px'
      gap={2}
      justifyContent={{ sm: "center", md: "space-between" }}
      align='center'
      backdropFilter='blur(21px)'
      boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
      border='1.5px solid'
      borderColor={borderProfileColor}
      bg={bgProfile}
      p='24px'
      borderRadius='20px'>
      <Flex
        align='center'
        mb={{ sm: "10px", md: "0px" }}
        direction={{ sm: "column", md: "row" }}
        w={{ sm: "100%" }}
        gap={2}
        textAlign={{ sm: "center", md: "start" }}>
        {renderAvatar()}
        {renderUserInfo()}
      </Flex>
      <Spacer/>
      <Flex
        direction={{ sm: "column", lg: "row" }}>
        {renderButton(buttonText, buttonIcon, isButtonSelected )}
      </Flex>
    </Flex>
  );
}
