import {
  Box,
  Stack,
  Flex,
  Text,
} from '@chakra-ui/react';

export interface FeatureProps {
  text: string | JSX.Element;
  icon: JSX.Element;
  iconBg?: string;
  iconBgSize?: number;
  subText?: string;
  subTextOrientation?: 'column' | 'row'
}

export default function Feature({ text, icon, iconBg = '', iconBgSize = 8, subText = '', subTextOrientation = 'column' }: FeatureProps) {
  return (
    <Box>
      <Stack direction={'row'} align={'center'} textAlign="left">
        <Flex
          w={iconBgSize}
          h={iconBgSize}
          align={'center'}
          justify={'center'}
          rounded={'full'}
          bg={iconBg}>
          {icon}
        </Flex>
        <Flex 
          direction={subTextOrientation} 
          alignItems={subTextOrientation === 'row' ? 'center' : 'flex-start'}
          gap={subTextOrientation === 'row' ? 1 : 0}
        >
          <Text fontWeight={600}>{text}</Text>
          <Text fontWeight={300} fontSize="xs" paddingLeft={1}>{subText}</Text>
        </Flex>
      </Stack>
    </Box>
  );
};
