import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { Toast } from 'models/types';

export class PopupSelectors {
  public static getToasts(state: ApplicationState): Toast[] {
    return RootSelectors.getPopupState(state).toasts;
  }

  public static getNextToastId(state: ApplicationState): number {
    return RootSelectors.getPopupState(state).nextToastId;
  }
}
