import { UrlConfig } from 'config';
import { AjaxService } from 'services';
import { ArtistConfig } from 'config';
import { ApiResponse } from 'models/Api/ApiResponse';
import { ArtistModel, ItemModel, ListingModel } from 'models';
import { arrayToCommaSeparatedString } from 'utils/format';
import { HomeFeedData, RawHomeFeedData } from 'models/Content/types';

export class ContentApiClient {
  public static async getHomeFeed(): Promise<HomeFeedData> {
    const enabledArtistIds = ArtistConfig.ENABLED_ARTISTS;
    const queryString = arrayToCommaSeparatedString(enabledArtistIds);
    const url = `${UrlConfig.CONTENT.HOME_FEED}?artist_ids=${queryString}`;
    const response: ApiResponse<RawHomeFeedData> = await AjaxService.getData(url);
    const homeFeedData: HomeFeedData = {
        featuredArtists: response.data.featured_artists.map(rawArtist => new ArtistModel(rawArtist)),
        featuredItems: response.data.featured_items.map(rawItem => new ItemModel(rawItem)),
        newReleasedItems: response.data.new_released_items.map(rawItem => new ItemModel(rawItem)),
        latestSales: response.data.latest_sales.map(rawListing => new ListingModel(rawListing)),
        biggestMarketMoverItems: response.data.biggest_market_mover_items.map(rawItem => new ItemModel(rawItem))
    };
    return homeFeedData;
  }
}
