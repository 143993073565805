import moment, { Moment } from 'moment';
import { Cloneable } from '../Utils/Cloneable';
import { RawMarketSummaryData } from './types';

export class MarketSummaryModel extends Cloneable {
  close: number;
  growth: number;
  max: number;
  mean: number;
  min: number;
  open: number;
  totalListings: number;
  totalSales: number;
  variance: number;
  estimatedValue: number;
  secondaryMarketPremium: number;
  lastSalePercentageChange: number;
  lastSaleDate: Moment | null;
  firstSaleDate: Moment | null;


  constructor(rawMarketSummaryData: RawMarketSummaryData) {
    super();
    this.close = rawMarketSummaryData.close;
    this.growth = Number((rawMarketSummaryData.growth || 0).toFixed(2));
    this.max = Number((rawMarketSummaryData.max || 0).toFixed(2));
    this.mean = Number((rawMarketSummaryData.mean || 0).toFixed(2));
    this.min = Number((rawMarketSummaryData.min || 0).toFixed(2));
    this.estimatedValue = Number((rawMarketSummaryData.estimated_value || 0).toFixed(2));
    this.open = rawMarketSummaryData.open;
    this.totalListings = rawMarketSummaryData.total_listings;
    this.totalSales = rawMarketSummaryData.total_sales;
    this.variance = Number((rawMarketSummaryData.variance || 0).toFixed(2));
    this.secondaryMarketPremium = Number((rawMarketSummaryData.secondary_market_premium || 0));
    this.lastSalePercentageChange = Number((rawMarketSummaryData.last_sale_percentage_change) || 0);
    this.lastSaleDate = rawMarketSummaryData.last_sale_date ? moment(rawMarketSummaryData.last_sale_date) : null;
    this.firstSaleDate = rawMarketSummaryData.first_sale_date ? moment(rawMarketSummaryData.first_sale_date) : null;
  }
}
