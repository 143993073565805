import moment from 'moment';
import { MetricModel } from './Metric';
import { RawMonthlySummary, RawMarketData, RawYearlySummary, MonthlySummary, YearlySummary, YearlySummaryItem, MarketChartData } from './types';
import { calculateMetricsForListings, getListingsByDateCombo } from 'utils/listing';
import { ListingModel } from '../Listing/Listing';

export class MarketModel {
  overview: MetricModel;
  yearlySummary: YearlySummary;

  constructor(rawMarketData: RawMarketData) {
    this.overview = new MetricModel(rawMarketData);
    this.yearlySummary = this.getYearlySummary(rawMarketData.yearly_summary);
  }

  public hasData(): boolean {
    return this.overview.totalListings > 0;
  }

  public getYearlySummary(rawYearlySummary: RawYearlySummary): YearlySummary {
    const yearlyOutput: YearlySummary = {};

    Object.entries(rawYearlySummary).forEach(([k,v]) => {
      yearlyOutput[Number(k)] = {
        overview: new MetricModel(v),
        monthlySummary: this.getMonthlySummary(v.monthly_summary || {})
      };
    });
    return yearlyOutput;
  }

  public getMonthlySummary(rawMonthlySummary: RawMonthlySummary): MonthlySummary {
    const monthlyOutput: MonthlySummary = {}
    Object.entries(rawMonthlySummary).forEach(([k,v]) => {
      monthlyOutput[Number(k)] = new MetricModel(v);
    });
    return monthlyOutput;
  }

  flattenSummary(): MetricModel[] {
    const flattenedOutput: MetricModel[] = [];
    Object.entries(this.yearlySummary).forEach(([_, v]) => {
      Object.entries(v.monthlySummary).forEach(([_, mv]) => {
        flattenedOutput.push(mv);
      });
    });
    return flattenedOutput;
  }

  getYearlyMarketFlattenedData(): MetricModel[] {
    const flattenedOutput: MetricModel[] = [];
    let startingIndex: number = this.getStartingYearForYearlySummary();
    let endingIndex: number = this.getEndingYearForYearlySummary();
    let objectIndex: number = startingIndex;
    for (let i = startingIndex; i <= endingIndex; i++) {
      let yearlyOverview: MetricModel;
      if (i in this.yearlySummary) {
        objectIndex = i;
        yearlyOverview = this.yearlySummary[objectIndex].overview;
      } else {
        yearlyOverview = MetricModel.deepCopy(this.yearlySummary[objectIndex].overview)
        yearlyOverview.key = i;
        yearlyOverview.variance = 0;
        yearlyOverview.open = yearlyOverview.close;
        yearlyOverview.max = 0;
        yearlyOverview.min = 0;
        yearlyOverview.growth = 0;
        yearlyOverview.totalListings = 0;
        yearlyOverview.totalSales = 0;
      }

      flattenedOutput.push(yearlyOverview);
    }

    return flattenedOutput;
  }

  getYearMarketFlattenedData(year: number): MetricModel[] {
    const flattenedOutput: MetricModel[] = [];
    if (!(year in this.yearlySummary)) {
      return flattenedOutput;
    }
    const yearlySummary: YearlySummaryItem = this.yearlySummary[year];
    let objectIndex: number = this.getStartingMonthForYear(yearlySummary);
    for(let i = 0; i <= 12; i++) {
      if (i in yearlySummary.monthlySummary) {
        objectIndex = i;
      }
      flattenedOutput.push(
        yearlySummary.monthlySummary[objectIndex]
      );
    }
    return flattenedOutput;
  }

  getStartingMonthForYear(yearSummary: YearlySummaryItem): number {
    const monthRange: number[] = Object.keys(yearSummary.monthlySummary).map(key => Number(key));
    return Math.min(...monthRange);
  }

  getStartingYearForYearlySummary(): number {
    const yearRange: number[] = Object.keys(this.yearlySummary).map(key => Number(key));
    return Math.min(...yearRange);
  }

  getEndingYearForYearlySummary(): number {
    const yearRange: number[] = Object.keys(this.yearlySummary).map(key => Number(key));
    return Math.max(...yearRange);
  }

  getMarketYearRange(): number[] {
    const yearRange = [];
    for (let i = this.getStartingYearForYearlySummary(); i <= this.getEndingYearForYearlySummary(); i++) {
      yearRange.push(i);
    }
    return yearRange;
  }

  public getOverviewMeanPrice(): number {
    return Math.round(this.overview.mean);
  }

  public getOverviewMaxPrice(): number {
    return Math.round(this.overview.max);
  }

  public getOverviewMinPrice(): number {
    return Math.round(this.overview.min);
  }

  public getOverviewClosingPrice(): number {
    return Math.round(this.overview.close);
  }

  public getOverviewGrowth(): number {
    return Math.round(this.overview.growth);
  }

  public getEstimatedMarketPrice(): number {
    let marketPrice = 0;
    const endingYear = this.getEndingYearForYearlySummary();
    if (this.yearlySummary && endingYear in this.yearlySummary) {
      marketPrice = this.yearlySummary[endingYear].overview.mean;
    }
    return marketPrice;
  }

  public getOverview(): MetricModel {
    return this.overview;
  }

  public getOverviewForYear(year: number): MetricModel | null {
    let rv = null
    if (year in this.yearlySummary) {
      rv = this.yearlySummary[year].overview;
    }
    return rv;
  }

  public getDataForCharts(): MarketChartData {
    return this.getChartDataFromFlattenedMarketData(this.flattenSummary());
  }

  public getChartDataFromFlattenedMarketData(data: MetricModel[]): MarketChartData {
    const marketData = this.getChartDataFromListData(data);
    marketData.meanPrice.labels.reverse();
    marketData.meanPrice.data.reverse();
    marketData.totalListings.labels.reverse();
    marketData.totalListings.data.reverse();
    marketData.variance.labels.reverse();
    marketData.variance.data.reverse();
    marketData.totalSales.labels.reverse();
    marketData.totalSales.data.reverse();
    marketData.growth.labels.reverse();
    marketData.growth.data.reverse();
    return marketData;
  }

  private getChartDataFromListData(listData: MetricModel[]): MarketChartData {
    const chartData: MarketChartData = {
      meanPrice: {
        labels: [],
        data: []
      },
      totalListings: {
        labels: [],
        data: []
      },
      variance: {
        labels: [],
        data: []
      },
      totalSales: {
        labels: [],
        data: []
      },
      growth: {
        labels: [],
        data: []
      }
    };

    for(let i = listData.length - 1; i >= 0; i -= 1) {
      chartData.meanPrice.labels.push(`${listData[i].key}`);
      chartData.meanPrice.data.push(listData[i].mean);

      chartData.totalListings.labels.push(`${listData[i].key}`);
      chartData.totalListings.data.push(listData[i].totalListings);

      chartData.variance.labels.push(`${listData[i].key}`);
      chartData.variance.data.push(listData[i].variance);

      chartData.totalSales.labels.push(`${listData[i].key}`);
      chartData.totalSales.data.push(listData[i].totalSales);

      chartData.growth.labels.push(`${listData[i].key}`);
      chartData.growth.data.push(listData[i].growth);
    }

    return chartData;
  }

  public getYearlyData(): YearlySummaryItem[] {
    return Object.values(this.yearlySummary);
  }

  public getMonthlyDataForYear(year: number): MetricModel[] {
    return year in this.yearlySummary ? Object.values(this.yearlySummary[year]?.monthlySummary || {}) : [];
  }

  public getDailyDataForMonthBasedOnListings(year: number, month: number, listings: ListingModel[]): MetricModel[] {
    let dailyData: MetricModel[] = [];
    const daysInMonth = moment({ year: year, month: month - 1 }).daysInMonth();
		for (let day = 1; day <= daysInMonth; day++) {
			const dailyListings = getListingsByDateCombo(listings, year, month, day);
			if (dailyListings.length > 0) {
				const dailyMetric = calculateMetricsForListings(dailyListings, day);
				dailyData.push(dailyMetric);
			}
		}
    return dailyData;
  }
}
