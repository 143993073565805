import { MarketDateFilter } from "models/types";
import { Moment } from "moment";

export function getYAxisTicks(minValue: number, maxValue: number): number[] {
	const range = maxValue - minValue;
	let interval;

	if (range <= 20) {
		interval = 5;
	} else if (range <= 100) {
		interval = 10;
	} else if (range <= 200) {
		interval = 25;
	} else if (range <= 500) {
		interval = 50;
	} else {
		interval = 100;
	}

	const ticks = [];
	for (let i = Math.floor(minValue / interval) * interval; i <= Math.ceil(maxValue / interval) * interval; i += interval) {
		ticks.push(i);
	}

	return ticks;
};

export function gradientOffset<T extends { [key: string]: any }>(data: T[], key: keyof T, threshold: number): number {
	const dataMax = Math.max(...data.map((i) => i[key]));
	const dataMin = Math.min(...data.map((i) => i[key]));

	if (dataMax <= threshold) {
		return 0;
	}
	if (dataMin >= threshold) {
		return 1;
	}

	return dataMax / (dataMax - dataMin);
}

export function getXAxisTicksAmountForTimeChart(isMobile: boolean, marketFilter?: MarketDateFilter): number {
	let totalTicks = 8;
	if (marketFilter && marketFilter === MarketDateFilter.SIX_MONTHS) {
		totalTicks = 6;
	}
	if (isMobile) {
		totalTicks = 4;
	}

	return totalTicks;
}