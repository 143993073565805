import moment, { Moment } from 'moment';
import { RawListingData, ListingType, ListingStatus } from './types';
import { MeasurementUnit, MeasurementData } from '../types';
import { RawItemData } from 'models/Item/types';
import { ItemModel } from 'models/Item/Item';
import { toDollarFormat } from 'utils/format';

export class ListingModel {
  id: string;
  artist: string;
  artistId: string;
  sourceId: string;
  date: Moment | null;
  image: string;
  sourceLink: string;
  name: string;
  price: string;
  processingStatus: ListingStatus;
  rawPrice: number;
  source: string;
  status: string;
  type: ListingType;
  items: (string | ItemModel)[];
  authentic: boolean;
  measurements: string;
  dimensions: MeasurementData | null;
  material: string;
  materialIds: string[];



  constructor(rawListingData: RawListingData) {
    this.id = rawListingData.id;
    this.artist = rawListingData.artist;
    this.artistId = rawListingData.artist_id;
    this.sourceId = rawListingData.source_id;
    this.date = rawListingData.date ? moment(rawListingData.date) : null;
    this.image = rawListingData.image;
    this.sourceLink = rawListingData.link;
    this.name = rawListingData.name;
    this.price = rawListingData.price;
    this.processingStatus = rawListingData.processing_status;
    this.rawPrice = rawListingData.raw_price;
    this.source = rawListingData.source;
    this.status = rawListingData.status;
    this.type = rawListingData.type || ListingType.UNKNOWN;
    this.items = rawListingData.items.map(item => {
      if (typeof item === 'string') {
        return item;
      } else {
        return new ItemModel(item as RawItemData);
      }
    });
    this.authentic = rawListingData.authentic;
    this.measurements = rawListingData?.measurements || '';
    this.dimensions = rawListingData?.dimensions || null;
    this.material = rawListingData?.material || '';
    this.materialIds = rawListingData?.material_ids || [];
  }

  public getFormattedDate(format: string = 'MMMM DD, YYYY') {
    return this.date ? this.date.format(format) : '';
  }

  public getRawPriceForDisplay(): string {
    return this.rawPrice.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  public getDateYear(): number {
    return this.date?.year() || 0
  }

  public isOriginal(): boolean {
    return this.type === ListingType.ORIGINAL;
  }

  public isSet(): boolean {
    return this.type === ListingType.SET;
  }

  public isAuthentic(): boolean {
    return this.authentic;
  }

  public getItemIds(): string[] {
    return this.items.map(item => item instanceof ItemModel ? item.id : item);
  }

  public getItems(): ItemModel[] {
    return this.items.filter(item => item instanceof ItemModel) as ItemModel[];
  }

  public getAssociatedItem(): ItemModel | undefined {
    return this.getItems()?.[0] || undefined;
  }

  public isGroupingPending(): boolean {
    return this.processingStatus === ListingStatus.GROUPING_PENDING;
  }

  public getDimensionsForDisplay(unit?: MeasurementUnit): string {
    let dimensions: number[] = [];
    const multiplier = unit === MeasurementUnit.IN ? 0.393701 : 1
    const unitLabel = (unit || MeasurementUnit.CM).toLowerCase();
    if (this.dimensions?.l) {
      dimensions.push(Math.round(this.dimensions.l * multiplier));
    }

    if (this.dimensions?.w) {
      dimensions.push(Math.round(this.dimensions.w * multiplier));
    }

    if (this.dimensions?.h) {
      dimensions.push(Math.round(this.dimensions.h * multiplier));
    }

    return dimensions.length ? dimensions.join(' x ') + ` ${unitLabel}` : '';
  }

  public getMaterialIds(): string[] {
    return this.materialIds;
  }

  public hasDimensions(): boolean {
    return !!this.dimensions?.l || !!this.dimensions?.w || !!this.dimensions?.h;
  }

  public getArea(): number {
    const possibleAreas: number[] = [0];
    if (this.dimensions?.l && this.dimensions?.w) {
      possibleAreas.push(this.dimensions.l * this.dimensions.w);
    } else if (this.dimensions?.l && this.dimensions?. h) {
      possibleAreas.push(this.dimensions.l * this.dimensions.h);
    } else if (this.dimensions?.h && this.dimensions?.w) {
      possibleAreas.push(this.dimensions.h * this.dimensions.w);
    }
    return Math.max(...possibleAreas);
  }

  public getPricePerCm2(): number {
    const area = this.getArea();
    if (area) {
      return this.rawPrice / area;
    } else {
      return 0;
    }
  }

  public getPricePerCm2ForDisplay(): string {
    const pricePerCm2 = this.getPricePerCm2();
    if (pricePerCm2) {
      return toDollarFormat(pricePerCm2, true) + '/cm²'
    } else {
      return '';
    }
  }
}


