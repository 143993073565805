import { Loop, LoopReducer } from 'redux-loop';
import { WishlistState, WishlistAction, WishlistActionTypes } from './types';
import { defaultState } from './consts';
import { WishlistHandlers } from './handlers';

export const wishlistReducer: LoopReducer<WishlistState, WishlistAction> = (
  state: WishlistState = defaultState,
  action: WishlistAction
): WishlistState | Loop<WishlistState, WishlistAction> => {
  switch (action.type) {
    case WishlistActionTypes.GET_USER_WISHLIST:
      return WishlistHandlers.handleGetUserWishlist(state);
    case WishlistActionTypes.GET_USER_WISHLIST_SUCCESS:
      return WishlistHandlers.handleGetUserWishlistSuccess(state, action);
    case WishlistActionTypes.GET_USER_WISHLIST_FAILURE:
      return WishlistHandlers.handleGetUserWishlistFailure(state);
    case WishlistActionTypes.GET_WISHLIST_BY_ID:
      return WishlistHandlers.handleGetWishlistById(state, action);
    case WishlistActionTypes.GET_WISHLIST_BY_ID_SUCCESS:
      return WishlistHandlers.handleGetWishlistByIdSuccess(state, action);
    case WishlistActionTypes.GET_WISHLIST_BY_ID_FAILURE:
      return WishlistHandlers.handleGetWishlistByIdFailure(state);
    case WishlistActionTypes.GET_WISHLIST_ARTISTS:
      return WishlistHandlers.handleGetWishlistArtists(state, action);
    case WishlistActionTypes.GET_WISHLIST_ARTISTS_SUCCESS:
      return WishlistHandlers.handleGetWishlistArtistsSuccess(state, action);
    case WishlistActionTypes.GET_WISHLIST_ARTISTS_FAILURE:
      return WishlistHandlers.handleGetWishlistArtistsFailure(state);
    case WishlistActionTypes.GET_WISHLIST_MARKET:
      return WishlistHandlers.handleGetWishlistMarket(state, action);
    case WishlistActionTypes.GET_WISHLIST_MARKET_SUCCESS:
      return WishlistHandlers.handleGetWishlistMarketSuccess(state, action);
    case WishlistActionTypes.GET_WISHLIST_MARKET_FAILURE:
      return WishlistHandlers.handleGetWishlistMarketFailure(state);
    case WishlistActionTypes.GET_WISHLIST_LISTINGS:
      return WishlistHandlers.handleGetWishlistListings(state, action);
    case WishlistActionTypes.GET_WISHLIST_LISTINGS_SUCCESS:
      return WishlistHandlers.handleGetWishlistListingsSuccess(state, action);
    case WishlistActionTypes.GET_WISHLIST_LISTINGS_FAILURE:
      return WishlistHandlers.handleGetWishlistListingsFailure(state);
    case WishlistActionTypes.ADD_ITEM_TO_WISHLIST:
      return WishlistHandlers.handleAddItemToWishlist(state, action);
    case WishlistActionTypes.ADD_ITEM_TO_WISHLIST_SUCCESS:
      return WishlistHandlers.handleAddItemToWishlistSuccess(state, action);
    case WishlistActionTypes.ADD_ITEM_TO_WISHLIST_FAILURE:
      return WishlistHandlers.handleAddItemToWishlistFailure(state);
    case WishlistActionTypes.REMOVE_ITEM_FROM_WISHLIST:
      return WishlistHandlers.handleRemoveItemFromWishlist(state, action);
    case WishlistActionTypes.REMOVE_ITEM_FROM_WISHLIST_SUCCESS:
      return WishlistHandlers.handleRemoveItemFromWishlistSuccess(state, action);
    case WishlistActionTypes.REMOVE_ITEM_FROM_WISHLIST_FAILURE:
      return WishlistHandlers.handleRemoveItemFromWishlistFailure(state);
    case WishlistActionTypes.CLEAR_WISHLIST:
      return WishlistHandlers.handleClearWishlist(state);
    default:
      return state;
  }
}
