import { Controller, useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  InputGroup,
  FormLabel,
  InputLeftElement,
  Input,
  FormErrorMessage,
  Checkbox,
  Flex,
  Spacer
} from '@chakra-ui/react';
import {
  AiFillPrinter
} from 'react-icons/ai';
import {
  FaRegClone
} from 'react-icons/fa';
import {
  BiCalendar
} from 'react-icons/bi';
import {
  BsTextareaT
} from 'react-icons/bs';
import { getFilteredObject } from 'utils/object';
import DimensionsInput from '../../Input/DimensionsInput/DimensionsInput';
import ArtistTable from '../../Artist/ArtistTable/ArtistTable';
import MaterialDropdown from '../../Material/MaterialDropdown/MaterialDropdown';
import MaterialTable from '../../Material/MaterialTable/MaterialTable';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import FilepondUploader from '../../Uploader/FilepondUploader/FilepondUploader';
import ActionButton from '../../Button/ActionButton';
import { ItemModel, ArtistModel, MaterialModel } from 'models';
import { AuthenticityInfoData } from 'models/Item/types';
import { MeasurementData } from 'models/types';

export type ItemDataSuggestionFormOption = 'releasePrice' | 'date' | 'publisher' | 'edition' | 'dimensions' | 'imageUrl' | 'name' | 'artistId' | 'handTreated' | 'authenticityInfo' | 'materialIds';

export interface ItemDataSuggestionFormProps {
  item?: ItemModel;
  artistList?: ArtistModel[];
  materialList?: MaterialModel[];
  defaultFormData?: ItemDataSuggestionFormData;
  onSubmit?: (data: ItemDataSuggestionFormData) => void;
  submitLoading?: boolean;
  displayOptions?: ItemDataSuggestionFormOption[];
  requiredOptions?: ItemDataSuggestionFormOption[];
  disabledOptions?: ItemDataSuggestionFormOption[];
}

export interface ItemDataSuggestionFormData {
  releasePrice?: number;
  date?: Date | string | undefined;
  publisher?: string;
  material?: string;
  edition?: number;
  dimensions?: MeasurementData;
  imageUrl?: string;
  name?: string;
  artistId?: string;
  materialIds?: string[];
  handTreated?: boolean;
  authenticityInfo?: AuthenticityInfoData;
}

export default function ItemDataSuggestionForm({ item, artistList, materialList, defaultFormData, onSubmit, submitLoading, displayOptions, requiredOptions = [], disabledOptions = [] }: ItemDataSuggestionFormProps) {
  const {
    control,
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors, isDirty, dirtyFields },
  } = useForm<ItemDataSuggestionFormData>({ defaultValues: defaultFormData });

  const formDisplayOptions: Record<string, boolean> = (
    displayOptions || ['releasePrice', 'date', 'publisher', 'edition', 'dimensions', 'handTreated', 'authenticityInfo', 'materialIds']
  ).reduce((acc: Record<string, boolean>, curr: ItemDataSuggestionFormOption) => (acc[curr] = true, acc), {});

  const formDisabledOptions: Record<string, boolean> = disabledOptions.reduce((acc: Record<string, boolean>, curr: ItemDataSuggestionFormOption) => (acc[curr] = true, acc), {});

  const formRequiredOptions: Record<string, boolean> = requiredOptions.reduce((acc: Record<string, boolean>, curr: ItemDataSuggestionFormOption) => (acc[curr] = true, acc), {});

  const onFormSubmit = (data: ItemDataSuggestionFormData) => {
    const changedData = getFilteredObject(Object.keys(dirtyFields), data as Record<string, unknown>);
    onSubmit?.(changedData);
  }

  function renderImageUploader() {
    const shouldDisplay = !!formDisplayOptions['imageUrl'];
    const isRequired = !!formRequiredOptions['imageUrl'];
    const isDisabled = !!formDisabledOptions['imageUrl'];

    return shouldDisplay && (
      <FormControl isInvalid={isRequired && !watch('imageUrl')?.length} isRequired={isRequired} isDisabled={isDisabled}>
        <FormLabel htmlFor="imageUrl" fontSize="sm">Image</FormLabel>
        <Controller
          control={control}
          name="imageUrl"
          rules={{ required: isRequired }}
          render={() => (
            <FilepondUploader
              onFileProcess={(url) => setValue('imageUrl', url, { shouldDirty: true })}
              onFileRevert={() => setValue('imageUrl', '')}
              maxFiles={1}
              label={'Drag & Drop your Image'} />
          )} />
        <FormErrorMessage>
          {(isRequired && !watch('imageUrl')?.length) && 'Image Must Be Uploaded'}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderName() {
    const shouldDisplay = !!formDisplayOptions['name'];
    const isRequired = !!formRequiredOptions['name'];
    const isDisabled = !!formDisabledOptions['name'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.name}>
        <FormLabel fontSize="sm" htmlFor="name">Name</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<BsTextareaT />}
          />
          <Input
            fontSize="sm"
            placeholder="Enter Name"
            {...register('name', {
              validate: {
                isDefined: v => (!isRequired || (v && v.length > 0)) || 'Name must be defined',
              }
            })} />
        </InputGroup>
        <FormErrorMessage>
          {errors.name && errors.name.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderRetailPrice() {
    const shouldDisplay = !!formDisplayOptions['releasePrice'];
    const isRequired = !!formRequiredOptions['releasePrice'];
    const isDisabled = !!formDisabledOptions['releasePrice'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.releasePrice}>
        <FormLabel htmlFor="releasePrice" fontSize="sm">Retail Price</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children='$'
          />
          <Input
            {...register('releasePrice', {
              validate: {
                isPositive: v => (!isRequired || (v && v > 0)) || 'Retail Price must be greater than 0',
              }
            })}
            fontSize="sm"
            type="number"
            placeholder='Enter Amount (USD)'
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.releasePrice && errors.releasePrice.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderDate() {
    const shouldDisplay = !!formDisplayOptions['date'];
    const isRequired = !!formRequiredOptions['date'];
    const isDisabled = !!formDisabledOptions['date'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.date}>
        <FormLabel fontSize="sm" htmlFor="date">Release Date</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<BiCalendar />}
          />
          <Input
            {...register('date', {
              required: 'Release Date is required',
              valueAsDate: true
            })}
            fontSize="sm"
            type="date"
            defaultValue={String(defaultFormData?.date)}
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.date && errors.date.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderPublisher() {
    const shouldDisplay = !!formDisplayOptions['publisher'];
    const isRequired = !!formRequiredOptions['publisher'];
    const isDisabled = !!formDisabledOptions['publisher'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.publisher}>
        <FormLabel fontSize="sm" htmlFor="publisher">Publisher</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<AiFillPrinter />}
          />
          <Input
            fontSize="sm"
            placeholder="Enter Publisher"
            {...register('publisher', {
              validate: {
                isDefined: v => (!isRequired || (v && v.length > 0)) || 'Publisher must be defined',
              }
            })} />
        </InputGroup>
        <FormErrorMessage>
          {errors.publisher && errors.publisher.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderEditionSize() {
    const shouldDisplay = !!formDisplayOptions['edition'];
    const isRequired = !!formRequiredOptions['edition'];
    const isDisabled = !!formDisabledOptions['edition'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.edition}>
        <FormLabel htmlFor="edition" fontSize="sm">Edition Size</FormLabel>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            color='gray.500'
            children={<FaRegClone />}
          />
          <Input
            {...register('edition', {
              validate: {
                isPositive: v => (!isRequired || (v && v > 0)) || 'Edition Size must be greater than 0',
              }
            })}
            fontSize="sm"
            type="number"
            placeholder='Enter Edition Size (Excl. APs & PPs)'
          />
        </InputGroup>
        <FormErrorMessage>
          {errors.edition && errors.edition.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderDimensions() {
    const shouldDisplay = !!formDisplayOptions['dimensions'];
    const isRequired = !!formRequiredOptions['dimensions'];
    const isDisabled = !!formDisabledOptions['dimensions'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.dimensions}>
        <FormLabel htmlFor="dimensions" fontSize="sm">Dimensions</FormLabel>
        <Controller
          control={control}
          name="dimensions"
          rules={{
            required: isRequired,
            validate: (dim) => (!isRequired || (!!dim && (!!dim.l || !!dim.h || !!dim.w))) || 'Item Dimensions must be specified'
          }}
          render={() => (
            <DimensionsInput
              defaultData={defaultFormData?.dimensions}
              onChange={(data) => setValue('dimensions', data, { shouldDirty: true })}
            />
          )} />
        <FormErrorMessage>
          {errors.dimensions && errors.dimensions.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderArtistTable() {
    const shouldDisplay = !!formDisplayOptions['artistId'] && (artistList || []).length > 0;
    const isRequired = !!formRequiredOptions['artistId'];
    const isDisabled = !!formDisabledOptions['artistId'];

    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.artistId}>
        <FormLabel htmlFor="artistId" fontSize="sm">Artist</FormLabel>
        <Controller
          control={control}
          name="artistId"
          rules={{
            required: isRequired,
            validate: (id) => (!isRequired || !!id) || 'Artist must be selected'
          }}
          render={() => (
            <ArtistTable
              artists={artistList || []}
              onSelect={(artistIds) => setValue('artistId', artistIds[0], { shouldDirty: true })}
              multiSelect={false}
            />
          )} />
        <FormErrorMessage>
          {errors.artistId && errors.artistId.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderMaterialsDropdown() {
    const shouldDisplay = !!formDisplayOptions['materialIds'] && (materialList || []).length > 0;
    const isRequired = !!formRequiredOptions['materialIds'];
    const isDisabled = !!formDisabledOptions['materialIds'];
    
    return shouldDisplay && (
      <FormControl isDisabled={isDisabled} isRequired={isRequired} isInvalid={!!errors.materialIds}>
        <FormLabel htmlFor="materialIds" fontSize="sm">Materials</FormLabel>
        <Controller
          control={control}
          name="materialIds"
          rules={{
            required: isRequired,
            validate: (values) => (!isRequired || !!values?.length) || 'Material must be selected'
          }}
          render={() => isDisabled ? (
            <MaterialDropdown
              disabled={isDisabled}
              materials={materialList || []}
              selectedMaterialIds={watch('materialIds')}
              onSelect={(materials) => setValue('materialIds', materials, { shouldDirty: true })}
            />
          ) : (
            <MaterialTable
              materials={materialList || []}
              selectedMaterialIds={watch('materialIds')}
              onSelect={(materials) => setValue('materialIds', materials, { shouldDirty: true })}
            />
          )} />
        <FormErrorMessage>
          {errors.materialIds && errors.materialIds.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderFinishesSection() {
    const shouldDisplay = !!formDisplayOptions['handTreated'];
    const isDisabled = !!formDisabledOptions['handTreated'];
    return shouldDisplay && (
      <FormControl isDisabled={isDisabled}>
        <FormLabel fontSize="sm">Finishing Details</FormLabel>
        <Flex direction="row" alignItems="center" gap={2} marginTop={2}>
          <Checkbox {...register('handTreated')} />
          <FormLabel htmlFor="handTreated" fontWeight={300} fontSize="xs" margin={0}>Hand Finished</FormLabel>
        </Flex>
        <FormErrorMessage>
          {errors.handTreated && errors.handTreated.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  function renderAuthenticityInfo() {
    const shouldDisplay = !!formDisplayOptions['authenticityInfo'];
    const isDisabled = !!formDisabledOptions['authenticityInfo'];
    return shouldDisplay && (
      <FormControl isDisabled={isDisabled}>
        <FormLabel fontSize="sm">Authenticity Information</FormLabel>
        <Flex direction="row" alignItems="center" marginTop={2}>
          <Flex direction="row" alignItems="center" gap={2}>
            <Checkbox {...register('authenticityInfo.signed')} />
            <FormLabel htmlFor="authenticityInfo.signed" fontWeight={300} fontSize="xs" margin={0}>Signed</FormLabel>
          </Flex>
          <Spacer />
          <Flex direction="row" alignItems="center" gap={2}>
            <Checkbox {...register('authenticityInfo.numbered')} />
            <FormLabel htmlFor="authenticityInfo.numbered" fontWeight={300} fontSize="xs" margin={0}>Numbered</FormLabel>
          </Flex>
          <Spacer />
          <Flex direction="row" alignItems="center" gap={2}>
            <Checkbox {...register('authenticityInfo.dated')} />
            <FormLabel htmlFor="authenticityInfo.dated" fontWeight={300} fontSize="xs" margin={0}>Dated</FormLabel>
          </Flex>
          <Spacer />
          <Flex direction="row" alignItems="center" gap={2}>
            <Checkbox {...register('authenticityInfo.certificateIssued')} />
            <FormLabel htmlFor="authenticityInfo.certificateIssued" fontWeight={300} fontSize="xs" margin={0}>C.O.A Issued</FormLabel>
          </Flex>
        </Flex>
      </FormControl>
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={8}>
        {!!item && <BasicItemCard item={item} />}
        {renderImageUploader()}
        {renderArtistTable()}
        {renderName()}
        {renderRetailPrice()}
        {renderDate()}
        {renderPublisher()}
        {renderMaterialsDropdown()}
        {renderEditionSize()}
        {renderDimensions()}
        {renderFinishesSection()}
        {renderAuthenticityInfo()}
        <Stack alignItems="center">
          <ActionButton type='submit' text={'Submit'} loading={submitLoading} size={'md'} disabled={!isDirty} />
        </Stack>
      </Stack>
    </form>
  );
}
