import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { UserActions } from 'reducers/User/actions';
import { UserSelectors } from 'reducers/User/selectors';
import {
  Box,
  Flex,
  Text,
} from '@chakra-ui/react';
import { UserModel } from 'models';
import { JSONObject } from 'models/Api/types';
import { UserDataForm, ChangePasswordForm, TabGroup, UserAvatarUploadForm } from 'components';
import { ChangePasswordFormData } from 'components/Form/ChangePasswordForm/ChangePasswordForm';
import { UserDataFormData } from 'components/Form/UserDataForm/UserDataForm';
import { AppConstants } from '../../constants';
import { convertJSONObjectToSnakecase } from 'utils/object';

interface UserSettingsViewProps extends RouterProps {
  user: UserModel;
  migrateUserImage: (url: string) => void;
  changePassword: (oldPassword: string, newPassword: string) => void;
  updateUserData: (userData: JSONObject) => void;
  userImageActionLoading: boolean;
  userPasswordActionLoading: boolean;
  userUpdateActionLoading: boolean;
}

class UserSettings extends Component<UserSettingsViewProps, {}> {
  getUserDataFormDefaultData = (): UserDataFormData => {
    const { user } = this.props;
    let data: UserDataFormData = {};
    if (user.name) {
      data.name = user.name;
    }

    if (user.country && user.country.code) {
      data.country = {
        code: user.country.code.toUpperCase(),
        name: user.country.name
      };
    }

    if (user.number && user.number.baseNumber) {
      data.number = {
        baseNumber: user.number.baseNumber,
        countryCode: user.number.countryCode
      };
    }

    if (user.birthYear) {
      data.birthYear = user.birthYear;
    }

    if (user.instagram) {
      data.instagram = user.instagram;
    }

    return data;
  }

  onUserDataFormSubmit = (data: UserDataFormData) => {
    const { updateUserData } = this.props;
    const snakeCasedData: JSONObject = convertJSONObjectToSnakecase(data as JSONObject);
    updateUserData(snakeCasedData);
  }

  onChangePasswordFormSubmit = (data: ChangePasswordFormData) => {
    this.props.changePassword(data.oldPassword, data.newPassword);
  };

  renderUserSettingsGeneral() {
    const { user, userUpdateActionLoading } = this.props;
    return (
      <UserDataForm user={user} submitLoading={userUpdateActionLoading} defaultFormData={this.getUserDataFormDefaultData()} displayOptions={['birthYear', 'country', 'biography', 'instagram', 'name', 'number']} submitText={'Update Information'} onSubmit={this.onUserDataFormSubmit} />
    );
  }

  renderUserPasswordChange() {
    const { user, userPasswordActionLoading } = this.props;
    return (
      <ChangePasswordForm user={user} onSubmit={this.onChangePasswordFormSubmit} submitLoading={userPasswordActionLoading} />
    )
  }

  renderUserAvatarChange() {
    const { user, migrateUserImage, userImageActionLoading } = this.props;
    return (
      <UserAvatarUploadForm user={user} onSubmit={migrateUserImage} submitLoading={userImageActionLoading} />
    )
  }

  renderTabs() {
    const labels = [
      <Flex gap="4px" alignItems="center" direction={'row'}>
        <Text> General </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={'row'}>
        <Text> Change Password </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={'row'}>
        <Text> Change Avatar </Text>
      </Flex>
    ];
    const content = [
      this.renderUserSettingsGeneral(),
      this.renderUserPasswordChange(),
      this.renderUserAvatarChange()
    ];

    return (
      <TabGroup labels={labels} content={content} orientation={'vertical'} />
    );
  }

  render() {
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
        {this.renderTabs()}
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: UserSelectors.getUser(state),
    userImageActionLoading: UserSelectors.getUserImageActionLoading(state),
    userPasswordActionLoading: UserSelectors.getUserPasswordActionLoading(state),
    userUpdateActionLoading: UserSelectors.getUserUpdateActionLoading(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      migrateUserImage: (url: string) => UserActions.migrateImage(url),
      changePassword: (oldPassword: string, newPassword: string) => UserActions.changePassword(oldPassword, newPassword),
      updateUserData: (userData: JSONObject) => UserActions.updateUserData(userData),
    },
    dispatch
  );
}

export const UserSettingsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserSettings));
