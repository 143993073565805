import { WishlistModel, ArtistModel, CompositeMarketModel, PaginationModel, ListingModel } from 'models';

export enum WishlistActionTypes {
  GET_USER_WISHLIST = 'WISHLIST_GET_USER_WISHLIST',
  GET_USER_WISHLIST_SUCCESS = 'WISHLIST_GET_USER_WISHLIST_SUCCESS',
  GET_USER_WISHLIST_FAILURE = 'WISHLIST_GET_USER_WISHLIST_FAILURE',

  GET_WISHLIST_BY_ID = 'WISHLIST_GET_WISHLIST_BY_ID',
  GET_WISHLIST_BY_ID_SUCCESS = 'WISHLIST_GET_WISHLIST_BY_ID_SUCCESS',
  GET_WISHLIST_BY_ID_FAILURE = 'WISHLIST_GET_WISHLIST_BY_ID_FAILURE',

  GET_WISHLIST_ARTISTS = 'WISHLIST_GET_WISHLIST_ARTISTS',
  GET_WISHLIST_ARTISTS_SUCCESS = 'WISHLIST_GET_WISHLIST_ARTISTS_SUCCESS',
  GET_WISHLIST_ARTISTS_FAILURE = 'WISHLIST_GET_WISHLIST_ARTISTS_FAILURE',

  GET_WISHLIST_MARKET = 'WISHLIST_GET_WISHLIST_MARKET',
  GET_WISHLIST_MARKET_SUCCESS = 'WISHLIST_GET_WISHLIST_MARKET_SUCCESS',
  GET_WISHLIST_MARKET_FAILURE = 'WISHLIST_GET_WISHLIST_MARKET_FAILURE',

  GET_WISHLIST_LISTINGS = 'WISHLIST_GET_WISHLIST_LISTINGS',
  GET_WISHLIST_LISTINGS_SUCCESS = 'WISHLIST_GET_WISHLIST_LISTINGS_SUCCESS',
  GET_WISHLIST_LISTINGS_FAILURE = 'WISHLIST_GET_WISHLIST_LISTINGS_FAILURE',

  ADD_ITEM_TO_WISHLIST = 'WISHLIST_ADD_ITEM_TO_WISHLIST',
  ADD_ITEM_TO_WISHLIST_SUCCESS = 'WISHLIST_ADD_ITEM_TO_WISHLIST_SUCCESS',
  ADD_ITEM_TO_WISHLIST_FAILURE = 'WISHLIST_ADD_ITEM_TO_WISHLIST_FAILURE',

  REMOVE_ITEM_FROM_WISHLIST = 'WISHLIST_REMOVE_ITEM_FROM_WISHLIST',
  REMOVE_ITEM_FROM_WISHLIST_SUCCESS = 'WISHLIST_REMOVE_ITEM_FROM_WISHLIST_SUCCESS',
  REMOVE_ITEM_FROM_WISHLIST_FAILURE = 'WISHLIST_REMOVE_ITEM_FROM_WISHLIST_FAILURE',

  CLEAR_WISHLIST = 'WISHLIST_CLEAR_WISHLIST'
};

export interface WishlistActionPayload {
  id?: string;
  itemId?: string;
  market?: CompositeMarketModel;
  wishlist?: WishlistModel;
  artistList?: ArtistModel[];
  page?: number; 
  listings?: PaginationModel<ListingModel>;
  wishlistId?: string;
};

export interface WishlistAction {
  type: WishlistActionTypes;
  payload?: WishlistActionPayload;
};

export interface WishlistStateLoading {
  item: boolean;
  action: boolean;
  artists: boolean;
  market: boolean;
  listings: boolean;
};

export interface WishlistState {
  wishlist: WishlistModel;
  error: Error | undefined;
  loading: WishlistStateLoading;
};
