import { UrlConfig } from 'config';
import { PortfolioModel, ArtistModel, CompositeMarketModel, DetailedPortfolioItemModel, PortfolioHistoryModel, PaginationModel, ListingModel } from 'models';
import { RawPortfolioData, ItemPurchaseInfo, RawDetailedPortfolioItemData, RawPortfolioHistory } from 'models/Portfolio/types';
import { RawArtistData } from 'models/Artist/Artist';
import { RawListingData } from 'models/Listing/types';
import { RawPaginationData } from 'models/Pagination/Pagination';
import { RawCompositeMarketData } from 'models/Market/types';
import { ApiResponse } from 'models/Api/ApiResponse';
import { AjaxService } from 'services';

export class PortfolioApiClient {
  public static async getPortfolioById(id: string): Promise<PortfolioModel> {
    const url = UrlConfig.PORTFOLIO.PORTFOLIO_BY_ID + `${id}`;
    const response: ApiResponse<RawPortfolioData> = await AjaxService.getData(url);
    const portfolio = PortfolioModel.fromRawPortfolioData(response.data);
    return portfolio;
  }

  public static async getPortfolioArtists(id: string): Promise<ArtistModel[]> {
    const url = UrlConfig.PORTFOLIO.PORTFOLIO_BY_ID + `${id}/artists`;
    const response: ApiResponse<RawArtistData[]> = await AjaxService.getData(url);
    const artistList = response.data.map((rawArtist: RawArtistData) => new ArtistModel(rawArtist));
    return artistList;
  }

  public static async getPortfolioHistory(id: string): Promise<PortfolioHistoryModel> {
    const url = UrlConfig.PORTFOLIO.PORTFOLIO_HISTORY + id;
    const response: ApiResponse<RawPortfolioHistory> = await AjaxService.getData(url);
    const portfolioHistory = new PortfolioHistoryModel(response.data);
    return portfolioHistory;
  }

  public static async getPortfolioListings(id: string, page: number = 1): Promise<PaginationModel<ListingModel>> {
    const url = UrlConfig.PORTFOLIO.PORTFOLIO_BY_ID + `${id}/listings?page=${page}`;
    const response: ApiResponse<RawPaginationData<RawListingData>> = await AjaxService.getData(url);
    const paginatedRespose: PaginationModel<RawListingData> = new PaginationModel(response.data);
    const formattedListings = paginatedRespose.data.map((rawListing: RawListingData) => new ListingModel(rawListing));
    const formattedPaginatedResponse: PaginationModel<ListingModel> = new PaginationModel({ ...response.data, data: formattedListings, fetched: true });
    return formattedPaginatedResponse;
  }

  public static async getPortfolioItems(id: string): Promise<DetailedPortfolioItemModel[]> {
    const url = UrlConfig.PORTFOLIO.PORTFOLIO_BY_ID + `${id}/items`;
    const response: ApiResponse<RawDetailedPortfolioItemData[]> = await AjaxService.getData(url);
    const items = response.data.map((rawItem: RawDetailedPortfolioItemData) => new DetailedPortfolioItemModel(rawItem));
    return items;
  }

  public static async addItemToPortfolio(item_id: string, purchase_info: ItemPurchaseInfo[]): Promise<string> {
    const url = UrlConfig.PORTFOLIO.PORTFOLIO_ADD_ITEM;
    const response: ApiResponse<string> = await AjaxService.postData(url, {
      item_id: item_id,
      purchase_info: purchase_info.map(item => ({
        quantity: item.quantity,
        price: item.price,
        date: item.date.format()
      }))
    });
    return response.data;
  }

  public static async removeItemFromPortfolio(item_id: string, purchase_info: ItemPurchaseInfo[]): Promise<string> {
    const url = UrlConfig.PORTFOLIO.PORTFOLIO_REMOVE_ITEM;
    const response: ApiResponse<string> = await AjaxService.postData(url, {
      item_id: item_id,
      purchase_info: purchase_info.map(item => ({
        quantity: item.quantity,
        price: item.price,
        date: item.date.format()
      }))
    });
    return response.data;
  }

  public static async replaceItemInPortfolio(item_id: string, purchase_info: ItemPurchaseInfo[]): Promise<string> {
    const url = UrlConfig.PORTFOLIO.PORTFOLIO_REPLACE_ITEM;
    const response: ApiResponse<string> = await AjaxService.postData(url, {
      item_id: item_id,
      purchase_info: purchase_info.map(item => ({
        quantity: item.quantity,
        price: item.price,
        date: item.date.format()
      }))
    });
    return response.data
  }
}
