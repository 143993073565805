import { ListingModel } from 'models';
import MarketListingTooltip from './MarketListingTooltip';
import MarketVolumeTooltip from './MarketVolumeTooltip';
import { getListingsByDateCombo } from 'utils/listing';
import { formatDate } from 'utils/date';
import { MarketTooltipData } from '../types';

interface MarketVolumeChartTooltipProps extends MarketTooltipData {
    selectedYear?: number | null;
    selectedMonth?: number | null;
    listings?: ListingModel[];
}

export default function MarketVolumeChartTooltip(props: MarketVolumeChartTooltipProps) {
    const { payload, active, selectedYear, selectedMonth, listings = [] } = props;
    const metricData = payload?.[0]?.payload;
    if (!metricData) return null;

    const getLabel = () => {
		if (selectedYear && selectedMonth) {
			return formatDate(selectedYear, selectedMonth, Number(metricData.key))
		} else if (selectedYear) {
			return formatDate(selectedYear, Number(metricData.key))
		} else {
			return formatDate(Number(metricData.key))
		}
	}

    let tooltipListings: ListingModel[] = [];

    if (selectedYear && selectedMonth) {
        tooltipListings = getListingsByDateCombo(listings, selectedYear, selectedMonth, Number(metricData.key));
    } else if (metricData.totalListings === 1 && selectedYear) {
        tooltipListings = getListingsByDateCombo(listings, selectedYear, Number(metricData.key));
    } else if (metricData.totalListings === 1 && !selectedYear) {
        tooltipListings = getListingsByDateCombo(listings, Number(metricData.key));
    }

    if (tooltipListings.length > 0) {
        return <MarketListingTooltip listings={tooltipListings} active={active} label={getLabel()} />;
    }

    return <MarketVolumeTooltip payload={payload} active={active} label={getLabel()} />;
}
