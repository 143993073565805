import moment, { Moment } from 'moment';
import { Cloneable } from '../Utils/Cloneable';
import { MarketDateFilter } from 'models/types';
import { getCurrentDate } from 'utils/date';

export interface RawArtistIndexData {
	date: string;
	index: number;
	arp: number;
	normalized_arp: number;
	weighted_index: number
}

export interface ArtistIndexData {
	date: Moment | null;
	index: number;
	arp: number;
	normalizedArp: number;
	weightedIndex: number;
}

export interface RawArtistIndex {
	id: string;
	artist_id: string;
	type: string;
	index_data: RawArtistIndexData[];
	recalculate: boolean;
	last_calculation_date: string;
}

export class ArtistIndexModel extends Cloneable {
	id: string;
	artistId: string;
	type: string;
	indexData: ArtistIndexData[];
	backfilledData: ArtistIndexData[];
	recalculate: boolean;
	lastCalculationDate: Moment | null;

	constructor(artistIndexData?: RawArtistIndex) {
		super();
		this.id = artistIndexData?.id || '';
		this.artistId = artistIndexData?.artist_id || '';
		this.type = artistIndexData?.type || '';
		this.indexData = (artistIndexData?.index_data || [])
			.map((rawPriceData: RawArtistIndexData): ArtistIndexData => {
				return {
					index: rawPriceData.index,
					date: moment(rawPriceData.date),
					arp: rawPriceData.arp,
					normalizedArp: rawPriceData.normalized_arp,
					weightedIndex: rawPriceData.weighted_index
				}
			});
		this.backfilledData = this.getBackfilledIndexData()
		this.recalculate = !!artistIndexData?.recalculate;
		this.lastCalculationDate = artistIndexData?.last_calculation_date ? moment(artistIndexData.last_calculation_date) : null;
	}

	public getPriceData(): ArtistIndexData[] {
		return this.indexData || [];
	}

	public getDateSortedIndexData(): ArtistIndexData[] {
		return this.indexData.filter(indexData => indexData.date != null)
		.sort((a, b) => {
			if (a.date && b.date) {
				return b.date.diff(a.date);
			}
			return 0;
		});

	}

	public getMostRecentIndexData(): ArtistIndexData | null {
		if (this.indexData.length) {
			return this.indexData[0];
		} else {
			return null;
		}
	}

	public getSecondMostRecentIndexData(): ArtistIndexData | null {
		if (this.indexData.length > 1) {
			return this.indexData[1];
		} else {
			return null;
		}
	}

	public getIndexDataUniqueYears(): number[] {
		const years = this.indexData.map(indexData => indexData.date ? indexData.date.year() : null)
									.filter(year => year !== null);
		return Array.from(new Set(years)) as number[];
	}

	public filterDataByMarketDateFilter = (range: MarketDateFilter, useBackfilledData?: boolean) => {
		const endDate = moment();
		let startDate: Moment;
		const data = useBackfilledData ? this.backfilledData : this.indexData;
	
		switch (range) {
			case MarketDateFilter.FIVE_YEARS:
				startDate = endDate.clone().subtract(5, 'years');
				break;
			case MarketDateFilter.THREE_YEARS:
				startDate = endDate.clone().subtract(3, 'years');
				break;
			case MarketDateFilter.ONE_YEAR:
				startDate = endDate.clone().subtract(1, 'years');
				break;
			case MarketDateFilter.SIX_MONTHS:
				startDate = endDate.clone().subtract(6, 'months');
				break;
			default:
				return data;
		}
		return data.filter(d => moment(d.date).isBetween(startDate, endDate, undefined, '[]'));
	};

	private getBackfilledIndexData(): ArtistIndexData[] {
		if (!this.indexData.length) return [];
	
		const sortedPriceData = this.getDateSortedIndexData().reverse();
		const startDate = sortedPriceData[0].date!;
		const endDate = getCurrentDate();
	
		let currentDate = startDate.clone();
		let currentIndex = 0;
		const backfilledData: ArtistIndexData[] = [];
		let foundFirstNonZero = false;
	
		while (currentDate.isSameOrBefore(endDate, 'day')) {
			if (sortedPriceData[currentIndex] && currentDate.isSame(sortedPriceData[currentIndex].date, 'day')) {
				backfilledData.push({
					date: currentDate.clone(),
					index: sortedPriceData[currentIndex].index,
					weightedIndex: sortedPriceData[currentIndex].weightedIndex,
					arp: sortedPriceData[currentIndex].arp,
					normalizedArp: sortedPriceData[currentIndex].normalizedArp
				});
				if (sortedPriceData[currentIndex].weightedIndex !== 0) {
					foundFirstNonZero = true;
				}
				currentIndex++;
			} else {
				const previousValue = backfilledData.length > 0 ? backfilledData[backfilledData.length - 1] : null;
				if (foundFirstNonZero || previousValue?.weightedIndex !== 0) {
					backfilledData.push({
						date: currentDate.clone(),
						index: previousValue?.index || 0,
						weightedIndex: previousValue?.weightedIndex || 0,
						arp: previousValue?.arp || 0,
						normalizedArp: previousValue?.normalizedArp || 0
					});
				}
			}
			currentDate.add(1, 'day');
		}
	
		return backfilledData;
	}
	
	
}

