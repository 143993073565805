import { useState } from 'react';
import { Stack, Radio, RadioGroup, Heading, Icon, Tag } from '@chakra-ui/react';
import Wizard from '../Wizard';
import { ListingModel } from 'models';
import { ListingType } from 'models/Listing/types';
import BasicListingCard from '../../Listing/BasicListingCard/BasicListingCard';
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { JSONObject } from 'models/Api/types';

interface ListingDataSuggestionWizardProps {
	listing: ListingModel;
	isLoading?: boolean;
	onSubmit?: (listing: ListingModel, data: JSONObject) => void;
	onRetry?: () => void;
	hasErrors?: boolean;
}

export default function ListingDataSuggestionWizard({
	listing,
	isLoading = false,
	onSubmit = () => { },
	onRetry = () => { },
	hasErrors
}: ListingDataSuggestionWizardProps) {
	const [selectedOption, setSelectedOption] = useState('');

	const onWizardSubmit = () => {
		let data = {};
		switch (selectedOption) {
			case 'ORIGINAL':
				data = { type: ListingType.ORIGINAL };
				break;
			case 'SET':
				data = { type: ListingType.SET };
				break;
			case 'FAKE':
				data = { authentic: false };
				break;
			default:
				break;
		}
		onSubmit(listing, data);
	}

	const getConfirmationCardOverrideConfig = () => {
		switch (selectedOption) {
			case 'ORIGINAL':
				return{ type: ListingType.ORIGINAL };
			case 'SET':
				return { type: ListingType.SET };
			case 'FAKE':
				return { fake: true };
			default:
				return {};
		}
	}

	const getConfirmationStepHeader = () => {
		switch (selectedOption) {
			case 'ORIGINAL':
				return 'The Sold Item is an Original (Non-Editioned)';
			case 'SET':
				return 'This Sale Contains Multiple Items';
			case 'FAKE':
				return 'This Sale Contains a Fake Item';
			default:
				return '';
		}
	}

	function renderRadioGroupSelection() {
		return (
			<RadioGroup onChange={setSelectedOption} value={selectedOption} textAlign={'left'}>
				<Stack direction="column" gap={2}>
					<Radio size="sm" value="ORIGINAL">
						<Heading size={'xs'}> The Sold Item is an Original (Non-Editioned)</Heading>
					</Radio>
					<Radio size="sm" value="SET">
						<Heading size={'xs'}>This Sale Contains Multiple Items</Heading>
					</Radio>
					<Radio size="sm" value="FAKE">
						<Heading size={'xs'}>This Sale Contains a Fake Item</Heading>
					</Radio>
				</Stack>
			</RadioGroup>
		);
	}



	function renderRadioSelectionConfirmation() {
		return (
			<Stack>
				<Heading fontSize={'sm'} textAlign={'center'}>{getConfirmationStepHeader()}</Heading>
				<BasicListingCard listing={listing} showLabel={true} overrideConfig={getConfirmationCardOverrideConfig()} />
			</Stack>
		);
	}

	function renderWizardFinishingSuccessStep() {
		if (hasErrors) {
			return (
				<Stack gap={4} alignItems={'center'}>
					<Icon as={AiFillCloseCircle} color={'red.500'} w={10} h={10} />
					<Heading size={'xs'} textAlign={'center'}>
						Your Suggestion Could Not Be Submitted.<br />Please Try Again
					</Heading>
				</Stack>
			);
		} else {
			return (
				<Stack gap={4} alignItems={'center'}>
					<Icon as={AiFillCheckCircle} color={'green.500'} w={10} h={10} />
					<Heading size={'xs'}>
						Your Suggestion Has Been Submitted
					</Heading>
				</Stack>
			);
		}
	}


	const wizardSteps = [
		{ label: 'Select Option', content: renderRadioGroupSelection(), requiresValidation: true, isValidated: selectedOption !== '' },
		{ label: 'Confirm Selection', content: renderRadioSelectionConfirmation() }
	];

	return (
		<Stack gap={2} width={'100%'}>
			<BasicListingCard listing={listing} />
			<Wizard steps={wizardSteps} finishStep={renderWizardFinishingSuccessStep()} onSubmit={onWizardSubmit} loading={isLoading} />
		</Stack>
	);
}
