import Carousel from 'react-elastic-carousel'
import {
  Box,
  useBreakpointValue
} from '@chakra-ui/react';
import './slider.css';

export interface SliderProps {
  content: JSX.Element[];
  slidesPerView?: number;
  slidesPerMobileView?: number;
  pagination?: boolean;
  enableAutoPlay?: boolean;
  transitionMs?: number;
  itemPadding?: number;
}

export default function Slider({ content, slidesPerView = 4, slidesPerMobileView = 3, pagination = true, enableAutoPlay = false, transitionMs = 500, itemPadding=0 }: SliderProps) {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const itemsToShow = isMobile && slidesPerMobileView ? slidesPerMobileView : slidesPerView;
  const sliderContent = content.map((item, index: number) => (
    <Box width="100%" height="100%" key={`slider_content_${index}`}> {item} </Box>
  ));
  return (
    <Carousel itemsToShow={itemsToShow} isRTL={false} showArrows={false} pagination={pagination} enableAutoPlay={enableAutoPlay} autoPlaySpeed={5000} transitionMs={transitionMs} itemPadding={[itemPadding]}>
      {sliderContent}
    </Carousel>
  );
}
