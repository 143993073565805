import {
  useState,
  useEffect
} from 'react';
import {
  Box,
  Tabs,
  TabList,
  Tab,
  Heading,
  TabPanels,
  TabPanel,
  Divider,
  useColorModeValue,
  useBreakpointValue
} from '@chakra-ui/react';
import Loader from '../Loader/Loader';

export interface TabGroupProps {
  labels: string[] | JSX.Element[] | (string | JSX.Element)[];
  content: JSX.Element[];
  onChange?: (index: number) => void;
  orientation?: 'vertical' | 'horizontal';
  startingTab?: number;
}

export default function TabGroup({ labels, content, onChange, orientation = 'horizontal',  startingTab = 0 }: TabGroupProps) {
  const [selectedTab, setSelectedTab] = useState(startingTab);
  const [loading, setLoading] = useState(false);
  const isVerticalDisplay = orientation === 'vertical';
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    const timeOutId = setTimeout(() => onTabChangeComplete(selectedTab), 100);
    return () => clearTimeout(timeOutId);
  }, [selectedTab]);


  const onTabChange = (index: number): void => {
    setLoading(true);
    setSelectedTab(index);
  }

  const onTabChangeComplete = (index: number): void => {
    onChange?.(index);
    setLoading(false);
  }

  const tabHeaders = labels.map((tab: string | JSX.Element, index: number) => (
    <Tab key={`tabGroup_label_${index}`}>
      <Heading color={useColorModeValue('black', 'white')} size="sm">
        {tab}
      </Heading>
    </Tab>
  ));

  const tabContent = content.map((element: JSX.Element, index: number) => (
    index === selectedTab ?
    <TabPanel paddingLeft={0} paddingRight={0} key={`tabGroup_panel_${index}`}>
      {element}
    </TabPanel> : <Box key={`tabGroup_emptyPanel_${index}`} />
  ));

  function renderTabHeaders() {
    return isVerticalDisplay && isMobile ? null : (
      <TabList margin={isVerticalDisplay ? '20px 20px 0px 0px' : '0px'} minWidth="25%" paddingBottom="20px" justifyContent={isVerticalDisplay ? 'start' : 'center'}>
        {tabHeaders}
      </TabList>
    );
  }

  function renderTabDivider() {
    return isVerticalDisplay ? null : <Divider />;
  }

  return (
    <Tabs defaultIndex={startingTab} orientation={orientation} onChange={onTabChange} align="center" marginTop="20px" variant='soft-rounded' colorScheme={useColorModeValue('blackAlpha', 'whiteAlpha')}>
      {renderTabHeaders()}
      {renderTabDivider()}
      <TabPanels>
        {loading ? <Loader /> : tabContent}
      </TabPanels>
    </Tabs>
  );
}
