export interface ApiResponse<T> {
  ok: boolean;
  status: number;
  data: T;
  message?: string;
}

export class ApiResponseModel<T> implements ApiResponse<T> {
  data: T;
  message?: string
  status: number;
  ok: boolean


  constructor(data: T, status: number, ok: boolean, message?: string) {
    this.data = data;
    this.status = status;
    this.ok = ok;
    this.message = message || '';
  }
}
