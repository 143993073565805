import {
  Box,
  Container,
  Divider,
  HStack,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react'
import LoginForm, { LoginFormData } from '../Form/LoginForm/LoginForm';
import { OAuthButtonGroup } from './OAuthButtonGroup';

export interface LoginCardProps {
  onLoginClick?: (email: string, password: string) => void;
  onForgotPasswordClick?: (email: string) => void;
  submitLoading?: boolean;
  showOAuthButtons?: boolean;
}

export default function LoginCard({ onLoginClick, onForgotPasswordClick, submitLoading = false, showOAuthButtons = true }: LoginCardProps) {
  function renderOAuthButtons() {
    if (showOAuthButtons) {
      return (
        <OAuthButtonGroup />
      );
    } else {
      return null;
    }
  }

  function renderOAuthSeperator() {
    if (showOAuthButtons) {
      return (
        <HStack>
          <Divider />
          <Text fontSize="sm" whiteSpace="nowrap" color="muted">
            or continue with
          </Text>
          <Divider />
        </HStack>
      );
    } else {
      return null;
    }
  }

  const onFormSubmit = (data: LoginFormData) => {
    if (onLoginClick) {
      onLoginClick(data.email, data.password);
    }
  }

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <Stack spacing="6">
          <LoginForm submitText={'Sign In'} onSubmit={onFormSubmit} onForgotPasswordSubmit={onForgotPasswordClick} submitLoading={submitLoading} />
          <Stack spacing="6">
            {renderOAuthSeperator()}
            {renderOAuthButtons()}
          </Stack>
        </Stack>
      </Box>
    </Container>
  );
}
