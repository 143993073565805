import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Stack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Textarea,
  InputGroup,
  InputLeftElement
} from '@chakra-ui/react';
import ActionButton from '../../Button/ActionButton';
import { isValidEmail } from 'utils/string';
import { BsPerson } from 'react-icons/bs';
import { MdOutlineEmail } from 'react-icons/md';

export interface ContactFormData {
  name?: string;
  email?: string;
  message?: string;
}

export interface ContactFormProps {
  onSubmit?: (data: ContactFormData) => void;
  submitText?: string;
  submitLoading?: boolean;
  defaultData?: ContactFormData;
}

export default function ContactForm({ onSubmit, submitLoading, submitText = 'Send Message', defaultData }: ContactFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm<ContactFormData>({
    defaultValues: defaultData
  });

  useEffect(() => {
    reset({ ...defaultData });
  }, [defaultData, reset]);

  const onFormSubmit = (data: ContactFormData) => {
    onSubmit?.(data);
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={8}>

        <FormControl isRequired isInvalid={!!errors.name}>
          <FormLabel htmlFor="name" fontSize="sm">Name</FormLabel>
          <InputGroup>
            <InputLeftElement>
              <BsPerson />
            </InputLeftElement>
            <Input
              placeholder='Your Name'
              disabled={!!defaultData?.name}
              {...register('name', {
                required: 'Name is required'
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.name && errors.name.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel htmlFor="email" fontSize="sm">Email</FormLabel>
          <InputGroup>
            <InputLeftElement>
              <MdOutlineEmail />
            </InputLeftElement>
            <Input
              type="email"
              placeholder="Your Email"
              disabled={isValidEmail(defaultData?.email || '')}
              {...register('email', {
                required: 'Email is required',
                validate: {
                  isValidEmail: (v: string | undefined) => isValidEmail(v || '') || 'Invalid Email Format Provided'
                }
              })}
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>

        <FormControl isRequired isInvalid={!!errors.message}>
          <FormLabel htmlFor="message" fontSize="sm">Message</FormLabel>
          <Textarea
            placeholder="Your Message"
            rows={6}
            resize="none"
            {...register('message', {
              required: 'Message is required'
            })}
          />
          <FormErrorMessage>
            {errors.message && errors.message.message}
          </FormErrorMessage>
        </FormControl>

        <Stack alignItems="center">
          <ActionButton type='submit' text={submitText} loading={submitLoading} />
        </Stack>

      </Stack>
    </form>
  );
}
