
interface IndexYTickProps {
    x: number;
    y: number;
    payload: {
        value: number;
    };
}

export default function IndexYTick({ x, y, payload }: IndexYTickProps) {
    return (
        <text x={x - 5} y={y + 5} fill="#666" fontSize="12px" fontWeight="bold" textAnchor="end">
            {payload.value}
        </text>
    );
}