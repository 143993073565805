import { Cloneable } from '../Utils/Cloneable';

export interface RawMetricData {
  close: number;
  growth: number;
  max: number;
  mean: number;
  min: number;
  open: number;
  total_listings: number;
  total_sales: number;
  variance: number;
  key: string | number;
}

export class MetricModel extends Cloneable {
  close: number;
  growth: number;
  max: number;
  mean: number;
  min: number;
  open: number;
  totalListings: number;
  totalSales: number;
  variance: number;
  key: string | number;

  constructor(rawMetricData: RawMetricData) {
    super();
    this.close = rawMetricData.close;
    this.growth = Number((rawMetricData.growth || 0).toFixed(2));
    this.max = Number((rawMetricData.max || 0).toFixed(2));
    this.mean = Number((rawMetricData.mean || 0).toFixed(2));
    this.min = Number((rawMetricData.min || 0).toFixed(2));
    this.open = rawMetricData.open;
    this.totalListings = rawMetricData.total_listings;
    this.totalSales = rawMetricData.total_sales;
    this.variance = Number((rawMetricData.variance || 0).toFixed(2));
    this.key = rawMetricData.key;
  }
}
