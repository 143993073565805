import { PortfolioModel, ArtistModel, DetailedPortfolioItemModel, PaginationModel, ListingModel, PortfolioHistoryModel } from 'models';
import { ItemPurchaseInfo } from 'models/Portfolio/types';

export enum PortfolioActionTypes {
  GET_USER_PORTFOLIO = 'PORTFOLIO_GET_USER_PORTFOLIO',
  GET_USER_PORTFOLIO_SUCCESS = 'PORTFOLIO_GET_USER_PORTFOLIO_SUCCESS',
  GET_USER_PORTFOLIO_FAILURE = 'PORTFOLIO_GET_USER_PORTFOLIO_FAILURE',

  GET_PORTFOLIO_BY_ID = 'PORTFOLIO_GET_PORTFOLIO_BY_ID',
  GET_PORTFOLIO_BY_ID_SUCCESS = 'PORTFOLIO_GET_PORTFOLIO_BY_ID_SUCCESS',
  GET_PORTFOLIO_BY_ID_FAILURE = 'PORTFOLIO_GET_PORTFOLIO_BY_ID_FAILURE',

  GET_PORTFOLIO_ARTISTS = 'PORTFOLIO_GET_PORTFOLIO_ARTISTS',
  GET_PORTFOLIO_ARTISTS_SUCCESS = 'PORTFOLIO_GET_PORTFOLIO_ARTISTS_SUCCESS',
  GET_PORTFOLIO_ARTISTS_FAILURE = 'PORTFOLIO_GET_PORTFOLIO_ARTISTS_FAILURE',

  GET_PORTFOLIO_HISTORY = 'PORTFOLIO_GET_PORTFOLIO_HISTORY',
  GET_PORTFOLIO_HISTORY_SUCCESS = 'PORTFOLIO_GET_PORTFOLIO_HISTORY_SUCCESS',
  GET_PORTFOLIO_HISTORY_FAILURE = 'PORTFOLIO_GET_PORTFOLIO_HISTORY_FAILURE',

  GET_PORTFOLIO_ITEMS = 'PORTFOLIO_GET_PORTFOLIO_ITEMS',
  GET_PORTFOLIO_ITEMS_SUCCESS = 'PORTFOLIO_GET_PORTFOLIO_ITEMS_SUCCESS',
  GET_PORTFOLIO_ITEMS_FAILURE = 'PORTFOLIO_GET_PORTFOLIO_ITEMS_FAILURE',

  GET_PORTFOLIO_LISTINGS = 'PORTFOLIO_GET_PORTFOLIO_LISTINGS',
  GET_PORTFOLIO_LISTINGS_SUCCESS = 'PORTFOLIO_GET_PORTFOLIO_LISTINGS_SUCCESS',
  GET_PORTFOLIO_LISTINGS_FAILURE = 'PORTFOLIO_GET_PORTFOLIO_LISTINGS_FAILURE',

  ADD_ITEM_TO_PORTFOLIO = 'PORTFOLIO_ADD_ITEM_TO_PORTFOLIO',
  ADD_ITEM_TO_PORTFOLIO_SUCCESS = 'PORTFOLIO_ADD_ITEM_TO_PORTFOLIO_SUCCESS',
  ADD_ITEM_TO_PORTFOLIO_FAILURE = 'PORTFOLIO_ADD_ITEM_TO_PORTFOLIO_FAILURE',

  REMOVE_ITEM_FROM_PORTFOLIO = 'PORTFOLIO_REMOVE_ITEM_FROM_PORTFOLIO',
  REMOVE_ITEM_FROM_PORTFOLIO_SUCCESS = 'PORTFOLIO_REMOVE_ITEM_FROM_PORTFOLIO_SUCCESS',
  REMOVE_ITEM_FROM_PORTFOLIO_FAILURE = 'PORTFOLIO_REMOVE_ITEM_FROM_PORTFOLIO_FAILURE',

  REPLACE_ITEM_IN_PORTFOLIO = 'PORTFOLIO_REPLACE_ITEM_IN_PORTFOLIO',
  REPLACE_ITEM_IN_PORTFOLIO_SUCCESS = 'PORTFOLIO_REPLACE_ITEM_IN_PORTFOLIO_SUCCESS',
  REPLACE_ITEM_IN_PORTFOLIO_FAILURE = 'PORTFOLIO_REPLACE_ITEM_IN_PORTFOLIO_FAILURE',

  CLEAR_PORTFOLIO = 'PORTFOLIO_CLEAR_PORTFOLIO'
};

export interface PortfolioActionPayload {
  id?: string;
  page?: number;
  itemId?: string;
  portfolioId?: string;
  portfolio?: PortfolioModel;
  portfolioHistory?: PortfolioHistoryModel;
  artistList?: ArtistModel[];
  items?: DetailedPortfolioItemModel[];
  listings?: PaginationModel<ListingModel>;
  purchaseInfo?: ItemPurchaseInfo[];
};

export interface PortfolioAction {
  type: PortfolioActionTypes;
  payload?: PortfolioActionPayload;
};

export interface PortfolioStateLoading {
  item: boolean;
  artists: boolean;
  action: boolean;
  items: boolean;
  listings: boolean;
  history: boolean;
};

export interface PortfolioState {
  portfolio: PortfolioModel;
  error: Error | undefined;
  loading: PortfolioStateLoading;
};
