import { Cloneable } from '../Utils/Cloneable';

export interface RawMaterialData {
  id: string;
  name: string;
  aliases: string[];
}

export class MaterialModel extends Cloneable {
  id: string;
  name: string;
  aliases: string[];

  constructor(rawMaterialData?: RawMaterialData) {
    super();
    this.id = rawMaterialData ?.id || '';
    this.name = rawMaterialData ?.name || '';
    this.aliases = rawMaterialData ?.aliases || [];
  }
}
