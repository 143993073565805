import { AppConfig } from './app';

export class UrlConfig {
  static AUTH = {
    LOGIN: `${AppConfig.API.CORE_URL}/auth`,
    REGISTER: `${AppConfig.API.CORE_URL}/auth/register`,
    REFRESH: `${AppConfig.API.CORE_URL}/refresh`,
    EMAIL_AVAILABLE: `${AppConfig.API.CORE_URL}/email/available`,
    EMAIL_WHITELISTED: `${AppConfig.API.CORE_URL}/email/whitelisted`,
    USERNAME_AVAILABLE: `${AppConfig.API.CORE_URL}/username/available`,
  };

  static USER = {
    BASE: `${AppConfig.API.CORE_URL}/user`,
    FOLLOWING_ARTISTS: `${AppConfig.API.CORE_URL}/user/following/artists`,
    FOLLOWING_ARTISTS_ITEMS: `${AppConfig.API.CORE_URL}/user/following/artists/items`,
    FOLLOWING_ARTISTS_LISTINGS: `${AppConfig.API.CORE_URL}/user/following/artists/listings`,
    MIGRATE_IMAGE: `${AppConfig.API.CORE_URL}/user/migrate-image`,
    CHANGE_PASSWORD: `${AppConfig.API.CORE_URL}/user/change-password`,
    PORTFOLIO: `${AppConfig.API.CORE_URL}/user/portfolio`,
    WISHLIST: `${AppConfig.API.CORE_URL}/user/wishlist`,
    CONFIRM_EMAIL: `${AppConfig.API.CORE_URL}/user/confirm-email`,
    CHANGE_FORGOTTEN_PASSWORD: `${AppConfig.API.CORE_URL}/user/change-forgotten-password`
  };

  static ITEM = {
    BASE: `${AppConfig.API.CORE_URL}/item`,
    ITEM_BY_ID: `${AppConfig.API.CORE_URL}/item/`,
    ITEM_BY_ARTIST: `${AppConfig.API.CORE_URL}/item/artist/`,
    ITEM_SEARCH: `${AppConfig.API.CORE_URL}/item/search`,
    ITEM_ALL: `${AppConfig.API.CORE_URL}/item/all`
  };

  static ITEM_DATA_SUGGESTION = {
    BASE: `${AppConfig.API.CORE_URL}/item-data-suggestion`,
    ITEM_DATA_SUGGESTION_CREATE: `${AppConfig.API.CORE_URL}/item-data-suggestion/create`
  }

  static ITEM_SALE_SUGGESTION = {
    BASE: `${AppConfig.API.CORE_URL}/item-sale-suggestion`,
    ITEM_SALE_SUGGESTION_CREATE: `${AppConfig.API.CORE_URL}/item-sale-suggestion/create`
  }

  static LISTING_DATA_SUGGESTION = {
    BASE: `${AppConfig.API.CORE_URL}/listing-data-suggestion`,
    LISTING_DATA_SUGGESTION_CREATE: `${AppConfig.API.CORE_URL}/listing-data-suggestion/create`
  }

  static LISTING = {
    BASE: `${AppConfig.API.CORE_URL}/listing`,
    LISTING_SEARCH: `${AppConfig.API.CORE_URL}/listing/search`
  };

  static PORTFOLIO = {
    BASE: `${AppConfig.API.CORE_URL}/portfolio`,
    PORTFOLIO_BY_ID: `${AppConfig.API.CORE_URL}/portfolio/`,
    PORTFOLIO_HISTORY: `${AppConfig.API.CORE_URL}/portfolio/history/`,
    PORTFOLIO_ADD_ITEM: `${AppConfig.API.CORE_URL}/portfolio/add_item`,
    PORTFOLIO_REMOVE_ITEM: `${AppConfig.API.CORE_URL}/portfolio/remove_item`,
    PORTFOLIO_REPLACE_ITEM: `${AppConfig.API.CORE_URL}/portfolio/replace_item`
  };

  static WISHLIST = {
    BASE: `${AppConfig.API.CORE_URL}/wishlist`,
    WISHLIST_BY_ID: `${AppConfig.API.CORE_URL}/wishlist/`,
    WISHLIST_ADD_ITEM: `${AppConfig.API.CORE_URL}/wishlist/add_item`,
    WISHLIST_REMOVE_ITEM: `${AppConfig.API.CORE_URL}/wishlist/remove_item`
  };

  static ARTIST = {
    BASE: `${AppConfig.API.CORE_URL}/artist`,
    ARTIST_BY_ID: `${AppConfig.API.CORE_URL}/artist/`,
    ARTIST_BY_ITEM: `${AppConfig.API.CORE_URL}/artist/item/`,
    ARTIST_ALL: `${AppConfig.API.CORE_URL}/artist/all`,
    ARTIST_ENABLED: `${AppConfig.API.CORE_URL}/artist/enabled`,
    ARTIST_FOLLOW: `${AppConfig.API.CORE_URL}/user/follow/artist`,
    ARTIST_UNFOLLOW: `${AppConfig.API.CORE_URL}/user/unfollow/artist`,
  }

  static UPLOAD = {
    BASE: `${AppConfig.API.CORE_URL}/upload`,
    IMAGE: `${AppConfig.API.CORE_URL}/upload/image`,
    FILEPOD_IMAGE: `${AppConfig.API.CORE_URL}/upload/fp/image`,
    FILEPOD_IMAGE_DELETE: `${AppConfig.API.CORE_URL}/upload/fp/image/delete`,
  }

  static MAIL = {
    BASE: `${AppConfig.API.CORE_URL}/mail`,
    SEND_USER_CONFIRMATION: `${AppConfig.API.CORE_URL}/mail/user/send-confirmation-email`,
    SEND_USER_FORGOT_PASSWORD: `${AppConfig.API.CORE_URL}/mail/user/send-forgot-password-email`,
    SEND_INBOUND_CONTACT_REQUEST: `${AppConfig.API.CORE_URL}/mail/inbound/contact-request`
  }

  static MATERIAL = {
    BASE: `${AppConfig.API.CORE_URL}/material`,
    MATERIAL_BY_ID: `${AppConfig.API.CORE_URL}/material/`,
    MATERIAL_ALL: `${AppConfig.API.CORE_URL}/material/all`,
  }

  static SALES_VENUE = {
    BASE: `${AppConfig.API.CORE_URL}/salesvenue`,
    SALES_VENUE_BY_ID: `${AppConfig.API.CORE_URL}/salesvenue/`,
    SALES_VENUE_ALL: `${AppConfig.API.CORE_URL}/salesvenue/all`,
  }

  static ITEM_LISTING_GROUPING = {
    BASE: `${AppConfig.API.CORE_URL}/grouping`
  }

  static WAITLIST = {
    BASE: `${AppConfig.API.CORE_URL}/user-waitlist`,
    CREATE: `${AppConfig.API.CORE_URL}/user-waitlist/create`
  }

  static CONTENT = {
    HOME_FEED: `${AppConfig.API.CORE_URL}/content/home/feed`
  }
}
