import { useState, useMemo } from 'react';
import {
	Area, YAxis, XAxis, Tooltip, ResponsiveContainer, ReferenceLine, CartesianGrid, ComposedChart
} from 'recharts';
import { ArtistIndexModel, ArtistModel } from 'models';
import { MarketDateFilter } from 'models/types';
import { IndexYTick, TimeseriesXTick} from './Ticks';
import { ArtistIndexTooltip } from './Tooltips';
import { MarketDateFilters } from './Filters';
import {
	Flex,
	Text,
	Box,
	useBreakpointValue
} from '@chakra-ui/react';
import Card, { CardVariants } from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import RefreshBadge from 'components/RefreshBadge/RefreshBadge';
import ArtistMetricCard from '../Artist/ArtistMetricCard/ArtistMetricCard';
import { gradientOffset, getXAxisTicksAmountForTimeChart } from 'utils/chart';

interface ArtistIndexChartProps {
	artist: ArtistModel;
	artistIndexData: ArtistIndexModel;
	showLastCalculationDate?: boolean;
	showBorder?: boolean;
}

function ArtistIndexChart({ artist, artistIndexData, showLastCalculationDate, showBorder }: ArtistIndexChartProps) {
	const [filter, setFilter] = useState<MarketDateFilter>(MarketDateFilter.MAX);
	const isMobile = useBreakpointValue({ base: true, md: false });

	const { filteredData } = useMemo(
		() => {
			const filteredData = artistIndexData.filterDataByMarketDateFilter(filter, true);
			return { filteredData }
		}, [filter, artistIndexData]
	);

	const { off, fullRed } = useMemo(() => {
		const off = gradientOffset(filteredData, 'weightedIndex', 0);
		const startPoint = filteredData[0]?.weightedIndex ?? 0;
		const endPoint = filteredData[filteredData.length - 1]?.weightedIndex ?? 0;
		const fullRed = startPoint > endPoint;
		return { off, fullRed };
	}, [filteredData]);

	const dateSortedData = artistIndexData.getDateSortedIndexData();

	const renderTitle = () => {
		return (
			<Flex direction={'row'} gap={4} alignItems={'center'}>
				<Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
					Index History
				</Text>
				{artistIndexData.recalculate && <RefreshBadge label={'Updating'}/>}
			</Flex>
		);
	};

	const renderXAxis = () => {
		const totalTicks = getXAxisTicksAmountForTimeChart(!!isMobile, filter);
		const dataLength = filteredData.length;
	
		let interval = Math.ceil(dataLength / totalTicks);
	
		return (
			<XAxis tick={(props) => <TimeseriesXTick {...props} data={filteredData} />} interval={interval} />
		);
	};


	const renderChartData = () => {
		return (
			<ComposedChart data={filteredData}>
				<CartesianGrid horizontal vertical={false} />
				<YAxis
					tick={IndexYTick}
				/>
				{renderXAxis()}
				<Tooltip content={(props) => <ArtistIndexTooltip indexPriceData={filteredData} {...props} />} />
				<Area
					type="monotone"
					dataKey="weightedIndex"
					stroke="none"
					fill="url(#splitColor)"
				/>
				<defs>
					<linearGradient id="splitColor" x1="0" y1="0" x2="0" y2="1">
						<stop offset={off} stopColor={fullRed ? "red" : "green"} stopOpacity={1} />
						{!fullRed && <stop offset={off} stopColor="red" stopOpacity={1} />}
					</linearGradient>
				</defs>
				<ReferenceLine y={0} stroke="#000" strokeDasharray="3 3" />
			</ComposedChart>
		);
	};

	function renderMetricCard() {
		if (dateSortedData.length) {
			return (
				<ArtistMetricCard artist={artist} metric={dateSortedData[0].weightedIndex} priorMetricValue={dateSortedData[1]?.weightedIndex} showBorder={false} showButton={false} />
			)
		}
	}

	function renderLastCalculationDate() {
		return (
			<Text fontWeight='light' textAlign={'start'} fontSize={'xs'}>
				{artistIndexData.lastCalculationDate?.format('llll')}
			</Text>
		)
	}

	function renderFilters() {
		return (
			<MarketDateFilters selectedFilter={filter} onFilterSelect={setFilter} />
		);
	}

	return (
		<Card p='28px 0px 0px 0px' borderRadius='8px' type={showBorder ? CardVariants.DASHED : CardVariants.NO_BORDER}>
			<CardHeader mb='20px'>
				<Flex direction='column' alignSelf='flex-start' width={'100%'}>
					{renderTitle()}
					{renderMetricCard()}
					{showLastCalculationDate && renderLastCalculationDate()}
				</Flex>
			</CardHeader>
			{renderFilters()}
			<Box w='100%' height="300px" justifyContent="center" alignItems="center">
				<ResponsiveContainer>
					{renderChartData()}
				</ResponsiveContainer>
			</Box>
		</Card>
	);
}

export default ArtistIndexChart;
