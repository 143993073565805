import moment from 'moment';
import { ItemModel } from '../Item/Item';
import { RawItemData } from '../Item/types';
import { ArtistModel } from '../Artist/Artist';
import { CompositeMarketModel } from '../Market/CompositeMarket';
import { DetailedPortfolioItemModel } from './DetailedPortfolioItem';
import { PortfolioHistoryModel } from './PortfolioHistory';
import { PortfolioItemModel } from './PortfolioItem';
import { Cloneable } from '../Utils/Cloneable';
import { RawDetailedPortfolioItemData, RawBasicPortfolioData, RawPortfolioData, ItemPurchaseInfo, PortfolioMarketData, PortfolioHistoryData } from './types'
import { ListingModel } from '../Listing/Listing';
import { PaginationModel } from '../Pagination/Pagination';
import { getCurrentDate } from 'utils/date';


export class PortfolioModel extends Cloneable {
  id: string;
  user: string;
  items: (string | ItemModel)[];
  artists: (string | ArtistModel)[];
  portfolioItems: (PortfolioItemModel | DetailedPortfolioItemModel)[]
  market?: CompositeMarketModel;
  history?: PortfolioHistoryModel;
  listings?: PaginationModel<ListingModel> | null;

  constructor(rawBasicPortfolioData?: RawBasicPortfolioData) {
    super();
    this.id = rawBasicPortfolioData?.id || '';
    this.user = rawBasicPortfolioData?.user || '';
    this.items = rawBasicPortfolioData?.items || [];
    this.portfolioItems = (rawBasicPortfolioData?.portfolio_items || []).map(item => new PortfolioItemModel(item));
    this.artists = [];
    this.listings = null;
  }

  static fromRawPortfolioData(rawPortfolioData: RawPortfolioData): PortfolioModel {
    const artists: string[] = Array.from(new Set(rawPortfolioData.items.map(item => item.artist_id)));
    const portfolio: PortfolioModel = new this({
      id: rawPortfolioData.id,
      user: rawPortfolioData.user,
      items: rawPortfolioData.items.map(item => item.id),
      portfolio_items: rawPortfolioData.portfolio_items.map(portfolioItem => ({
        item_id: portfolioItem.item.id,
        purchase_info: portfolioItem.purchase_info
      }))
    });
    portfolio.setRawItems(rawPortfolioData.items);
    portfolio.setRawPortfolioItems(rawPortfolioData.portfolio_items);
    return portfolio;
  }

  setRawItems(items: RawItemData[]): void {
    const formattedItems: ItemModel[] = items.map(item => new ItemModel(item));
    this.setItems(formattedItems);
  }

  setRawPortfolioItems(items: RawDetailedPortfolioItemData[]): void {
    const formattedPortfolioItems = items.map(item => new DetailedPortfolioItemModel(item));
    const artists: string[] = Array.from(new Set(formattedPortfolioItems.map(portfolioItem => portfolioItem.item.artistId)));
    this.setDetailedPortfolioItems(formattedPortfolioItems);
    this.setArtists(artists);
  }

  setItems(items: ItemModel[]): void {
    if (items && items.length) {
      this.items = items;
    }
  }

  setDetailedPortfolioItems(portfolioItems: DetailedPortfolioItemModel[]): void {
    if (portfolioItems && portfolioItems.length) {
      this.portfolioItems = portfolioItems;
      const artists: string[] = Array.from(new Set(portfolioItems.map(portfolioItem => portfolioItem.item.artistId)));
      this.setArtists(artists);
    }
  }

  setMarket(market: CompositeMarketModel | undefined) {
    if (market) {
      this.market = market;
    }
  }

  setHistory(history: PortfolioHistoryModel | undefined) {
    if (history) {
      this.history = history;
    }
  }

  setListings(listings: PaginationModel<ListingModel> | null | undefined) {
    if (listings) {
      this.listings = listings;
    }
  }

  setArtists(artists: (ArtistModel | string)[]): void {
    this.artists = artists;
  }

  public getArtistIds(): string[] {
    return this.artists.map(
      (artist: ArtistModel | string) => artist instanceof ArtistModel ? artist.id : artist
    );
  }

  public getArtists(): ArtistModel[] {
    const artists: ArtistModel[] = [];
    this.artists.forEach((artist: string | ArtistModel) => {
      if (artist instanceof ArtistModel) {
        artists.push(artist);
      }
    });

    return artists;
  }

  public getPortfolioItems(): ItemModel[] {
    const items: ItemModel[] = [];
    this.portfolioItems.forEach((portfolioItem: PortfolioItemModel | DetailedPortfolioItemModel) => {
      if (portfolioItem instanceof DetailedPortfolioItemModel) {
        items.push(portfolioItem.item);
      }
    });
    return items;
  }

  public getPortfolioDetailedItems(): DetailedPortfolioItemModel[] {
    const items: DetailedPortfolioItemModel[] = [];
    this.portfolioItems.forEach((item: PortfolioItemModel | DetailedPortfolioItemModel) => {
      if (item instanceof DetailedPortfolioItemModel) {
        items.push(item);
      }
    });
    return items;
  }

  public containsItem(itemId: string): boolean {
    return !!this.items.find((item: ItemModel | string) => {
      if (item instanceof ItemModel) {
        return item.id === itemId;
      } else {
        return item === itemId;
      }
    }) || !!this.portfolioItems.find((portfolioItem) => {
      if (portfolioItem instanceof DetailedPortfolioItemModel) {
        return portfolioItem.item.id === itemId;
      } else {
        return portfolioItem.itemId === itemId;
      }
    });
  }

  public hasItems(): boolean {
    return !!this.items.length || !!this.portfolioItems.length;
  }

  public hasListingData(): boolean {
    return !!this.listings && this.listings?.data?.length > 0
  }

  public getPortfolioItemPurchaseInfo(itemId: string): ItemPurchaseInfo[] {
    return this.portfolioItems.find((portfolioItem) => {
      if (portfolioItem instanceof DetailedPortfolioItemModel) {
        return portfolioItem.item.id === itemId;
      } else {
        return portfolioItem.itemId === itemId;
      }
    })?.purchaseInfo || [];
  }

  public getDetailedPortfolioItem(itemId: string): DetailedPortfolioItemModel | undefined {
    return this.getPortfolioDetailedItems().find((portfolioItem) => {
      if (portfolioItem instanceof DetailedPortfolioItemModel) {
        return portfolioItem.item.id === itemId;
      }
    });
  }

  public getPortfolioItemsByDateCombo(
    year: number,
    month?: number,
    day?: number
  ): DetailedPortfolioItemModel[] {
    return this.getPortfolioDetailedItems().filter(portfolioItem => {
      return portfolioItem.purchaseInfo.some(purchaseInfo => {
        const date = purchaseInfo.date;
        if (day) {
          return (
            date.year() === year &&
            date.month() + 1 === month &&
            date.date() === day
          );
        } else if (month) {
          return (
            date.year() === year &&
            date.month() + 1 === month
          );
        } else {
          return date.year() === year;
        }
      });
    });
  }

  public getYearlyData(): PortfolioMarketData[] {
    let yearlyData: PortfolioMarketData[] = [];
    const earliestPurchaseYear = Math.min(
      ...this.getPortfolioDetailedItems().map((portfolioItem: DetailedPortfolioItemModel) => portfolioItem.getEarliestPurchaseDate().year())
    );
    const currentYear = moment().year();

    for (let year = earliestPurchaseYear; year <= currentYear; year++) {
      let sum = 0;
      let cost = 0
      this.getPortfolioDetailedItems().forEach(portfolioItem => {
        portfolioItem.purchaseInfo.forEach(purchaseEvent => {
          if (purchaseEvent.date.year() <= year) {
            sum += portfolioItem.item.getEstimatedMarketPrice() * purchaseEvent.quantity;
            cost += purchaseEvent.price * purchaseEvent.quantity
          }
        })
      });
      yearlyData.push({ key: year, value: sum, cost: cost });
    }

    return yearlyData;
  };

  public getMonthlyDataForYear(year: number): PortfolioMarketData[] {
    let monthlyData: PortfolioMarketData[] = Array(12).fill(null).map((_, idx) => ({
      key: idx + 1,
      value: 0,
      cost: 0
    }));

    this.getPortfolioDetailedItems().forEach(portfolioItem => {
      portfolioItem.purchaseInfo.forEach(purchaseEvent => {
        if (purchaseEvent.date.year() < year) {
          const marketPrice = portfolioItem.item.getEstimatedMarketPrice() * purchaseEvent.quantity;
          const purchaseCost = purchaseEvent.price * purchaseEvent.quantity;
          monthlyData.forEach(month => {
            month.value += marketPrice;
            month.cost += purchaseCost;
          });
        }
      });
    });

    this.getPortfolioDetailedItems().forEach(portfolioItem => {
      portfolioItem.purchaseInfo.forEach(purchaseEvent => {
        if (purchaseEvent.date.year() === year) {
          const monthIdx = purchaseEvent.date.month();
          const marketPrice = portfolioItem.item.getEstimatedMarketPrice() * purchaseEvent.quantity;
          const purchaseCost = purchaseEvent.price * purchaseEvent.quantity;
          for (let i = monthIdx; i < monthlyData.length; i++) {
            monthlyData[i].value += marketPrice;
            monthlyData[i].cost += purchaseCost;
          }
        }
      });
    });

    return monthlyData;
  }


  public getDailyDataForMonth(year: number, month: number): PortfolioMarketData[] {
    const daysInMonth = moment([year, month - 1]).daysInMonth();
    let dailyData: PortfolioMarketData[] = Array.from({ length: daysInMonth }, (_, idx) => ({
      key: idx + 1,
      value: 0,
      cost: 0,
    }));

    this.getPortfolioDetailedItems().forEach(portfolioItem => {
      portfolioItem.purchaseInfo.forEach(purchaseEvent => {
        const eventYear = purchaseEvent.date.year();
        const eventMonth = purchaseEvent.date.month();
        if (eventYear < year || (eventYear === year && eventMonth < month - 1)) {
          const marketPrice = portfolioItem.item.getEstimatedMarketPrice() * purchaseEvent.quantity;
          const purchaseCost = purchaseEvent.price * purchaseEvent.quantity;
          dailyData.forEach(day => {
            day.value += marketPrice;
            day.cost += purchaseCost;
          });
        }
      });
    });


    this.getPortfolioDetailedItems().forEach(portfolioItem => {
      portfolioItem.purchaseInfo.forEach(purchaseEvent => {
        const eventYear = purchaseEvent.date.year();
        const eventMonth = purchaseEvent.date.month();
        if (eventYear === year && eventMonth === month - 1) {
          const dayIdx = purchaseEvent.date.date() - 1;
          const marketPrice = portfolioItem.item.getEstimatedMarketPrice() * purchaseEvent.quantity;
          const purchaseCost = purchaseEvent.price * purchaseEvent.quantity;
          for (let i = dayIdx; i < dailyData.length; i++) {
            dailyData[i].value += marketPrice;
            dailyData[i].cost += purchaseCost;
          }
        }
      });
    });

    return dailyData;
  }

  public getPortfolioBasicMarketSummary(): PortfolioHistoryData {
    const historyData = this.history?.getMostRecentHistoryData();
    if (historyData) {
      return historyData;
    } else {
      let sum = 0;
      let cost = 0
      this.getPortfolioDetailedItems().forEach(portfolioItem => {
        portfolioItem.purchaseInfo.forEach(purchaseEvent => {
          sum += portfolioItem.item.getEstimatedMarketPrice() * purchaseEvent.quantity;
          cost += purchaseEvent.price * purchaseEvent.quantity
        })
      });
  
      return {
        value: sum,
        cost,
        date: getCurrentDate()
      };
    }
  }

  public hasHistoryData(): boolean {
    return !!this.history;
  }
}
