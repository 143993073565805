import { Toast } from 'models/types';

export enum PopupActionTypes {
  ADD_POPUP = 'ADD_POPUP',
  REMOVE_POPUP = 'REMOVE_POPUP',
  CLEAR_POPUPS = 'CLEAR_POPUPS',
};

export interface PopupActionPayload {
  toast?: Toast;
  id?: number;
};

export interface PopupAction {
  type: PopupActionTypes;
  payload?: PopupActionPayload;
};

export interface PopupState {
  nextToastId: number;
  toasts: Toast[];
};
