import {
	Text,
	CheckboxGroup,
	HStack,
	Checkbox,
	Stack,
	Heading,
	Button
} from '@chakra-ui/react';
import { ItemModel } from 'models';
import { ItemType } from 'models/Item/types';
import DateSlider from '../../DateSlider/DateSlider';
import NumberSlider from '../../NumberSlider/NumberSlider';
import { FcClearFilters } from "react-icons/fc";


export interface ItemGridFiltersProps {
	onItemDateRangeSelect?: (minYear: number, maxYear: number) => void;
	onItemEditionRangeSelect?: (minEdition: number, maxEdition: number) => void;
	onItemEstimatedValueRangeSelect?: (minValue: number, maxValue: number) => void;
	onItemTypeFilterSelect?: (types: ItemType[]) => void;
	items: ItemModel[];
	defaultDateRange?: number[];
	defaultEditionRange?: number[];
	defaultValueRange?: number[];
	defaultTypes?: ItemType[];
	showClearOption?: boolean;
	onResetFilters?: () => void;
}

export default function ItemGridFilters({ 
	items = [], 
	onItemDateRangeSelect = () => { }, 
	onItemEditionRangeSelect = () => { }, 
	onItemEstimatedValueRangeSelect = () => { }, 
	onItemTypeFilterSelect = () => { },
	defaultDateRange,
	defaultEditionRange,
	defaultValueRange,
	defaultTypes,
	showClearOption,
	onResetFilters
}: ItemGridFiltersProps) {
	const getDateRangeMinValue = () => {
		let minItemYear = 1980;
		const itemReleaseYears = items.map(item => item.getReleaseYear()).filter(releaseYear => releaseYear != Infinity && releaseYear != 0);
		if (itemReleaseYears.length > 0) {
			minItemYear = Math.min(...itemReleaseYears);
		}
		return minItemYear;
	}

	function renderFilterLabel(label: string) {
		return (
			<Heading size={'xs'} textAlign={'left'}>{label}</Heading>
		);
	}

	function renderTypeFilters() {
		return (
			<Stack>
				{renderFilterLabel('Types')}
				<CheckboxGroup defaultValue={defaultTypes || []} onChange={((selectedVals: ItemType[]) => onItemTypeFilterSelect(selectedVals))}>
					<HStack gap={4} padding={2}>
						{[ItemType.SCULPTURE, ItemType.PRINT, ItemType.MISC].map((itemType) => (
							<Checkbox value={itemType} key={`typeCheckbox_${itemType}`}>
								<Text fontSize={'sm'}>{itemType}</Text>
							</Checkbox>
						))}
					</HStack>
				</CheckboxGroup>
			</Stack>
		);
	}

	function renderValueRangeSlider() {
		return (
			<Stack>
				{renderFilterLabel('Estimated Value')}
				<NumberSlider
					dropdownDisplay={false}
					rangeMax={50000}
					startingMax={defaultValueRange?.[1] || 50000}
					startingMin={defaultValueRange?.[0]}
					onNumberRangeSelect={onItemEstimatedValueRangeSelect} />
			</Stack>
		);
	}

	function renderEditionSizeSlider() {
		return (
			<Stack>
				{renderFilterLabel('Edition Size')}
				<NumberSlider
					dropdownDisplay={false}
					isDollarValue={false}
					rangeMax={500}
					startingMax={defaultEditionRange?.[1] || 500}
					startingMin={defaultEditionRange?.[0]}
					step={50}
					onNumberRangeSelect={onItemEditionRangeSelect} />
			</Stack>
		);
	}

	function renderDateRangeSlider() {
		return (
			<Stack>
				{renderFilterLabel('Release Year')}
				<DateSlider 
					onYearRangeSelect={onItemDateRangeSelect} 
					rangeMin={getDateRangeMinValue()} 
					startingMinDateYear={defaultDateRange?.[0] || getDateRangeMinValue()}
					startingMaxDateYear={defaultDateRange?.[1]}
					dropdownDisplay={false} />
			</Stack>
		);
	}

	function renderResetButton() {
		if (showClearOption) {
			return (
				<Button size={'md'} leftIcon={<FcClearFilters />} variant='outline' maxWidth={'fit-content'} onClick={onResetFilters}>
    			Reset Filters
  			</Button>
			);
		}
	}

	return (
		<Stack marginBottom={10} gap={4} paddingInline={{ base: 3, md: 0 }}>
			{renderResetButton()}
			{renderTypeFilters()}
			{renderDateRangeSlider()}
			{renderEditionSizeSlider()}
			{renderValueRangeSlider()}
		</Stack>
	);
}
