import moment from 'moment';
import { Cloneable } from '../Utils/Cloneable';
import { TimestampData } from '../types';
import { RawSalesVenueData, SalesVenueContactInfo, SalesVenueFeesInfo } from './types';


export class SalesVenueModel extends Cloneable {
  id: string;
  name: string;
  aliases: string[];
  timestamps: TimestampData;
  contactInfo: SalesVenueContactInfo;
  feesInfo: SalesVenueFeesInfo;

  constructor(rawSalesVenuData?: RawSalesVenueData) {
    super();
    this.id = rawSalesVenuData?.id || '';
    this.name = rawSalesVenuData?.name || '';
    this.aliases = rawSalesVenuData?.aliases || [];
    this.contactInfo = {
      email: rawSalesVenuData?.contact_info.email || '',
      siteUrl: rawSalesVenuData?.contact_info.site_url || ''
    };
    this.feesInfo = {
      buyersPremiumPercentage: rawSalesVenuData?.fees_info.buyers_premium_percentage || 0,
      sellersFeePercentage: rawSalesVenuData?.fees_info.sellers_fee_percentage || 0
    };
    this.timestamps = {
      createdAt: rawSalesVenuData?.timestamps ? moment(rawSalesVenuData.timestamps.created_at) : null,
      updatedAt: rawSalesVenuData?.timestamps ? moment(rawSalesVenuData.timestamps.updated_at) : null,
    };
  }
}
