import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { UserSelectors } from 'reducers/User/selectors';
import { PortfolioSelectors } from 'reducers/Portfolio/selectors';
import {
  Box,
  Icon,
  Flex,
  Text,
} from '@chakra-ui/react';
import { AiOutlineHeart } from 'react-icons/ai';
import { BsFolder, BsBell } from "react-icons/bs";
import { UserModel, ItemModel, ArtistModel, PortfolioModel, WishlistModel } from 'models';
import { TabGroup, UserBanner, EmptyState, PortfolioTable, ItemGrid, ArtistGrid } from 'components';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';
import { WishlistSelectors } from 'reducers/Wishlist/selectors';


interface UserProfileDetailsViewProps extends RouterProps {
  user: UserModel;
  portfolio: PortfolioModel;
  wishlist: WishlistModel;
  userLoading: boolean;
  userPortfolioLoading: boolean;
  portfolioLoading: boolean;
  userWishlistLoading: boolean;
  wishlistLoading: boolean;
  userFollowingArtistsLoading: boolean;
}

  
interface UserProfileDetailsViewState {}
  

class UserProfileDetails extends Component<UserProfileDetailsViewProps, UserProfileDetailsViewState> {
  onBrowseItems = () => {
    this.props.navigate(
      NavigationService.getExplorePath() + '?tab=items'
    );
  };

	onItemClick = (item: ItemModel) => {
    this.props.navigate(NavigationService.getItemDetailsPath(item.id));
  };

	onUserBannerButtonClick = () => {
		this.props.navigate(
			NavigationService.getUserSettingsPath()
		);
	}

  onUserConfirmationClick = () => {
    this.props.navigate(
      NavigationService.getUserConfirmationPath()
    );
  }

	onArtistClick = (artist: ArtistModel) => {
    this.props.navigate(
      NavigationService.getArtistDetailsPath(artist.id)
    );
  }

  onPortfolioItemEditClick = () => {
    const { user } = this.props;
    this.props.navigate(
      NavigationService.getPortfolioDetailsPath(user.getPortfolioId())
    )
  }

  onPortfolioArtistClick = (artistId: string) => {
    this.props.navigate(
      NavigationService.getArtistDetailsPath(artistId)
    );
  }

  getPortfolioData = (): PortfolioModel | null => {
    const { portfolio, user } = this.props;
    if (portfolio && portfolio.id) {
      return portfolio;
    } else if (user && user.hasFetchedPortfolio()) {
      return user.portfolio;
    }
    return null;
  }

  getWishlistData = (): WishlistModel | null => {
    const { wishlist, user } = this.props;
    if (wishlist && wishlist.id) {
      return wishlist;
    } else if (user && user.hasFetchedWishlist()) {
      return user.wishlist;
    }
    return null;
  }

	onBrowseArtists = () => {
    this.props.navigate(
      NavigationService.getArtistsPath()
    );
  };

  isFetchingPortfolioData = (): boolean => {
    const { userLoading, userPortfolioLoading, portfolioLoading } = this.props;
    return userLoading || userPortfolioLoading || portfolioLoading;
  }

	isFetchingWishlistData = (): boolean => {
		const { userLoading, userWishlistLoading } = this.props;
		return userLoading || userWishlistLoading;
	}

  renderProfileSectionHeader(header: string) {
    return (
      <Flex p={{ base: '12px 20px', md: '12px 0px' }} mb='12px'>
        <Text fontSize='lg' fontWeight='bold'>
          {header}
        </Text>
      </Flex>
    )
  }

  renderPortfolioItems() {
    const portfolio = this.getPortfolioData();
    return (
        <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
          {this.renderProfileSectionHeader('Items')}
          {portfolio && <PortfolioTable items={portfolio.getPortfolioDetailedItems()} isLoading={this.isFetchingPortfolioData()} onItemEditClick={this.onPortfolioItemEditClick} onItemRemovalClick={this.onPortfolioItemEditClick} onItemNameClick={(item) => this.onItemClick(item)} onItemArtistClick={this.onPortfolioArtistClick} />}
        </Box>
    );
  }

	renderWishlistItems() {
    const wishlist = this.getWishlistData();
    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        {this.renderProfileSectionHeader('Items')}
        {wishlist && <ItemGrid onItemCardClick={this.onItemClick} items={wishlist.getWishlistItems()} gridDisplay={true} isLoading={this.isFetchingWishlistData()} />}
      </Box>
    );
  }

  renderPortfolioContent() {
    const portfolio = this.getPortfolioData();
    if (portfolio && portfolio.hasItems()) {
      return (
        <Box>
          {this.renderPortfolioItems()}
        </Box>
      )
    } else {
      return <EmptyState header="No Items" description="No Items in Portfolio" buttonText="Browse Items" showButton={true} onButtonClick={this.onBrowseItems} />;
    }
  }

	renderWishlistContent() {
    const wishlist = this.getWishlistData();
    if (wishlist && wishlist.hasItems()) {
      return (
        <Box>
          {this.renderWishlistItems()}
        </Box>
      );
    } else {
      return (
        <EmptyState header="No Items" description="No Items in Wishlist" buttonText="Browse Items" showButton={true} onButtonClick={this.onBrowseItems} />
      );
    }
  }

	renderFollowingArtists() {
    const { user, userFollowingArtistsLoading } = this.props;
		if (user.getFollowingArtistIds().length > 0) {
			return (
				<Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
					{this.renderProfileSectionHeader('Artists')}
					<ArtistGrid onArtistCardClick={this.onArtistClick} artists={user.getFollowingArtists()} gridDisplay={true} isLoading={userFollowingArtistsLoading} />
				</Box>
			);
		} else {
			return <EmptyState header="No Artists" description="No Artists Being Followed" buttonText="Browse Artists" showButton={true} onButtonClick={this.onBrowseArtists} />;
		}
  }

  renderBanner() {
    const { user } = this.props;
    return (
      <UserBanner
        user={user}
				onBannerButtonClick={this.onUserBannerButtonClick}
        onAvatarClick={this.onUserBannerButtonClick}
        onUserConfirmationClick={this.onUserConfirmationClick}
      />
    );
  }


  renderTabs() {
    const labels = [
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={BsFolder} />
        <Text> Portfolio </Text>
      </Flex>,
			<Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
				<Icon as={AiOutlineHeart} />
				<Text> Wishlist </Text>
			</Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={BsBell} />
        <Text> Following Artists </Text>
      </Flex>
    ];

    const content = [
      this.renderPortfolioContent(),
			this.renderWishlistContent(),
      this.renderFollowingArtists()
    ];
    return (
      <TabGroup labels={labels} content={content} />
    );
  }

	
  render() {
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
        {this.renderBanner()}
        {this.renderTabs()}
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
    return {
      user: UserSelectors.getUser(state),
      userLoading: UserSelectors.getUserLoading(state),
      userPortfolioLoading: UserSelectors.getUserPortfolioLoading(state),
      userWishlistLoading: UserSelectors.getUserWishlistLoading(state),
      userFollowingArtistsLoading: UserSelectors.getUserFollowingArtistsLoading(state),
      portfolio: PortfolioSelectors.getPortfolio(state),
      portfolioLoading: PortfolioSelectors.getPortfolioLoading(state),
      wishlist: WishlistSelectors.getWishlist(state),
      wishlistLoading: WishlistSelectors.getWishlistLoading(state),
    }
  }
  
  function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
    return bindActionCreators(
      {},
      dispatch
    );
  }
  
  export const UserProfileDetailsView = connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(UserProfileDetails));