import { useState, useEffect } from 'react';
import moment from 'moment';
import {
	LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend, ReferenceLine
} from 'recharts';
import { ListingModel } from 'models';
import { MarketModel } from 'models';
import { YearlySummary, YearlySummaryItem } from 'models/Market/types';
import { MetricModel } from 'models/Market/Metric';
import {
	Flex,
	Text,
	Box
} from '@chakra-ui/react';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import { getLastKnownValueForYear, getLastKnownValueForMonth, getFullYearlyData, getFullDailyDataForMonth, getFullMonthlyDataForYear } from 'utils/market';
import { ActiveDot, DayDot, MonthDot, YearDot } from './Dots';
import { DayXTick, MonthXTick, YearXTick, PriceYTick } from './Ticks';
import { MarketPriceChartTooltip } from './Tooltips';
import { roundUpToSignificant } from 'utils/number';

interface MarketPriceChartProps {
	market: MarketModel;
	listings?: ListingModel[];
	startingPrice?: number | null;
}

function MarketPriceChart({ market, listings = [], startingPrice = null }: MarketPriceChartProps) {
	const [selectedYear, setSelectedYear] = useState<number | null>(null);
	const [selectedMonth, setSelectedMonth] = useState<number | null>(null);
	const [lastKnownValue, setLastKnownValue] = useState<number | null>(startingPrice);

	const data: YearlySummary = market.yearlySummary;
	const yearlyData: YearlySummaryItem[] = market.getYearlyData();
	const monthlyData: MetricModel[] = selectedYear ? market.getMonthlyDataForYear(selectedYear) : [];
	const dailyData: MetricModel[] = selectedYear && selectedMonth ? market.getDailyDataForMonthBasedOnListings(selectedYear, selectedMonth, listings) : [];

	useEffect(() => {
		if (selectedYear) {
			const lastKnownValueForSelectedYear = getLastKnownValueForYear(selectedYear, yearlyData);
			if (lastKnownValueForSelectedYear) {
				setLastKnownValue(lastKnownValueForSelectedYear);
			} else if (startingPrice) {
				setLastKnownValue(startingPrice);
			}
		}
	}, [selectedYear, yearlyData]);

	useEffect(() => {
		if (selectedYear && selectedMonth) {
			const lastKnownValueForSelectedMonth = getLastKnownValueForMonth(selectedYear, selectedMonth, yearlyData);
			if (lastKnownValueForSelectedMonth) {
				setLastKnownValue(lastKnownValueForSelectedMonth);
			} else if (startingPrice) {
				setLastKnownValue(startingPrice);
			}
		}
	}, [selectedMonth, monthlyData]);

	const handleDotClick = (payload: MetricModel | undefined) => {
		if (payload?.key) {
			if (selectedYear && !selectedMonth) {
				setSelectedMonth(Number(payload.key));
			} else if (!selectedYear) {
				const actualYearData = yearlyData.find(y => y.overview.key === Number(payload.key));
				if (actualYearData) {
					setSelectedYear(Number(payload.key));
				}
			}
		}
	};

	const getYDomain = (data: MetricModel[], startingPrice?: number | null | undefined) => {
		const values = data.map(d => d.mean);
		if (startingPrice !== null && startingPrice !== undefined) {
			values.push(startingPrice);
		}

		const roundedMax = roundUpToSignificant(Math.max(...values));
		return [0, roundedMax];
	};

	function renderTitle() {
		if (selectedYear && selectedMonth) {
			return (
				<Flex direction={'column'}>
					<Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
						Price History
					</Text>
					<Text textAlign="left" fontSize='sm' fontWeight='bold' mb='6px'>
						{moment(selectedMonth, 'M').format('MMMM')}, {selectedYear}
					</Text>
				</Flex>

			);
		} else if (selectedYear) {
			return (
				<Flex direction={'column'}>
					<Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
						Price History
					</Text>
					<Text textAlign="left" fontSize='sm' fontWeight='bold' mb='6px'>
						{selectedYear}
					</Text>
				</Flex>
			);
		} else {
			return (
				<Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
					Price History
				</Text>
			);
		}
	}

	function renderChartBackButton() {
		if (selectedMonth) {
			return (
				<Text textAlign={'right'} fontSize={10} cursor={'pointer'} color={'blue.500'} fontWeight={'bold'} onClick={() => setSelectedMonth(null)} colorScheme="blue">
					Back to Monthly View
				</Text>
			);
		} else if (selectedYear) {
			return (
				<Text textAlign={'right'} fontSize={10} cursor={'pointer'} color={'blue.500'} fontWeight={'bold'} onClick={() => setSelectedYear(null)} colorScheme="blue">
					Back to Yearly View
				</Text>
			);
		}
	}

	function renderChartData() {
		if (selectedYear && selectedMonth) {
			return (
				<LineChart data={getFullDailyDataForMonth(selectedYear, selectedMonth, listings, lastKnownValue)}>
					<XAxis dataKey="key" tick={DayXTick} />
					<YAxis tick={PriceYTick} />
					<Tooltip content={(props) => <MarketPriceChartTooltip selectedYear={selectedYear} selectedMonth={selectedMonth} listings={listings} {...props} />} />
					<Line name="Mean Price" dot={(props) => <DayDot dailyData={dailyData} {...props} />} type="monotone" dataKey="mean" />
					<Legend iconSize={14} wrapperStyle={{ fontSize: "12px", fontWeight: "bold" }} />
					{startingPrice && <ReferenceLine y={startingPrice} style={{ fontSize: "12px", fontWeight: "bold" }} stroke="blue" strokeDasharray="3 3" />}
				</LineChart>
			);
		} else if (selectedYear) {
			return (
				<LineChart data={getFullMonthlyDataForYear(selectedYear, data, lastKnownValue)}>
					<XAxis dataKey="key" tick={MonthXTick} />
					<YAxis tick={PriceYTick} />
					<Tooltip content={(props) => <MarketPriceChartTooltip selectedYear={selectedYear} selectedMonth={selectedMonth} listings={listings} {...props} />} />
					<Line
						name="Mean Price"
						dot={(props) => <MonthDot monthlyData={monthlyData} {...props} />}
						dataKey="mean"
						type="monotone"
						activeDot={<ActiveDot onClickHandler={handleDotClick} />}
					/>
					<Legend iconSize={14} wrapperStyle={{ fontSize: "12px", fontWeight: "bold" }} />
					{startingPrice && <ReferenceLine y={startingPrice} style={{ fontSize: "12px", fontWeight: "bold" }} stroke="blue" strokeDasharray="3 3" />}
				</LineChart>
			);
		} else {
			const fullYearlyData: MetricModel[] = getFullYearlyData(data).map(yearData => yearData.overview);
			return (
				<LineChart data={fullYearlyData} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
					<XAxis dataKey="key" interval={0} tick={YearXTick} />
					<YAxis tick={PriceYTick} domain={getYDomain(fullYearlyData, startingPrice)} />
					<Tooltip content={(props) => <MarketPriceChartTooltip selectedYear={selectedYear} selectedMonth={selectedMonth} listings={listings} {...props} />} />
					<Line
						name="Mean Price"
						dot={(props) => <YearDot yearlyData={yearlyData}{...props} />}
						dataKey="mean"
						type="monotone"
						activeDot={<ActiveDot onClickHandler={handleDotClick} />}
					/>
					<Legend iconSize={14} wrapperStyle={{ fontSize: "12px", fontWeight: "bold" }} />
					{startingPrice && <ReferenceLine y={startingPrice} style={{ fontSize: "12px", fontWeight: "bold" }} stroke="blue" strokeDasharray="3 3" />}
				</LineChart>
			)
		}
	}

	return (
		<Card p='28px 0px 0px 0px' borderRadius='8px'>
			<CardHeader mb='20px'>
				<Flex
					direction='column'
					alignSelf='flex-start'>
					{renderTitle()}
				</Flex>
			</CardHeader>
			<Box w='100%' height="300px" justifyContent="center" alignItems="center">
				<ResponsiveContainer>
					{renderChartData()}
				</ResponsiveContainer>
				{renderChartBackButton()}
			</Box>
		</Card>
	);
}

export default MarketPriceChart;
