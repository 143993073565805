export function getPercentageDifference(baseValue: number, compValue: number): number {
  if (baseValue === 0) {
    return compValue === 0 ? 0 : 100;
  } else {
    return ((compValue - baseValue) / Math.abs(baseValue)) * 100;
  }
}

export function roundUpToSignificant(num: number): number {
  if (num <= 0) return 0;

  let magnitude = Math.pow(10, Math.floor(Math.log10(num)));

  if (num === magnitude) return num;

  return Math.ceil(num / magnitude) * magnitude;
}