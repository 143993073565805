import { useState } from 'react';
import {
	Text,
	Flex,
	Box,
	useToken
} from '@chakra-ui/react';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { DistributionActiveShape } from './Shapes';
import { TypeLegend } from './Legends';
import { TypeDistributionTooltip } from './Tooltips';
import { ItemType } from 'models/Item/types';
import { DetailedPortfolioItemModel, ItemModel } from 'models';

interface TypeDistributionPieChartProps {
	items: (ItemModel | DetailedPortfolioItemModel)[];
}

export default function TypeDistributionPieChart({ items }: TypeDistributionPieChartProps) {
	const [activeIndex, setActiveIndex] = useState(0);
	const brandColors = useToken(
		'colors',
		['green.800', 'teal.700', 'blue.600', 'cyan.500', 'purple.400', 'pink.300', 'red.200', 'orange.100']
	).reverse();

	const onPieEnter = (_: any, index: number) => {
		setActiveIndex(index);
	};

	const aggregateItemsByType = (items: (ItemModel | DetailedPortfolioItemModel)[]) => {
		const typeCounts: { [type in ItemType]: number } = {
			[ItemType.PRINT]: 0,
			[ItemType.SCULPTURE]: 0,
			[ItemType.MISC]: 0,
			[ItemType.UNKNOWN]: 0
		};

		items.forEach((item: ItemModel | DetailedPortfolioItemModel) => {
			if (item instanceof ItemModel) {
				typeCounts[item.type]++;
			} else {
				typeCounts[item.item.type] += item.getTotalPurchaseCount();
			}
		});

		return typeCounts;
	};

	const getTotalCount = (artistIdCounts: { [artistId: string]: number }) => {
		return Object.values(artistIdCounts).reduce((total, count) => total + count, 0);
	};

	const typeCounts = aggregateItemsByType(items);
	const totalCount = getTotalCount(typeCounts);

	const pieChartData = Object.entries(typeCounts).map(([type, count]) => ({
		name: type,
		value: count,
		percentage: ((count / totalCount) * 100).toFixed(2)
	}));

	const colors = pieChartData.map((_, index) => brandColors[index % brandColors.length]);

	return (
		<Card p='28px 0px 0px 0px' borderRadius='8px'>
			<CardHeader mb='20px'>
				<Flex direction='column' alignSelf='flex-start'>
					<Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
						Item Type Distribution
					</Text>
				</Flex>
			</CardHeader>
			<Box w='100%' justifyContent="center" alignItems="center">
				<ResponsiveContainer width="100%" height={400}>
					<PieChart>
						<Pie
							activeIndex={activeIndex}
							activeShape={DistributionActiveShape}
							data={pieChartData}
							cx="50%"
							cy="50%"
							labelLine={false}
							outerRadius={150}
							fill="#8884d8"
							dataKey="value"
							onMouseEnter={onPieEnter}
						>
							{pieChartData.map((entry, index) => (
								<Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
							))}
						</Pie>
						<Tooltip content={TypeDistributionTooltip} />
						<Legend content={TypeLegend} />
					</PieChart>
				</ResponsiveContainer>
			</Box>
		</Card>
	);
}