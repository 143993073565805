import { HomeFeedData } from "models/Content/types";

export enum HomeActionTypes {
	GET_FEED_CONTENT = 'HOME_GET_FEED_CONTENT',
	GET_FEED_CONTENT_SUCCESS = 'GET_FEED_CONTENT_SUCCESS',
	GET_FEED_CONTENT_FAILURE = 'GET_FEED_CONTENT_FAILURE'
};

export interface HomeActionPayload {
	homeFeedData: HomeFeedData;
};

export interface HomeAction {
  type: HomeActionTypes;
  payload?: HomeActionPayload;
};

export interface HomeStateLoading {
	feedContentFetch: boolean;
};

export interface HomeStateError {
	feedContentFetch: Error | undefined;
}

export interface HomeState {
  homeFeedData: HomeFeedData | undefined;
  error: HomeStateError;
  loading: HomeStateLoading;
};
