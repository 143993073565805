import { ItemModel } from '../Item/Item';
import { RawItemData }  from '../Item/types';
import { ArtistModel } from '../Artist/Artist';
import { CompositeMarketModel } from '../Market/CompositeMarket';
import { Cloneable } from '../Utils/Cloneable';
import { ListingModel } from '../Listing/Listing';
import { PaginationModel } from '../Pagination/Pagination';

export interface RawBasicWishlistData {
  id: string;
  user: string;
  items: string[];
}

export interface RawWishlistData {
  id: string;
  user: string;
  items: RawItemData[];
}

export class WishlistModel extends Cloneable {
  id: string;
  user: string;
  items: (string | ItemModel)[];
  artists: (string | ArtistModel)[];
  market?: CompositeMarketModel;
  listings?: PaginationModel<ListingModel> | null;

  constructor(rawBasicWishlistData?: RawBasicWishlistData) {
    super();
    this.id = rawBasicWishlistData?.id || '';
    this.user = rawBasicWishlistData?.user || '';
    this.items = rawBasicWishlistData?.items || [];
    this.artists = [];
    this.listings = null;
  }

  static fromRawWishlistData(rawWishlistData: RawWishlistData): WishlistModel {
    const artists: string[] = Array.from(new Set(rawWishlistData.items.map(item => item.artist_id)));
    const wishlist: WishlistModel = new this({
      id: rawWishlistData.id,
      user: rawWishlistData.user,
      items: rawWishlistData.items.map(item => item.id)
    });
    wishlist.setRawItems(rawWishlistData.items);
    wishlist.setArtists(artists);
    return wishlist;
  }

  setRawItems(items: RawItemData[]): void {
    const formattedItems: ItemModel[] = items.map(item => new ItemModel(item));
    this.setItems(formattedItems);
  }

  setItems(items: ItemModel[]): void {
    if (items && items.length) {
      this.items = items;
    }
  }

  setMarket(market: CompositeMarketModel | undefined) {
    if (market) {
      this.market = market;
    }
  }

  setListings(listings: PaginationModel<ListingModel> | null | undefined) {
    if (listings) {
      this.listings = listings;
    }
  }
  
  setArtists(artists: (ArtistModel | string)[]): void {
    this.artists = artists;
  }

  public getArtistIds(): string[] {
    return this.artists.map(
      (artist: ArtistModel | string) => artist instanceof ArtistModel ? artist.id : artist
    );
  }

  public getArtists(): ArtistModel[] {
    const artists: ArtistModel[] = [];
    this.artists.forEach((artist: string | ArtistModel) => {
      if (artist instanceof ArtistModel) {
        artists.push(artist);
      }
    });

    return artists;
  }

  public getWishlistItems(): ItemModel[] {
    const items: ItemModel[] = [];
    this.items.forEach((item: string | ItemModel) => {
      if (item instanceof ItemModel) {
        items.push(item);
      }
    });
    return items;
  }

  public containsItem(itemId: string): boolean {
    return !!this.items.find((item : ItemModel | string) => {
      if (item instanceof ItemModel) {
        return item.id === itemId;
      } else {
        return item === itemId;
      }
    });
  }

  public hasItems(): boolean {
    return !!this.items.length;
  }

  public getEstimatedMarketValue(): number {
    return this.getWishlistItems().reduce((total, item) => total + item.getEstimatedMarketPrice(), 0)
  }
}
