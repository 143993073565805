import {
  Box,
  Image,
  Heading,
  Text,
  Flex,
  Icon,
  Link,
  Tooltip,
  Stack,
  StackDivider,
  Spacer,
  Button,
  Avatar
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import Feature from '../../Feature/Feature';
import { BsCalendar3, BsGear, BsAt } from 'react-icons/bs';
import { FaInstagram } from 'react-icons/fa';
import { FiExternalLink } from 'react-icons/fi';
import { MdOutlineMailOutline } from 'react-icons/md';
import { UserModel } from 'models/User/User';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';


export interface UserBannerProps {
  user: UserModel;
  onBannerButtonClick?: () => void;
  onAvatarClick?: () => void;
  onUserConfirmationClick?: () => void;
}

export default function UserBanner({ user, onAvatarClick, onUserConfirmationClick, onBannerButtonClick = () => { } }: UserBannerProps) {
  function renderUserContactIcons() {
    const icons = [];
    if (user.instagram) {
      icons.push(
        <Tooltip label={'Instagram'} key="userBanner_instagram">
          <Link
            href={`https://www.instagram.com/${user.instagram}`}
            isExternal={true}
            fontSize='lg'
            _hover={{ color: "blue.500" }}>
            <Icon as={FaInstagram} />
          </Link>
        </Tooltip>
      );
    }

    return (
      <Flex alignItems="center" justifyContent={{ 'base': 'center', 'md': 'right' }} direction={'row'} gap={'8px'}>
        {icons}
      </Flex>
    );
  }

  function renderUserInfo() {
    const containerDivider = <StackDivider borderColor={'gray.100'} />;
    return (
      <Stack
        paddingTop={6}
        spacing={4}
        divider={containerDivider}>
        {!!user.country.code && <Feature
          icon={
            <Text fontSize="2xl">
              {getUnicodeFlagIcon(user.country.code)}
            </Text>
          }
          iconBg={'grey.100'}
          text={user.country.name}
        />}
        <Feature
          icon={<Icon as={MdOutlineMailOutline} color={'yellow.500'} w={5} h={5} />}
          iconBg={'yellow.100'}
          text={(
            <Flex alignItems={'center'} gap={2}>
              <Text>{user.email}</Text>
              {user.isEmailVerified() ? (
                <CheckCircleIcon w={4} h={4} color={'blue.500'} />
              ) : (
                <Tooltip label={'Confirm Your Email'}>
                  <WarningIcon w={4} h={4} color={'orange.500'} onClick={onUserConfirmationClick} cursor={onUserConfirmationClick ? 'pointer' : 'cursor'} />
                </Tooltip>
              )}
            </Flex>
          )}
        />
        {user.hasBirthYear() && (
          <Feature
            icon={<Icon as={BsCalendar3} color={'green.500'} w={5} h={5} />}
            iconBg={'green.100'}
            text={`${user.birthYear}`}
          />
        )}
      </Stack>
    );
  }

  function renderBannerButton() {
    return (
      <Button leftIcon={<BsGear />} onClick={onBannerButtonClick} border="1px" marginTop={{ 'base': '20px', 'md': '0px' }} padding={2}>
        Settings
      </Button>
    );
  }

  function renderImage() {
    if (user.hasImage()) {
      return (
        <Avatar
          src={user.getImage()}
          me={{ md: "22px" }}
          w='200px'
          h='200px'
        />
      );
    } else {
      return (
        <Avatar
          me={{ md: "22px" }}
          cursor={onAvatarClick ? 'pointer' : 'cursor'}
          onClick={onAvatarClick}
          w='50%%'
          h='50%'
        />
      );
    }
  }

  return (
    <Box borderBottomWidth='1px' borderRadius='lg' paddingBottom="20px" width={'100%'}>
      <Flex direction={{ 'base': 'column', 'md': 'row' }}>
        <Flex width={{ 'base': '100%', 'md': '50%' }} direction={'column'} alignItems={'center'} gap={4}>
          {renderImage()}
          {renderUserContactIcons()}
        </Flex>
        <Box textAlign={{ 'base': 'center', 'md': 'left' }} paddingLeft={{ 'base': '0px', 'md': '48px' }} paddingTop={{ 'base': '20px', 'md': '0px' }}>
          <Flex direction={{ 'base': 'column', 'md': 'row' }} alignItems="center" gap={2}>
            <Flex direction={'column'}>
              <Heading fontSize={'2xl'}>
                @{user.userName}
              </Heading>
            </Flex>
            <Spacer />
            {renderBannerButton()}
          </Flex>
          {renderUserInfo()}
        </Box>
      </Flex>
    </Box>
  );
}
