import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';

export class ItemSaleSuggestionSelectors {
  public static getError(state: ApplicationState): Error | undefined {
    return RootSelectors.getItemSaleSuggestionState(state).error;
  }

  public static getItemSaleSuggestionCreateLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemSaleSuggestionState(state).loading.create;
  }
}
