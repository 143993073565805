import { Box, useStyleConfig } from "@chakra-ui/react";


const noBorderStyle = {
	p: "22px",
	display: "flex",
	flexDirection: "column",
	width: "100%",
	backgroundClip: "border-box",
	border: 'none'
}

const colorStyle = {
	backdropFilter: "blur(120px)",
	borderRadius: "10px",
	bg: "black",
	...noBorderStyle
};

const dashedStyle = {
	...noBorderStyle,
	border: "1px solid"
}

export enum CardVariants {
	COLORED,
	DASHED,
	NO_BORDER
}

export default function Card(props: any) {
	const { variant, children, ...rest } = props;
	const styles = useStyleConfig("Card");
	let variantSpecificStyle = {};

	if (props.type === CardVariants.COLORED) {
		variantSpecificStyle = colorStyle;
	} else if (props.type === CardVariants.NO_BORDER) {
		variantSpecificStyle = noBorderStyle;
	} else {
		variantSpecificStyle = dashedStyle;
	}

	return (
		<Box __css={styles} {...rest} {...variantSpecificStyle}>
			{children}
		</Box>
	);
}
