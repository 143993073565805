import { Loop, LoopReducer } from 'redux-loop';
import { HomeHandlers } from './handlers';
import { HomeState, HomeAction, HomeActionTypes } from './types';
import { defaultState } from './consts';

export const homeReducer: LoopReducer<HomeState, HomeAction> = (
  state: HomeState = defaultState,
  action: HomeAction
): HomeState | Loop<HomeState, HomeAction> => {
  switch (action.type) {
    case HomeActionTypes.GET_FEED_CONTENT:
      return HomeHandlers.handleGetFeedContent(state);
    case HomeActionTypes.GET_FEED_CONTENT_SUCCESS:
      return HomeHandlers.handleGetFeedContentSuccess(state, action);
    case HomeActionTypes.GET_FEED_CONTENT_FAILURE:
      return HomeHandlers.handleGetFeedContentFailure(state);
    default:
      return state;
  }
}
