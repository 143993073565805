import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { ArtistSelectors } from 'reducers/Artist/selectors';
import { UserSelectors } from 'reducers/User/selectors';
import { ArtistActions } from 'reducers/Artist/actions';
import { UserActions } from 'reducers/User/actions';
import { ListingActions } from 'reducers/Listing/actions';
import { ListingSelectors } from 'reducers/Listing/selectors';
import { ItemListingGroupingActions } from 'reducers/ItemListingGrouping/actions';
import { ItemListingGroupingSelectors } from 'reducers/ItemListingGrouping/selectors';
import { ListingDataSuggestionActions } from 'reducers/ListingDataSuggestion/actions';
import { ListingDataSuggestionSelectors } from 'reducers/ListingDataSuggestion/selectors';
import {
  Box,
  Icon,
  Flex,
  Text,
  Stack,
  Circle
} from '@chakra-ui/react';
import { IoGridOutline } from 'react-icons/io5';
import { RiAuctionLine } from 'react-icons/ri';
import { BsSearch } from 'react-icons/bs';
import { ImStatsDots } from 'react-icons/im';
import { JSONObject } from 'models/Api/types';
import { ArtistModel, ItemModel, UserModel, ListingModel, PaginationModel } from 'models';
import { ItemGrid, PaginatedListingGrid, TabGroup, ArtistBanner, ListingItemSuggestionWizard, ListingDataSuggestionWizard, Modal, ListingSearchFiltersForm } from 'components';
import { ListingSearchFiltersFormData } from 'components/Form/ListingSearchFiltersForm/ListingSearchFiltersForm';
import { NavigationService, FeatureToggleService } from 'services';
import { FeatureToggleKeys } from 'constants/toggles';
import { AppConstants } from '../../constants';
import { convertListingSearchFilterDataToQuery } from 'utils/search';
import ArtistIndexChart from 'components/Charts/ArtistIndexChart';

interface ArtistDetailsViewProps extends RouterProps {
  artist: ArtistModel;
  user: UserModel;
  artistItemLoading: boolean;
  artistListingLoading: boolean;
  artistEditionIndexLoading: boolean;
  listingSearchLoading: boolean;
  userFollowingArtistsLoading: boolean;
  userArtistActionLoading: boolean;
  createItemListingGroupingLoading: boolean;
  createListingDataSuggestionLoading: boolean;
  listingSearchResults: PaginationModel<ListingModel>;
  getArtist: (id: string) => void;
  getArtistItems: (id: string) => void;
  getArtistEditionIndex: (id: string) => void;
  getArtistListings: (id: string, page?: number) => void;
  followArtist: (id: string) => void;
  unfollowArtist: (id: string) => void;
  createItemListingGrouping: (itemId: string, listingIds: string[]) => void;
  createListingDataSuggestion: (listingId: string, data: JSONObject) => void;
  onListingSearch: (query: JSONObject) => void;
}

interface ArtistDetailsViewModalState {
  listingItemSuggestionModal: ListingModel | undefined;
  listingDataSuggestionModal: ListingModel | undefined;
  listingSearchFiltersModal: boolean;
}

interface ArtistDetailsState {
  fetchedAdditionalData: boolean;
  modals: ArtistDetailsViewModalState;
  listingItemSuggestionSelectedItem: ItemModel | undefined;
  listingSearchFilters: ListingSearchFiltersFormData | undefined;
  listingSearchMode: boolean;
}

class ArtistDetails extends Component<ArtistDetailsViewProps, ArtistDetailsState> {
  state = {
    fetchedAdditionalData: false,
    listingItemSuggestionSelectedItem: undefined,
    listingSearchFilters: undefined,
    listingSearchMode: false,
    modals: {
      listingItemSuggestionModal: undefined,
      listingDataSuggestionModal: undefined,
      listingSearchFiltersModal: false
    }
  };

  componentDidMount() {
    const { params } = this.props;
    if (params && params.id) {
      this.props.getArtist(params.id);
    }
  }

  componentDidUpdate(prevProps: ArtistDetailsViewProps) {
    const { artist, params } = this.props;

    if (params.id && this.hasRouteChanged(prevProps)) {
      this.props.getArtist(params.id);
      this.setState({ fetchedAdditionalData: false })
    }

    if (!this.state.fetchedAdditionalData) {
      this.props.getArtistItems(artist.id);
      this.props.getArtistListings(artist.id, 1);
      this.props.getArtistEditionIndex(artist.id);
      this.setState({ fetchedAdditionalData: true, listingSearchFilters: this.getDefaultListingSearchFilters() });
    }
  }

  getDefaultListingSearchFilters = (): ListingSearchFiltersFormData => {
    const { artist } = this.props;
    return { artistId: [artist.id] };
  }

  hasRouteChanged = (prevProps: ArtistDetailsViewProps): boolean => {
    const { params } = this.props;
    return !!params.id && (params.id !== prevProps.params.id);
  }

  onPaginatedListingGridLoad = (): void => {
    const { artist, getArtistListings, listingSearchResults, onListingSearch } = this.props;
    const { listingSearchMode, listingSearchFilters } = this.state;

    if (listingSearchMode && listingSearchFilters && listingSearchResults.hasMorePages()) {
      onListingSearch({
        ...convertListingSearchFilterDataToQuery(listingSearchFilters, true),
        page: listingSearchResults.currentPage + 1
      });
    } else {
      getArtistListings(artist.id, artist.getSoldListings().currentPage + 1);
    }
  }

  onPaginatedListingPageSelect = (page: number) => {
    const { artist, getArtistListings, onListingSearch } = this.props
    const { listingSearchMode, listingSearchFilters } = this.state;

    if (listingSearchMode && listingSearchFilters) {
      onListingSearch({
        ...convertListingSearchFilterDataToQuery(listingSearchFilters, true),
        page
      });
    } else {
      getArtistListings(artist.id, page);
    }
  }

  onArtistItemGridClick = (item: ItemModel) => {
    this.props.navigate(NavigationService.getItemDetailsPath(item.id));
  };

  onListingItemClick = (id: string) => {
    this.props.navigate(NavigationService.getItemDetailsPath(id));
  };

  onListingItemSuggestionModalOpen = (listing: ListingModel) => {
    const { user, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          listingItemSuggestionModal: listing
        }
      });
    }
  };

  onListingItemSuggestionModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        listingItemSuggestionModal: undefined
      }
    });
  };

  onListingDataSuggestionModalOpen = (listing: ListingModel) => {
    const { user, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      this.setState({
        modals: {
          ...this.state.modals,
          listingDataSuggestionModal: listing
        }
      });
    }
  };

  onListingDataSuggestionModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        listingDataSuggestionModal: undefined
      }
    });
  };

  onListingSearchQueryModalOpen = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        listingSearchFiltersModal: true
      }
    });
  }

  onListingSearchQueryModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        listingSearchFiltersModal: false
      }
    });
  }

  onListingItemSuggestionSubmit = (listing: ListingModel, item: ItemModel) => {
    const { createItemListingGrouping } = this.props;
    createItemListingGrouping(item.id, [listing.id]);
    this.onListingItemSuggestionModalClose();
  }

  onListingDataSuggestionSubmit = (listing: ListingModel, data: JSONObject) => {
    const { createListingDataSuggestion } = this.props;
    createListingDataSuggestion(listing.id, data);
    this.onListingDataSuggestionModalClose();
  }

  onListingSearchFiltersFormSubmit = (searchQuery: ListingSearchFiltersFormData) => {
    this.setState({ listingSearchFilters: searchQuery, listingSearchMode: true });
    this.props.onListingSearch(convertListingSearchFilterDataToQuery(searchQuery, true));
    this.onListingSearchQueryModalClose();
  }

  onListingSearchFiltersFormReset = (): void => {
    this.setState({ listingSearchFilters: this.getDefaultListingSearchFilters() })
  }

  onArtistBannerFollowClick = () => {
    const { user, artist, followArtist, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      followArtist(artist.id);
    }
  }

  onArtistBannerUnfollowClick = () => {
    const { user, artist, unfollowArtist, navigate } = this.props;
    if (!user.isAuthenticated()) {
      navigate(
        NavigationService.getAuthLoginPath()
      );
    } else {
      unfollowArtist(artist.id);
    }
  }

  renderListingDataSuggestionWizard() {
    const { createListingDataSuggestionLoading } = this.props;
    const { modals } = this.state;
    const { listingDataSuggestionModal } = modals;

    return (
      <Flex>
        {listingDataSuggestionModal &&
          <ListingDataSuggestionWizard
            listing={listingDataSuggestionModal}
            isLoading={createListingDataSuggestionLoading}
            onSubmit={this.onListingDataSuggestionSubmit} />
        }
      </Flex>
    );
  }

  renderListingItemSuggestionWizard() {
    const { artist, artistItemLoading, createItemListingGroupingLoading } = this.props;
    const { modals } = this.state;
    const { listingItemSuggestionModal } = modals;

    return (
      <Flex>
        {listingItemSuggestionModal &&
          <ListingItemSuggestionWizard
            listing={listingItemSuggestionModal}
            items={artist.getItems()}
            isLoading={artistItemLoading || createItemListingGroupingLoading}
            onSubmit={this.onListingItemSuggestionSubmit} />
        }
      </Flex>
    )
  }

  renderListingSearchFiltersModalContent() {
    const { artist, listingSearchLoading } = this.props;
    const { listingSearchFilters } = this.state;
    return (
      <ListingSearchFiltersForm artistList={[artist]} artistSelectionEnabled={false} submitLoading={listingSearchLoading} defaultFormData={listingSearchFilters} resetState={this.getDefaultListingSearchFilters()} onSubmit={this.onListingSearchFiltersFormSubmit} onReset={this.onListingSearchFiltersFormReset} />
    );
  }

  renderArtistItemsGrid() {
    const { artist, artistItemLoading } = this.props;
    return (
      <ItemGrid onItemCardClick={this.onArtistItemGridClick} items={artist.getItems()} isLoading={artistItemLoading} paginate={true} />
    );
  }

  renderListingSearchButton() {
    return (
      <Circle size='40px' bg='gray.300' left={'50%'} transform={'translateX(-50%)'} position={'fixed'} bottom={100} cursor={'pointer'} onClick={this.onListingSearchQueryModalOpen}>
        <Icon as={BsSearch} color={'white.100'} />
      </Circle>
    );
  }

  renderArtistListingsGrid() {
    const { artistListingLoading, artist, listingSearchResults, listingSearchLoading } = this.props;
    const { listingSearchMode } = this.state;
    const paginatedListings = listingSearchMode ? listingSearchResults : artist.getSoldListings();
    return (
      <Stack>
        <PaginatedListingGrid
          paginatedListings={paginatedListings}
          onScrollEndLoad={this.onPaginatedListingGridLoad}
          onPageSelect={this.onPaginatedListingPageSelect}
          isLoading={artistListingLoading || listingSearchLoading}
          onListingItemClick={this.onListingItemClick}
          onItemSuggestionClick={this.onListingItemSuggestionModalOpen}
          onTagListingClick={this.onListingDataSuggestionModalOpen}
          scrollMode={!listingSearchMode}
        />
        {this.renderListingSearchButton()}
      </Stack>
    );
  }

  renderArtistEditionIndex() {
    const { artist } = this.props;
    const editionIndexData = artist.getEditionIndex();
    if (editionIndexData)
      return (
        <ArtistIndexChart artistIndexData={editionIndexData} artist={artist} />
      )
  }

  renderArtistStats() {
    return (
      <Stack>
        {this.renderArtistEditionIndex()}
      </Stack>
    );
  }

  renderBanner() {
    const { artist, user, userFollowingArtistsLoading, userArtistActionLoading } = this.props;
    const isFollowing = user.followsArtist(artist.id);
    const followActionLoading = userFollowingArtistsLoading || userArtistActionLoading;
    return (
      <ArtistBanner
        artist={artist}
        isFollowing={isFollowing}
        followActionLoading={followActionLoading}
        onFollowClick={this.onArtistBannerFollowClick}
        onUnfollowClick={this.onArtistBannerUnfollowClick}
      />
    );
  }

  renderTabs() {
    let labels = [
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={IoGridOutline} />
        <Text> Items </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={RiAuctionLine} />
        <Text> Past Sales </Text>
      </Flex>
    ];

    let content = [
      this.renderArtistItemsGrid(),
      this.renderArtistListingsGrid(),
    ];

    if (FeatureToggleService.isToggleEnabled(FeatureToggleKeys.ARTIST_DETAILS_STATS)) {
      labels.push(
        <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
          <Icon as={ImStatsDots} />
          <Text> Stats </Text>
        </Flex>
      );

      content.push(
        this.renderArtistStats()
      );

    }
    return (
      <TabGroup labels={labels} content={content} />
    );
  }

  renderModals() {
    const { modals } = this.state;
    return (
      <Stack>
        <Modal title={'Suggest Item'} isOpen={!!modals.listingItemSuggestionModal} onClose={this.onListingItemSuggestionModalClose} content={this.renderListingItemSuggestionWizard()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Search Filters'} isOpen={!!modals.listingSearchFiltersModal} onClose={this.onListingSearchQueryModalClose} content={this.renderListingSearchFiltersModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Tag Sale'} isOpen={!!modals.listingDataSuggestionModal} onClose={this.onListingDataSuggestionModalClose} content={this.renderListingDataSuggestionWizard()} showCloseButton={false} showSubmitButton={false} />
      </Stack>
    );
  }

  render() {
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center">
        {this.renderBanner()}
        {this.renderTabs()}
        {this.renderModals()}
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    artist: ArtistSelectors.getArtist(state),
    artistItemLoading: ArtistSelectors.getArtistItemLoading(state),
    artistListingLoading: ArtistSelectors.getArtistListingLoading(state),
    artistEditionIndexLoading: ArtistSelectors.getArtistEditionIndexLoading(state),
    userArtistActionLoading: UserSelectors.getUserArtistActionLoading(state),
    userFollowingArtistsLoading: UserSelectors.getUserFollowingArtistsLoading(state),
    createItemListingGroupingLoading: ItemListingGroupingSelectors.getItemListingGroupingCreateLoading(state),
    createListingDataSuggestionLoading: ListingDataSuggestionSelectors.getListingDataSuggestionCreateLoading(state),
    user: UserSelectors.getUser(state),
    listingSearchLoading: ListingSelectors.getListingSearchLoading(state),
    listingSearchResults: ListingSelectors.getPaginatedListings(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      getArtist: (id: string) => ArtistActions.getArtistById(id),
      getArtistItems: (id: string) => ArtistActions.getArtistItems(id),
      getArtistListings: (id: string, page?: number) => ArtistActions.getArtistListings(id, page),
      getArtistEditionIndex: (id: string) => ArtistActions.getArtistEditionIndex(id),
      followArtist: (id: string) => UserActions.followArtist(id),
      unfollowArtist: (id: string) => UserActions.unfollowArtist(id),
      createItemListingGrouping: (itemId: string, listingIds: string[]) => ItemListingGroupingActions.createItemListingGrouping(itemId, listingIds),
      createListingDataSuggestion: (listingId: string, data: JSONObject) => ListingDataSuggestionActions.createListingDataSuggestion(listingId, data),
      onListingSearch: (query: JSONObject) => ListingActions.searchListings(query)
    },
    dispatch
  );
}

export const ArtistDetailsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArtistDetails));
