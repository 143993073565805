import { Cmd, loop, Loop } from 'redux-loop';
import { SalesVenueInternalActions } from './actions';
import { SalesVenueApiClient } from 'api';
import { SalesVenueModel } from 'models';
import { SalesVenueState, SalesVenueAction } from './types';
import { defaultState } from './consts';

export enum SalesVenueStateErrors {
  ID_FETCH = 'Unable to fetch salesVenue',
  ALL_FETCH = 'Unable to fetch all salesVenues',
}

export class SalesVenueHandlers {
  public static handleGetSalesVenueById(state: SalesVenueState, action: SalesVenueAction): Loop<SalesVenueState, SalesVenueAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        item: true
      }
    };

    return loop(
      newState,
      Cmd.run(SalesVenueApiClient.getSalesVenueById, {
        args: [payload?.id || ''],
        successActionCreator: SalesVenueInternalActions.getSalesVenueByIdSuccess,
        failActionCreator: SalesVenueInternalActions.getSalesVenueByIdFailure,
      })
    );
  }

  public static handleGetSalesVenueByIdSuccess(state: SalesVenueState, action: SalesVenueAction): SalesVenueState | Loop<SalesVenueState, SalesVenueAction> {
    const salesVenue: SalesVenueModel = action.payload?.salesVenue || defaultState.salesVenue;

    return {
      ...state,
      salesVenue,
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetSalesVenueByIdFailure(state: SalesVenueState): SalesVenueState {
    return {
      ...state,
      error: new Error(SalesVenueStateErrors.ID_FETCH),
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetAllSalesVenues(state: SalesVenueState): Loop<SalesVenueState, SalesVenueAction> {
    const newState: SalesVenueState = {
      ...state,
      loading: {
        ...state.loading,
        list: true
      }
    };

    return loop(
      newState,
      Cmd.run(SalesVenueApiClient.getAllSalesVenues, {
        args: [],
        successActionCreator: SalesVenueInternalActions.getAllSalesVenuesSuccess,
        failActionCreator: SalesVenueInternalActions.getAllSalesVenuesFailure,
      })
    );
  }

  public static handleGetAllSalesVenuesSuccess(state: SalesVenueState, action: SalesVenueAction): SalesVenueState {
    const salesVenueList = action.payload?.salesVenueList || defaultState.salesVenueList;
    return {
      ...state,
      salesVenueList,
      loading: {
        ...state.loading,
        list: false
      }
    };
  }

  public static handleGetAllSalesVenuesFailure(state: SalesVenueState): SalesVenueState {
    return {
      ...state,
      error: new Error(SalesVenueStateErrors.ALL_FETCH),
      loading: {
        ...state.loading,
        list: false
      }
    };
  }
}
