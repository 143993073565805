import { Cmd, loop, Loop } from 'redux-loop';
import { HomeInternalActions } from './actions';
import { ContentApiClient } from 'api';
import { HomeFeedData } from "models/Content/types";
import { HomeState, HomeAction } from './types';

export enum HomeStateErrors {
  FEED_CONTENT_FETCH = 'Unable to fetch home feed content',
}

export class HomeHandlers {
  public static handleGetFeedContent(state: HomeState): Loop<HomeState, HomeAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        feedContentFetch: true
      }
    };

    return loop(
      newState,
      Cmd.run(ContentApiClient.getHomeFeed, {
        args: [],
        successActionCreator: HomeInternalActions.getFeedContentSuccess,
        failActionCreator: HomeInternalActions.getFeedContentFailure,
      })
    );
  }

  public static handleGetFeedContentSuccess(state: HomeState, action: HomeAction): HomeState | Loop<HomeState, HomeAction> {
    const feedData: HomeFeedData | undefined = action.payload?.homeFeedData || undefined;

    return {
      ...state,
      homeFeedData: feedData,
      error: {
        ...state.error,
        feedContentFetch: undefined
      },
      loading: {
        ...state.loading,
        feedContentFetch: false
      }
    };
  }

  public static handleGetFeedContentFailure(state: HomeState): HomeState {
    return {
      ...state,
      error: {
        feedContentFetch: new Error(HomeStateErrors.FEED_CONTENT_FETCH),
      },
      loading: {
        ...state.loading,
        feedContentFetch: false
      }
    };
  }
}
