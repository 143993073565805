import { MetricModel } from 'models/Market/Metric';
import { Dot } from 'recharts';
import { ExtendedActiveDotProps } from '../types';

export interface MonthDotProps extends ExtendedActiveDotProps {
	monthlyData: MetricModel[];
}

export default function MonthDot(dotProps: MonthDotProps) {
	const { payload, monthlyData } = dotProps;
	const actualData = monthlyData.find(m => m?.key === payload?.key);
	if (actualData) {
		return <Dot {...dotProps} r={4} />;
	}
	return <Dot {...dotProps} r={0} opacity={0} style={{ pointerEvents: "none" }} />;
}
