import { ListingDataSuggestionActionTypes, ListingDataSuggestionAction } from './types';
import { JSONObject } from 'models/Api/types';

export class ListingDataSuggestionActions {
  public static createListingDataSuggestion(listingId: string, data: JSONObject): ListingDataSuggestionAction {
    return {
      type: ListingDataSuggestionActionTypes.CREATE_LISTING_DATA_SUGGESTION,
      payload: { listingId, data },
    };
  }
}

export class ListingDataSuggestionInternalActions {
  public static createListingDataSuggestionSuccess(id: string): ListingDataSuggestionAction {
    return {
      type: ListingDataSuggestionActionTypes.CREATE_LISTING_DATA_SUGGESTION_SUCCESS,
      payload: { id },
    };
  }

  public static createListingDataSuggestionFailure(): ListingDataSuggestionAction {
    return { type: ListingDataSuggestionActionTypes.CREATE_LISTING_DATA_SUGGESTION_FAILURE };
  }
}
