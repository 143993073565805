import { UrlConfig } from 'config';
import { MaterialModel } from 'models';
import { RawMaterialData } from 'models/Material/Material';
import { ApiResponse } from 'models/Api/ApiResponse';
import { AjaxService } from 'services';

export class MaterialApiClient {
  public static async getMaterialById(id: string): Promise<MaterialModel> {
    const url = UrlConfig.MATERIAL.MATERIAL_BY_ID + `${id}`;
    const response: ApiResponse<RawMaterialData> = await AjaxService.getData(url);
    const material = new MaterialModel(response.data);
    return material;
  }

  public static async getAllMaterials(): Promise<MaterialModel[]> {
    const url = UrlConfig.MATERIAL.MATERIAL_ALL;
    const response: ApiResponse<RawMaterialData[]> = await AjaxService.getData(url);
    const materialList = response.data.map((rawMaterial: RawMaterialData) => new MaterialModel(rawMaterial));
    return materialList;
  }
}
