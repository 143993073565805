import snakecaseKeys from 'snakecase-keys';
import { JSONObject } from 'models/Api/types';

export function getFilteredObject(
  filterKeys: string[],
  originalObject: Record<string, unknown>
): Record<string, unknown> {
  return Object.keys(originalObject)
    .filter((key) => filterKeys.includes(key))
    .reduce((obj, key) => {
      return Object.assign(obj, {
        [key]: originalObject[key]
      });
    }, {});
};

export function convertJSONObjectToSnakecase(data: JSONObject): JSONObject {
  return snakecaseKeys(data);
}

export function flattenKeys(obj: any, parent?: string): any {
  let flat: { [key: string]: any } = {};
  for (let key in obj) {
      let newKey = parent ? `${parent}.${key}` : key;
      if (typeof obj[key] === 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
          let subFlat = flattenKeys(obj[key], newKey);
          flat = { ...flat, ...subFlat };
      } else {
          flat[newKey] = obj[key];
      }
  }
  return flat;
}

export function deflattenKeys(flatObj: { [key: string]: any }): any {
  const nestedObj: any = {};

  for (let key in flatObj) {
      const value = flatObj[key];
      const keys = key.split('.');
      let current = nestedObj;

      // Iterate over each part of the key except for the last part
      for (let i = 0; i < keys.length - 1; i++) {
          const part = keys[i];
          if (!current[part] || typeof current[part] !== 'object') {
              // Initialize as an object if it doesn't exist or isn't an object
              current[part] = {};
          }
          current = current[part];
      }

      // Set the value at the last part of the key
      current[keys[keys.length - 1]] = value;
  }

  return nestedObj;
}

export function getNestedValueByPath<T>(obj: T, path: string): any {
  return path.split('.').reduce((acc: any, part: string) => acc && acc[part], obj);
}

export function deepEqual(object1: any, object2: any): boolean {
  if (object1 === undefined && object2 === undefined) {
    return true;
  }

  if (object1 === null && object2 === null) {
    return true;
  }

  if ((!object1 && object2) || (object1 && !object2)) {
    return false;
  }

  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);

  function isObject(object: any): boolean {
    return object != null && typeof object === 'object';
  }

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (areObjects && !deepEqual(val1, val2) || !areObjects && val1 !== val2) {
      return false;
    }
  }

  return true;
}

export function filterEmptyValues(obj: any): any {
  function isObject(object: any): boolean {
    return object != null && typeof object === 'object';
  }

  function isEmptyValue(value: any): boolean {
    return (
      value === undefined ||
      value === '' ||
      (Array.isArray(value) && value.length === 0) ||
      (isObject(value) && Object.keys(value).length === 0)
    );
  }

  if (!isObject(obj)) {
    return obj;
  }

  return Object.entries(obj)
    .filter(([_, value]) => !isEmptyValue(value))
    .reduce((acc, [key, value]) => ({
      ...acc,
      [key]: isObject(value) ? filterEmptyValues(value) : value
    }), {});
}

export function compareWithFilteringEmptyValues(object1: any, object2: any): boolean {
  const filteredObject1 = filterEmptyValues(object1);
  const filteredObject2 = filterEmptyValues(object2);

  return deepEqual(filteredObject1, filteredObject2);
}