import { useState } from 'react';
import { connect, Dispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Banner } from 'components';
import { NavigationService } from 'services';
import { UserModel } from 'models';
import { AppConfig } from 'config';
import { UserSelectors } from 'reducers/User/selectors';
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';


export interface AppBannerProps extends RouterProps {
  user: UserModel;
}


function AppBannerUnconnected({ user, navigate, location }: AppBannerProps) {
  const [showBanner, setShowBanner] = useState(true);
  const isWishlistRoute = location.pathname === NavigationService.getWaitlistPath();

  const onBannerClose = () => {
    setShowBanner(false);
  };

  const onJoinWaitlistBannerClick = () => {
    navigate(NavigationService.getWaitlistPath());
  };

  const onConfirmAccountBannerClick = () => {
    navigate(NavigationService.getUserConfirmationPath());
  }

  const renderBanner = () => {
    if (!showBanner) return null;

    if (!user || !user.isAuthenticated()) {
      return (
        <Banner
          header={`${AppConfig.APP_NAME} is currently in Beta Mode`}
          message={'Join our waitlist to gain early access'}
          buttonText={'Join Waitlist'}
          buttonClick={onJoinWaitlistBannerClick}
          buttonDisabled={isWishlistRoute}
          onClose={onBannerClose}
          dismissable={true} />
      );
    } else if (user && !user.isConfirmed()) {
      return (
        <Banner
          header={"Account not confirmed"}
          message={"Please confirm your account to gain full access"}
          buttonText={"Confirm Account"}
          buttonClick={onConfirmAccountBannerClick}
          status={'warning'}
          dismissable={false} />
      );
    }
  };

  return (
    <>
      {renderBanner()}
    </>
  );
};

const mapStateToProps = (state: ApplicationState) => ({
  user: UserSelectors.getUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch<ApplicationState>) => bindActionCreators(
  {},
  dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AppBannerUnconnected));
