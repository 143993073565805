import { ItemType } from 'models/Item/types';

interface TypeLegendProps {
  payload?: Array<{
    value: ItemType;
    color: string;
  }> | any[];
}

export default function TypeLegend(props: TypeLegendProps) {
  const { payload } = props;
  if (!payload) return null;

  return (
    <ul style={{ listStyle: 'none', margin: 0, padding: 0, fontSize: '12px', fontWeight: 'bold' }}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
          <div
            style={{
              width: '16px',
              height: '16px',
              marginRight: '10px',
              borderRadius: '50%',
              backgroundColor: entry.color,
            }}
          />
          <span>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};
