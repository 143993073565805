import { Loop, LoopReducer } from 'redux-loop';
import { ListingHandlers } from './handlers';
import { ListingState, ListingAction, ListingActionTypes } from './types';
import { defaultState } from './consts';

export const listingReducer: LoopReducer<ListingState, ListingAction> = (
  state: ListingState = defaultState,
  action: ListingAction
): ListingState | Loop<ListingState, ListingAction> => {
  switch (action.type) {
    case ListingActionTypes.SEARCH_LISTING_BY_QUERY:
      return ListingHandlers.handleSearchListingByQuery(state, action);
    case ListingActionTypes.SEARCH_LISTING_BY_QUERY_SUCCESS:
      return ListingHandlers.handleSearchListingByQuerySuccess(state, action);
    case ListingActionTypes.SEARCH_LISTING_BY_QUERY_FAILURE:
      return ListingHandlers.handleSearchListingByQueryFailure(state);
    case ListingActionTypes.SEARCH_LISTINGS:
      return ListingHandlers.handleSearchListings(state, action);
    case ListingActionTypes.SEARCH_LISTINGS_SUCCESS:
      return ListingHandlers.handleSearchListingsSuccess(state, action);
    case ListingActionTypes.SEARCH_LISTINGS_FAILURE:
      return ListingHandlers.handleSearchListingsFailure(state);
    case ListingActionTypes.GET_USER_FOLLOWING_ARTISTS_LISTINGS:
      return ListingHandlers.handleGetUserFollowingArtistsListings(state, action);
    case ListingActionTypes.GET_USER_FOLLOWING_ARTISTS_LISTINGS_SUCCESS:
      return ListingHandlers.handleGetUserFollowingArtistsListingsSuccess(state, action);
    case ListingActionTypes.GET_USER_FOLLOWING_ARTISTS_LISTINGS_FAILURE:
      return ListingHandlers.handleGetUserFollowingArtistsListingsFailure(state);
    default:
      return state;
  }
}
