import { useState, useEffect } from 'react';
import { AppConfig } from 'config';
import {
  InputGroup
} from '@chakra-ui/react';
import { formatNumberWithCountryCode } from 'utils/format';
import { isValidPhone } from 'utils/phone';
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export interface InputPhoneData {
  countryCode: string;
  dialCode: string;
  format: string;
  name: string;
}

interface PhoneInputProps {
  countryCode?: string;
  defaultValue?: string | number;
  onChange?: (phone: number | string, countryCode: string) => void;
}

export function PhoneInput({ countryCode, onChange = () => {}, defaultValue = '' }: PhoneInputProps) {
  const getFormattedPhoneFromValue = (phoneVal: string | number): string => {
    if (phoneVal) {
      return formatNumberWithCountryCode(String(phoneVal))
    }
    return '';
  } 

  const [phone, setPhone] = useState(getFormattedPhoneFromValue(defaultValue));
  const [isValid, setIsValid] = useState(true);

  useEffect(() => {
    setPhone(getFormattedPhoneFromValue(defaultValue));
  }, [defaultValue]);

  const onPhoneValueChange = (phone: number | string, data: InputPhoneData) => {
    setPhone(getFormattedPhoneFromValue(phone));
    onChange(phone, data.countryCode);
    if (isValidPhone(phone, data.countryCode)) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  };

  return (
    <InputGroup minHeight="40px">
      <ReactPhoneInput
        inputStyle={{'height': '100%', 'width': '100%'}}
        dropdownStyle={{'textAlign': 'left'}}
        enableSearch={true}
        country={countryCode || AppConfig.DEFAULT_COUNTRY_CODE}
        value={phone}
        onChange={onPhoneValueChange}
        isValid={isValid}
      />
    </InputGroup>
  );
}
