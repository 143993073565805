import { UserModel } from 'models';
import { UserState } from './types';

export const defaultState: UserState = {
  user: new UserModel(),
  error: undefined,
  loading: {
    user: false,
    portfolio: false,
    wishlist: false,
    followingArtists: false,
    wishlistAction: false,
    artistAction: false,
    imageAction: false,
    passwordAction: false,
    confirmationAction: false,
    mailAction: false,
    waitlistAction: false,
    updateAction: false
  }
};
