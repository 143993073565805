import { toDollarFormat } from 'utils/format';

interface PriceYTickProps {
    x: number;
    y: number;
    payload: {
        value: number;
    };
}

export default function PriceYTick({ x, y, payload }: PriceYTickProps) {
    return (
        <text x={x - 5} y={y + 5} fill="#666" fontSize="12px" fontWeight="bold" textAnchor="end">
            {toDollarFormat(payload.value, false, true)}
        </text>
    );
}
