interface DayXTickProps {
    x: number;
    y: number;
    payload: {
        value: string | number;
    };
}

export default function DayXTick({ x, y, payload }: DayXTickProps) {
    return (
        <text x={x} y={y + 15} fill="#666" fontSize="12px" fontWeight="bold" textAnchor="middle">
            {payload.value}
        </text>
    );
}
