import { useState } from "react";
import { ActualFileObject, FilePondFile } from 'filepond';
import { FilePond, registerPlugin } from "react-filepond";
import { Box } from "@chakra-ui/react";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileEncode from "filepond-plugin-file-encode";
import 'filepond/dist/filepond.min.css';
import './FilepondUploader.css';
import { UrlConfig } from 'config';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode
);

export interface FileUploaderProps {
  maxFiles?: number;
  label?: string;
  showBrowseInLabel?: boolean;
  onFileProcess?: (url: string) => void;
  onFileRevert?: (url: string) => void;
}

const DEFAULT_LABEL = 'Drag & Drop your files';

export default function FilePondUploader({ maxFiles = 3, label = DEFAULT_LABEL, showBrowseInLabel = true, onFileProcess, onFileRevert }: FileUploaderProps) {
  const [files, setFiles] = useState<(File | ActualFileObject)[]>([]);
  const [uploadedUrls, setUploadedUrls] = useState<string[]>([]);
  const url = UrlConfig.UPLOAD.FILEPOD_IMAGE;
  const labelIdle = showBrowseInLabel
    ? label + ' or <span class="filepond--label-action">Browse</span>'
    : label;

  return (
    <Box>
      <FilePond
        files={files}
        allowMultiple={maxFiles > 1}
        onupdatefiles={(fileItems: FilePondFile[]) => {
          setFiles(fileItems.map(fileItem => fileItem.file))
        }}
        maxFiles={maxFiles}
        server={{
          url: url,
          process: {
            url: '',
            onload: (response) => {
              const parsedResponse = JSON.parse(response);
              const uploadedUrl = parsedResponse.data;
              setUploadedUrls([uploadedUrl, ...uploadedUrls]);
              onFileProcess && onFileProcess(uploadedUrl);
              return response;
            }
          },
          revert: {
            url: '/delete',
            method: 'POST',
            onload: (response) => {
              const parsedResponse = JSON.parse(response);
              const removedUrl = parsedResponse.data;
              setUploadedUrls(uploadedUrls.filter(url => url !== removedUrl));
              onFileRevert && onFileRevert(removedUrl);
              return response;
            }
          }
        }}
        name="file"
        labelIdle={labelIdle}
      />
    </Box>
  );
}
