import { UserModel } from 'models';
import { AuthData } from 'models/Auth/types';

export enum AuthActionTypes {
  REGISTER_USER = 'REGISTER_USER',
  REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS',
  REGISTER_USER_FAILURE = 'REGISTER_USER_FAILURE',
  LOGIN_BY_EMAIL = 'LOGIN_BY_EMAIL',
  LOGIN_BY_EMAIL_SUCCESS = 'LOGIN_BY_EMAIL_SUCCESS',
  LOGIN_BY_EMAIL_FAILURE = 'LOGIN_BY_EMAIL_FAILURE',
  GET_AUTHENTICATED_USER = 'GET_AUTHENTICATED_USER',
  GET_AUTHENTICATED_USER_SUCCESS = 'GET_AUTHENTICATED_USER_SUCCESS',
  GET_AUTHENTICATED_USER_FAILURE = 'GET_AUTHENTICATED_USER_FAILURE',
  REFRESH_AUTH_TOKEN = 'REFRESH_AUTH_TOKEN',
  REFRESH_AUTH_TOKEN_SUCCESS = 'REFRESH_AUTH_TOKEN_SUCCESS',
  REFRESH_AUTH_TOKEN_FAILURE = 'REFRESH_AUTH_TOKEN_FAILURE',
  LOGOUT = 'LOGOUT',
  LOGOUT_SUCCESS = 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE = 'LOGOUT_FAILURE'
};

export interface AuthActionPayload {
  user?: UserModel;
  authData?: AuthData;
  email?: string;
  password?: string;
  username?: string;
};

export interface AuthAction {
  type: AuthActionTypes;
  payload?: AuthActionPayload;
};

export interface AuthStateLoading {
  user: boolean;
  logout: boolean;
  login: boolean;
  refreshAuth: boolean;
  register: boolean;
};

export interface AuthState {
  authUser: UserModel;
  error: Error | undefined;
  loading: AuthStateLoading;
};
