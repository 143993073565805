import { ItemModel, ListingModel, PaginationModel, ArtistModel, ArtistIndexModel } from 'models';

export enum ArtistActionTypes {
  GET_ARTIST_BY_ID = 'GET_ARTIST_BY_ID',
  GET_ARTIST_BY_ID_SUCCESS = 'GET_ARTIST_BY_ID_SUCCESS',
  GET_ARTIST_BY_ID_FAILURE = 'GET_ARTIST_BY_ID_FAILURE',
  GET_ALL_ARTISTS = 'GET_ALL_ARTISTS',
  GET_ALL_ARTISTS_SUCCESS = 'GET_ALL_ARTISTS_SUCCESS',
  GET_ALL_ARTISTS_FAILURE = 'GET_ALL_ARTISTS_FAILURE',
  GET_ARTIST_ITEMS = 'GET_ARTIST_ITEMS',
  GET_ARTIST_ITEMS_SUCCESS = 'GET_ARTIST_ITEMS_SUCCESS',
  GET_ARTIST_ITEMS_FAILURE = 'GET_ARTIST_ITEMS_FAILURE',
  GET_ARTIST_LISTINGS = 'GET_ARTIST_LISTINGS',
  GET_ARTIST_LISTINGS_SUCCESS = 'GET_ARTIST_LISTINGS_SUCCESS',
  GET_ARTIST_LISTINGS_FAILURE = 'GET_ARTIST_LISTINGS_FAILURE',
  GET_ARTIST_EDITIONS_INDEX = 'GET_ARTIST_EDITIONS_INDEX',
  GET_ARTIST_EDITIONS_INDEX_SUCCESS = 'GET_ARTIST_EDITIONS_INDEX_SUCCESS',
  GET_ARTIST_EDITIONS_INDEX_FAILURE = 'GET_ARTIST_EDITIONS_INDEX_FAILURE',
};

export interface ArtistActionPayload {
  id?: string;
  page?: number;
  artist?: ArtistModel;
  artistList?: ArtistModel[];
  itemsList?: ItemModel[];
  paginatedListings?: PaginationModel<ListingModel>;
  artistIndexData?: ArtistIndexModel;
};

export interface ArtistAction {
  type: ArtistActionTypes;
  payload?: ArtistActionPayload;
};

export interface ArtistStateLoading {
  item: boolean;
  list: boolean;
  itemList: boolean;
  listingList: boolean;
  editionIndex: boolean;
};

export interface ArtistState {
  artist: ArtistModel;
  artistList: ArtistModel[];
  error: Error | undefined;
  loading: ArtistStateLoading;
};
