import { ListingModel, PaginationModel } from 'models';
import { ListingActionTypes, ListingAction } from './types';
import { JSONObject } from 'models/Api/types';

export class ListingActions {
  public static searchListingsByQuery(query: string, page?: number): ListingAction {
    return {
      type: ListingActionTypes.SEARCH_LISTING_BY_QUERY,
      payload: { query, page }
    }
  }

  public static getUserFollowingArtistsListings(page?: number): ListingAction {
    return {
      type: ListingActionTypes.GET_USER_FOLLOWING_ARTISTS_LISTINGS,
      payload: { page }
    }
  }

  public static searchListings(searchQuery: JSONObject): ListingAction {
    return {
      type: ListingActionTypes.SEARCH_LISTINGS,
      payload: { searchQuery }
    }
  }
}

export class ListingInternalActions {
  public static searchListingByQuerySuccess(paginatedListings: PaginationModel<ListingModel>) {
    return {
      type: ListingActionTypes.SEARCH_LISTING_BY_QUERY_SUCCESS,
      payload: { paginatedListings },
    };
  }

  public static searchListingByQueryFailure() {
    return {
      type: ListingActionTypes.SEARCH_LISTING_BY_QUERY_FAILURE
    };
  }

  public static getUserFollowingArtistsListingsSuccess(paginatedListings: PaginationModel<ListingModel>) {
    return {
      type: ListingActionTypes.GET_USER_FOLLOWING_ARTISTS_LISTINGS_SUCCESS,
      payload: { paginatedListings },
    };
  }

  public static getUserFollowingArtistsListingsFailure() {
    return {
      type: ListingActionTypes.GET_USER_FOLLOWING_ARTISTS_LISTINGS_FAILURE
    };
  }

  public static searchListingsSuccess(paginatedListings: PaginationModel<ListingModel>) {
    return {
      type: ListingActionTypes.SEARCH_LISTINGS_SUCCESS,
      payload: { paginatedListings },
    };
  }

  public static searchListingsFailure() {
    return {
      type: ListingActionTypes.SEARCH_LISTINGS_FAILURE
    };
  }
}
