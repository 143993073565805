import { Loop, LoopReducer } from 'redux-loop';
import { SalesVenueHandlers } from './handlers';
import { SalesVenueState, SalesVenueAction, SalesVenueActionTypes } from './types';
import { defaultState } from './consts';

export const salesVenueReducer: LoopReducer<SalesVenueState, SalesVenueAction> = (
  state: SalesVenueState = defaultState,
  action: SalesVenueAction
): SalesVenueState | Loop<SalesVenueState, SalesVenueAction> => {
  switch (action.type) {
    case SalesVenueActionTypes.GET_SALES_VENUE_BY_ID:
      return SalesVenueHandlers.handleGetSalesVenueById(state, action);
    case SalesVenueActionTypes.GET_SALES_VENUE_BY_ID_SUCCESS:
      return SalesVenueHandlers.handleGetSalesVenueByIdSuccess(state, action);
    case SalesVenueActionTypes.GET_SALES_VENUE_BY_ID_FAILURE:
      return SalesVenueHandlers.handleGetSalesVenueByIdFailure(state);
    case SalesVenueActionTypes.GET_ALL_SALES_VENUES:
      return SalesVenueHandlers.handleGetAllSalesVenues(state);
    case SalesVenueActionTypes.GET_ALL_SALES_VENUES_SUCCESS:
      return SalesVenueHandlers.handleGetAllSalesVenuesSuccess(state, action);
    case SalesVenueActionTypes.GET_ALL_SALES_VENUES_FAILURE:
      return SalesVenueHandlers.handleGetAllSalesVenuesFailure(state);
    default:
      return state;
  }
}
