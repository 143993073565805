import { UserModel, PortfolioModel, WishlistModel, ArtistModel } from 'models';
import { JSONObject } from 'models/Api/types';
import { UserActionTypes, UserAction } from './types';

export class UserActions {
  public static setUser(user: UserModel): UserAction {
    return {
      type: UserActionTypes.SET_USER,
      payload: { user }
    };
  }

  public static clearUser(): UserAction {
    return {
      type: UserActionTypes.CLEAR_USER
    };
  }

  public static getUserPortfolio(id: string): UserAction {
    return {
      type: UserActionTypes.GET_USER_PORTFOLIO,
      payload: { id }
    };
  }

  public static getUserWishlist(id: string): UserAction {
    return {
      type: UserActionTypes.GET_USER_WISHLIST,
      payload: { id }
    };
  }

  public static getUserFollowingArtists(): UserAction {
    return {
      type: UserActionTypes.GET_USER_FOLLOWING_ARTISTS
    }
  }

  public static followArtist(artistId: string): UserAction {
    return {
      type: UserActionTypes.FOLLOW_ARTIST,
      payload: { artistId }
    };
  }

  public static unfollowArtist(artistId: string): UserAction {
    return {
      type: UserActionTypes.UNFOLLOW_ARTIST,
      payload: { artistId }
    };
  }

  public static migrateImage(imageUrl?: string): UserAction {
    return {
      type: UserActionTypes.MIGRATE_USER_IMAGE,
      payload: { imageUrl }
    }
  }

  public static changePassword(oldPassword: string, newPassword: string): UserAction {
    return {
      type: UserActionTypes.CHANGE_USER_PASSWORD,
      payload: { oldPassword, newPassword }
    }
  }

  public static sendConfirmationEmail(): UserAction {
    return {
      type: UserActionTypes.SEND_CONFIRMATION_EMAIL,
    }
  }

  public static sendForgotPasswordEmail(email: string): UserAction {
    return {
      type: UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL,
      payload: { email }
    }
  }

  public static confirmEmail(token: string): UserAction {
    return {
      type: UserActionTypes.CONFIRM_EMAIL,
      payload: { token }
    }
  }

  public static changeForgottenPassword(token: string, newPassword: string): UserAction {
    return {
      type: UserActionTypes.CHANGE_FORGOTTEN_PASSWORD,
      payload: { token, newPassword }
    }
  }

  public static joinUserWaitlist(email: string): UserAction {
    return {
      type: UserActionTypes.JOIN_USER_WAITLIST,
      payload: { email }
    }
  }

  public static sendInboundContactRequest(email: string, name: string, message: string): UserAction {
    return {
      type: UserActionTypes.SEND_INBOUND_CONTACT_REQUEST,
      payload: { email, name, message }
    }
  }

  public static updateUserData(updatedData: JSONObject) {
    return {
      type: UserActionTypes.UPDATE_USER_DATA,
      payload: { updatedData }
    }
  }
}

export class UserInternalActions {
  public static getUserPortfolioSuccess(portfolio: PortfolioModel): UserAction {
    return {
      type: UserActionTypes.GET_USER_PORTFOLIO_SUCCESS,
      payload: { portfolio }
    };
  }

  public static getUserPortfolioFailure(): UserAction {
    return {
      type: UserActionTypes.GET_USER_PORTFOLIO_FAILURE
    };
  }

  public static getUserWishlistSuccess(wishlist: WishlistModel): UserAction {
    return {
      type: UserActionTypes.GET_USER_WISHLIST_SUCCESS,
      payload: { wishlist }
    };
  }

  public static getUserWishlistFailure(): UserAction {
    return {
      type: UserActionTypes.GET_USER_WISHLIST_FAILURE
    };
  }

  public static getUserFollowingArtistsSuccess(artistList: ArtistModel[]): UserAction {
    return {
      type: UserActionTypes.GET_USER_FOLLOWING_ARTISTS_SUCCESS,
      payload: { artistList }
    };
  }

  public static getUserFollowingArtistsFailure(): UserAction {
    return {
      type: UserActionTypes.GET_USER_FOLLOWING_ARTISTS_FAILURE
    };
  }

  public static followArtistSuccess(): UserAction {
    return {
      type: UserActionTypes.FOLLOW_ARTIST_SUCCESS
    };
  }

  public static followArtistFailure(): UserAction {
    return {
      type: UserActionTypes.FOLLOW_ARTIST_FAILURE
    };
  }

  public static unfollowArtistSuccess(): UserAction {
    return {
      type: UserActionTypes.UNFOLLOW_ARTIST_SUCCESS
    };
  }

  public static unfollowArtistFailure(): UserAction {
    return {
      type: UserActionTypes.UNFOLLOW_ARTIST_FAILURE
    };
  }

  public static migrateImageSuccess(newImageUrl: string): UserAction {
    return {
      type: UserActionTypes.MIGRATE_USER_IMAGE_SUCCESS,
      payload: { imageUrl: newImageUrl }
    }
  }

  public static migrateImageFailure(): UserAction {
    return {
      type: UserActionTypes.MIGRATE_USER_IMAGE_FAILURE,
    }
  }

  public static changePasswordSuccess(): UserAction {
    return {
      type: UserActionTypes.CHANGE_USER_PASSWORD_SUCCESS
    };
  }

  public static changePasswordFailure(): UserAction {
    return {
      type: UserActionTypes.CHANGE_USER_PASSWORD_FAILURE
    };
  }

  public static sendConfirmationEmailSuccess(): UserAction {
    return {
      type: UserActionTypes.SEND_CONFIRMATION_EMAIL_SUCCESS
    };
  }

  public static sendConfirmationEmailFailure(): UserAction {
    return {
      type: UserActionTypes.SEND_CONFIRMATION_EMAIL_FAILURE
    };
  }

  public static sendForgotPasswordEmailSuccess(): UserAction {
    return {
      type: UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_SUCCESS
    };
  }

  public static sendForgotPasswordEmailFailure(): UserAction {
    return {
      type: UserActionTypes.SEND_FORGOT_PASSWORD_EMAIL_FAILURE
    };
  }

  public static confirmEmailSuccess(user: UserModel): UserAction {
    return {
      type: UserActionTypes.CONFIRM_EMAIL_SUCCESS,
      payload: { user }
    };
  }

  public static confirmEmailFailure(): UserAction {
    return {
      type: UserActionTypes.CONFIRM_EMAIL_FAILURE
    };
  }

  public static changeForgottenPasswordSuccess(): UserAction {
    return {
      type: UserActionTypes.CHANGE_FORGOTTEN_PASSWORD_SUCCESS
    };
  }

  public static changeForgottenPasswordFailure(): UserAction {
    return {
      type: UserActionTypes.CHANGE_FORGOTTEN_PASSWORD_FAILURE
    };
  }

  public static joinUserWaitlistSuccess(): UserAction {
    return {
      type: UserActionTypes.JOIN_USER_WAITLIST_SUCCESS
    };
  }

  public static joinUserWaitlistFailure(): UserAction {
    return {
      type: UserActionTypes.JOIN_USER_WAITLIST_FAILURE
    };
  }

  public static sendInboundContactRequestSuccess(): UserAction {
    return {
      type: UserActionTypes.SEND_INBOUND_CONTACT_REQUEST_SUCCESS
    };
  }

  public static sendInboundContactRequestFailure(): UserAction {
    return {
      type: UserActionTypes.SEND_INBOUND_CONTACT_REQUEST_FAILURE
    };
  }

  public static updateUserDataSuccess(user: UserModel): UserAction {
    return {
      type: UserActionTypes.UPDATE_USER_DATA_SUCCESS,
      payload: { user }
    };
  }

  public static updateUserDataFailure(): UserAction {
    return {
      type: UserActionTypes.UPDATE_USER_DATA_FAILURE
    };
  }
}
