import { Cmd, loop, Loop } from 'redux-loop';
import { ItemListingGroupingInternalActions } from './actions';
import { ItemListingGroupingState, ItemListingGroupingAction } from './types';
import { ItemListingGroupingApiClient } from 'api';

export enum ItemListingGroupingStateErrors {
  CREATE = 'Unable to create item listing grouping',
}

export class ItemListingGroupingHandlers {
  public static handleCreateItemListingGrouping(state: ItemListingGroupingState, action: ItemListingGroupingAction): Loop<ItemListingGroupingState, ItemListingGroupingAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        create: true
      }
    };

    return loop(
      newState,
      Cmd.run(ItemListingGroupingApiClient.createItemListingGrouping, {
        args: [payload?.itemId || '', payload?.listingIds || []],
        successActionCreator: ItemListingGroupingInternalActions.createItemListingGroupingSuccess,
        failActionCreator: ItemListingGroupingInternalActions.createItemListingGroupingFailure,
      })
    );
  }

  public static handleCreateItemListingGroupingSuccess(state: ItemListingGroupingState): ItemListingGroupingState {
    return {
      ...state,
      loading: {
        ...state.loading,
        create: false
      }
    };
  }

  public static handleCreateItemListingGroupingFailure(state: ItemListingGroupingState): ItemListingGroupingState {
    return {
      ...state,
      error: new Error(ItemListingGroupingStateErrors.CREATE),
      loading: {
        ...state.loading,
        create: false
      }
    };
  }
}
