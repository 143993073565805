import { Cmd, loop, Loop } from 'redux-loop';
import { MaterialInternalActions } from './actions';
import { MaterialApiClient } from 'api';
import { MaterialModel } from 'models';
import { MaterialState, MaterialAction } from './types';
import { defaultState } from './consts';

export enum MaterialStateErrors {
  ID_FETCH = 'Unable to fetch material',
  ALL_FETCH = 'Unable to fetch all materials',
}

export class MaterialHandlers {
  public static handleGetMaterialById(state: MaterialState, action: MaterialAction): Loop<MaterialState, MaterialAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        item: true
      }
    };

    return loop(
      newState,
      Cmd.run(MaterialApiClient.getMaterialById, {
        args: [payload?.id || ''],
        successActionCreator: MaterialInternalActions.getMaterialByIdSuccess,
        failActionCreator: MaterialInternalActions.getMaterialByIdFailure,
      })
    );
  }

  public static handleGetMaterialByIdSuccess(state: MaterialState, action: MaterialAction): MaterialState | Loop<MaterialState, MaterialAction> {
    const material: MaterialModel = action.payload?.material || defaultState.material;

    return {
      ...state,
      material,
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetMaterialByIdFailure(state: MaterialState): MaterialState {
    return {
      ...state,
      error: new Error(MaterialStateErrors.ID_FETCH),
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetAllMaterials(state: MaterialState): Loop<MaterialState, MaterialAction> {
    const newState: MaterialState = {
      ...state,
      loading: {
        ...state.loading,
        list: true
      }
    };

    return loop(
      newState,
      Cmd.run(MaterialApiClient.getAllMaterials, {
        args: [],
        successActionCreator: MaterialInternalActions.getAllMaterialsSuccess,
        failActionCreator: MaterialInternalActions.getAllMaterialsFailure,
      })
    );
  }

  public static handleGetAllMaterialsSuccess(state: MaterialState, action: MaterialAction): MaterialState {
    const materialList = action.payload?.materialList || defaultState.materialList;
    return {
      ...state,
      materialList,
      loading: {
        ...state.loading,
        list: false
      }
    };
  }

  public static handleGetAllMaterialsFailure(state: MaterialState): MaterialState {
    return {
      ...state,
      error: new Error(MaterialStateErrors.ALL_FETCH),
      loading: {
        ...state.loading,
        list: false
      }
    };
  }
}
