import { useState } from 'react';
import moment from 'moment';
import { ComposedChart, AreaChart, Area, XAxis, YAxis, ResponsiveContainer, Tooltip, Legend, Line } from 'recharts';
import { PortfolioModel } from 'models';
import { Flex, Text, Box } from '@chakra-ui/react';
import Card from '../Card/Card';
import CardHeader from '../Card/CardHeader';
import { ActiveDot, DayDot } from './Dots';
import { DayXTick, MonthXTick, YearXTick, PriceYTick } from './Ticks';
import { PortfolioValueChartTooltip } from './Tooltips';

interface PortfolioValueChartProps {
	portfolio: PortfolioModel;
}

function PortfolioValueChart({ portfolio }: PortfolioValueChartProps) {
	const [selectedYear, setSelectedYear] = useState<number | null>(null);
	const [selectedMonth, setSelectedMonth] = useState<number | null>(null);

	const yearlyData = portfolio.getYearlyData();
	const monthlyData = selectedYear ? portfolio.getMonthlyDataForYear(selectedYear) : [];
	const dailyData = selectedYear && selectedMonth ? portfolio.getDailyDataForMonth(selectedYear, selectedMonth) : [];

	const handleDotClick = (payload: any) => {
		if (payload?.key) {
			if (selectedYear && !selectedMonth) {
				setSelectedMonth(Number(payload.key));
			} else if (!selectedYear) {
				setSelectedYear(Number(payload.key));
			}
		}
	};

	function renderTitle() {
		if (selectedYear && selectedMonth) {
			return (
				<Flex direction={'column'}>
					<Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
						Portfolio Value History
					</Text>
					<Text textAlign="left" fontSize='sm' fontWeight='bold' mb='6px'>
						{moment(selectedMonth, 'M').format('MMMM')}, {selectedYear}
					</Text>
				</Flex>
			);
		} else if (selectedYear) {
			return (
				<Flex direction={'column'}>
					<Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
						Portfolio Value History
					</Text>
					<Text textAlign="left" fontSize='sm' fontWeight='bold' mb='6px'>
						{selectedYear}
					</Text>
				</Flex>
			);
		} else {
			return (
				<Text textAlign="left" fontSize='md' fontWeight='bold' mb='6px'>
					Portfolio Value History
				</Text>
			);
		}
	}

	function renderChartBackButton() {
		if (selectedMonth) {
			return (
				<Text textAlign={'right'} fontSize={10} cursor={'pointer'} color={'blue.500'} fontWeight={'bold'} onClick={() => setSelectedMonth(null)} colorScheme="blue">
					Back to Monthly View
				</Text>
			);
		} else if (selectedYear) {
			return (
				<Text textAlign={'right'} fontSize={10} cursor={'pointer'} color={'blue.500'} fontWeight={'bold'} onClick={() => setSelectedYear(null)} colorScheme="blue">
					Back to Yearly View
				</Text>
			);
		}
	}

	function renderChartData() {
		if (selectedYear && selectedMonth) {
			return (
				<ComposedChart data={dailyData} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
					<XAxis dataKey="key" tick={DayXTick} />
					<YAxis tick={PriceYTick} />
					<Tooltip content={(props) => <PortfolioValueChartTooltip selectedYear={selectedYear} selectedMonth={selectedMonth} portfolio={portfolio} {...props} />} />
					<Area type="monotone" name="Value" dataKey="value" fill="#82ca9d" stroke="#82ca9d" />
					<Area type="monotone" name="Cost" dataKey="cost" fill="#8884d8" stroke="#8884d8" activeDot={<ActiveDot onClickHandler={handleDotClick} />} />
					<Legend iconType='circle' iconSize={14} wrapperStyle={{ fontSize: "12px", fontWeight: "bold" }} />
				</ComposedChart>
			);
		} else if (selectedYear) {
			return (
				<AreaChart data={monthlyData} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
					<XAxis dataKey="key" tick={MonthXTick} />
					<YAxis tick={PriceYTick} />
					<Tooltip content={(props) => <PortfolioValueChartTooltip selectedYear={selectedYear} selectedMonth={selectedMonth} portfolio={portfolio} {...props} />} />
					<Area type="monotone" name="Value" dataKey="value" fill="#82ca9d" stroke="#82ca9d" activeDot={<ActiveDot onClickHandler={handleDotClick} />} />
					<Area type="monotone" name="Cost" dataKey="cost" fill="#8884d8" stroke="#8884d8" activeDot={<ActiveDot onClickHandler={handleDotClick} />} />
					<Legend iconType='circle' iconSize={14} wrapperStyle={{ fontSize: "12px", fontWeight: "bold" }} />
				</AreaChart>
			);
		} else {
			return (
				<ComposedChart data={yearlyData} margin={{ top: 10, right: 10, left: 10, bottom: 10 }}>
					<XAxis dataKey="key" interval={0} tick={YearXTick} />
					<YAxis tick={PriceYTick} />
					<Tooltip content={(props) => <PortfolioValueChartTooltip selectedYear={selectedYear} selectedMonth={selectedMonth} portfolio={portfolio} {...props} />} />
					<Area type="monotone" name="Value" dataKey="value" fill="#82ca9d" stroke="#82ca9d" activeDot={<ActiveDot onClickHandler={handleDotClick} />} />
					<Area type="monotone" name="Cost" dataKey="cost" fill="#8884d8" stroke="#8884d8" activeDot={<ActiveDot onClickHandler={handleDotClick} />} />
					<Legend iconType='circle' iconSize={14} wrapperStyle={{ fontSize: "12px", fontWeight: "bold" }} />
				</ComposedChart>
			);
		}
	}

	return (
		<Card p='28px 0px 0px 0px' borderRadius='8px'>
			<CardHeader mb='20px'>
				<Flex direction='column' alignSelf='flex-start'>
					{renderTitle()}
				</Flex>
			</CardHeader>
			<Box w='100%' height="300px" justifyContent="center" alignItems="center">
				<ResponsiveContainer>
					{renderChartData()}
				</ResponsiveContainer>
				{renderChartBackButton()}
			</Box>
		</Card>
	);
}

export default PortfolioValueChart;
