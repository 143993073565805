import { Action } from 'redux';

function doDelay(isTimeout: boolean, ms: number, fn: () => void) {
  const method = isTimeout ? setTimeout : setInterval;
  return method(fn, ms);
}

export function delayedDispatch(
  isTimeout: boolean,
  ms: number,
  dispatch: (action: Action) => any,
  actionCreator: (id: string) => Action,
  id: string
) {
  const intervalId = doDelay(isTimeout, ms, () => {
    dispatch(actionCreator(id));
  });
  return intervalId;
}
