import { connect } from "react-redux";
import { Navigate } from 'react-router-dom';
import { UserSelectors } from 'reducers/User/selectors';
import { ApplicationState } from 'reducers/types';
import { NavigationService, CredentialService } from 'services';
import { UserModel } from 'models';

interface ProtectedRouteProps {
	user: UserModel;
	children: JSX.Element;
}

const ProtectedRoute = ({ user, children }: ProtectedRouteProps) => {
	if (!user.isAuthenticated() && !CredentialService.isAuthenticated()) {
		return <Navigate to={NavigationService.getAuthLoginPath()} replace />;
	}

	return children;
};

const mapStateToProps = (state: ApplicationState) => ({
	user: UserSelectors.getUser(state),
});

export default connect(mapStateToProps)(ProtectedRoute);
