import { UrlConfig } from 'config';
import { SalesVenueModel } from 'models';
import { RawSalesVenueData } from 'models/SalesVenue/types';
import { ApiResponse } from 'models/Api/ApiResponse';
import { AjaxService } from 'services';

export class SalesVenueApiClient {
  public static async getSalesVenueById(id: string): Promise<SalesVenueModel> {
    const url = UrlConfig.SALES_VENUE.SALES_VENUE_BY_ID + `${id}`;
    const response: ApiResponse<RawSalesVenueData> = await AjaxService.getData(url);
    const salesVenue = new SalesVenueModel(response.data);
    return salesVenue;
  }

  public static async getAllSalesVenues(): Promise<SalesVenueModel[]> {
    const url = UrlConfig.SALES_VENUE.SALES_VENUE_ALL;
    const response: ApiResponse<RawSalesVenueData[]> = await AjaxService.getData(url);
    const salesVenueList = response.data.map((rawSalesVenue: RawSalesVenueData) => new SalesVenueModel(rawSalesVenue));
    return salesVenueList;
  }
}
