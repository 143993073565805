import { createStore, compose, StoreEnhancer} from 'redux';
import { rootReducer, defaultState } from '../reducers';
import { ApplicationState } from '../reducers/types';
import { install, StoreCreator } from 'redux-loop';

const enhancedCreateStore = createStore as StoreCreator;

const enhancer: StoreEnhancer<ApplicationState> = (window as any)
  .__REDUX_DEVTOOLS_EXTENSION__
  ? compose(
      install(),
      (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    )
  : install()


const store = enhancedCreateStore(rootReducer, defaultState, enhancer);

export default store;
