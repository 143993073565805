import { MaterialModel } from 'models';
import { MaterialActionTypes, MaterialAction } from './types';

export class MaterialActions {
  public static getMaterialById(id: string): MaterialAction {
    return {
      type: MaterialActionTypes.GET_MATERIAL_BY_ID,
      payload: { id },
    };
  }

  public static getAllMaterials(): MaterialAction {
    return {
      type: MaterialActionTypes.GET_ALL_MATERIALS
    }
  }
}

export class MaterialInternalActions {
  public static getMaterialByIdSuccess(material: MaterialModel): MaterialAction {
    return {
      type: MaterialActionTypes.GET_MATERIAL_BY_ID_SUCCESS,
      payload: { material },
    };
  }

  public static getMaterialByIdFailure(): MaterialAction {
    return {
      type: MaterialActionTypes.GET_MATERIAL_BY_ID_FAILURE,
    };
  }

  public static getAllMaterialsSuccess(materialList: MaterialModel[]): MaterialAction {
    return {
      type: MaterialActionTypes.GET_ALL_MATERIALS_SUCCESS,
      payload: { materialList }
    }
  }

  public static getAllMaterialsFailure(): MaterialAction {
    return {
      type: MaterialActionTypes.GET_ALL_MATERIALS_FAILURE
    }
  }
}
