import moment from 'moment';
import { ListingModel } from 'models';
import { MetricModel } from 'models/Market/Metric';
import { YearlySummary, YearlySummaryItem } from 'models/Market/types';
import { calculateMetricsForListings, getListingsByDateCombo } from './listing';

const defaultMetricModel: MetricModel = {
    key: -1,
    mean: 0,
    open: 0,
    close: 0,
    max: 0,
    min: 0,
    growth: 0,
    totalListings: 0,
    totalSales: 0,
    variance: 0
};

export function getLastKnownValueForYear(year: number, yearlyData: YearlySummaryItem[]): number | null {
    for (let i = year - 1; i >= 0; i--) {
        const yearData = yearlyData.find(y => y.overview.key === i);
        if (yearData) {
            const monthlyValues = Object.values(yearData.monthlySummary);
            const lastNonZeroMonth = monthlyValues.reverse().find(month => month.mean !== 0);
            if (lastNonZeroMonth) {
                return lastNonZeroMonth.mean;
            }
        }
    }
    return null;
}

export function getLastKnownValueForMonth(year: number, month: number, yearlyData: YearlySummaryItem[]): number | null {
    let currentYear = year;
    while (currentYear >= 0) {
        const yearData = yearlyData.find(y => y.overview.key === currentYear);

        const startMonth = (currentYear === year) ? month - 1 : 11;

        if (yearData) {
            for (let m = startMonth; m >= 0; m--) {
                const monthlyValues = Object.values(yearData.monthlySummary);
                const monthData = monthlyValues.find(month => month.key === m);
                if (monthData && monthData.mean !== 0) {
                    return monthData.mean;
                }
            }
        }

        currentYear--;
    }
    return null;
}

export function getFullYearlyData( data: YearlySummary): YearlySummaryItem[] {
    const yearlyData: YearlySummaryItem[] = Object.values(data);
    const fullYearlyData: YearlySummaryItem[] = [];
    const startYear = Number(yearlyData[0]?.overview.key) || new Date().getFullYear();
    const endYear = new Date().getFullYear();
    let currentKnownValue: number | null = null;

    for (let i = startYear; i <= endYear; i++) {
        const yearData = yearlyData.find(y => y.overview.key === i);
        if (yearData && yearData.overview.mean !== 0) {
            currentKnownValue = yearData.overview.mean;
            fullYearlyData.push(yearData);
        } else {
            fullYearlyData.push({
                overview: {
                    ...defaultMetricModel,
                    key: i,
                    mean: currentKnownValue || 0
                },
                monthlySummary: {}
            });
        }
    }

    return fullYearlyData;
}

export function getFullDailyDataForMonth(year: number, month: number, listings: ListingModel[], lastKnownValue: number | null = null): MetricModel[] {
    const daysInMonth = moment({ year, month: month - 1 }).daysInMonth();
    const dailyData: MetricModel[] = [];
    let currentKnownValue = lastKnownValue || 0;
    
    for (let day = 1; day <= daysInMonth; day++) {
        const dailyListings = getListingsByDateCombo(listings, year, month, day);
        const dailyMetric = calculateMetricsForListings(dailyListings, day);
        if (dailyMetric.mean !== 0) {
            currentKnownValue = dailyMetric.mean;
            dailyData.push(dailyMetric);
        } else {
            dailyData.push({ ...defaultMetricModel, key: day, mean: currentKnownValue });
        }
    }
    return dailyData;
};

export function getFullMonthlyDataForYear(year: number, data: YearlySummary, lastKnownValue: number | null = null): MetricModel[] {
    const monthlyData: MetricModel[] = Object.values(data[year]?.monthlySummary || {}) || [];
    const fullMonthlyData: MetricModel[] = [];
    let currentKnownValue = lastKnownValue || 0;
    for (let i = 1; i <= 12; i++) {
        const monthData = monthlyData.find(m => m.key === i);
        if (monthData && monthData.mean !== 0) {
            currentKnownValue = monthData.mean;
            fullMonthlyData.push(monthData);
        } else {
            fullMonthlyData.push({ ...defaultMetricModel, key: i, mean: currentKnownValue });
        }
    }

    return fullMonthlyData;
}