import { Component } from 'react';
import { AppConfig } from 'config';
import {
  Stack,
  Box,
  Heading,
  Flex,
  Text,
  StackDivider,
  Icon,
  Link
} from '@chakra-ui/react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { UserSelectors } from 'reducers/User/selectors';
import { RouterProps, withRouter } from "utils/route";
import { ContactForm, Feature } from 'components';
import { UserModel } from 'models';
import { AppConstants } from '../../constants';
import { ApplicationState } from 'reducers/types';
import { ContactFormData } from 'components/Form/ContactForm/ContactForm';
import { FaInstagram } from 'react-icons/fa';
import { MdOutlineEmail } from 'react-icons/md';
import { UserActions } from 'reducers/User/actions';

interface ContactUsViewProps extends RouterProps {
  user: UserModel;
  sendInboundContactRequest: (email: string, name: string, message: string) => void;
  userMailActionLoading: boolean;
}

class ContactUs extends Component<ContactUsViewProps, {}> {
  getDefaultContactFormData = (): ContactFormData => {
    const { user } = this.props;
    let defaultData: ContactFormData = {};
    if (user.email) {
      defaultData.email = user.email;
    }

    if (user.name) {
      defaultData.name = user.name;
    }

    return defaultData;
  }

  onContactFormSubmit = (data: ContactFormData) => {
    const { sendInboundContactRequest } = this.props;
    if (data.email && data.message) {
      sendInboundContactRequest(data.email, data.name || '', data.message);
    };
  }

  renderContactFeatures() {
    const containerDivider = <StackDivider borderColor={'gray.100'} />;
    return (
      <Stack
        spacing={4}
        marginTop={16}
        marginBottom={16}
        divider={containerDivider}>
        <Feature
          icon={<Icon as={MdOutlineEmail} color={'gray.500'} w={6} h={6} />}
          iconBg={'gray.100'}
          iconBgSize={10}
          subTextOrientation={'column'}
          text={AppConfig.CONTACT_EMAIL}
          subText={'Email Us'}
        />
        <Link
            href={`https://www.instagram.com/${AppConfig.CONTACT_INSTAGRAM}`}
            isExternal={true}
            cursor={'pointer'}
        >
          <Feature
            icon={
              <Icon as={FaInstagram} color={'red.500'} w={6} h={6} />
            }
            iconBg={'red.100'}
            iconBgSize={10}
            subTextOrientation={'column'}
            text={`@${AppConfig.CONTACT_INSTAGRAM}`}
            subText={'Instagram'}
          />
        </Link>
      </Stack>
    );
  }

  render() {
    const { userMailActionLoading } = this.props;
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
        <Flex direction={{ base: 'column', md: 'row' }}>
          <Box textAlign="left" width={{ base: '100%', md: '50%' }} padding={{base: 4, md: 0}}>
            <Heading fontSize="4xl" marginBottom={4}>
              Artist Recs?<br />Got Feedback?<br />Noticing Issues?
            </Heading>
            <Text fontSize="lg" marginTop={8}>Get in touch with us!</Text>
            {this.renderContactFeatures()}
          </Box>
          <Box width={{ base: '100%', md: '50%' }} padding={{base: 4, md: 0}}>
            <Heading fontSize="2xl" marginBottom={4}>
              Send us a message
            </Heading>
            <ContactForm defaultData={this.getDefaultContactFormData()} onSubmit={this.onContactFormSubmit} submitLoading={userMailActionLoading} />
          </Box>
        </Flex>
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: UserSelectors.getUser(state),
    userMailActionLoading: UserSelectors.getUserMailActionLoading(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      sendInboundContactRequest: (email: string, name: string, message: string) => UserActions.sendInboundContactRequest(email, name, message)
    },
    dispatch
  );
}


export const ContactUsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ContactUs));
