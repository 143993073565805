import {
	Avatar,
	Flex,
	Heading,
	Text,
	Spacer,
	useColorModeValue
} from '@chakra-ui/react';
import { ArtistModel } from 'models';
import GrowthBadge from '../../GrowthBadge/GrowthBadge';

export interface ArtistMetricCardProps {
	artist: ArtistModel;
	metric: number;
	priorMetricValue?: number;
	onClick?: () => void;
	onArtistTextClick?: () => void;
	avatarSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
	titleSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
	showButton?: boolean;
	buttonText?: string;
	buttonIcon?: JSX.Element;
	onButtonClick?: () => void;
	buttonLoading?: boolean;
	showBorder?: boolean;
	disabled?: boolean;
}

export enum ArtistMetricCardSizingConfig {
	CARD_HEIGHT = '60px',
	PANEL_MIN_HEIGHT = '80px'
}

export enum ArtistMetricCardStylingConfig {
	BORDER_COLOR_LIGHT = '#f1f3f7',
	BORDER_COLOR_DARK = '#3f434c'
}

export default function ArtistMetricCard({ artist, metric, priorMetricValue, onClick, onArtistTextClick, avatarSize = 'sm', titleSize = 'md', showBorder = true, disabled = false }: ArtistMetricCardProps) {

	function renderArtistName() {
		const contentProps = {
			_hover: onArtistTextClick ? { color: 'blue.500' } : {},
			cursor: onArtistTextClick ? 'pointer' : '',
			onClick: onArtistTextClick
		}

		return (
			<Text fontSize='xs' fontWeight='light' {...contentProps}>
				{artist.name}
			</Text>
		);
	}

	function renderArtistMetric() {
		return (
			<Flex direction={'row'} gap={2}>
				<Heading fontSize={titleSize}>
					{metric.toFixed(2)}
				</Heading>
				{!!priorMetricValue && <GrowthBadge starting={priorMetricValue} ending={metric} showRawDelta />}
			</Flex>
		);
	}


	return (
		<Flex
			direction='row'
			alignItems='center'
			gap='8px'
			minHeight={ArtistMetricCardSizingConfig.CARD_HEIGHT}
			width="100%"
			overflow='hidden'
			onClick={onClick}
			cursor={onClick && !disabled ? 'pointer' : ''}
			padding={showBorder ? '10px' : '10px 0px'}
			border={showBorder ? "1px solid" : 'none'}
			borderColor={useColorModeValue(ArtistMetricCardStylingConfig.BORDER_COLOR_LIGHT, ArtistMetricCardStylingConfig.BORDER_COLOR_DARK)}
			_hover={onClick && !disabled ? { 'backgroundColor': useColorModeValue('gray.200', 'gray.800') } : {}}
		>
			<Avatar marginRight="16px" size={avatarSize} name={artist.name} src={artist.image} />
			<Flex direction="column" textAlign="left">
				{renderArtistMetric()}
				{renderArtistName()}
			</Flex>
			<Spacer />
		</Flex>
	);
}
