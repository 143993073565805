import {
  Box,
  Container,
  Link,
  Stack,
  Text,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { AppConstants } from '../../constants';
import { FaInstagram } from 'react-icons/fa';
import { NavigationService } from 'services';
import Logo from '../Logo/Logo';
import SocialButton from '../Button/SocialButton';
import { AppConfig } from 'config';

export interface FooterProps {
  title?: string;
}

export default function Footer({ title = `${AppConfig.APP_NAME} © All Rights Reserved` }: FooterProps) {
  return (
    <Box
      marginTop="auto"
      bg={useColorModeValue('gray.50', 'gray.900')}
      color={useColorModeValue('gray.700', 'gray.200')}>
      <Container
        as={Stack}
        maxW={`${AppConstants.NAVBAR_MAXWIDTH}px`}
        margin="0 auto"
        py={8}
        spacing={4}
        justify={'center'}
        align={'center'}>
        <Link href={NavigationService.getHomePath()}>
          <Logo h={"40px"} />
        </Link>
        <Stack direction={'row'} spacing={6}>
          <Link href={NavigationService.getHomePath()}>
            <Heading fontSize="md">
              Home
            </Heading>
          </Link>
          <Link href={NavigationService.getPrivacyContentPath()}>
            <Heading fontSize="md">
              Privacy
            </Heading>
          </Link>
          <Link href={NavigationService.getContactPath()}>
            <Heading fontSize="md">
              Contact
            </Heading>
          </Link>
          <Link href={NavigationService.getWaitlistPath()}>
            <Heading fontSize="md">
              Waitlist
            </Heading>
          </Link>
        </Stack>
      </Container>
      <Box
        borderTopWidth={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.700')}>
        <Container
          as={Stack}
          py={4}
          paddingInline={{base: 3, md: 0}}
          maxW={`${AppConstants.NAVBAR_MAXWIDTH}px`}
          margin="0 auto"
          direction={{ base: 'column', md: 'row' }}
          spacing={4}
          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <Text fontSize="sm">{title}</Text>
          <Stack direction={'row'} spacing={6}>
            <Link cursor={'pointer'} href={`https://www.instagram.com/${AppConfig.CONTACT_INSTAGRAM}`} isExternal={true}>
              <SocialButton label={'Instagram'}>
                <FaInstagram />
              </SocialButton>
            </Link>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
