import { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Spacer,
  Square,
  Icon,
  Tooltip
} from '@chakra-ui/react';
import { processDateSort, processNumberSort } from 'utils/sort';
import { filterListingsByQuery } from 'utils/search';
import { ListingModel } from 'models';
import ListingAccordion from '../ListingAccordion/ListingAccordion';
import Loader from '../../Loader/Loader';
import SearchBar from '../../SearchBar/SearchBar';
import ListingGridFilters from '../ListingGridFilters/ListingGridFilters';
import SortDropdown from '../../SortDropdown/SortDropdown';
import { ListingSortFilter } from '../types';
import { AiOutlineFilter, AiFillFilter } from 'react-icons/ai';


export interface ListingGridProps {
  gridListings: ListingModel[];
  isLoading: boolean;
  showFilters?: boolean;
  showFiltersLabel?: boolean;
  showAssociatedItems?: boolean;
  showAssociationAction?: boolean;
  onListingItemClick?: (id: string) => void;
  onItemSuggestionClick?: (listing: ListingModel) => void;
  onTagListingClick?: (listing: ListingModel) => void;
}

export default function ListingGrid({ gridListings, isLoading, onListingItemClick, onItemSuggestionClick, onTagListingClick, showFilters = true, showFiltersLabel = true, showAssociatedItems = false, showAssociationAction = false }: ListingGridProps) {
  const [listings, setListings] = useState<ListingModel[]>(gridListings);
  const [listingsQuery, setListingsQuery] = useState<string>('');
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const [selectedPrices, setSelectedPrices] = useState<number[]>([]);
  const [listingsSort, setListingsSort] = useState<ListingSortFilter>(ListingSortFilter.DEFAULT);
  const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
  const [displayFilters, setDisplayFilters] = useState<boolean>(false);

  useEffect(() => {
    if (gridListings.length) {
      setListings(gridListings);
    }

    if (isLoading) {
      onClearFilters();
      resetSort();
      clearQuery();
    }
  }, [gridListings, isLoading]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [listingsQuery, selectedYears, selectedPrices, listingsSort, sortType]);

  const applyFiltersAndSort = () => {
    let filteredListings = gridListings;

    if (listingsQuery) {
      filteredListings = filterListingsByQuery(filteredListings, listingsQuery);
    }

    if (selectedYears.length > 0) {
      filteredListings = filterListingsBySaleDateRange(filteredListings, selectedYears);
    }

    if (selectedPrices.length > 0) {
      filteredListings = filterListingsBySalePriceRange(filteredListings, selectedPrices);
    }

    setListings(processSortKey(filteredListings, listingsSort));
  };

  const isOnFilterMode = (): boolean => {
    return listingsQuery.length > 0 || listingsSort !== ListingSortFilter.DEFAULT || hasFiltersApplied();
  }

  const resetSort = () => {
    setListingsSort(ListingSortFilter.DEFAULT);
  }

  const clearQuery = () => {
    setListingsQuery('');
  }

  const onClearFilters = () => {
    setSelectedYears([]);
    setSelectedPrices([])
    setDisplayFilters(false);
  }

  const hasFiltersApplied = (): boolean => {
    return selectedYears.length > 0 || selectedPrices.length > 0;
  }

  const toggleFilterDisplay = (): void => {
    setDisplayFilters(!displayFilters);
  }

  const onListingSalePriceRangeSelect = (minPrice: number, maxPrice: number): void => {
    const priceRange = [minPrice, maxPrice];
    setSelectedPrices(priceRange);
  }

  const onListingSaleDateRangeSelect = (minYear: number, maxYear: number): void => {
    const yearRange = [minYear, maxYear];
    setSelectedYears(yearRange);
  }

  const onListingSearch = (query: string): void => {
    setListingsQuery(query);
  }

  const filterListingsBySaleDateRange = (listings: ListingModel[], dateYearRanges: number[]): ListingModel[] => {
    return listings.filter((listing: ListingModel) => listing.getDateYear() >= dateYearRanges[0] && listing.getDateYear() <= dateYearRanges[1]);
  }

  const filterListingsBySalePriceRange = (listings: ListingModel[], priceRanges: number[]): ListingModel[] => {
    return listings.filter((listing: ListingModel) => listing.rawPrice >= priceRanges[0] && listing.rawPrice <= priceRanges[1]);
  }

  const getListingData = (): ListingModel[] => {
    if (isOnFilterMode()) {
      return listings;
    } else {
      return gridListings;
    }
  }

  const processSortKey = (listings: ListingModel[], sortKey: ListingSortFilter): ListingModel[] => {
    let sortedListings = [...listings];
    const isAscending: boolean = sortType === 'asc';

    if (sortType === 'asc') {
      if (sortKey === ListingSortFilter.TITLE) {
        sortedListings = sortedListings.sort((a, b) => (a.name > b.name ? 1 : -1));
      }
    } else {
      if (sortKey === ListingSortFilter.TITLE) {
        sortedListings = sortedListings.sort((a, b) => a.name > b.name ? -1 : 1);
      }
    }

    if (sortKey === ListingSortFilter.SALE_DATE) {
      sortedListings = sortedListings.sort((a, b) => processDateSort(a.date, b.date, !isAscending));
    } else if (sortKey === ListingSortFilter.PRICE) {
      sortedListings = sortedListings.sort((a, b) => processNumberSort(a.rawPrice, b.rawPrice, isAscending));
    }
    return sortedListings;
  }

  function renderFiltersSort() {
    return (
      <SortDropdown<ListingSortFilter>
        options={ListingSortFilter}
        selectedSortBy={listingsSort}
        sortType={sortType}
        onSortBySelect={setListingsSort}
        onSortTypeToggle={() => setSortType(sortType === 'asc' ? 'desc' : 'asc')}
      />
    );
  }

  function renderFilterButton() {
    return (
      <Tooltip label={'Filter Results'}>
        <Square size={10} border={'1px solid'} borderColor={'gray.300'} cursor={'pointer'} borderRadius={'md'} onClick={() => toggleFilterDisplay()}>
          <Icon as={displayFilters ? AiFillFilter : AiOutlineFilter} />
        </Square>
      </Tooltip>
    );
  }

  function renderListingGridFilters() {
    if (displayFilters) {
      return (
        <ListingGridFilters
          listings={gridListings}
          onListingPriceRangeSelect={onListingSalePriceRangeSelect}
          onListingSaleDateRangeSelect={onListingSaleDateRangeSelect}
          defaultPriceRange={selectedPrices}
          defaultSaleDateRange={selectedYears}
          showClearOption={hasFiltersApplied()}
          onResetFilters={onClearFilters}
        />
      );
    }
  }

  function renderFilters() {
    if (showFilters) {
      return (
        <Flex minWidth='fit-content' alignItems={{ base: 'start', md: 'center' }} gap='2' marginBottom="40px" p={{ base: '20px', md: '8px 0px' }} direction={{ base: 'column', md: 'row' }}>
          <Box>
            {showFiltersLabel && <Text fontSize='xs'>Filters: </Text>}
          </Box>
          <Flex direction={{ base: 'column', md: 'row' }} width="100%" gap={2}>
            <SearchBar onChange={onListingSearch} defaultValue={listingsQuery} />
            <Flex direction='row' gap={2} width={'100%'}>
              {renderFilterButton()}
              <Spacer />
              {renderFiltersSort()}
            </Flex>
          </Flex>
        </Flex>
      );
    }
  }

  function renderContent() {
    if (isLoading) {
      return <Loader key="listingGrid_content_loader" />;
    } else {
      return (
        <Flex direction="column" gap="12px">
          {getListingData().map((listing: ListingModel, index: number) => <ListingAccordion key={`listing_grid_listing_${index}`} listing={listing} onItemClick={onListingItemClick} onItemSuggestionClick={onItemSuggestionClick} onTagListingClick={onTagListingClick} showAssociatedItem={showAssociatedItems} showAssociationAction={showAssociationAction} />)}
        </Flex>
      );
    }
  }

  return (
    <Box>
      {renderFilters()}
      {renderListingGridFilters()}
      {renderContent()}
    </Box>
  );
}
