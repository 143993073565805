import { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  Spacer,
  SimpleGrid,
	useColorModeValue,
  Square,
  Icon,
  Tooltip
} from '@chakra-ui/react';
import { PaginationModel, ItemModel } from 'models';
import { processDateSort, processNumberSort } from 'utils/sort';
import { filterItemsByQuery } from 'utils/search';
import { ItemType } from 'models/Item/types';
import ItemCard from '../ItemCard/ItemCard';
import BasicItemCard from '../BasicItemCard/BasicItemCard';
import Loader from '../../Loader/Loader';
import SearchBar from '../../SearchBar/SearchBar';
import EmptyState from '../../EmptyState/EmptyState'
import PaginationFooter from '../../Pagination/PaginationFooter/PaginationFooter';
import ItemGridFilters from '../ItemGridFilters/ItemGridFilters';
import SortDropdown from '../../SortDropdown/SortDropdown';
import InfiniteScroll from 'react-infinite-scroller';
import { ItemSortFilter } from '../types'
import { AiOutlineFilter, AiFillFilter } from 'react-icons/ai';

export interface PaginatedItemGridProps {
  paginatedItems: PaginationModel<ItemModel>;
  onScrollEndLoad?: () => void;
  onPageSelect?: (page: number) => void;
  isLoading: boolean;
  showFilters?: boolean;
  scrollMode?: boolean;
  gridDisplay?: boolean;
	gridMaxHeight?: number;
  onItemCardClick?: (item: ItemModel) => void;
}

export default function PaginatedItemGrid({ paginatedItems, isLoading, onScrollEndLoad, onPageSelect, gridDisplay, showFilters = true, scrollMode = true, gridMaxHeight = 1200, onItemCardClick = () => {} }: PaginatedItemGridProps) {
  const [items, setItems] = useState<PaginationModel<ItemModel>>(paginatedItems);
  const [itemsQuery, setItemsQuery] = useState<string>('');
	const [selectedTypes, setSelectedTypes] = useState<ItemType[]>([]);
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const [selectedEditionSizes, setSelectedEditionSizes] = useState<number[]>([]);
  const [selectedEstimatedValues, setSelectedEstimatedValues] = useState<number[]>([]);
  const [itemsSort, setItemsSort] = useState<ItemSortFilter>(ItemSortFilter.DEFAULT);
  const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
  const [displayFilters, setDisplayFilters] = useState<boolean>(false);

  useEffect(() => {
    if (paginatedItems.hasData()) {
      setItems(paginatedItems);
      applyFiltersAndSort();
    }
  }, [paginatedItems]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [itemsQuery, selectedTypes, selectedYears, selectedEditionSizes, selectedEstimatedValues, itemsSort, sortType]);

	const isOnFilterMode = (): boolean => {
    return itemsSort !== ItemSortFilter.DEFAULT || itemsQuery.length > 0 || hasFiltersApplied();
  }

  const onClearFilters = () => {
    setItemsQuery('');
    setSelectedTypes([]);
    setSelectedYears([]);
    setSelectedEditionSizes([]);
    setSelectedEstimatedValues([]);
    setDisplayFilters(false);
  }

  const hasFiltersApplied = (): boolean => {
    return selectedTypes.length > 0 || selectedYears.length > 0 || selectedEditionSizes.length > 0 || selectedEstimatedValues.length > 0;
  }

  const toggleFilterDisplay = (): void => {
    setDisplayFilters(!displayFilters);
  }

  const applyFiltersAndSort = () => {
    let filteredItems = paginatedItems;
  
    if (itemsQuery) {
      filteredItems = filterPaginatedItemsByQuery(filteredItems, itemsQuery);
    }
    if (selectedTypes.length > 0) {
      filteredItems = filterItemsByTypes(filteredItems, selectedTypes);
    }
    if (selectedYears.length > 0) {
      filteredItems = filterItemsByDateRange(filteredItems, selectedYears);
    }
    if (selectedEditionSizes.length > 0) {
      filteredItems = filterItemsByEditionSizes(filteredItems, selectedEditionSizes);
    }
    if (selectedEstimatedValues.length > 0) {
      filteredItems = filterItemsByEstimatedValues(filteredItems, selectedEstimatedValues);
    }

    setItems(processSortKey(filteredItems, itemsSort));
  };
  

  const onItemSearch = (query: string): void => {
    setItemsQuery(query);
  }

  const onItemDateRangeSelect = (minYear: number, maxYear: number): void => {
    const yearRange = [minYear, maxYear];
    setSelectedYears(yearRange);
  }

  const onItemTypeFilterSelect = (types: ItemType[]): void => {
    setSelectedTypes(types);
  }

  const onItemEditionSizeSelect = (minEditionSize: number, maxEditionSize: number) => {
    const editionSizeRange = [minEditionSize, maxEditionSize];
    setSelectedEditionSizes(editionSizeRange);
  }

  const onItemEstimatedValueSelect = (minEstimatedValue: number, maxEstimatedValue: number) => {
    const estimatedValueRange = [minEstimatedValue, maxEstimatedValue];
    setSelectedEstimatedValues(estimatedValueRange);
  }

  const filterPaginatedItemsByQuery = (items: PaginationModel<ItemModel>, query: string): PaginationModel<ItemModel> => {
    const newItemData = filterItemsByQuery(paginatedItems.data, query);
		return new PaginationModel<ItemModel>({ per_page: items.perPage, current_page: items.currentPage, total_count: items.totalCount, total_pages: items.totalPages, data: newItemData});

  }

  const filterItemsByTypes = (items: PaginationModel<ItemModel>, types: ItemType[]): PaginationModel<ItemModel> => {
    const newItemData = items.data.filter((item: ItemModel) => types.includes(item.type));		
		return new PaginationModel<ItemModel>({ per_page: items.perPage, current_page: items.currentPage, total_count: items.totalCount, total_pages: items.totalPages, data: newItemData});
  }

  const filterItemsByDateRange = (items: PaginationModel<ItemModel>, dateYearRanges: number[]): PaginationModel<ItemModel> => {
    const newItemData = items.data.filter((item: ItemModel) => item.getReleaseYear() >= dateYearRanges[0] &&  item.getReleaseYear() <= dateYearRanges[1]);
		return new PaginationModel<ItemModel>({ per_page: items.perPage, current_page: items.currentPage, total_count: items.totalCount, total_pages: items.totalPages, data: newItemData});
  }

  const filterItemsByEditionSizes = (items: PaginationModel<ItemModel>, editionSizeRanges: number[]): PaginationModel<ItemModel> => {
    const newItemData = items.data.filter((item: ItemModel) => !item.isOpenEdition() && item.hasEditionSize() && item.getEditionSize() >= editionSizeRanges[0] && item.getEditionSize() <= editionSizeRanges[1]);
		return new PaginationModel<ItemModel>({ per_page: items.perPage, current_page: items.currentPage, total_count: items.totalCount, total_pages: items.totalPages, data: newItemData});
  }

  const filterItemsByEstimatedValues = (items: PaginationModel<ItemModel>, estimatedValueRanges: number[]): PaginationModel<ItemModel> => {
    const newItemData = items.data.filter((item: ItemModel) => item.hasEstimatedMarketPrice() && item.getEstimatedMarketPrice() >= estimatedValueRanges[0] && item.getEstimatedMarketPrice() <= estimatedValueRanges[1]);
		return new PaginationModel<ItemModel>({ per_page: items.perPage, current_page: items.currentPage, total_count: items.totalCount, total_pages: items.totalPages, data: newItemData});
  }


  const onLoadMore = (): void => {
    if (paginatedItems.hasMorePages() && !isLoading && !isOnFilterMode()) {
      onScrollEndLoad?.();
    }
  }

  const processSortKey = (items: PaginationModel<ItemModel>, sortKey: ItemSortFilter): PaginationModel<ItemModel> => {
    let sortedItems = [...items.data];
    const isAscending: boolean = sortType === 'asc';

    if (sortType === 'asc') {
      if (sortKey === ItemSortFilter.TITLE) {
        sortedItems = sortedItems.sort((a, b) => (a.name > b.name ? 1 : -1));
      }
    } else {
      if (sortKey === ItemSortFilter.TITLE) {
        sortedItems = sortedItems.sort((a: ItemModel, b: ItemModel) => a.name > b.name ? -1 : 1);
      }
    }

    if (sortKey === ItemSortFilter.RELEASE_DATE) {
      sortedItems = sortedItems.sort((a, b) => processDateSort(a.date, b.date, !isAscending));
    } else if (sortKey === ItemSortFilter.LAST_SALE_DATE) {
      sortedItems = sortedItems.sort((a, b) => processDateSort(a.getLastSaleDate(), b.getLastSaleDate(), !isAscending));
    } else if (sortKey === ItemSortFilter.EDITION_SIZE) {
      sortedItems = sortedItems.sort((a, b) => processNumberSort(a.getEditionSize(), b.getEditionSize(), isAscending));
    } else if (sortKey === ItemSortFilter.ESTIMATED_PRICE) {
      sortedItems = sortedItems.sort((a, b) => processNumberSort(a.getEstimatedMarketPrice(), b.getEstimatedMarketPrice(), isAscending));
    } else if (sortKey === ItemSortFilter.MARKET_VOLUME) {
      sortedItems = sortedItems.sort((a, b) => processNumberSort(a.getSecondaryMarketVolume(), b.getSecondaryMarketVolume(), isAscending));
    } else if (sortKey === ItemSortFilter.BIGGEST_MARKET_CHANGE) {
      sortedItems = sortedItems.sort((a, b) => processNumberSort(a.getLastSalePercentageChange(), b.getLastSalePercentageChange(), isAscending));
    }

    return new PaginationModel<ItemModel>({ per_page: items.perPage, current_page: items.currentPage, total_count: items.totalCount, total_pages: items.totalPages, data: sortedItems});
  }

  const getItemData = (): ItemModel[] => {
    if (isOnFilterMode()) {
      return items.data;
    } else {
      return paginatedItems.data;
    }
  }

  const scrollLoadEnabled: boolean = !isLoading && (items?.hasMorePages() || false) && !isOnFilterMode() && (paginatedItems.hasData());


  function renderFiltersSort() {
    return (
      <SortDropdown<ItemSortFilter>
        options={ItemSortFilter}
        selectedSortBy={itemsSort}
        sortType={sortType}
        onSortBySelect={setItemsSort}
        onSortTypeToggle={() => setSortType(sortType === 'asc' ? 'desc' : 'asc')}
      />
    );
  }

  function renderFilterButton() {
    return (
      <Tooltip label={'Filter Results'}>
        <Square size={10} border={'1px solid'} borderColor={'gray.300'} cursor={'pointer'} borderRadius={'md'} onClick={() => toggleFilterDisplay()}>
          <Icon as={displayFilters ? AiFillFilter : AiOutlineFilter} />
        </Square>
      </Tooltip>
    );
  }

  function renderItemsFilters() {
    if (showFilters) {
      return (
        <Flex minWidth='fit-content' alignItems={{ base: 'start', md: 'center' }} gap='2' marginBottom="40px" p={{ base: '20px', md: '8px 0px' }} direction={{ base: 'column', md: 'row' }}>
          <Box>
            <Text fontSize='xs'>Filters: </Text>
          </Box>
          <Flex direction={{ base: 'column', md: 'row' }} width="100%" gap={2}>
            <SearchBar onChange={onItemSearch} />
            <Flex direction='row' gap={2} width={'100%'}>
              {renderFilterButton()}
              <Spacer />
              {renderFiltersSort()}
            </Flex>
          </Flex>
        </Flex>
      );
    }
  }

  function renderItemGridFilters() {
    if (displayFilters) {
      return (
        <ItemGridFilters 
          items={paginatedItems.data} 
          onItemDateRangeSelect={onItemDateRangeSelect} 
          onItemEditionRangeSelect={onItemEditionSizeSelect} 
          onItemTypeFilterSelect={onItemTypeFilterSelect} 
          onItemEstimatedValueRangeSelect={onItemEstimatedValueSelect}
          defaultDateRange={selectedYears}
          defaultEditionRange={selectedEditionSizes}
          defaultValueRange={selectedEstimatedValues}
          defaultTypes={selectedTypes}
          showClearOption={hasFiltersApplied()}
          onResetFilters={onClearFilters} />
      );
    }
  }

  function renderItems() {
    const itemData = getItemData();
    if (gridDisplay) {
      return (
        <Box backgroundColor={useColorModeValue("white", "black")} borderRadius="16px">
          <Flex direction='column'>
            {itemData.map((item: ItemModel, index: number) => <BasicItemCard onClick={() => onItemCardClick(item)} key={`item_card_${index}`} item={item} />)}
          </Flex>
        </Box>
      );
    } else {
      return (
        <SimpleGrid columns={[2, 4, 4]} spacing={{ base: "0px", md: "20px" }}>
          {itemData.map((item: ItemModel, index: number) => <ItemCard onClick={() => onItemCardClick(item)} key={`item_card_${index}`} item={item} />)}
        </SimpleGrid>
      );
    }
  }


  function renderData() {
    const itemData = getItemData();
    if (itemData.length) {
      const pageFooterModeStyles = !scrollMode ? {
        overflow: 'scroll',
        maxH: 800
      } : {};
      return (
        <Flex direction="column" gap="12px" {...{}}>
					{renderItems()}
        </Flex>
      );
    } else {
      return (
        <EmptyState header="No Items" description="No Results Found" showButton={false} />
      );
    }
  }

  function renderInfiniteScrollLoader() {
    if (!!onScrollEndLoad) {
      return (
        <Flex justifyContent="center" alignItems="center">
          <Loader key="itemGrid_infiniteScroll_loader"/>
        </Flex>
      );
    } else {
      return <Box/>;
    }
  }

  function renderPagesFooter() {
    if (!scrollMode) {
      return (
        <PaginationFooter currentPage={paginatedItems.currentPage} lastPage={paginatedItems.totalPages} siblingsCount={2} onPageChange={(page) => onPageSelect?.(page)} />
      );
    }
  }

  function renderContent() {
    if (scrollMode) {
      return (
        <InfiniteScroll
          pageStart={0}
          loadMore={onLoadMore}
          hasMore={scrollLoadEnabled}
          loader={renderInfiniteScrollLoader()}
        >
          {renderData()}
        </InfiniteScroll>
      );
    } else {
      return isLoading ? (
        <Flex justifyContent="center" alignItems="center" h={800}>
          <Loader key="itemGrid_content_loader" />
        </Flex>
      ) : renderData();
    }
  }

  function renderScrollModeLoader() {
    if (isLoading && scrollMode && !paginatedItems.hasData()) {
      return (
        <Flex justifyContent="center" alignItems="center">
          <Loader key="itemGrid_content_loader" />
        </Flex>
      );
    } else {
      return null;
    }
  }

  return (
    <Box width="100%">
      {renderItemsFilters()}
      {renderItemGridFilters()}
      {renderContent()}
      {renderPagesFooter()}
      {renderScrollModeLoader()}
    </Box>
  );
}
