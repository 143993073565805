import { UserModel } from 'models';
import { AuthState } from './types';

export const defaultState: AuthState = {
  authUser: new UserModel(),
  error: undefined,
  loading: {
    user: false,
    logout: false,
    login: false,
    refreshAuth: false,
    register: false
  }
};
