import { UrlConfig } from 'config';
import { ListingModel, PaginationModel } from 'models';
import { JSONObject } from 'models/Api/types';
import { RawListingData, ListingStatus } from 'models/Listing/types';
import { ApiResponse } from 'models/Api/ApiResponse';
import { RawPaginationData } from 'models/Pagination/Pagination';
import { AjaxService } from 'services';
import { ArtistConfig } from 'config';
import { filterEnabledArtists } from 'utils/artist';

export class ListingApiClient {
  public static async searchListingsByQuery(query: string, page: number = 1): Promise<PaginationModel<ListingModel>> {
    let url = UrlConfig.LISTING.LISTING_SEARCH;

    const response: ApiResponse<RawPaginationData<RawListingData>> = await AjaxService.postData(url, {
      "query": query,
      "page": page,
      "processing_status": [ListingStatus.PENDING, ListingStatus.APPROVED, ListingStatus.GROUPING_PENDING],
      "authentic": true,
      "artist_id": ArtistConfig.ENABLED_ARTISTS
    });
    const paginatedRespose: PaginationModel<RawListingData> = new PaginationModel(response.data);
    const formattedListings = paginatedRespose.data.map((rawListing: RawListingData) => new ListingModel(rawListing));
    const formattedPaginatedResponse: PaginationModel<ListingModel> = new PaginationModel({ ...response.data, data: formattedListings, fetched: true });
    return formattedPaginatedResponse;
  }

  public static async searchListings(query: JSONObject): Promise<PaginationModel<ListingModel>> {
    let url = UrlConfig.LISTING.LISTING_SEARCH;
    if (query['artist_id']) {
      query['artist_id'] = filterEnabledArtists(query['artist_id'] as (string[] | string));
    } else {
      query['artist_id'] = ArtistConfig.ENABLED_ARTISTS
    }

    if (!query['processing_status']) {
      query['processing_status'] = [ListingStatus.PENDING, ListingStatus.APPROVED, ListingStatus.GROUPING_PENDING];
    }
    
    const response: ApiResponse<RawPaginationData<RawListingData>> = await AjaxService.postData(url, {
      "authentic": true,
      ...query
    });
    const paginatedRespose: PaginationModel<RawListingData> = new PaginationModel(response.data);
    const formattedListings = paginatedRespose.data.map((rawListing: RawListingData) => new ListingModel(rawListing));
    const formattedPaginatedResponse: PaginationModel<ListingModel> = new PaginationModel({ ...response.data, data: formattedListings, fetched: true });
    return formattedPaginatedResponse;
  }
}
