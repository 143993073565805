import { ArtistIndexModel, ArtistModel, ItemModel, ListingModel, PaginationModel } from 'models';
import { ArtistActionTypes, ArtistAction } from './types';

export class ArtistActions {
  public static getArtistById(id: string): ArtistAction {
    return {
      type: ArtistActionTypes.GET_ARTIST_BY_ID,
      payload: { id },
    };
  }

  public static getAllArtists(): ArtistAction {
    return {
      type: ArtistActionTypes.GET_ALL_ARTISTS
    };
  }

  public static getArtistItems(id: string): ArtistAction {
    return {
      type: ArtistActionTypes.GET_ARTIST_ITEMS,
      payload: { id }
    };
  }

  public static getArtistListings(id: string, page?: number): ArtistAction {
    return {
      type: ArtistActionTypes.GET_ARTIST_LISTINGS,
      payload: { id, page }
    }
  }

  public static getArtistEditionIndex(id: string): ArtistAction {
    return {
      type: ArtistActionTypes.GET_ARTIST_EDITIONS_INDEX,
      payload: { id }
    }
  }
}

export class ArtistInternalActions {
  public static getArtistByIdSuccess(artist: ArtistModel): ArtistAction {
    return {
      type: ArtistActionTypes.GET_ARTIST_BY_ID_SUCCESS,
      payload: { artist },
    };
  }

  public static getArtistByIdFailure(): ArtistAction {
    return { type: ArtistActionTypes.GET_ARTIST_BY_ID_FAILURE };
  }

  public static getAllArtistsSuccess(artistList: ArtistModel[]): ArtistAction {
    return {
      type: ArtistActionTypes.GET_ALL_ARTISTS_SUCCESS,
      payload: { artistList },
    };
  }

  public static getAllArtistsFailure(): ArtistAction {
    return { type: ArtistActionTypes.GET_ALL_ARTISTS_FAILURE };
  }

  public static getArtistItemsSuccess(itemsList: ItemModel[]): ArtistAction {
    return {
      type: ArtistActionTypes.GET_ARTIST_ITEMS_SUCCESS,
      payload: { itemsList },
    };
  }

  public static getArtistItemsFailure(): ArtistAction {
    return { type: ArtistActionTypes.GET_ARTIST_ITEMS_FAILURE };
  }

  public static getArtistListingsSuccess(paginatedListings: PaginationModel<ListingModel>): ArtistAction {
    return {
      type: ArtistActionTypes.GET_ARTIST_LISTINGS_SUCCESS,
      payload: { paginatedListings },
    };
  }

  public static getArtistListingsFailure(): ArtistAction {
    return { type: ArtistActionTypes.GET_ARTIST_LISTINGS_FAILURE };
  }

  public static getArtistEditionIndexSuccess(artistIndexData: ArtistIndexModel): ArtistAction {
    return {
      type: ArtistActionTypes.GET_ARTIST_EDITIONS_INDEX_SUCCESS,
      payload: { artistIndexData },
    };
  }

  public static getArtistEditionIndexFailure(): ArtistAction {
    return { type: ArtistActionTypes.GET_ARTIST_EDITIONS_INDEX_FAILURE };
  }
}
