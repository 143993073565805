import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';

export class ListingDataSuggestionSelectors {
  public static getError(state: ApplicationState): Error | undefined {
    return RootSelectors.getListingDataSuggestionState(state).error;
  }

  public static getListingDataSuggestionCreateLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getListingDataSuggestionState(state).loading.create;
  }
}
