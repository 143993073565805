interface YearXTickProps {
    x: number;
    y: number;
    payload: {
        value: string | number;
    };
}

export default function YearXTick({ x, y, payload }: YearXTickProps) {
    const lastTwoDigits = String(payload.value).slice(-2);
    return (
        <text x={x} y={y + 15} fill="#666" fontSize="12px" fontWeight="bold" textAnchor="middle">
            {'\'' + lastTwoDigits}
        </text>
    );
}
