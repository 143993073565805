import { ArtistModel } from "models";

interface ArtistLegendProps {
  payload?: Array<{
    value: string;
    payload: ArtistModel;
    color: string;
  }> | any[];
  showImage?: boolean;
}

export default function ArtistLegend(props: ArtistLegendProps) {
  const { payload, showImage } = props;
  if (!payload) return null;
  return (
    <ul style={{ listStyle: 'none', margin: 0, padding: 0, fontSize: '12px', fontWeight: 'bold' }}>
      {payload.map((entry, index) => (
        <li key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
          {entry.payload?.data.image && showImage ? (
            <img
              src={entry.payload.data.image}
              alt={entry.value}
              style={{
                width: '20px',
                height: '20px',
                marginRight: '10px',
                borderRadius: '50%',
                objectFit: 'cover',
                border: `1px solid ${entry.color}`,
              }}
            />
          ) :
            <div
              style={{
                width: '16px',
                height: '16px',
                marginRight: '10px',
                borderRadius: '50%',
                backgroundColor: entry.color,
              }}
            />}
          <span style={{ marginRight: '10px' }}>{entry.value}</span>
        </li>
      ))}
    </ul>
  );
};

