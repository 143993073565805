import { ItemDataSuggestionActionTypes, ItemDataSuggestionAction } from './types';
import { JSONObject } from 'models/Api/types';

export class ItemDataSuggestionActions {
  public static createItemDataSuggestion(itemId: string, data: JSONObject): ItemDataSuggestionAction {
    return {
      type: ItemDataSuggestionActionTypes.CREATE_ITEM_DATA_SUGGESTION,
      payload: { itemId, data },
    };
  }
}

export class ItemDataSuggestionInternalActions {
  public static createItemDataSuggestionSuccess(id: string): ItemDataSuggestionAction {
    return {
      type: ItemDataSuggestionActionTypes.CREATE_ITEM_DATA_SUGGESTION_SUCCESS,
      payload: { id },
    };
  }

  public static createItemDataSuggestionFailure(): ItemDataSuggestionAction {
    return { type: ItemDataSuggestionActionTypes.CREATE_ITEM_DATA_SUGGESTION_FAILURE };
  }
}
