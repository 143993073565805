import { PopupActionTypes, PopupAction } from './types';
import { Toast } from 'models/types';

export class PopupActions {
  public static addPopup(toast: Toast): PopupAction {
    return {
      type: PopupActionTypes.ADD_POPUP,
      payload: { toast },
    };
  }

  public static removePopup(id: number): PopupAction {
    return {
      type: PopupActionTypes.REMOVE_POPUP,
      payload: { id },
    };
  }

  public static clearPopups(): PopupAction {
    return {
      type: PopupActionTypes.CLEAR_POPUPS,
    };
  }
}
