import PortfolioValueTooltip from './PortfolioValueTooltip';
import { PortfolioTooltipData } from '../types';

interface PortfolioHistoryChartTooltipProps extends PortfolioTooltipData {}

export default function PortfolioHistoryChartTooltip(props: PortfolioHistoryChartTooltipProps) {
    const { payload, active } = props;
    const historyData = payload?.[0]?.payload;

    const label = historyData?.date?.format('D MMMM, YYYY') || '';

    return <PortfolioValueTooltip payload={payload} active={active} label={label} />;
}
