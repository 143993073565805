import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { FiArrowRight } from "react-icons/fi";

export interface CategoryCardProps {
    title: string;
    imageSrc: string;
    buttonText: string;
    onButtonClick?: () => void;
}

export default function CategoryCard({ title, imageSrc, buttonText, onButtonClick = () => {} }: CategoryCardProps) {
  return (
    <Box
      borderRadius="md"
      boxShadow="md"
      overflow="hidden"
      width="300px"
      position="relative"
    >
        <Box position="relative" height="200px">
            <Image
                src={imageSrc}
                alt={title}
                objectFit="cover"
                position="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                height="100%"
                width="auto"
            />
        </Box>
        <Flex
            direction="column"
            justify="center"
            align="center"
            position="absolute"
            top="0"
            bottom="0"
            left="0"
            right="0"
            background="rgba(0, 0, 0, 0.5)"
            color="white"
            padding="2"
        >
            <Text fontSize="xl" fontWeight="bold" mb="2">
                {title}
            </Text>

            <Button colorScheme="orange" onClick={onButtonClick} rightIcon={<FiArrowRight />}>
                {buttonText}
            </Button>
        </Flex>
    </Box>
  );
};