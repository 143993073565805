import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
	Stack,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Checkbox,
	CheckboxGroup,
	HStack,
	Text,
	Button,
	Spacer
} from '@chakra-ui/react';
import { getFilteredObject } from 'utils/object';
import ArtistTable from '../../Artist/ArtistTable/ArtistTable';
import SearchBar from '../../SearchBar/SearchBar';
import ActionButton from '../../Button/ActionButton';
import DateSlider from '../../DateSlider/DateSlider';
import NumberSlider from '../../NumberSlider/NumberSlider';
import SortDropdown from '../../SortDropdown/SortDropdown';
import { ArtistModel } from 'models';
import { FcClearFilters } from "react-icons/fc";
import { ListingType, ListingStatus } from 'models/Listing/types';
import { deepEqual } from 'utils/object';

export interface ListingSearchFiltersFormProps {
	artistList?: ArtistModel[];
	defaultFormData?: ListingSearchFiltersFormData;
	resetState?: ListingSearchFiltersFormData;
	onSubmit?: (data: ListingSearchFiltersFormData) => void;
	onReset?: () => void;
	submitLoading?: boolean;
	artistSelectionEnabled?: boolean;
}

export enum ListingSortFilter {
	PRICE = 'raw_price',
	DATE = 'date'
}

export interface ListingSearchFiltersFormRangeEligibleData {
	date?: string;
	rawPrice?: number;
}

export interface ListingSearchFiltersFormData extends ListingSearchFiltersFormRangeEligibleData {
	query?: string;
	artistId?: string[];
	type?: string[];
	processingStatus?: string[];
	gt?: ListingSearchFiltersFormRangeEligibleData;
	lt?: ListingSearchFiltersFormRangeEligibleData;
	sortBy?: ListingSortFilter;
	sortType?: 'asc' | 'desc';
}

export default function ListingSearchFiltersForm({ artistList, defaultFormData, resetState, onSubmit, onReset, submitLoading, artistSelectionEnabled = true }: ListingSearchFiltersFormProps) {
	const {
		control,
		handleSubmit,
		setValue,
		getValues,
		reset,
		watch,
		formState: { errors, isDirty, dirtyFields },
	} = useForm<ListingSearchFiltersFormData>({ defaultValues: defaultFormData });
	const [resetCounter, setResetCounter] = useState(0);
	const [isFormReset, setIsFormReset] = useState(false);

	const onFormSubmit = (data: ListingSearchFiltersFormData) => {
		const changedData = getFilteredObject([...Object.keys(dirtyFields), ...Object.keys(defaultFormData || {})], data as Record<string, unknown>);
		onSubmit?.(changedData);
		setIsFormReset(false);
	}

	const handleSortBySelect = (value: ListingSortFilter) => {
		const sortType = getValues('sortType') || 'desc';
		setValue('sortBy', value, { shouldDirty: true });
		setValue('sortType', sortType, { shouldDirty: true });
	};

	const handleSortTypeToggle = () => {
		const currentSortOrder = getValues('sortType');
		const newOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';
		setValue('sortType', newOrder, { shouldDirty: true });
	};

	const onResetFilters = () => {
		reset(resetState);
		onReset?.();
		setResetCounter(c => c + 1);
		setIsFormReset(true);
	};

	const displayResetFilters = isDirty || !deepEqual(defaultFormData, resetState);
	const shouldEnableSubmit = isDirty || isFormReset;

	function renderQuery() {
		return (
			<FormControl isInvalid={!!errors.query}>
				<FormLabel fontSize="sm" htmlFor="name">Query</FormLabel>
				<Controller
					control={control}
					name="query"
					render={() => (
						<SearchBar key={`search-bar-${resetCounter}`} onChange={(val) => setValue('query', val, { shouldDirty: val.length > 0 })} defaultValue={defaultFormData?.query} />
					)} />
				<FormErrorMessage>
					{errors.query && errors.query.message}
				</FormErrorMessage>
			</FormControl>
		);
	}

	function renderTypeSection() {
		return (
			<FormControl isInvalid={!!errors.type}>
				<FormLabel htmlFor="type" fontSize="sm">Types</FormLabel>
				<Controller
					control={control}
					name="type"
					render={() => (
						<CheckboxGroup key={`type-checkbox-${resetCounter}`} defaultValue={defaultFormData?.type || []} onChange={(selectedVals => {
							if (selectedVals.length === 0) {
								setValue('type', undefined, { shouldDirty: true });
							} else {
								setValue('type', selectedVals as string[], { shouldDirty: true });
							}
						})}>
							<HStack gap={4} padding={2}>
								{[ListingType.ORIGINAL, ListingType.SET].map((itemType) => (
									<Checkbox value={itemType} key={`typeCheckbox_${itemType}`}>
										<Text fontSize={'sm'}>{itemType}</Text>
									</Checkbox>
								))}
							</HStack>
						</CheckboxGroup>
					)} />
				<FormErrorMessage>
					{errors.type && errors.type.message}
				</FormErrorMessage>
			</FormControl>
		);
	}

	function renderProcessingStatusSection() {
		return (
			<FormControl isInvalid={!!errors.type}>
				<FormLabel htmlFor="processingStatus" fontSize="sm">Show Only</FormLabel>
				<Controller
					control={control}
					name="type"
					render={() => (
						<CheckboxGroup key={`status-checkbox-${resetCounter}`} defaultValue={defaultFormData?.processingStatus || []} onChange={(selectedVals => {
							if (selectedVals.length === 0) {
								setValue('processingStatus', undefined, { shouldDirty: true });
							} else {
								setValue('processingStatus', selectedVals as string[], { shouldDirty: true });
							}
						})}>
							<HStack gap={4} padding={2}>
								{[ListingStatus.PENDING, ListingStatus.APPROVED].map((listingStatus) => (
									<Checkbox value={listingStatus} key={`processingStatusCheckbox_${listingStatus}`}>
										<Text fontSize={'sm'}>
											{listingStatus === ListingStatus.PENDING ? 'Untagged' : 'Tagged'} Listings
										</Text>
									</Checkbox>
								))}
							</HStack>
						</CheckboxGroup>
					)} />
				<FormErrorMessage>
					{errors.processingStatus && errors.processingStatus.message}
				</FormErrorMessage>
			</FormControl>
		);
	}

	function renderArtistDropdown() {
		return (
			<FormControl isInvalid={!!errors.artistId}>
				<FormLabel htmlFor="artistId" fontSize="sm">Artist</FormLabel>
				<Controller
					control={control}
					name="artistId"
					render={() => (
						<ArtistTable
							artists={artistList || []}
							key={`artist-dropdown-${resetCounter}`}
							selectedArtistIds={defaultFormData?.artistId || []}
							onSelect={(artistIds) => {
								if (artistIds.length === 0) {
									setValue('artistId', undefined, { shouldDirty: true });
								} else {
									setValue('artistId', artistIds, { shouldDirty: true })
								}
							}}
							multiSelect={true}
							disabled={!artistSelectionEnabled}
						/>
					)} />
				<FormErrorMessage>
					{errors.artistId && errors.artistId.message}
				</FormErrorMessage>
			</FormControl>
		);
	}


	function renderDateSlider() {
		const startingMax = defaultFormData?.lt?.date ? new Date(defaultFormData?.lt?.date).getFullYear() : new Date().getFullYear();
		let startingMin = 1980;
		if (defaultFormData?.gt?.date) {
			const savedDate = new Date(defaultFormData?.gt?.date);
			savedDate.setDate(savedDate.getDate() + 1);
			startingMin = savedDate.getFullYear();
		}
		return (
			<FormControl isInvalid={!!errors.gt?.date || !!errors.lt?.date}>
				<FormLabel htmlFor="date" fontSize="sm">Sale Date</FormLabel>
				<Controller
					control={control}
					name="date"
					render={() => (
						<DateSlider
							key={`date-slider-${resetCounter}`}
							dropdownDisplay={false}
							rangeMin={1980}
							startingMaxDateYear={startingMax}
							startingMinDateYear={startingMin}
							onYearRangeSelect={(minYear, maxYear) => {
								setValue('gt.date', `${minYear}-01-01`, { shouldDirty: true });
								setValue('lt.date', `${maxYear + 1}-01-01`, { shouldDirty: true });
							}}
						/>
					)} />
			</FormControl>
		);
	}

	function renderPriceSlider() {
		const releasePriceMax = 50000
		return (
			<FormControl isInvalid={!!errors.gt?.rawPrice || !!errors.lt?.rawPrice}>
				<FormLabel htmlFor="rawPrice" fontSize="sm">Sale Price</FormLabel>
				<Controller
					control={control}
					name="rawPrice"
					render={() => (
						<NumberSlider
							key={`price-slider-${resetCounter}`}
							dropdownDisplay={false}
							rangeMax={releasePriceMax}
							startingMin={defaultFormData?.gt?.rawPrice}
							startingMax={defaultFormData?.lt?.rawPrice}
							onNumberRangeSelect={(minPrice, maxPrice) => {
								setValue('gt.rawPrice', minPrice, { shouldDirty: true });
								if (maxPrice < releasePriceMax) {
									setValue('lt.rawPrice', maxPrice, { shouldDirty: true });
								} else {
									const oldLtData = getValues('lt');
									if (oldLtData && 'rawPrice' in oldLtData) {
										delete oldLtData['rawPrice'];
										setValue('lt', oldLtData);
									}
								}
							}}
						/>
					)} />
			</FormControl>
		);
	}

	function renderSortOptions() {
		const selectedSortBy = watch('sortBy');
		const sortType = watch('sortType');

		return (
			<Stack>
				<FormLabel fontSize="sm">Sorting</FormLabel>
				<SortDropdown<ListingSortFilter>
					options={ListingSortFilter}
					selectedSortBy={selectedSortBy}
					sortType={sortType}
					onSortBySelect={handleSortBySelect}
					onSortTypeToggle={handleSortTypeToggle}
				/>
			</Stack>
		);
	}

	return (
		<form onSubmit={handleSubmit(onFormSubmit)}>
			<Stack spacing={8}>
				{renderQuery()}
				{renderArtistDropdown()}
				{renderDateSlider()}
				{renderPriceSlider()}
				{renderTypeSection()}
				{renderProcessingStatusSection()}
				{renderSortOptions()}
				<HStack alignItems={'center'} justifyContent={'center'} width={'100%'}>
					{displayResetFilters && (
						<Button size={'md'} leftIcon={<FcClearFilters />} variant='outline' maxWidth={'fit-content'} onClick={onResetFilters}>
							Reset Filters
						</Button>
					)}
					{displayResetFilters && <Spacer />}
					<ActionButton type='submit' text={'Search'} loading={submitLoading} size={'md'} disabled={!shouldEnableSubmit} />
				</HStack>
			</Stack>
		</form>
	);
}
