import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { HomeFeedData } from 'models/Content/types';

export class HomeSelectors {
  public static getHomeFeedData(state: ApplicationState): HomeFeedData | undefined {
    return RootSelectors.getHomeState(state).homeFeedData;
  }

	public static hasHomeFeedData(state: ApplicationState): boolean {
		return !!RootSelectors.getHomeState(state).homeFeedData;
	}

  public static hasFetchedHomeFeedData(state: ApplicationState): boolean {
		return !!RootSelectors.getHomeState(state).homeFeedData || !!RootSelectors.getHomeState(state).error.feedContentFetch;
	}

  public static getHomeFeedDataLoading(state: ApplicationState): boolean {
    return RootSelectors.getHomeState(state).loading.feedContentFetch;
  }

  public static getHomeFeedDataError(state: ApplicationState): Error | undefined {
    return RootSelectors.getHomeState(state).error.feedContentFetch;
  }
}
