import _ from 'lodash';

export function chunkArray<T>(inputArray: T[], chunk: number): T[][] {
  return inputArray.reduce((resultArray: T[][], item: T, index: number) => {
    const chunkIndex = Math.floor(index / chunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = [];
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, []);
}

export function getSampleSizedArray<T>(inputArray: T[], sampleSize: number): T[] {
  return _.sampleSize(inputArray, sampleSize);
}


export function convertToArray<T>(input: T[] | T): T[] {
  return Array.isArray(input) ? input : [input];
}
