import { useState } from 'react';
import {
	Heading,
	Stack,
	Icon
} from '@chakra-ui/react';
import ItemGrid from '../../Item/ItemGrid/ItemGrid';
import { ItemGridSelectionType } from '../../Item/ItemGrid/ItemGrid';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import BasicListingCard from '../../Listing/BasicListingCard/BasicListingCard';
import Wizard from '../Wizard';
import { ItemModel, ListingModel } from 'models';
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";


interface ListingItemSuggestionWizardProps {
	listing: ListingModel;
	items: ItemModel[];
	isLoading?: boolean;
	onSubmit?: (listing: ListingModel, item: ItemModel) => void;
	onRetry?: () => void;
	hasErrors?: boolean;
}

export default function ListingItemSuggestionWizard({
	listing,
	items,
	isLoading = false,
	onSubmit = () => { },
	onRetry = () => { },
	hasErrors
}: ListingItemSuggestionWizardProps) {
	const [selectedItem, setSelectedItem] = useState<ItemModel | undefined>(undefined);
	const onWizardSubmit = () => {
		if (selectedItem) {
			onSubmit(listing, selectedItem);
		}
	}

	function renderListingItemSuggestionWizardItemSelection() {
		return (
			<ItemGrid items={items} isLoading={isLoading} gridMaxHeight={240} gridDisplay={true} showFiltersLabel={false} gridSelection={ItemGridSelectionType.SINGLE} onItemCardClick={setSelectedItem} defaultSelectedItems={selectedItem ? [selectedItem] : []} />
		);
	}

	function renderListingItemSuggestionWizardSelectionConfirmation() {
		return (
			<Stack>
				<Heading fontSize={'sm'} textAlign={'center'}>Confirm Your Selection</Heading>
				{selectedItem && <BasicItemCard item={selectedItem} />}
			</Stack>
		);
	}

	function renderWizardFinishingSuccessStep() {
		if (hasErrors) {
			return (
				<Stack gap={4} alignItems={'center'}>
					<Icon as={AiFillCloseCircle} color={'red.500'} w={10} h={10} />
					<Heading size={'xs'} textAlign={'center'}>
						Your Suggestion Could Not Be Submitted.<br />Please Try Again
					</Heading>
				</Stack>
			);
		} else {
			return (
				<Stack gap={4} alignItems={'center'}>
					<Icon as={AiFillCheckCircle} color={'green.500'} w={10} h={10} />
					<Heading size={'xs'}>
						Your Suggestion Has Been Submitted
					</Heading>
					{selectedItem && <BasicItemCard item={selectedItem} />}
				</Stack>
			);
		}
	}

	const wizardSteps = [
		{ label: 'Select Item', content: renderListingItemSuggestionWizardItemSelection(), requiresValidation: true, isValidated: !!selectedItem },
		{ label: 'Confirm', content: renderListingItemSuggestionWizardSelectionConfirmation() },
	];

	return (
		<Stack gap={2} width={'100%'}>
			<BasicListingCard listing={listing} />
			<Wizard steps={wizardSteps} finishStep={renderWizardFinishingSuccessStep()} onSubmit={onWizardSubmit} loading={isLoading} />
		</Stack>
	);

};
