import { Moment } from 'moment';

export function processDateSort(a: Moment | null, b: Moment | null, ascending?: boolean): number {
  if (a === null) {
    return 1;
  }

  if (b === null) {
    return -1;
  }

  if (a && b) {
    if (a.isSame(b)) {
      return 0;
    }

    if (ascending) {
      return a.isBefore(b) ? 1 : -1;
    } else {
      return a.isBefore(b) ? -1 : 1;
    }
  }

  return 0;
}


export function processNumberSort(a: number, b: number, ascending?: boolean): number {
  if (a === 0) {
    return 1;
  }

  if (b === 0) {
    return -1;
  }

  if (a && b) {
    if (a === b) {
      return 0;
    }

    if (ascending) {
      return a < b ? -1 : 1;
    } else {
      return a < b ? 1 : -1;
    }
  }

  return 0;
}
