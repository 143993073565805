import {
  Box,
  Text,
  Heading,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  useColorModeValue
} from '@chakra-ui/react';

export interface InfoAccordionProps {
  header: string;
  content: string;
  isOpen?: boolean
}

export enum InfoAccordionStylingConfig {
  BORDER_COLOR_LIGHT = 'black',
  BORDER_COLOR_DARK = '#3f434c'
}

export default function InfoAccordion({ header, content, isOpen = false }: InfoAccordionProps) {
  const defaultIndex: number = isOpen ? 0 : 1;
  return (
    <Accordion defaultIndex={[defaultIndex]} allowToggle border="1px solid" borderColor={useColorModeValue(InfoAccordionStylingConfig.BORDER_COLOR_LIGHT, InfoAccordionStylingConfig.BORDER_COLOR_DARK)}>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex='1' textAlign='center'>
              <Heading fontSize="md">{header}</Heading>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4} borderTop="2px solid" borderTopColor={useColorModeValue(InfoAccordionStylingConfig.BORDER_COLOR_LIGHT, InfoAccordionStylingConfig.BORDER_COLOR_DARK)}>
          <Text fontSize="sm">{content}</Text>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );

}
