import React, { useState, useEffect } from 'react';
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Box,
  Text,
  RangeSlider,
  RangeSliderTrack,
  RangeSliderFilledTrack,
  RangeSliderThumb
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';

export interface DateSliderProps {
    onYearRangeSelect?: (minYear: number, maxYear: number) => void;
    rangeMin?: number;
    rangeMax?: number;
    startingMinDateYear?: number;
    startingMaxDateYear?: number;
    dropdownDisplay?: boolean;
}

export default function DateSlider({ rangeMin = 1980, rangeMax = new Date().getFullYear(), startingMinDateYear = 1980, startingMaxDateYear = new Date().getFullYear(), onYearRangeSelect = () => {}, dropdownDisplay = true}: DateSliderProps) {
  const [year, setYear] = useState(startingMaxDateYear);
  const [minYear, setMinYear] = useState(startingMinDateYear);
  const [hasFilteredRange, setHasFilteredRange] = useState(false);

  const handleRangeSliderChange = (value: number[]) => {
    setHasFilteredRange(true);
    setMinYear(value[0]);
    setYear(value[1]);
  }

  useEffect(() => {
    setMinYear(startingMinDateYear);
  }, [startingMinDateYear]);
  
  useEffect(() => {
    if (hasFilteredRange) {
      onYearRangeSelect(minYear, year)
    }
  }, [year, minYear]);

  function renderRangeSlider() {
    return (
        <Box width={dropdownDisplay ? "200px" : "100%"} paddingX={2}>
          <RangeSlider
            min={rangeMin}
            max={rangeMax}
            defaultValue={[rangeMin, rangeMax]}
            value={[minYear, year]}
            step={1}
            onChange={handleRangeSliderChange}
          >
            <RangeSliderTrack>
              <RangeSliderFilledTrack />
            </RangeSliderTrack>
            <RangeSliderThumb boxSize={6} index={0} />
            <RangeSliderThumb boxSize={6} index={1} />
          </RangeSlider>
          <Text mt={2} textAlign="center" fontSize={'sm'}>
              {`${minYear} - ${year}`}
          </Text>
        </Box>
    );
  }

  if (dropdownDisplay) {
    return (
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} variant="outline" rightIcon={<ChevronDownIcon />}>
          Year
        </MenuButton>
        <MenuList>
          <MenuItem>
            {renderRangeSlider()}
          </MenuItem>
        </MenuList>
      </Menu>
    );
  } else {
    return renderRangeSlider();
  }
}