import { Controller, useForm } from 'react-hook-form';
import {
	Stack,
	FormControl,
	FormLabel,
	FormErrorMessage,
	Checkbox,
	CheckboxGroup,
	HStack,
	Flex,
	Text
} from '@chakra-ui/react';
import { getFilteredObject } from 'utils/object';
import ArtistTable from '../../Artist/ArtistTable/ArtistTable';
import MaterialDropdown from '../../Material/MaterialDropdown/MaterialDropdown';
import SearchBar from '../../SearchBar/SearchBar';
import ActionButton from '../../Button/ActionButton';
import DateSlider from '../../DateSlider/DateSlider';
import NumberSlider from '../../NumberSlider/NumberSlider';
import SortDropdown from '../../SortDropdown/SortDropdown';
import { ArtistModel, MaterialModel } from 'models';
import { ItemType } from 'models/Item/types';

export interface ItemSearchFiltersFormProps {
	artistList?: ArtistModel[];
	materialList?: MaterialModel[];
	defaultFormData?: ItemSearchFiltersFormData;
	onSubmit?: (data: ItemSearchFiltersFormData) => void;
	submitLoading?: boolean;
}

export interface ItemSearchFiltersFormRangeEligibleData {
	date?: string;
	marketSummary?: {
		estimatedValue?: number;
	}
	releaseInfo?: {
		convertedPrice?: number;
	}
	editionInfo?: {
		size?: number
	}
}

export interface ItemSearchFiltersFormData extends ItemSearchFiltersFormRangeEligibleData {
	query?: string;
	artistId?: string[];
	type?: string[];
	materialIds?: string[];
	handTreated?: boolean;
	gt?: ItemSearchFiltersFormRangeEligibleData;
	lt?: ItemSearchFiltersFormRangeEligibleData;
	sortBy?: ItemSortFilter;
	sortType?: 'asc' | 'desc';
}

export enum ItemSortFilter {
	MARKET_VALUE = 'market_summary.estimated_value',
	MARKET_VOLUME = 'market_summary.total_sales',
	LAST_SALE_DATE = 'market_summary.last_sale_date',
	BIGGEST_MARKET_CHANGE = 'market_summary.last_sale_percentage_change',
	RELEASE_YEAR = 'release_info.date',
	EDITION_SIZE = 'edition_info.size',
}

export default function ItemSearchFiltersForm({ artistList, materialList, defaultFormData, onSubmit, submitLoading }: ItemSearchFiltersFormProps) {
	const {
		control,
		handleSubmit,
		register,
		watch,
		setValue,
		getValues,
		formState: { errors, isDirty, dirtyFields },
	} = useForm<ItemSearchFiltersFormData>({ defaultValues: defaultFormData });

	const onFormSubmit = (data: ItemSearchFiltersFormData) => {
		const changedData = getFilteredObject([...Object.keys(dirtyFields), ...Object.keys(defaultFormData || {})], data as Record<string, unknown>);
		onSubmit?.(changedData);
	}

	const handleSortBySelect = (value: ItemSortFilter) => {
		setValue('sortBy', value, { shouldDirty: true });
	};

	const handleSortTypeToggle = () => {
		const currentSortOrder = getValues('sortType');
		const newOrder = currentSortOrder === 'asc' ? 'desc' : 'asc';
		setValue('sortType', newOrder, { shouldDirty: true });
	};

	function renderQuery() {
		return (
			<FormControl isInvalid={!!errors.query}>
				<FormLabel fontSize="sm" htmlFor="name">Query</FormLabel>
				<Controller
					control={control}
					name="query"
					render={() => (
						<SearchBar onChange={(val) => setValue('query', val, { shouldDirty: val.length > 0 })} defaultValue={defaultFormData?.query} />
					)} />
				<FormErrorMessage>
					{errors.query && errors.query.message}
				</FormErrorMessage>
			</FormControl>
		);
	}

	function renderTypeSection() {
		return (
			<FormControl isInvalid={!!errors.type}>
				<FormLabel htmlFor="type" fontSize="sm">Types</FormLabel>
				<Controller
					control={control}
					name="type"
					render={() => (
						<CheckboxGroup defaultValue={defaultFormData?.type || []} onChange={(selectedVals => setValue('type', selectedVals as string[], { shouldDirty: true }))}>
							<HStack gap={4} padding={2}>
								{[ItemType.SCULPTURE, ItemType.PRINT, ItemType.MISC].map((itemType) => (
									<Checkbox value={itemType} key={`typeCheckbox_${itemType}`}>
										<Text fontSize={'sm'}>{itemType}</Text>
									</Checkbox>
								))}
							</HStack>
						</CheckboxGroup>
					)} />
				<FormErrorMessage>
					{errors.type && errors.type.message}
				</FormErrorMessage>
			</FormControl>
		);
	}

	function renderArtistDropdown() {
		return (
			<FormControl isInvalid={!!errors.artistId}>
				<FormLabel htmlFor="artistId" fontSize="sm">Artist</FormLabel>
				<Controller
					control={control}
					name="artistId"
					render={() => (
						<ArtistTable
							artists={artistList || []}
							selectedArtistIds={defaultFormData?.artistId || []}
							onSelect={(artistIds) => setValue('artistId', artistIds, { shouldDirty: true })}
							multiSelect={true}
						/>
					)} />
				<FormErrorMessage>
					{errors.artistId && errors.artistId.message}
				</FormErrorMessage>
			</FormControl>
		);
	}

	function renderMaterialsDropdown() {
		return (
			<FormControl isInvalid={!!errors.materialIds}>
				<FormLabel htmlFor="materialIds" fontSize="sm">Materials</FormLabel>
				<Controller
					control={control}
					name="materialIds"
					render={() => (
						<MaterialDropdown
							materials={materialList || []}
							selectedMaterialIds={watch('materialIds')}
							onSelect={(materials) => setValue('materialIds', materials, { shouldDirty: true })}
						/>
					)} />
				<FormErrorMessage>
					{errors.materialIds && errors.materialIds.message}
				</FormErrorMessage>
			</FormControl>
		);
	}

	function renderDateSlider() {
		const startingMax = defaultFormData?.lt?.date ? new Date(defaultFormData?.lt?.date).getFullYear() : new Date().getFullYear();
		let startingMin = 1980;
		if (defaultFormData?.gt?.date) {
			const savedDate = new Date(defaultFormData?.gt?.date);
			savedDate.setDate(savedDate.getDate() + 1);
			startingMin = savedDate.getFullYear();
		}
		return (
			<FormControl isInvalid={!!errors.gt?.date || !!errors.lt?.date}>
				<FormLabel htmlFor="date" fontSize="sm">Release Date</FormLabel>
				<Controller
					control={control}
					name="date"
					render={() => (
						<DateSlider
							dropdownDisplay={false}
							rangeMin={1980}
							startingMaxDateYear={startingMax}
							startingMinDateYear={startingMin}
							onYearRangeSelect={(minYear, maxYear) => {
								setValue('gt.date', `${minYear}-01-01`, { shouldDirty: true });
								setValue('lt.date', `${maxYear + 1}-01-01`, { shouldDirty: true });
							}}
						/>
					)} />
			</FormControl>
		);
	}

	function renderEstimatedValueSlider() {
		const estimatedValueMax = 50000
		return (
			<FormControl isInvalid={!!errors.gt?.marketSummary?.estimatedValue || !!errors.lt?.marketSummary?.estimatedValue}>
				<FormLabel htmlFor="estimatedValue" fontSize="sm">Estimated Value</FormLabel>
				<Controller
					control={control}
					name="marketSummary.estimatedValue"
					render={() => (
						<NumberSlider
							dropdownDisplay={false}
							rangeMax={estimatedValueMax}
							startingMin={defaultFormData?.gt?.marketSummary?.estimatedValue}
							startingMax={defaultFormData?.lt?.marketSummary?.estimatedValue}
							onNumberRangeSelect={(minPrice, maxPrice) => {
								setValue('gt.marketSummary.estimatedValue', minPrice, { shouldDirty: true });
								if (maxPrice < estimatedValueMax) {
									setValue('lt.marketSummary.estimatedValue', maxPrice, { shouldDirty: true });
								} else {
									const oldLtData = getValues('lt');
									if (oldLtData && 'marketSummary' in oldLtData) {
										delete oldLtData['marketSummary'];
										setValue('lt', oldLtData);
									}
								}
							}}
						/>
					)} />
			</FormControl>
		);
	}

	function renderEditionSizeSlider() {
		const editionSizeMax = 500
		return (
			<FormControl isInvalid={!!errors.gt?.editionInfo?.size || !!errors.lt?.editionInfo?.size}>
				<FormLabel htmlFor="editionInfo.size" fontSize="sm">Edition Size</FormLabel>
				<Controller
					control={control}
					name="editionInfo.size"
					render={() => (
						<NumberSlider
							dropdownDisplay={false}
							isDollarValue={false}
							rangeMax={editionSizeMax}
							step={50}
							startingMin={defaultFormData?.gt?.editionInfo?.size}
							startingMax={defaultFormData?.lt?.editionInfo?.size || editionSizeMax}
							onNumberRangeSelect={(minEdition, maxEdition) => {
								setValue('gt.editionInfo.size', minEdition, { shouldDirty: true });
								if (maxEdition < editionSizeMax) {
									setValue('lt.editionInfo.size', maxEdition, { shouldDirty: true });
								} else {
									const oldLtData = getValues('lt');
									if (oldLtData && 'editionInfo' in oldLtData) {
										delete oldLtData['editionInfo'];
										setValue('lt', oldLtData);
									}
								}
							}}
						/>
					)} />
			</FormControl>
		);
	}

	function renderReleasePriceSlider() {
		const releasePriceMax = 50000
		return (
			<FormControl isInvalid={!!errors.gt?.releaseInfo?.convertedPrice || !!errors.lt?.releaseInfo?.convertedPrice}>
				<FormLabel htmlFor="releasePrice" fontSize="sm">Release Price</FormLabel>
				<Controller
					control={control}
					name="releaseInfo.convertedPrice"
					render={() => (
						<NumberSlider
							dropdownDisplay={false}
							rangeMax={releasePriceMax}
							startingMin={defaultFormData?.gt?.releaseInfo?.convertedPrice}
							startingMax={defaultFormData?.lt?.releaseInfo?.convertedPrice}
							onNumberRangeSelect={(minPrice, maxPrice) => {
								setValue('gt.releaseInfo.convertedPrice', minPrice, { shouldDirty: true });
								if (maxPrice < releasePriceMax) {
									setValue('lt.releaseInfo.convertedPrice', maxPrice, { shouldDirty: true });
								} else {
									const oldLtData = getValues('lt');
									if (oldLtData && 'releaseInfo' in oldLtData) {
										delete oldLtData['releaseInfo'];
										setValue('lt', oldLtData);
									}
								}
							}}
						/>
					)} />
			</FormControl>
		);
	}

	function renderFinishesSection() {
		return (
			<FormControl>
				<FormLabel fontSize="sm">Finishing Details</FormLabel>
				<Flex direction="row" alignItems="center" gap={2} marginTop={2} padding={2}>
					<Checkbox {...register('handTreated')}>
						<Text fontSize={'sm'}>Hand Finished</Text>
					</Checkbox>
				</Flex>
				<FormErrorMessage>
					{errors.handTreated && errors.handTreated.message}
				</FormErrorMessage>
			</FormControl>
		);
	}

	function renderSortOptions() {
		const selectedSortBy = watch('sortBy');
		const sortType = watch('sortType');

		return (
			<Stack>
				<FormLabel fontSize="sm">Sorting</FormLabel>
				<SortDropdown<ItemSortFilter>
					options={ItemSortFilter}
					selectedSortBy={selectedSortBy}
					sortType={sortType}
					onSortBySelect={handleSortBySelect}
					onSortTypeToggle={handleSortTypeToggle}
				/>
			</Stack>
		);
	}

	return (
		<form onSubmit={handleSubmit(onFormSubmit)}>
			<Stack spacing={8}>
				{renderQuery()}
				{renderArtistDropdown()}
				{renderTypeSection()}
				{renderEstimatedValueSlider()}
				{renderDateSlider()}
				{renderReleasePriceSlider()}
				{renderEditionSizeSlider()}
				{renderSortOptions()}
				<Stack alignItems="center">
					<ActionButton type='submit' text={'Search'} loading={submitLoading} size={'md'} disabled={!isDirty} />
				</Stack>
			</Stack>
		</form>
	);
}
