import {
  Box,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

interface StatsCardProps {
  title?: string;
  stat?: string;
  icon?: ReactNode;
}

export default function StatsCard({ title = '', stat = '', icon = null }: StatsCardProps) {
  return (
    <Stat
      px={{ base: 2, md: 4 }}
      py={'5'}
      shadow={'xl'}
      border={'1px solid'}
      borderColor={useColorModeValue('gray.800', 'gray.500')}
      rounded={'lg'}>
      <Flex justifyContent={'space-between'}>
        <Box pl={{ base: 2, md: 4 }} textAlign="left">
          {!!title &&
            <StatLabel fontWeight={'medium'} paddingTop="4px" isTruncated>
              {title}
            </StatLabel>
          }
          {!!stat &&
            <StatNumber fontSize={'lg'} fontWeight={'medium'}>
              {stat}
            </StatNumber>
          }
        </Box>
        {!!icon &&
          <Box
            my={'auto'}
            color={useColorModeValue('gray.800', 'gray.200')}
            alignContent={'center'}>
            {icon}
          </Box>
        }
      </Flex>
    </Stat>
  );
}
