import { SalesVenueModel } from 'models';

export enum SalesVenueActionTypes {
  GET_SALES_VENUE_BY_ID = 'SALES_VENUE_GET_SALES_VENUE_BY_ID',
  GET_SALES_VENUE_BY_ID_SUCCESS = 'SALES_VENUE_GET_SALES_VENUE_BY_ID_SUCCESS',
  GET_SALES_VENUE_BY_ID_FAILURE = 'SALES_VENUE_GET_SALES_VENUE_BY_ID_FAILURE',

  GET_ALL_SALES_VENUES = 'GET_ALL_SALES_VENUES',
  GET_ALL_SALES_VENUES_SUCCESS = 'GET_ALL_SALES_VENUES_SUCCESS',
  GET_ALL_SALES_VENUES_FAILURE = 'GET_ALL_SALES_VENUES_FAILURE',
};

export interface SalesVenueActionPayload {
  id?: string;
  salesVenue?: SalesVenueModel;
  salesVenueList?: SalesVenueModel[];
};

export interface SalesVenueAction {
  type: SalesVenueActionTypes;
  payload?: SalesVenueActionPayload;
};

export interface SalesVenueStateLoading {
  item: boolean;
  list: boolean;
};

export interface SalesVenueState {
  salesVenue: SalesVenueModel;
  salesVenueList: SalesVenueModel[];
  error: Error | undefined;
  loading: SalesVenueStateLoading;
};
