import { Loop, LoopReducer } from 'redux-loop';
import { ItemHandlers } from './handlers';
import { ItemState, ItemAction, ItemActionTypes } from './types';
import { defaultState } from './consts';

export const itemReducer: LoopReducer<ItemState, ItemAction> = (
  state: ItemState = defaultState,
  action: ItemAction
): ItemState | Loop<ItemState, ItemAction> => {
  switch (action.type) {
    case ItemActionTypes.GET_ITEM_BY_ID:
      return ItemHandlers.handleGetItemById(state, action);
    case ItemActionTypes.GET_ITEM_BY_ID_SUCCESS:
      return ItemHandlers.handleGetItemByIdSuccess(state, action);
    case ItemActionTypes.GET_ITEM_BY_ID_FAILURE:
      return ItemHandlers.handleGetItemByIdFailure(state);
    case ItemActionTypes.GET_ITEM_LISTINGS:
      return ItemHandlers.handleGetItemListings(state, action);
    case ItemActionTypes.GET_ITEM_LISTINGS_SUCCESS:
      return ItemHandlers.handleGetItemListingsSuccess(state, action);
    case ItemActionTypes.GET_ITEM_LISTINGS_FAILURE:
      return ItemHandlers.handleGetItemListingsFailure(state);
    case ItemActionTypes.GET_ITEM_MARKET:
      return ItemHandlers.handleGetItemMarket(state, action);
    case ItemActionTypes.GET_ITEM_MARKET_SUCCESS:
      return ItemHandlers.handleGetItemMarketSuccess(state, action);
    case ItemActionTypes.GET_ITEM_MARKET_FAILURE:
      return ItemHandlers.handleGetItemMarketFailure(state);
    case ItemActionTypes.GET_ITEM_ARTIST:
      return ItemHandlers.handleGetItemArtist(state, action);
    case ItemActionTypes.GET_ITEM_ARTIST_SUCCESS:
      return ItemHandlers.handleGetItemArtistSuccess(state, action);
    case ItemActionTypes.GET_ITEM_ARTIST_FAILURE:
      return ItemHandlers.handleGetItemArtistFailure(state);
    case ItemActionTypes.SEARCH_ITEM_BY_QUERY:
      return ItemHandlers.handleSearchItemByQuery(state, action);
    case ItemActionTypes.SEARCH_ITEM_BY_QUERY_SUCCESS:
      return ItemHandlers.handleSearchItemByQuerySuccess(state, action);
    case ItemActionTypes.SEARCH_ITEM_BY_QUERY_FAILURE:
      return ItemHandlers.handleSearchItemByQueryFailure(state);
    case ItemActionTypes.SEARCH_ITEM:
      return ItemHandlers.handleSearchItem(state, action);
    case ItemActionTypes.SEARCH_ITEM_SUCCESS:
      return ItemHandlers.handleSearchItemSuccess(state, action);
    case ItemActionTypes.SEARCH_ITEM_FAILURE:
      return ItemHandlers.handleSearchItemFailure(state);
    case ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS:
      return ItemHandlers.handleGetUserFollowingArtistsItems(state, action);
    case ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS_SUCCESS:
      return ItemHandlers.handleGetUserFollowingArtistsItemsSuccess(state, action);
    case ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS_FAILURE:
      return ItemHandlers.handleGetUserFollowingArtistsItemsFailure(state);
    case ItemActionTypes.GET_ITEM_SUGGESTIONS:
      return ItemHandlers.handleGetItemSuggestions(state, action);
    case ItemActionTypes.GET_ITEM_SUGGESTIONS_SUCCESS:
      return ItemHandlers.handleGetItemSuggestionsSuccess(state, action);
    case ItemActionTypes.GET_ITEM_SUGGESTIONS_FAILURE:
      return ItemHandlers.handleGetItemSuggestionsFailure(state);
    case ItemActionTypes.GET_ALL_ITEMS:
      return ItemHandlers.handleGetAllItems(state, action);
    case ItemActionTypes.GET_ALL_ITEMS_SUCCESS:
      return ItemHandlers.handleGetAllItemsSuccess(state, action);
    case ItemActionTypes.GET_ALL_ITEMS_FAILURE:
      return ItemHandlers.handleGetAllItemsFailure(state);
    default:
      return state;
  }
}
