import { useState } from 'react';
import {
  InputGroup,
  Input,
  InputLeftElement,
  Flex,
  Select
} from '@chakra-ui/react';
import { convertDimensions } from 'utils/dimensions';
import { MeasurementData, MeasurementUnit } from 'models/types';

interface DimensionsInputProps {
  defaultData?: MeasurementData | undefined;
  onChange?: (data: MeasurementData) => void;
}

export default function DimensionsInput({ onChange = () => {}, defaultData }: DimensionsInputProps) {
  const [dimensions, setDimensions] = useState<MeasurementData>(defaultData || {
    l: 0,
    w: 0,
    h: 0
  });
  const [unit, setUnit] = useState<MeasurementUnit>(MeasurementUnit.CM);

  const onUnitChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    if (value === MeasurementUnit.IN && unit === MeasurementUnit.CM) {
      if (dimensions.l || dimensions.h || dimensions.w) {
        const newDimensions = convertDimensions(dimensions, MeasurementUnit.IN);
        setDimensions(newDimensions);
        onChange(newDimensions);
      }

      setUnit(MeasurementUnit.IN);
    } else if (value === MeasurementUnit.CM && unit === MeasurementUnit.IN) {
      if (dimensions.l || dimensions.h || dimensions.w) {
        const newDimensions = convertDimensions(dimensions, MeasurementUnit.CM);
        setDimensions(newDimensions);
        onChange(newDimensions);
      }
      setUnit(MeasurementUnit.CM);
    }
  }

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>, key: 'l' | 'w' | 'h') => {
    const value = Number(e.target.value);
    const newDimensions: MeasurementData = {
      h: dimensions.h,
      w: dimensions.w,
      l: dimensions.l
    };
    newDimensions[key] = value;
    setDimensions(newDimensions);
    onChange(newDimensions);
  }

  function renderInputIcon(letter: string) {
    return (
      <Flex
        w={4}
        h={4}
        align={'center'}
        justify={'center'}
        rounded={'full'}
        border="1px"
        color='gray.500'
        fontSize="xs">
        {letter}
      </Flex>
    );
  }

  return (
    <Flex direction={'row'} gap={2}>
      <InputGroup>
        <InputLeftElement
          pointerEvents='none'
          children={renderInputIcon('l')}
        />
        <Input onChange={(e) => onInputChange(e, 'l')} fontSize='sm' value={dimensions.l || ''} type='number' placeholder='Length' />
      </InputGroup>
      <InputGroup>
        <InputLeftElement
          pointerEvents='none'
          children={renderInputIcon('w')}
        />
        <Input onChange={(e) => onInputChange(e, 'w')} fontSize='sm' value={dimensions.w || ''} type='number' placeholder='Width' />
      </InputGroup>
      <InputGroup>
        <InputLeftElement
          pointerEvents='none'
          children={renderInputIcon('h')}
        />
        <Input onChange={(e) => onInputChange(e, 'h')} fontSize='sm' value={dimensions.h || ''} type='number' placeholder='Height' />
      </InputGroup>
      <Select maxWidth="20%" fontSize="sm" onChange={onUnitChange}>
        <option>cm</option>
        <option>in</option>
      </Select>
    </Flex>
  );
}
