import { useState } from 'react';
import { Checkbox, Flex, Box, Table, Tbody, Tr, Td } from '@chakra-ui/react';
import { ArtistModel } from 'models/Artist/Artist';
import BasicArtistCard from '../BasicArtistCard/BasicArtistCard';
import SearchBar from '../../SearchBar/SearchBar';

export interface ArtistTableProps {
  artists: ArtistModel[];
  onSelect: (artistIds: string[]) => void;
  selectedArtistIds?: string[];
  disabled?: boolean;
  multiSelect?: boolean;
}

export default function ArtistTable({
  artists,
  onSelect,
  selectedArtistIds = [],
  disabled = false,
  multiSelect = false
}: ArtistTableProps) {
  const [filter, setFilter] = useState('');
  const [selectedArtists, setSelectedArtists] = useState<string[]>(selectedArtistIds);

  const filteredArtists = artists.filter(artist => 
    artist.name.toLowerCase().includes(filter.toLowerCase())
  ).sort((a, b) => 
    Number(selectedArtists.includes(b.id)) - Number(selectedArtists.includes(a.id))
  );

  const toggleArtistSelection = (artistId: string) => {
    if (disabled) return;

    let updatedArtists;
    if (selectedArtists.includes(artistId)) {
      updatedArtists = selectedArtists.filter(id => id !== artistId);
    } else {
      if (multiSelect) {
        updatedArtists = [...selectedArtists, artistId];
      } else {
        updatedArtists = [artistId];
      }
    }

    setSelectedArtists(updatedArtists);
    onSelect(updatedArtists);
  };

  return (
    <Flex direction="column" width="100%">
      <Flex alignItems="center" marginBottom="4">
        <Box marginBottom={2}>
          {!disabled && <SearchBar onChange={setFilter} showResultBox={false} disabled={disabled} />}
        </Box>
      </Flex>
      <Table size="sm" width="100%">
        <Tbody maxHeight="200px" overflowY="scroll" display="block" width="100%">
          {filteredArtists.map((artist) => (
            <Tr key={artist.id} onClick={() => toggleArtistSelection(artist.id)} cursor={disabled ? 'not-allowed' : 'pointer'}>
              <Td onClick={(e) => e.stopPropagation()}>
                <Checkbox 
                  isChecked={selectedArtists.includes(artist.id)}
                  onChange={() => toggleArtistSelection(artist.id)}
                  isDisabled={disabled}
                  required={false}
                />
              </Td>
              <Td width={'100%'} padding={0}>
                <BasicArtistCard 
                  artist={artist} 
                  showBorder={false}
                  avatarSize="sm"
                  titleSize="sm"
                  disabled={disabled}
                  onClick={() => toggleArtistSelection(artist.id)}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Flex>
  );
}
