import { Cmd, loop, Loop } from 'redux-loop';
import { WishlistInternalActions } from './actions';
import { WishlistApiClient, UserApiClient } from 'api';
import { WishlistModel } from 'models';
import { WishlistActions } from './actions';
import { WishlistState, WishlistAction } from './types';
import { defaultState } from './consts';

export enum WishlistStateErrors {
  USER_FETCH = 'Unable to fetch user wishlist',
  ID_FETCH = 'Unable to fetch wishlist',
  ARTISTS_FETCH = 'Unable to fetch wishlist artists',
  MARKET_FETCH = 'Unable to fetch wishlist market',
  LISTINGS_FETCH = 'Unable to fetch wishlist listings',
  ADD_ITEM_TO_WISHLIST = 'Unable to add item to wishlist',
  REMOVE_ITEM_FROM_WISHLIST = 'Unable to remove item from wishlist',
}

export class WishlistHandlers {
  public static handleGetUserWishlist(state: WishlistState): Loop<WishlistState, WishlistAction> {
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        item: true
      }
    };

    return loop(
      newState,
      Cmd.run(UserApiClient.getWishlist, {
        args: [],
        successActionCreator: WishlistInternalActions.getUserWishlistSuccess,
        failActionCreator: WishlistInternalActions.getUserWishlistFailure,
      })
    );
  }

  public static handleGetUserWishlistSuccess(state: WishlistState, action: WishlistAction): WishlistState | Loop<WishlistState, WishlistAction> {
    const wishlist: WishlistModel = action.payload?.wishlist || defaultState.wishlist;

    return {
      ...state,
      wishlist,
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetUserWishlistFailure(state: WishlistState): WishlistState {
    return {
      ...state,
      error: new Error(WishlistStateErrors.USER_FETCH),
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetWishlistById(state: WishlistState, action: WishlistAction): Loop<WishlistState, WishlistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        item: true
      }
    };

    return loop(
      newState,
      Cmd.run(WishlistApiClient.getWishlistById, {
        args: [payload?.id || ''],
        successActionCreator: WishlistInternalActions.getWishlistByIdSuccess,
        failActionCreator: WishlistInternalActions.getWishlistByIdFailure,
      })
    );
  }

  public static handleGetWishlistByIdSuccess(state: WishlistState, action: WishlistAction): WishlistState | Loop<WishlistState, WishlistAction> {
    const wishlist: WishlistModel = action.payload?.wishlist || defaultState.wishlist;
    const newState = {
      ...state,
      wishlist,
      loading: {
        ...state.loading,
        item: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(WishlistActions.getWishlistMarket(wishlist.id)),
        Cmd.action(WishlistActions.getWishlistListings(wishlist.id)),
      ])
    );
  }

  public static handleGetWishlistByIdFailure(state: WishlistState): WishlistState {
    return {
      ...state,
      error: new Error(WishlistStateErrors.ID_FETCH),
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetWishlistArtists(state: WishlistState, action: WishlistAction): Loop<WishlistState, WishlistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        artists: true
      }
    };

    return loop(
      newState,
      Cmd.run(WishlistApiClient.getWishlistArtists, {
        args: [payload?.id || ''],
        successActionCreator: WishlistInternalActions.getWishlistArtistsSuccess,
        failActionCreator: WishlistInternalActions.getWishlistArtistsFailure,
      })
    );
  }

  public static handleGetWishlistArtistsSuccess(state: WishlistState, action: WishlistAction): WishlistState | Loop<WishlistState, WishlistAction> {
    const { wishlist } = state;
    const artistList = action.payload?.artistList || [];
    const newWishlist = WishlistModel.deepCopy(wishlist);
    newWishlist.setArtists(artistList);

    return {
      ...state,
      wishlist: newWishlist,
      loading: {
        ...state.loading,
        artists: false
      }
    };
  }

  public static handleGetWishlistArtistsFailure(state: WishlistState): WishlistState {
    return {
      ...state,
      error: new Error(WishlistStateErrors.ARTISTS_FETCH),
      loading: {
        ...state.loading,
        artists: false
      }
    };
  }

  public static handleGetWishlistMarket(state: WishlistState, action: WishlistAction): Loop<WishlistState, WishlistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        market: true
      }
    };

    return loop(
      newState,
      Cmd.run(WishlistApiClient.getWishlistMarket, {
        args: [payload?.id || ''],
        successActionCreator: WishlistInternalActions.getWishlistMarketSuccess,
        failActionCreator: WishlistInternalActions.getWishlistMarketFailure,
      })
    );
  }

  public static handleGetWishlistMarketSuccess(state: WishlistState, action: WishlistAction): WishlistState | Loop<WishlistState, WishlistAction> {
    const { wishlist } = state;
    const market = action.payload?.market;
    const newWishlist = WishlistModel.deepCopy(wishlist);
    newWishlist.setMarket(market);

    return {
      ...state,
      wishlist: newWishlist,
      loading: {
        ...state.loading,
        market: false
      }
    };
  }

  public static handleGetWishlistMarketFailure(state: WishlistState): WishlistState {
    return {
      ...state,
      error: new Error(WishlistStateErrors.MARKET_FETCH),
      loading: {
        ...state.loading,
        market: false
      }
    };
  }

  public static handleGetWishlistListings(state: WishlistState, action: WishlistAction): Loop<WishlistState, WishlistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        listings: true
      }
    };

    return loop(
      newState,
      Cmd.run(WishlistApiClient.getWishlistListings, {
        args: [payload?.id || '', payload?.page || 1],
        successActionCreator: WishlistInternalActions.getWishlistListingsSuccess,
        failActionCreator: WishlistInternalActions.getWishlistListingsFailure,
      })
    );
  }

  public static handleGetWishlistListingsSuccess(state: WishlistState, action: WishlistAction): WishlistState | Loop<WishlistState, WishlistAction> {
    const { wishlist } = state;
    const listings = action.payload?.listings;
    const newWishlist = WishlistModel.deepCopy(wishlist);
    newWishlist.setListings(listings);

    return {
      ...state,
      wishlist: newWishlist,
      loading: {
        ...state.loading,
        listings: false
      }
    };
  }

  public static handleGetWishlistListingsFailure(state: WishlistState): WishlistState {
    return {
      ...state,
      error: new Error(WishlistStateErrors.LISTINGS_FETCH),
      loading: {
        ...state.loading,
        listings: false
      }
    };
  }

  public static handleAddItemToWishlist(state: WishlistState, action: WishlistAction): Loop<WishlistState, WishlistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        action: true
      }
    };

    return loop(
      newState,
      Cmd.run(WishlistApiClient.addItemToWishlist, {
        args: [payload?.itemId || ''],
        successActionCreator: WishlistInternalActions.addItemToWishlistSuccess,
        failActionCreator: WishlistInternalActions.addItemToWishlistFailure,
      })
    );
  }

  public static handleAddItemToWishlistSuccess(state: WishlistState, action: WishlistAction): Loop<WishlistState, WishlistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        action: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(WishlistActions.getWishlistById(payload?.wishlistId || '')),
      ])
    );
  }

  public static handleAddItemToWishlistFailure(state: WishlistState): WishlistState {
    return {
      ...state,
      error: new Error(WishlistStateErrors.ADD_ITEM_TO_WISHLIST),
      loading: {
        ...state.loading,
        action: false
      }
    };
  }

  public static handleRemoveItemFromWishlist(state: WishlistState, action: WishlistAction): Loop<WishlistState, WishlistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        action: true
      }
    };

    return loop(
      newState,
      Cmd.run(WishlistApiClient.removeItemFromWishlist, {
        args: [payload?.itemId || ''],
        successActionCreator: WishlistInternalActions.removeItemFromWishlistSuccess,
        failActionCreator: WishlistInternalActions.removeItemFromWishlistFailure,
      })
    );
  }

  public static handleRemoveItemFromWishlistSuccess(state: WishlistState, action: WishlistAction): Loop<WishlistState, WishlistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        action: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(WishlistActions.getWishlistById(payload?.wishlistId || '')),
      ])
    );
  }

  public static handleRemoveItemFromWishlistFailure(state: WishlistState): WishlistState {
    return {
      ...state,
      error: new Error(WishlistStateErrors.REMOVE_ITEM_FROM_WISHLIST),
      loading: {
        ...state.loading,
        action: false
      }
    };
  }

  public static handleClearWishlist(state: WishlistState): WishlistState {
    return {
      ...state,
      wishlist: defaultState.wishlist
    };
  }
}
