import { useState, useEffect } from 'react';
import { AppConfig } from 'config';
import ReactFlagsSelect from "react-flags-select";

interface CountryInputProps {
  defaultData?: string | undefined;
  onChange?: (data: string) => void;
  disabled?: boolean;
}

export default function CountryInput({ defaultData, onChange = () => {}, disabled = false }: CountryInputProps) {
  const [country, setCountry] = useState<string>(defaultData || AppConfig.DEFAULT_COUNTRY_CODE.toUpperCase());

  useEffect(() => {
    if (defaultData) {
      setCountry(defaultData);
    }
  }, [defaultData]);

  const onCountrySelect = (data: string) => {
    setCountry(data);
    onChange?.(data);
  }

  return (
    <ReactFlagsSelect disabled={disabled} selected={country} onSelect={onCountrySelect} />
  );
}
