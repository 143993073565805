import { Moment } from 'moment';
import { RawMarketData, RawMarketSummaryData } from '../Market/types';
import { RawArtistData } from '../Artist/Artist';
import { RawListingData } from '../Listing/types';
import { MeasurementData } from '../types';

export enum ItemType {
  PRINT = 'Prints',
  SCULPTURE = 'Sculpture',
  MISC = 'Misc.',
  UNKNOWN = 'Unknown'
}

export interface RawReleaseInfoData {
  symbol: string;
  raw_price: string;
  date: string;
  converted_price: number;
}

export interface RawAuthenticityInfoData {
  certificate_issued: boolean;
  dated: boolean;
  has_fakes: boolean;
  numbered: boolean;
  signed: boolean;
}

export interface EditionVariantData {
  type: string;
  size: number;
}

export interface RawEditionData {
  size: number;
  variants: EditionVariantData[];
  is_open: boolean;
}

export interface EditionData {
  size: number;
  variants: EditionVariantData[];
  isOpen: boolean;
}

export interface RawItemData {
  id: string
  date: string;
  description: string;
  edition: string;
  image: string;
  source_link: string;
  name: string;
  release_price: string;
  publisher: string;
  measurements: string;
  dimensions: MeasurementData;
  material: string;
  type: ItemType;
  artist?: string;
  artist_id: string;
  listings?: string[];
  market?: string;
  release_info: RawReleaseInfoData;
  edition_info: RawEditionData;
  authenticity_info: RawAuthenticityInfoData;
  hand_treated: boolean;
  market_summary: RawMarketSummaryData;
  material_ids?: string[];
  colors?: string[];
}

export interface RawEnhancedItemData {
  id: string;
  date: string;
  description: string;
  edition: string;
  image: string;
  source_link: string;
  name: string;
  release_price: string;
  publisher: string;
  measurements: string;
  dimensions: MeasurementData;
  material: string;
  material_ids?: string[];
  colors?: string[];
  type: ItemType;
  artist: RawArtistData;
  listings: RawListingData[];
  market: RawMarketData;
  release_info: RawReleaseInfoData;
  authenticity_info: RawAuthenticityInfoData;
  market_summary: RawMarketSummaryData;
  edition_info: RawEditionData;
  hand_treated: boolean;
}

export interface ItemReleaseInfo {
  date: Moment | null;
  symbol: string;
  rawPrice: string;
  convertedPrice: number;
}

export interface AuthenticityInfoData {
  certificateIssued: boolean;
  dated: boolean;
  hasFakes: boolean;
  numbered: boolean;
  signed: boolean;
}

export interface ItemSearchQuery extends Partial<RawItemData> {
  query?: string;
  page?: string;
}
