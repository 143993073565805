export enum LocalStorageKeys {
  AUTH_TOKEN = 'authToken',
  REFRESH_TOKEN = 'refreshToken'
}

export class CredentialService {
  public static setAuthToken(authToken: string): void {
    localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, authToken);
  }

  public static fetchAuthToken(): string | null {
    return localStorage.getItem(LocalStorageKeys.AUTH_TOKEN);
  }

  public static deleteAuthToken(): void {
    localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
  }

  public static setRefreshToken(refreshToken: string): void {
    localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, refreshToken);
  }

  public static fetchRefreshToken(): string | null {
    return localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);
  }

  public static deleteRefreshToken(): void {
    localStorage.removeItem(LocalStorageKeys.REFRESH_TOKEN);
  }

  public static isAuthenticated(): boolean {
    return !!localStorage.getItem(LocalStorageKeys.AUTH_TOKEN);
  }
}
