import {
  Box,
  Image,
  Heading,
  Text,
  Flex,
  Stack,
  Spacer,
  Icon,
  Divider,
  Badge
} from '@chakra-ui/react';
import {
  GiThorHammer,
} from 'react-icons/gi';
import { ItemModel } from 'models';
import GrowthBadge from '../../GrowthBadge/GrowthBadge';
import { FeatureToggleService } from 'services';
import { FeatureToggleKeys } from 'constants/toggles';

export interface ItemCardProps {
  item: ItemModel;
  onClick?: () => void;
  size?: 'sm' | 'md';
  clickOnImage?: boolean;
}

export enum ItemCardSizingConfig {
  CARD_BORDER_WIDTH = '1px',
  MD_IMAGE_WIDTH = '302px',
  MD_IMAGE_HEIGHT = '200px',
  SMALL_IMAGE_WIDTH = '200px',
  SMALL_IMAGE_HEIGHT = '140px'
}

export default function ItemCard({ item, onClick, size = 'sm', clickOnImage = false }: ItemCardProps) {
  const imageWidth = size === 'md' ? ItemCardSizingConfig.MD_IMAGE_WIDTH : ItemCardSizingConfig.SMALL_IMAGE_WIDTH;
  const imageHeight = size === 'md' ? ItemCardSizingConfig.MD_IMAGE_HEIGHT : ItemCardSizingConfig.SMALL_IMAGE_HEIGHT;
  const showLastSale = FeatureToggleService.isToggleEnabled(FeatureToggleKeys.ITEM_CARD_LAST_SALE);

  function renderTitle() {
    return (
      <Box mt='2' lineHeight='tight'>
        <Heading fontSize="sm" textAlign={'center'}>{item.getDisplayName()}</Heading>
      </Box>
    );
  }

  function renderMarketData() {
    if (item.hasMarketSummaryData()) {
      return (
        <Stack width={'100%'} gap={2}>
          <Flex direction="row" alignItems="center" width={'100%'}>
            <Flex direction={'column'} textAlign={'left'}>
              <Heading fontSize="sm">{item.getEstimatedMarketPriceForDisplay()}</Heading>
            </Flex>
            <Spacer />
            <Flex direction="row" gap={1} alignItems="center">
              <Text fontSize={'xs'} fontWeight={'light'}>
                {item.getSoldListingsQuantity()}
              </Text>
              <Flex
                padding={1}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                border="1px">
                <Icon as={GiThorHammer} w={3} h={3} />
              </Flex>
            </Flex>
          </Flex>
          {showLastSale && <Divider />}
          {showLastSale && (
            <Flex direction="row" alignItems="center" width={'100%'}>
              <Flex direction={'column'} textAlign={'left'}>
                <Text fontSize={'xs'}>{item.getClosePriceForDisplay()}</Text>
                <Heading fontSize={'10px'}>Last Sale</Heading>
              </Flex>
              <Spacer />
              {item.hasMultipleSales() && <GrowthBadge percentage={item.getLastSalePercentageChange()} />}
            </Flex>
          )}
        </Stack>
      );
    } else if (item.hasReleasePriceData()) {
      return (
        <Flex direction="row" alignItems="center" width={'100%'}>
          <Flex direction={'column'} textAlign={'left'}>
            <Text fontSize={'xs'}>{item.getReleaseConvertedPriceForDisplay()}</Text>
            <Heading fontSize={'10px'}>Retail Price</Heading>
          </Flex>
          <Spacer />
          <Badge colorScheme='purple'>
            No Sales
          </Badge>
        </Flex>
      );
    }
  }

  function renderImage() {
    return (
      <Image loading="lazy" src={item.image} alt={item.name} fit={'contain'} height={imageHeight} width={imageWidth} onClick={clickOnImage ? onClick : () => { }} cursor={clickOnImage && onClick ? 'pointer' : ''} />
    );
  }

  return (
    <Flex
      p='6'
      direction="column"
      borderWidth={ItemCardSizingConfig.CARD_BORDER_WIDTH}
      borderRadius='lg'
      height={'100%'}
      minHeight={'360px'}
      gap={2}
      alignItems={'center'}
      onClick={!clickOnImage ? onClick : () => { }}
      cursor={!clickOnImage && onClick ? 'pointer' : ''}
      _hover={!clickOnImage && onClick ? { transform: 'scale(1.1)', boxShadow: '0px 10px 20px 2px rgba(0, 0, 0, 0.25)' } : {}}
    >
      {renderImage()}
      {renderTitle()}
      <Spacer />
      {renderMarketData()}
    </Flex>
  )
}
