import { Component } from 'react';
import moment from 'moment';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { PortfolioSelectors } from 'reducers/Portfolio/selectors';
import { UserSelectors } from 'reducers/User/selectors';
import { ArtistSelectors } from 'reducers/Artist/selectors';
import { PortfolioActions } from 'reducers/Portfolio/actions';
import {
  Box,
  Flex,
  Stack,
  Spacer,
  Text,
  Icon
} from '@chakra-ui/react';
import { IoGridOutline } from 'react-icons/io5';
import { AiFillFormatPainter } from 'react-icons/ai';
import { ImStatsDots } from 'react-icons/im';
import { RiAuctionLine } from 'react-icons/ri';
import { PortfolioModel, ItemModel, UserModel, ArtistModel } from 'models';
import { ItemPurchaseInfo } from 'models/Portfolio/types';
import { Loader, EmptyState, Modal, PortfolioItemManagementForm, ArtistDistributionPieChart, ArtistGrid, TypeDistributionPieChart, UserPortfolioBadge, TabGroup, PaginatedListingGrid, PortfolioHistoryChart, PortfolioTable } from 'components';
import { PortfolioItemManagementFormData, PortfolioItemPurchaseInfo } from 'components/Form/PortfolioItemManagementForm/PortfolioItemManagementForm';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';

interface PortfolioDetailsViewProps extends RouterProps {
  portfolio: PortfolioModel;
  user: UserModel;
  artistList: ArtistModel[];
  portfolioLoading: boolean;
  portfolioHistoryLoading: boolean;
  portfolioListingsLoading: boolean;
  portfolioActionLoading: boolean;
  getPortfolioById: (id: string) => void;
  getPortfolioListings: (id: string, page?: number) => void;
  replaceItemInPortfolio: (itemId: string, purchaseInfo: ItemPurchaseInfo[]) => void;
}

interface PortfolioDetailsViewModalState {
  itemEditModal: string | undefined;
  itemRemovalModal: string | undefined;
}


interface PortfolioDetailsState {
  modals: PortfolioDetailsViewModalState;
}

class PortfolioDetails extends Component<PortfolioDetailsViewProps, PortfolioDetailsState> {
  state = {
    modals: {
      itemEditModal: undefined,
      itemRemovalModal: undefined
    }
  };

  componentDidMount() {
    const { params, getPortfolioById } = this.props;
    if (params && params.id) {
      getPortfolioById(params.id);
    }
  }

  componentDidUpdate(prevProps: PortfolioDetailsViewProps) {
    const { params, getPortfolioById } = this.props;

    if (params.id && this.hasRouteChanged(prevProps)) {
      getPortfolioById(params.id);
    }
  }

  hasRouteChanged = (prevProps: PortfolioDetailsViewProps): boolean => {
    const { params } = this.props;
    return !!params.id && (params.id !== prevProps.params.id);
  };

  onItemClick = (item: ItemModel) => {
    this.props.navigate(NavigationService.getItemDetailsPath(item.id));
  };

  onPortfolioTableArtistClick = (artistId: string) => {
    this.props.navigate(NavigationService.getArtistDetailsPath(artistId));
  }

  onArtistClick = (artist: ArtistModel) => {
    this.props.navigate(
      NavigationService.getArtistDetailsPath(artist.id)
    );
  };

  onBrowseItems = () => {
    this.props.navigate(
      NavigationService.getExplorePath() + '?tab=items'
    );
  };

  isFetchingPortfolioData = (): boolean => {
    const { portfolioLoading, portfolioActionLoading } = this.props;
    return portfolioLoading || portfolioActionLoading;
  };

  onItemEditModalOpen = (itemId: string) => {
    this.setState({
      modals: {
        ...this.state.modals,
        itemEditModal: itemId
      }
    });
  };

  onItemEditModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        itemEditModal: undefined
      }
    });
  };

  onItemRemovalModalOpen = (itemId: string) => {
    this.setState({
      modals: {
        ...this.state.modals,
        itemRemovalModal: itemId
      }
    });
  };

  onItemRemovalModalClose = () => {
    this.setState({
      modals: {
        ...this.state.modals,
        itemRemovalModal: undefined
      }
    });
  };

  getPortfolioItemManagementFormData = (): PortfolioItemManagementFormData | undefined => {
    const { portfolio } = this.props;
    const { modals } = this.state;
    const purchaseData = portfolio.getPortfolioItemPurchaseInfo(modals.itemEditModal || '');
    if (purchaseData.length) {
      return {
        numPurchases: purchaseData.length || 1,
        purchaseInfo: purchaseData.map(purchase => ({
          price: purchase.price,
          quantity: purchase.quantity,
          date: purchase.date.format('YYYY-MM-DD')
        }))
      }
    } else {
      return undefined;
    }
  };

  getPortfolioArtists = (): ArtistModel[] => {
    const { artistList, portfolio } = this.props;
    return portfolio.getArtistIds()
      .map(artistId => artistList.find(artist => artist.id === artistId))
      .filter((artist): artist is ArtistModel => artist !== undefined);
  };


  onPortfolioManagementFormSubmit = (data: PortfolioItemManagementFormData) => {
    const { replaceItemInPortfolio } = this.props;
    const { modals } = this.state;
    const purchaseInfo: ItemPurchaseInfo[] = data.purchaseInfo.map((item: PortfolioItemPurchaseInfo) => ({
      date: moment(item.date),
      quantity: item.quantity,
      price: item.price
    }));

    replaceItemInPortfolio(modals.itemEditModal || '', purchaseInfo);
    this.onItemEditModalClose();
  };

  onPortfolioRemoveFormSubmit = () => {
    const { replaceItemInPortfolio } = this.props;
    const { modals } = this.state;
    replaceItemInPortfolio(modals.itemRemovalModal || '', []);
    this.onItemRemovalModalClose();
  }

  onPaginatedListingPageSelect = (page: number) => {
    const { portfolio, getPortfolioListings } = this.props;
    getPortfolioListings(portfolio.id, page);
  }

  renderProfileSectionHeader(header: string) {
    return (
      <Flex p={{ base: '12px 20px', md: '12px 0px' }} mb='12px'>
        <Text fontSize='lg' fontWeight='bold'>
          {header}
        </Text>
        <Spacer />
      </Flex>
    )
  }

  renderPortfolioItems() {
    const { portfolio } = this.props;
    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        <PortfolioTable 
          items={portfolio.getPortfolioDetailedItems()} 
          isLoading={this.isFetchingPortfolioData()} 
          onItemEditClick={(item) => this.onItemEditModalOpen(item.id)} 
          onItemRemovalClick={(item) => this.onItemRemovalModalOpen(item.id)} 
          onItemNameClick={(item) => this.onItemClick(item)} 
          onItemArtistClick={this.onPortfolioTableArtistClick} />
      </Box>
    );
  }

  renderPortfolioChart() {
    const { portfolio, portfolioHistoryLoading } = this.props;
    if (portfolioHistoryLoading) {
      return <Loader />;
    } else if (portfolio.history) {
      return (
        <Box marginBottom={"40px"}>
          <PortfolioHistoryChart portfolioHistoryData={portfolio.history} />
        </Box>
      );
    }
  }

  renderPortfolioArtists() {
    const artists = this.getPortfolioArtists();
    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        {this.renderProfileSectionHeader('Artists')}
        <ArtistGrid onArtistCardClick={this.onArtistClick} artists={artists} gridDisplay={true} isLoading={this.isFetchingPortfolioData()} />
      </Box>
    );
  }

  renderPortfolioListings() {
    const { portfolio, portfolioListingsLoading } = this.props;
    const listings = portfolio.listings;
    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        {this.renderProfileSectionHeader('Listings')}
        {listings && <PaginatedListingGrid paginatedListings={listings} isLoading={portfolioListingsLoading} scrollMode={false} showAssociationAction={false} onPageSelect={this.onPaginatedListingPageSelect} />}
      </Box>
    );
  }

  renderDistributionCharts() {
    const { portfolio, artistList } = this.props;
    return (
      <Flex direction={{ base: 'column', md: 'row' }} gap={2}>
        <ArtistDistributionPieChart items={portfolio.getPortfolioDetailedItems()} artists={artistList} />
        <TypeDistributionPieChart items={portfolio.getPortfolioDetailedItems()} />
      </Flex>
    );
  }

  renderPortfolioStats() {
    return (
      <Box my={{ sm: "24px", xl: "0px" }} textAlign={{ base: 'center', md: 'left' }} borderRadius="16px">
        {this.renderPortfolioChart()}
        {this.renderDistributionCharts()}
      </Box>
    );
  }

  renderTabs() {
    const labels = [
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={IoGridOutline} />
        <Text> Items </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={ImStatsDots} />
        <Text> Stats </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={AiFillFormatPainter} />
        <Text> Artists </Text>
      </Flex>,
      <Flex gap="4px" alignItems="center" direction={{ base: 'column', md: 'row' }}>
        <Icon as={RiAuctionLine} />
        <Text> Sales </Text>
      </Flex>
    ];

    const content = [
      this.renderPortfolioItems(),
      this.renderPortfolioStats(),
      this.renderPortfolioArtists(),
      this.renderPortfolioListings()
    ];

    return (
      <TabGroup labels={labels} content={content} />
    );
  }


  renderContent() {
    const { user, portfolio, portfolioHistoryLoading, portfolioLoading } = this.props;
    if (portfolio.hasItems()) {
      return (
        <Box>
          <UserPortfolioBadge user={user} portfolio={portfolio} dataLoading={portfolioHistoryLoading || portfolioLoading} />
          {this.renderTabs()}
        </Box>
      )
    } else if (this.isFetchingPortfolioData()) {
      return (
        <Loader />
      );
    } else {
      return <EmptyState header="No Items" description="No Items in Portfolio" buttonText="Browse Items" showButton={true} onButtonClick={this.onBrowseItems} />;
    }
  }

  renderItemEditModalContent() {
    const { portfolio, portfolioActionLoading } = this.props;
    const { modals } = this.state;
    const itemId: string | undefined = modals.itemEditModal;
    const portfolioItem = portfolio.getDetailedPortfolioItem(itemId || '');
    if (portfolioItem) {
      return (
        <PortfolioItemManagementForm item={portfolioItem.item} defaultFormData={this.getPortfolioItemManagementFormData()} onSubmit={this.onPortfolioManagementFormSubmit} submitLoading={portfolioActionLoading} />
      );
    } else {
      return <Box />;
    }
  }

  renderItemRemovalModalContent() {
    const { portfolio, portfolioActionLoading } = this.props;
    const { modals } = this.state;
    const itemId: string | undefined = modals.itemRemovalModal;
    const portfolioItem = portfolio.getDetailedPortfolioItem(itemId || '');
    if (portfolioItem) {
      return (
        <PortfolioItemManagementForm item={portfolioItem.item} defaultFormData={undefined} onSubmit={this.onPortfolioRemoveFormSubmit} submitLoading={portfolioActionLoading} removeItem={true} />
      );
    } else {
      return <Box />;
    }
  }

  renderModals() {
    const { modals } = this.state;
    return (
      <Stack>
        <Modal title={'Update Portfolio'} isOpen={!!modals.itemEditModal} onClose={this.onItemEditModalClose} content={this.renderItemEditModalContent()} showCloseButton={false} showSubmitButton={false} />
        <Modal title={'Update Portfolio'} isOpen={!!modals.itemRemovalModal} onClose={this.onItemRemovalModalClose} content={this.renderItemRemovalModalContent()} showCloseButton={false} showSubmitButton={false} />
      </Stack>
    );
  }

  render() {
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
        {this.renderContent()}
        {this.renderModals()}
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    portfolio: PortfolioSelectors.getPortfolio(state),
    portfolioLoading: PortfolioSelectors.getPortfolioLoading(state),
    portfolioHistoryLoading: PortfolioSelectors.getPortfolioHistoryLoading(state),
    portfolioListingsLoading: PortfolioSelectors.getPortfolioListingsLoading(state),
    portfolioActionLoading: PortfolioSelectors.getPortfolioActionLoading(state),
    user: UserSelectors.getUser(state),
    artistList: ArtistSelectors.getArtistList(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      getPortfolioById: (id: string) => PortfolioActions.getPortfolioById(id),
      getPortfolioListings: (id: string, page?: number) => PortfolioActions.getPortfolioListings(id, page),
      replaceItemInPortfolio: (itemId: string, purchaseInfo: ItemPurchaseInfo[]) => PortfolioActions.replaceItemInPortfolio(itemId, purchaseInfo),
    },
    dispatch
  );
}

export const PortfolioDetailsView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PortfolioDetails));
