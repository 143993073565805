import { useState } from 'react';
import {
  Flex,
  Input,
  Heading,
  Stack,
  useColorModeValue
} from '@chakra-ui/react';
import { isValidEmail } from 'utils/string'; // Make sure this path is correct
import ActionButton from '../Button/ActionButton';
import { FiArrowRight } from 'react-icons/fi';

export interface WaitlistCardProps {
  showButton?: boolean;
  buttonLoading?: boolean;
  onButtonClick?: (email: string) => void;
}

export default function WaitlistCard({
  showButton = true,
  buttonLoading = false,
  onButtonClick
}: WaitlistCardProps) {
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [submissionSuccess, setSubmissionSuccess] = useState(false);
  const textColor = useColorModeValue("white", "black");
  const bgColor = useColorModeValue("gray.800", "white");
  const borderProfileColor = useColorModeValue("white", "transparent");

  const onEmailInputChange = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue: string = (event.target as HTMLInputElement).value;
    setEmail(inputValue);
    setIsEmailValid(isValidEmail(inputValue));
    setSubmissionSuccess(false);
  };

  const handleButtonClick = () => {
    if (onButtonClick && isEmailValid && email.length > 0) {
      onButtonClick(email);
      setSubmissionSuccess(true);
    }
  }

  function renderButton() {
    return (
      <ActionButton
        inverse
        rightIcon={FiArrowRight}
        text={'Join Waitlist'}
        loading={buttonLoading}
        onClick={handleButtonClick}
        disabled={!isEmailValid || email.length === 0}
      />
    );
  }

  function renderSuccessMessage() {
    if (submissionSuccess && !buttonLoading) {
      return (
        <Heading color="green.500" fontSize="md" mt={2}>
          Thanks! We'll be in touch.
        </Heading>
      );
    }
  }

  function renderHeader() {
    return (
      <Heading fontSize="xl"> Join for early access </Heading>
    );
  }

  function renderInput() {
    return (
      <Input
        mt={4}
        backgroundColor={useColorModeValue('white', 'gray.700')}
        color={useColorModeValue('black', 'white')}
        placeholder={'Your Email*'}
        onChange={onEmailInputChange}
        isInvalid={!isEmailValid && email.length > 0}
      />
    );
  }

  return (
    <Flex
      direction={"column"}
      mb='24px'
      maxH='330px'
      gap={8}
      align='start'
      backdropFilter='blur(21px)'
      boxShadow='0px 2px 5.5px rgba(0, 0, 0, 0.02)'
      border='2px solid'
      borderColor={borderProfileColor}
      color={textColor}
      bg={bgColor}
      p='24px'
      width="100%"
      borderRadius='20px'>
      {renderHeader()}
      <Stack width="100%" textAlign={'left'}>
        {renderSuccessMessage()}
        {renderInput()}
      </Stack>
      {renderButton()}
    </Flex>
  );
}
