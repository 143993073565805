import {
  Box,
  Container,
  Divider,
  HStack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { OAuthButtonGroup } from './OAuthButtonGroup';
import RegisterForm, { RegisterFormData } from '../Form/RegisterForm/RegisterForm';

export interface RegisterCardProps {
  onRegisterClick?: (email: string, username: string, password: string) => void;
  registerLoading?: boolean;
  showOAuthButtons?: boolean;
}

export default function RegisterCard({ onRegisterClick, showOAuthButtons = true, registerLoading = false }: RegisterCardProps) {
  function renderOAuthButtons() {
    if (showOAuthButtons) {
      return (
        <OAuthButtonGroup />
      );
    } else {
      return null;
    }
  }

  function renderOAuthSeperator() {
    if (showOAuthButtons) {
      return (
        <HStack>
          <Divider />
          <Text fontSize="sm" whiteSpace="nowrap" color="muted">
            or sign up with
          </Text>
          <Divider />
        </HStack>
      );
    } else {
      return null;
    }
  }

  const onFormSubmit = (data: RegisterFormData) => {
    if (onRegisterClick) {
      onRegisterClick(data.email, data.username, data.password);
    }
  }

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <RegisterForm onSubmit={onFormSubmit} submitLoading={registerLoading} />
        {renderOAuthSeperator()}
        {renderOAuthButtons()}
      </Box>
    </Container>
  );
}
