import moment, { Moment } from 'moment';
import { PortfolioModel } from '../Portfolio/Portfolio';
import { RawBasicPortfolioData } from '../Portfolio/types';
import { WishlistModel, RawBasicWishlistData } from '../Wishlist/Wishlist';
import { ItemModel } from '../Item/Item';
import { ArtistModel } from '../Artist/Artist';
import { Cloneable } from '../Utils/Cloneable';
import { CountryData, RawPhoneData, PhoneData, RawTimestampData, TimestampData } from '../types';

export interface UserVerficiationData {
  email: boolean;
  number: boolean;
}

export interface UserRawTimestampData extends RawTimestampData {
  confirmed_at: string;
}

export interface UserTimestampData extends TimestampData {
  confirmedAt: Moment | null;
}

export enum UserAccountStatus {
  PENDING = 'Pending',
  CONFIRMED = 'Confirmed',
  BLOCKED = 'Blocked'
}

export interface RawUserData {
  email: string;
  id: string;
  name: string;
  number: RawPhoneData;
  user_name: string;
  birth_year: number;
  portfolio: RawBasicPortfolioData;
  wishlist: RawBasicWishlistData;
  following_artists: string[];
  image?: string;
  country?: CountryData;
  verification?: UserVerficiationData;
  timestamps?: UserRawTimestampData;
  account_status?: UserAccountStatus;
  instagram?: string;
  biography?: string;
}

export class UserModel extends Cloneable {
  email: string;
  id: string;
  name: string;
  number: PhoneData;
  userName: string;
  portfolio: PortfolioModel;
  wishlist: WishlistModel;
  followingArtists: ArtistModel[] | string[];
  country: CountryData;
  image: string;
  verification: UserVerficiationData;
  timestamps: UserTimestampData;
  accountStatus: UserAccountStatus;
  birthYear: number;
  instagram: string;
  biography: string;

  constructor(rawUserData?: RawUserData) {
    super();
    this.email = rawUserData?.email || '';
    this.id = rawUserData?.id || '';
    this.name = rawUserData?.name || '';
    this.number = {
      countryCode: rawUserData?.number.country_code || '',
      baseNumber: rawUserData?.number.base_number || 0,
    };
    this.userName = rawUserData?.user_name || '';
    this.portfolio = rawUserData?.portfolio ? new PortfolioModel(rawUserData.portfolio) : new PortfolioModel();
    this.wishlist = rawUserData?.wishlist ? new WishlistModel(rawUserData.wishlist) : new WishlistModel();
    this.followingArtists = rawUserData?.following_artists || [];
    this.country = rawUserData?.country || { code: '', name: '' };
    this.image = rawUserData?.image || '';
    this.verification = {
      email: rawUserData?.verification?.email || false,
      number: rawUserData?.verification?.number || false
    };
    this.timestamps = {
      createdAt: rawUserData?.timestamps?.created_at ? moment(rawUserData.timestamps.created_at) : null,
      updatedAt: rawUserData?.timestamps?.updated_at ? moment(rawUserData.timestamps.updated_at) : null,
      confirmedAt: rawUserData?.timestamps?.confirmed_at ? moment(rawUserData.timestamps.confirmed_at) : null
    };
    this.accountStatus = rawUserData?.account_status || UserAccountStatus.PENDING;
    this.birthYear = rawUserData?.birth_year || 0;
    this.instagram = rawUserData?.instagram || '';
    this.biography = rawUserData?.biography || '';
  }

  isAuthenticated(): boolean {
    return !!this.userName && !!this.id;
  }

  isConfirmed(): boolean {
    return this.accountStatus === UserAccountStatus.CONFIRMED;
  }

  isBlocked(): boolean {
    return this.accountStatus === UserAccountStatus.BLOCKED;
  }

  isPhoneVerified(): boolean {
    return this.verification.number;
  }

  isEmailVerified(): boolean {
    return this.verification.email;
  }

  public setFollowingArtists(artists: ArtistModel[]) {
    if (artists) {
      this.followingArtists = artists;
    }
  }

  public setPortfolio(portfolio: PortfolioModel | undefined) {
    if (portfolio) {
      this.portfolio = portfolio;
    }
  }

  public setWishlist(wishlist: WishlistModel | undefined) {
    if (wishlist) {
      this.wishlist = wishlist;
    }
  }

  getPortfolioId(): string {
    return this.portfolio.id;
  }

  getWishlistId(): string {
    return this.wishlist.id;
  }

  hasFetchedWishlist(): boolean {
    return !!this.wishlist.id;
  }

  hasFetchedPortfolio(): boolean {
    return !!this.portfolio.id;
  }

  portfolioContainsItem(itemId: string): boolean {
    return !!this.portfolio.containsItem(itemId);
  }

  wishlistContainsItem(itemId: string): boolean {
    return !!this.wishlist.containsItem(itemId);
  }

  getFollowingArtistIds(): string[] {
    const artistIds: string[] = [];
    this.followingArtists.forEach((artist: string | ArtistModel) => {
      if (artist instanceof ArtistModel) {
        artistIds.push(artist.id);
      } else {
        artistIds.push(artist);
      }
    });

    return artistIds;
  }

  getFollowingArtists(): ArtistModel[] {
    const artists: ArtistModel[] = [];
    this.followingArtists.forEach((artist: string | ArtistModel) => {
      if (artist instanceof ArtistModel) {
        artists.push(artist);
      }
    });

    return artists;
  }

  isFollowingArtists(): boolean {
    return !!this.followingArtists.length;
  }

  getWishlistItems(): ItemModel[] {
    return this.wishlist.getWishlistItems();
  }

  getPortfolioItems(): ItemModel[] {
    return this.portfolio.getPortfolioItems();
  }

  followsArtist(artistId: string): boolean {
    return !!this.getFollowingArtistIds().includes(artistId);
  }

  getImage(): string {
    return this.image;
  }

  setImage(url: string): void {
    this.image = url;
  }

  getFormattedNumber(): string {
    return this.number.countryCode + this.number.baseNumber;
  }

  getBirthYear(): number {
    return this.birthYear;
  }

  getJoinedYear(): number {
    return this.timestamps.createdAt?.year() || 0;
  }

  getJoinedMonth(): number {
    return this.timestamps.createdAt?.month() || 0;
  }

  hasImage(): boolean {
    return !!this.image;
  }

  hasBirthYear(): boolean {
    return this.birthYear > 0;
  }

  hasInstagram(): boolean {
    return !!this.instagram;
  }
}
