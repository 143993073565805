import { MaterialModel } from 'models';

export enum MaterialActionTypes {
  GET_MATERIAL_BY_ID = 'MATERIAL_GET_MATERIAL_BY_ID',
  GET_MATERIAL_BY_ID_SUCCESS = 'MATERIAL_GET_MATERIAL_BY_ID_SUCCESS',
  GET_MATERIAL_BY_ID_FAILURE = 'MATERIAL_GET_MATERIAL_BY_ID_FAILURE',

  GET_ALL_MATERIALS = 'GET_ALL_MATERIALS',
  GET_ALL_MATERIALS_SUCCESS = 'GET_ALL_MATERIALS_SUCCESS',
  GET_ALL_MATERIALS_FAILURE = 'GET_ALL_MATERIALS_FAILURE',
};

export interface MaterialActionPayload {
  id?: string;
  material?: MaterialModel;
  materialList?: MaterialModel[];
};

export interface MaterialAction {
  type: MaterialActionTypes;
  payload?: MaterialActionPayload;
};

export interface MaterialStateLoading {
  item: boolean;
  list: boolean;
};

export interface MaterialState {
  material: MaterialModel;
  materialList: MaterialModel[];
  error: Error | undefined;
  loading: MaterialStateLoading;
};
