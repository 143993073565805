import { JSONObject } from 'models/Api/types';

export enum ListingDataSuggestionActionTypes {
  CREATE_LISTING_DATA_SUGGESTION = 'CREATE_LISTING_DATA_SUGGESTION',
  CREATE_LISTING_DATA_SUGGESTION_SUCCESS = 'CREATE_LISTING_DATA_SUGGESTION_SUCCESS',
  CREATE_LISTING_DATA_SUGGESTION_FAILURE = 'CREATE_LISTING_DATA_SUGGESTION_FAILURE',
};

export interface ListingDataSuggestionActionPayload {
  id?: string;
  listingId?: string
  data?: JSONObject;
};

export interface ListingDataSuggestionAction {
  type: ListingDataSuggestionActionTypes;
  payload?: ListingDataSuggestionActionPayload;
};

export interface ListingDataSuggestionStateLoading {
  create: boolean
};

export interface ListingDataSuggestionState {
  error: Error | undefined;
  loading: ListingDataSuggestionStateLoading;
};
