import { UrlConfig } from 'config';
import { ApiResponse } from 'models/Api/ApiResponse';
import { AjaxService } from 'services';

export class ItemListingGroupingApiClient {
  public static async createItemListingGrouping(itemId: string, listingIds: string[]): Promise<string> {
    const url = UrlConfig.ITEM_LISTING_GROUPING.BASE;
    const response: ApiResponse<string> = await AjaxService.postData(url, {
      item_id: itemId,
      listings: listingIds
    });
    return response.data;
  }
}
