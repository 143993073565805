import { Loop, LoopReducer } from 'redux-loop';
import { ItemDataSuggestionHandlers } from './handlers';
import { ItemDataSuggestionState, ItemDataSuggestionAction, ItemDataSuggestionActionTypes } from './types';
import { defaultState } from './consts';

export const itemDataSuggestionReducer: LoopReducer<ItemDataSuggestionState, ItemDataSuggestionAction> = (
  state: ItemDataSuggestionState = defaultState,
  action: ItemDataSuggestionAction
): ItemDataSuggestionState | Loop<ItemDataSuggestionState, ItemDataSuggestionAction> => {
  switch (action.type) {
    case ItemDataSuggestionActionTypes.CREATE_ITEM_DATA_SUGGESTION:
      return ItemDataSuggestionHandlers.handleCreateItemDataSuggestion(state, action);
    case ItemDataSuggestionActionTypes.CREATE_ITEM_DATA_SUGGESTION_SUCCESS:
      return ItemDataSuggestionHandlers.handleCreateItemDataSuggestionSuccess(state);
    case ItemDataSuggestionActionTypes.CREATE_ITEM_DATA_SUGGESTION_FAILURE:
      return ItemDataSuggestionHandlers.handleCreateItemDataSuggestionFailure(state);
    default:
      return state;
  }
}
