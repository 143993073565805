import { Loop, LoopReducer } from 'redux-loop';
import { ItemSaleSuggestionHandlers } from './handlers';
import { ItemSaleSuggestionState, ItemSaleSuggestionAction, ItemSaleSuggestionActionTypes } from './types';
import { defaultState } from './consts';

export const itemSaleSuggestionReducer: LoopReducer<ItemSaleSuggestionState, ItemSaleSuggestionAction> = (
  state: ItemSaleSuggestionState = defaultState,
  action: ItemSaleSuggestionAction
): ItemSaleSuggestionState | Loop<ItemSaleSuggestionState, ItemSaleSuggestionAction> => {
  switch (action.type) {
    case ItemSaleSuggestionActionTypes.CREATE_ITEM_SALE_SUGGESTION:
      return ItemSaleSuggestionHandlers.handleCreateItemSaleSuggestion(state, action);
    case ItemSaleSuggestionActionTypes.CREATE_ITEM_SALE_SUGGESTION_SUCCESS:
      return ItemSaleSuggestionHandlers.handleCreateItemSaleSuggestionSuccess(state);
    case ItemSaleSuggestionActionTypes.CREATE_ITEM_SALE_SUGGESTION_FAILURE:
      return ItemSaleSuggestionHandlers.handleCreateItemSaleSuggestionFailure(state);
    default:
      return state;
  }
}
