import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import {
  Stack,
  FormControl,
  InputGroup,
  FormLabel,
  InputLeftElement,
  Input,
  FormErrorMessage
} from '@chakra-ui/react';
import {
  FaLink,
} from 'react-icons/fa';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import ActionButton from '../../Button/ActionButton';
import SalesVenueTable from '../../SalesVenue/SalesVenueTable/SalesVenueTable';
import { ItemModel, SalesVenueModel } from 'models';
import { isValidHttpUrl } from 'utils/string';

export interface ItemSaleSuggestionFormProps {
  item: ItemModel;
  salesVenues?: SalesVenueModel[];
  defaultFormData?: ItemSaleSuggestionFormData;
  onSubmit?: (data: ItemSaleSuggestionFormData) => void;
  submitLoading?: boolean;
}

export interface ItemSaleSuggestionFormData {
  rawPrice: number;
  date:  Date;
  link: string;
  sourceId: string;
}

export default function ItemSaleSuggestionForm({ item, salesVenues, defaultFormData, onSubmit, submitLoading }: ItemSaleSuggestionFormProps) {
  const {
    handleSubmit,
    register,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<ItemSaleSuggestionFormData>({defaultValues: defaultFormData});

  const onFormSubmit = (data: ItemSaleSuggestionFormData) => {
    onSubmit?.(data);
  }

  function renderSalesVenueTable() {
    return (
      <FormControl isRequired isInvalid={!!errors.sourceId}>
        <FormLabel htmlFor="sourceId" fontSize="sm">Venue</FormLabel>
        <Controller
          control={control}
          name="sourceId"
          rules={{
            required: true,
            validate: (values) => (!!values?.length) || 'Venue must be selected'
          }}
          render={() => (
            <SalesVenueTable
              venues={salesVenues || []}
              selectedVenueIds={watch('sourceId') ? [watch('sourceId')] : []}
              onSelect={(salesVenues) => setValue('sourceId', salesVenues[0], { shouldDirty: true })}
              paginate={true}
            />
          )} />
        <FormErrorMessage>
          {errors.sourceId && errors.sourceId.message}
        </FormErrorMessage>
      </FormControl>
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={8}>
        <BasicItemCard item={item} />
        <FormControl isRequired isInvalid={!!errors.rawPrice}>
          <FormLabel htmlFor="salePrice" fontSize="sm">Price</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              color='gray.500'
              children='$'
            />
            <Input
              {...register('rawPrice', {
                required: 'Sales Price is required',
                validate: {
                  isPositive: v =>  v && v > 0 || 'Sales Price must be greater than 0',
                }
              })}
              fontSize="sm"
              type="number"
              placeholder='Enter Amount (USD)'
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.rawPrice && errors.rawPrice.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.date}>
          <FormLabel fontSize="sm" htmlFor="date">Date</FormLabel>
          <Input
            {...register('date', {
              required: 'Sales Date is required',
              max: { value: moment.now(), message: 'Sales Date must be before current date'},
              valueAsDate: true
            })}
            fontSize="sm"
            type="date"
          />
          <FormErrorMessage>
            {errors.date && errors.date.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isRequired isInvalid={!!errors.link}>
          <FormLabel fontSize="sm" htmlFor="link">Link</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              color='gray.500'
              children={<FaLink />}
            />
            <Input
              {...register('link', {
                validate: {
                  isDefined: v => v.length > 0 || 'Sales Link must be specified',
                  isValidUrl: v => isValidHttpUrl(v) || 'Sales Link must be a valid url'
                },
              })}
              fontSize="sm"
              placeholder='Site Link'
              type="url"
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.link && errors.link.message}
          </FormErrorMessage>
        </FormControl>
        {renderSalesVenueTable()}
        <Stack alignItems="center">
          <ActionButton type='submit' text={'Submit'} loading={submitLoading} size={'md'}/>
        </Stack>
      </Stack>
    </form>
  );
}
