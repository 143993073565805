import {
	Box,
	Flex,
	Heading,
	Image,
	Text,
	Stack,
	StackDivider,
	useColorModeValue
} from '@chakra-ui/react';
import { ListingModel } from 'models';

interface MarketListingTooltipProps {
	listings: ListingModel[];
	active?: boolean;
	label?: string;
}

const MarketListingTooltipSizingConfig = {
	IMAGE_HEIGHT: '100px',
	IMAGE_WIDTH: '100px',
};

export default function MarketListingTooltip({ listings = [], active = false, label = '' }: MarketListingTooltipProps) {
	if (!active || listings.length === 0) return null;

	function renderListing(listing: ListingModel, index: number) {
		return (
			<Flex direction="row" justifyContent="center" alignItems="center" key={`market_tooltip_listing_index_${index}`}>
						<Image
							padding={2}
							fit={'contain'}
							src={listing.image}
							fallbackSrc={`https://via.placeholder.com/${MarketListingTooltipSizingConfig.IMAGE_HEIGHT}`}
							height={MarketListingTooltipSizingConfig.IMAGE_HEIGHT}
							width={MarketListingTooltipSizingConfig.IMAGE_WIDTH}
						/>
						<Flex width="100%" direction={'column'} marginLeft="20px">
							<Box p={{ base: 1, md: 6 }} textAlign={{ base: 'left', md: 'center' }}>
								<Box lineHeight='tight'>
									<Heading fontSize="sm">{listing.name}</Heading>
								</Box>
								<Box marginTop={2}>
									<Heading fontSize="sm" marginRight="8px">{listing.price}</Heading>
									<Text fontSize="xs">{listing.getRawPriceForDisplay()}</Text>
								</Box>
								<Box marginTop={2}>
									<Heading fontSize={{ base: 'xs', md: 'sm' }}>{listing.getFormattedDate()}</Heading>
									<Text fontSize="xs">{listing.source}</Text>
								</Box>
							</Box>
						</Flex>
					</Flex>
		);
	}

	return (
		<Stack overflow={'scroll'} divider={<StackDivider />} spacing='4' textAlign={'left'} key={`market_tooltip_listing_${label}`} borderWidth="1px" borderRadius="lg" shadow="md" backgroundColor={useColorModeValue("white", "black")} zIndex={10}>
			<Box paddingTop={4} paddingLeft={2} textAlign={'center'} alignItems={'center'}>
				<Heading mb={4} fontSize='sm'>{label}</Heading>
				<Flex direction={'row'} alignItems={'center'} gap={2}>
					<Text fontSize='xs' textTransform='uppercase'>
						Total Sales:
					</Text>
					<Heading fontSize='sm'>
						{1}
					</Heading>
				</Flex>
			</Box>
			{listings.map((listing, index) => renderListing(listing, index))}
		</Stack>
	);
}
