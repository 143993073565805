import {
  Box,
  Image,
  Avatar,
  Flex
} from '@chakra-ui/react';
import { ArtistModel } from 'models/Artist/Artist';

export interface ArtistCardProps {
  artist: ArtistModel;
  showItemCount?: boolean;
  onClick?: () => void;
  useAvatar?: boolean;
}

export enum ArtistCardSizingConfig {
  CARD_BORDER_WIDTH = '1px',
  IMAGE_HEIGHT = '200px'
}

export default function ArtistCard({ artist, onClick, useAvatar = false }: ArtistCardProps) {
  function renderArtistAvatar() {
    return (
      <Flex height={ArtistCardSizingConfig.IMAGE_HEIGHT}>
        <Avatar src={artist.image} name={artist.name} size={'full'} loading={'lazy'} />
      </Flex>
    )
  }

  function renderArtistImage() {
    if (useAvatar) {
      return renderArtistAvatar();
    } else {
      return (
        <Image src={artist.image} alt={artist.name} height={ArtistCardSizingConfig.IMAGE_HEIGHT} width="100%" />
      );
    }
  }

  return (
    <Box
      maxW='sm'
      borderWidth={ArtistCardSizingConfig.CARD_BORDER_WIDTH}
      borderRadius='lg'
      overflow='hidden'
      onClick={onClick}
      cursor={onClick ? 'pointer' : ''}
      _hover={onClick ? { transform: 'scale(1.1)', boxShadow: '0px 10px 20px 2px rgba(0, 0, 0, 0.25)' } : {}}
    >
      {renderArtistImage()}
      <Box p='6'>
        <Box
          fontWeight='semibold'
          as='h4'
          lineHeight='tight'
        >
          {artist.name}
        </Box>
      </Box>
    </Box>
  )
}
