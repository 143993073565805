import {
  Flex,
  Heading,
  Text,
  Icon,
  Image
} from "@chakra-ui/react";
import { IconType } from 'react-icons';

export interface HomeInfoBlockProps {
  icon?: IconType;
  imageUrl?: string;
  header: string;
  content: string | JSX.Element;
}

export default function HomeInfoBlock({
  icon,
  imageUrl,
  header,
  content
}: HomeInfoBlockProps) {
  function renderIcon() {
    if (icon) {
      return <Icon as={icon} w={12} h={12} />;
    }

    if (imageUrl) {
      return <Image src={imageUrl} w={24} h={24}/>
    }

    return null;
  }

  return (
    <Flex direction='column' gap={2} textAlign={{base: 'center', md: 'left'}} alignItems={'center'}>
      {renderIcon()}
      <Heading fontSize='2xl' width={'100%'}> {header} </Heading>
      <Text fontSize='sm'> {content} </Text>
    </Flex>
  );
}
