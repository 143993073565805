import React, { useState, useEffect } from 'react';
import {
  Box,
  InputGroup,
  InputLeftElement,
  Input,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import Loader from '../Loader/Loader';

export interface SearchBarProps {
  onChange?: (input: string) => void;
  inputPlaceholder?: string;
  showResultBox?: boolean;
  resultsLoading?: boolean;
  hasValidResults?: boolean;
  results?: JSX.Element[] | JSX.Element;
  disabled?: boolean;
  defaultValue?: string;
}

export enum SearchBarConfig {
  ON_CHANGE_DELAY = 500,
  RESULTS_HIDE_DELAY_ON_BLUR = 200
}

export default function SearchBar({ onChange, inputPlaceholder, defaultValue="", showResultBox = false, resultsLoading = false, results = [], hasValidResults = true, disabled = false }: SearchBarProps) {
  const [value, setValue] = useState<string>(defaultValue);
  const [showResults, setShowResults] = useState<boolean>(false);
  const resultsBackgroundColor = useColorModeValue('white', 'black');

  useEffect(() => {
    const timeOutId = setTimeout(() => onChange && onChange(value), SearchBarConfig.ON_CHANGE_DELAY);
    return () => clearTimeout(timeOutId);
  }, [value]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onInputBlur = () => {
    setTimeout(() => setShowResults(false), SearchBarConfig.RESULTS_HIDE_DELAY_ON_BLUR);
  };

  function renderResults() {
    if (showResults && showResultBox && (resultsLoading || hasValidResults)) {
      const content = resultsLoading ? <Loader /> : results;
      return (
        <Flex direction="column" overflow="scroll" minHeight="100px" maxHeight="600px" minWidth="240px" background={resultsBackgroundColor} position="absolute" marginRight="40px" marginTop="20px" borderWidth='1px' borderRadius='lg'>
          {content}
        </Flex>
      );
    }
  }

  const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
    const inputValue: string = (event.target as HTMLTextAreaElement).value;
    setValue(inputValue);
  };

  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')}>
        <InputGroup>
          <InputLeftElement
            pointerEvents='none'
            children={<SearchIcon color='gray.300' />}
          />
          <Input onBlur={onInputBlur} onSelect={() => setShowResults(true)} fontSize="sm" value={value} onChange={handleChange} placeholder={inputPlaceholder || 'Search'} disabled={disabled} required={false}>
          </Input>
        </InputGroup>
        {renderResults()}
      </Box>
    </>
  );
}
