import { PortfolioHistoryData } from "models/Portfolio/types";
import { ArtistIndexData } from 'models/Artist/ArtistIndex';

interface TimeseriesXTickProps {
    x: number;
    y: number;
    payload: {
        value: string | number;
    };
    data: PortfolioHistoryData[] | ArtistIndexData[];
}

export default function TimeseriesXTick({ x, y, payload, data }: TimeseriesXTickProps) {
    const index: number = Number(payload.value);
    const dataPoint = data[index];
    const formattedData = dataPoint.date?.format('MMM, YYYY');

    return (
        <text x={x} y={y + 10} fill="#666" fontSize="12px" fontWeight="bold" textAnchor="middle">
            {formattedData}
        </text>
    );
}
