import { Box, Flex, Container, Stack, Text, IconButton, Button, Spacer, Heading } from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';
import { AppConstants } from 'constants/app';

export type BannerStatus = 'info' | 'success' | 'warning' | 'error';

export interface BannerProps {
  header: string;
  message?: string;
  buttonText?: string;
  buttonClick?: () => void;
  buttonDisabled?: boolean;
  dismissable: boolean;
  onClose?: () => void;
  status?: BannerStatus;
}


export default function Banner({ header, message, buttonText, buttonClick, buttonDisabled, onClose, dismissable, status = 'info' }: BannerProps) {
  const colorSchemes = {
    'success': 'green',
    'error': 'red',
    'warning': 'orange',
    'info': 'gray'
  };

  const colorScheme = colorSchemes[status];

  return (
    <Box as="section" width={'100%'} bg={`${colorScheme}.200`}>
      <Box
        maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`}
        minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}
        borderBottomWidth="1px"
        px={{ base: 4, md: 0 }}
        marginLeft={'auto'}
        textAlign={'left'}
        marginRight={'auto'}>
        <Flex
          py={4}
          width={"100%"}
          direction="row"
          alignItems={'center'}
          gap={2}
        >
          <Flex direction={'column'}>
            <Heading fontSize={'md'}>{header}</Heading>
            <Text color="fg.muted" fontSize={'sm'}>{message}</Text>
          </Flex>
          <Spacer />
          <Flex direction={{ base: 'column-reverse', md: 'row' }}>
            {buttonText && (
              <Button border={'1px solid'} bg={`${colorScheme}.200`} onClick={buttonClick} disabled={buttonDisabled}>
                {buttonText}
              </Button>
            )}
            {dismissable && (
              <IconButton
                justifyContent={'flex-end'}
                alignItems={{ base: 'baseline', md: 'center' }}
                onClick={onClose}
                icon={<FiX />}
                variant="tertiary"
                aria-label="Close banner"
              />
            )}
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
