import React from 'react';
import { Badge, Flex, Icon, Text } from '@chakra-ui/react';
import { BsFillArrowDownCircleFill, BsFillArrowUpCircleFill, BsDashCircleFill } from 'react-icons/bs';
import { getPercentageDifference } from 'utils/number';
import { toPercentageFormat } from 'utils/format';

export interface GrowthBadgeProps {
  starting?: number;
  ending?: number;
  percentage?: number;
  showRawDelta?: boolean;
}

export default function GrowthBadge({ starting, ending, percentage, showRawDelta }: GrowthBadgeProps) {
  let percentageDifference;

  if (percentage !== undefined) {
    percentageDifference = percentage;
  } else if (starting !== undefined && ending !== undefined) {
    percentageDifference = getPercentageDifference(starting, ending);
  } else {
    percentageDifference = 0;
  }

  const roundedDifference = Math.round(percentageDifference);
  let colorScheme, icon, title;

  if (roundedDifference > 0) {
    colorScheme = 'green';
    icon = BsFillArrowUpCircleFill;
  } else if (roundedDifference < 0) {
    colorScheme = 'red';
    icon = BsFillArrowDownCircleFill;
  } else {
    colorScheme = 'gray';
    icon = BsDashCircleFill;
  }

  if (showRawDelta && starting !== undefined && ending !== undefined) {
    const rawDelta = ending - starting;
    title = rawDelta > 0 ? `+${rawDelta.toFixed(2)}` : rawDelta.toFixed(2);
  } else {
    const formattedPercentage = toPercentageFormat(percentageDifference);
    title = roundedDifference > 0 ? `+${formattedPercentage}` : formattedPercentage;
  }

  return (
    <Badge padding="2px 4px" borderRadius={4} width={'fit-content'} height={'fit-content'} colorScheme={colorScheme}>
      <Flex direction="row" gap={2} alignItems="center">
        <Icon as={icon} w={3} h={3} />
        {title}
      </Flex>
    </Badge>
  );
};
