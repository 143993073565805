import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { UserActions } from 'reducers/User/actions';
import { UserSelectors } from 'reducers/User/selectors';
import {
  Box,
  Flex,
  Heading,
  Spacer,
  Icon,
} from '@chakra-ui/react';
import { NavigationService } from 'services';
import { ArtistModel, ItemModel, UserModel } from 'models';
import { Loader, Hero, ArtistAvatarGrid, ItemGrid, WaitlistCard, CategoryCard, Slider, ListingGrid } from 'components';
import { getSampleSizedArray } from 'utils/array';
import { BsFillArrowRightCircleFill } from 'react-icons/bs';
import { AppConstants } from '../../constants';
import { HomeSelectors } from 'reducers/Home/selectors';
import { HomeActions } from 'reducers/Home/actions';
import { HomeFeedData } from 'models/Content/types';


interface HomeViewProps extends RouterProps {
  user: UserModel;
  userWaitlistActionLoading: boolean;
  joinUserWaitlist: (email: string) => void;
  homeFeedData: HomeFeedData | undefined;
  homeFeedDataLoading: boolean;
  hasHomeFeedData: boolean;
  getFeedContent: () => void;
}

class Home extends Component<HomeViewProps> {
  componentDidMount() {
    const { hasHomeFeedData, getFeedContent } = this.props;
    if (!hasHomeFeedData) {
      getFeedContent();
    }
  }

  onArtistCardClick = (artist: ArtistModel) => {
    this.props.navigate(NavigationService.getArtistDetailsPath(artist.id));
  };

  onItemClick = (item: ItemModel) => {
    this.props.navigate(NavigationService.getItemDetailsPath(item.id));
  };

  onListingItemClick = (id: string) => {
    this.props.navigate(NavigationService.getItemDetailsPath(id));
  };

  onClickArtistSeeMore = () => {
    this.props.navigate(NavigationService.getArtistsPath());
  }

  onClickListingsSeeMore = () => {
    this.props.navigate(NavigationService.getExplorePath() + '?tab=listings');
  }

  onClickFeaturedItemsSeeMore = () => {
    this.props.navigate(NavigationService.getExplorePath() + '?tab=items');
  }

  onClickNewReleasedItemsSeeMore = () => {
    this.props.navigate(NavigationService.getItemSearchPath() + '?sortBy=release_info.date&sortType=descending');
  }

  onBiggestMoverItemsSeeMore = () => {
    this.props.navigate(NavigationService.getItemSearchPath() + '?sortBy=market_summary.last_sale_percentage_change&sortType=descending');
  }

  onClickCategoryCard = (type: string) => {
    const query = `?type=${type}`
    this.props.navigate(NavigationService.getItemSearchPath() + query);
  }

  onExploreButtonClick = () => {
    this.props.navigate(NavigationService.getExplorePath());
  }



  getFeaturedItemSectionData = (): ItemModel[] => {
    const { homeFeedData } = this.props;
    return getSampleSizedArray(homeFeedData?.featuredItems || [], 8);
  }

  renderLandingPageHero() {
    const heros = [
      (
        <Hero
          title="Demystifying The Art Market"
          subtitle='We harness the power of AI to provide real-time market valuations, analysis, and sales history.'
          image="https://storage.googleapis.com/artcore-image-set/assets/artcore-logo-rotating.gif"
          ctaText="Explore"
          onCtaClick={this.onExploreButtonClick} />
      ),
      (
        <Hero
          title="Seamless Portfolio Management"
          subtitle='Traditional inventory management systems can be a headache to handle. Our technology handles everything for you.'
          image="https://storage.googleapis.com/artcore-image-set/content-images/Portfolio%20Ex%20New.png"
          ctaText="Explore"
          onCtaClick={this.onExploreButtonClick} />
      ),
      (
        <Hero
          title="Dedicated Marketplace"
          subtitle='Coming Soon! An efficient, transparent, peer-to-peer marketplace with low fees, vetted sellers, and escrow services.'
          image="https://storage.googleapis.com/artcore-image-set/content-images/Beige%20Artist%208%20Grid%20Collage.png"
          ctaText="Explore"
          onCtaClick={this.onExploreButtonClick} />
      )
    ];

    return (
      <Slider content={heros} enableAutoPlay={true} slidesPerView={1} slidesPerMobileView={1} transitionMs={1000} itemPadding={20} />
    );
  }

  renderArtistSection() {
    const { homeFeedData, homeFeedDataLoading } = this.props;
    const content = homeFeedDataLoading ? (
      <Flex justifyContent="center">
        <Loader />
      </Flex>
    ) : (
      <ArtistAvatarGrid artists={homeFeedData?.featuredArtists || []} rows={2} showArtistName={true} onClick={this.onArtistCardClick} />
    );
    return (
      <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
        <Flex direction='row' padding={{ base: 3, md: 0 }}>
          <Heading size='md'>{'Featured Artists'}</Heading>
          <Spacer />
          <Flex onClick={this.onClickArtistSeeMore} cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }}>
            <Heading fontSize="sm">See More</Heading>
            <Icon as={BsFillArrowRightCircleFill} />
          </Flex>
        </Flex>
        {content}
      </Flex>
    );
  }

  renderFeaturedItemSection() {
    const { homeFeedDataLoading } = this.props;
    return (
      <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
        <Flex onClick={this.onClickFeaturedItemsSeeMore} direction='row' padding={{ base: 3, md: 0 }}>
          <Heading size='md'>{'Featured Items'}</Heading>
          <Spacer />
          <Flex cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }}>
            <Heading fontSize="sm">See More</Heading>
            <Icon as={BsFillArrowRightCircleFill} />
          </Flex>
        </Flex>
        <ItemGrid onItemCardClick={this.onItemClick} items={this.getFeaturedItemSectionData()} isLoading={homeFeedDataLoading} showFilters={false} />
      </Flex>
    );
  }

  renderNewReleasedItemSection() {
    const { homeFeedData, homeFeedDataLoading } = this.props;
    if (homeFeedData?.newReleasedItems) {
      return (
        <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
          <Flex onClick={this.onClickNewReleasedItemsSeeMore} direction='row' padding={{ base: 3, md: 0 }}>
            <Heading size='md'>{'New Releases'}</Heading>
            <Spacer />
            <Flex cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }}>
              <Heading fontSize="sm">See More</Heading>
              <Icon as={BsFillArrowRightCircleFill} />
            </Flex>
          </Flex>
          <ItemGrid onItemCardClick={this.onItemClick} items={homeFeedData.newReleasedItems.slice(0,8)} isLoading={homeFeedDataLoading} showFilters={false} />
        </Flex>
      );
    }
  }

  renderBiggestMoverItemSection() {
    const { homeFeedData, homeFeedDataLoading } = this.props;
    if (homeFeedData?.newReleasedItems) {
      return (
        <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
          <Flex onClick={this.onBiggestMoverItemsSeeMore} direction='row' padding={{ base: 3, md: 0 }}>
            <Heading size='md'>{'Biggest Market Movers'}</Heading>
            <Spacer />
            <Flex cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }}>
              <Heading fontSize="sm">See More</Heading>
              <Icon as={BsFillArrowRightCircleFill} />
            </Flex>
          </Flex>
          <ItemGrid onItemCardClick={this.onItemClick} items={homeFeedData.biggestMarketMoverItems.slice(0,4)} isLoading={homeFeedDataLoading} showFilters={false} />
        </Flex>
      );
    }
  }

  renderListingSection() {
    const { user, homeFeedData, homeFeedDataLoading } = this.props;
    if (homeFeedData?.latestSales) {
      return (
        <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
          <Flex direction='row' padding={{ base: 3, md: 0 }}>
            <Heading size='md'>{'Past Sales'}</Heading>
            <Spacer />
            <Flex onClick={this.onClickListingsSeeMore} cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }}>
              <Heading fontSize="sm">See More</Heading>
              <Icon as={BsFillArrowRightCircleFill} />
            </Flex>
          </Flex>
          <Flex maxH={800} overflow={'scroll'}>
            <ListingGrid gridListings={homeFeedData?.latestSales} isLoading={homeFeedDataLoading} showAssociatedItems={true} showAssociationAction={false} onListingItemClick={this.onListingItemClick}/>
          </Flex>
        </Flex>
      );
    }
  }

  renderCategoryCards() {
    const content = (
      <Flex direction={{ base: 'column', md: 'row' }} gap='60px' alignItems='center'>
        <CategoryCard
          title="Sculptures"
          imageSrc="https://storage.googleapis.com/artcore-image-set/content-images/Sculpture%20Cover.webp"
          buttonText="Explore"
          onButtonClick={() => this.onClickCategoryCard('sculpture')}
        />
        <CategoryCard
          title="Prints"
          imageSrc="https://storage.googleapis.com/artcore-image-set/content-images/Print%20Cover.webp"
          buttonText="Explore"
          onButtonClick={() => this.onClickCategoryCard('prints')}
        />
        <CategoryCard
          title="Misc."
          imageSrc="https://storage.googleapis.com/artcore-image-set/content-images/Misc%20Cover.webp"
          buttonText="Explore"
          onButtonClick={() => this.onClickCategoryCard('misc.')}
        />
      </Flex>
    );
    return (
      <Flex direction='column' textAlign={{ base: 'center', md: 'left' }} gap='60px' marginTop={20}>
        <Flex direction='row' padding={{ base: 3, md: 0 }}>
          <Heading size='md'>{'Explore Categories'}</Heading>
        </Flex>
        {content}
      </Flex>
    );
  }

  renderWaitlistCard() {
    const { user, joinUserWaitlist, userWaitlistActionLoading } = this.props;
    if (!user.isAuthenticated()) {
      return (
        <Flex marginTop={20}>
          <WaitlistCard onButtonClick={joinUserWaitlist} buttonLoading={userWaitlistActionLoading} />
        </Flex>
      );
    }
  }

  render() {
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
        {this.renderLandingPageHero()}
        {this.renderWaitlistCard()}
        {this.renderArtistSection()}
        {this.renderFeaturedItemSection()}
        {this.renderCategoryCards()}
        {this.renderBiggestMoverItemSection()}
        {this.renderNewReleasedItemSection()}
        {this.renderListingSection()}
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    user: UserSelectors.getUser(state),
    userWaitlistActionLoading: UserSelectors.getUserWaitlistActionLoading(state),
    homeFeedData: HomeSelectors.getHomeFeedData(state),
    homeFeedDataLoading: HomeSelectors.getHomeFeedDataLoading(state),
    hasHomeFeedData: HomeSelectors.hasHomeFeedData(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      joinUserWaitlist: (email: string) => UserActions.joinUserWaitlist(email),
      getFeedContent: () => HomeActions.getFeedContent()
    },
    dispatch
  );
}

export const HomeView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Home));
