import { PortfolioModel, ArtistModel, DetailedPortfolioItemModel, PaginationModel, ListingModel, PortfolioHistoryModel } from 'models';
import { ItemPurchaseInfo } from 'models/Portfolio/types';
import { PortfolioActionTypes, PortfolioAction } from './types';

export class PortfolioActions {
  public static getUserPortfolio(): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_USER_PORTFOLIO,
    };
  }

  public static getPortfolioById(id: string): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_BY_ID,
      payload: { id },
    };
  }

  public static getPortfolioArtists(id: string): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_ARTISTS,
      payload: { id },
    };
  }

  public static getPortfolioHistory(id: string): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_HISTORY,
      payload: { id },
    };
  }

  public static getPortfolioItems(id: string): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_ITEMS,
      payload: { id },
    };
  }

  public static getPortfolioListings(id: string, page?: number): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_LISTINGS,
      payload: { id, page },
    };
  }

  public static addItemToPortfolio(itemId: string, purchaseInfo: ItemPurchaseInfo[]): PortfolioAction {
    return {
      type: PortfolioActionTypes.ADD_ITEM_TO_PORTFOLIO,
      payload: { itemId, purchaseInfo }
    };
  }

  public static removeItemFromPortfolio(itemId: string, purchaseInfo: ItemPurchaseInfo[]): PortfolioAction {
    return {
      type: PortfolioActionTypes.REMOVE_ITEM_FROM_PORTFOLIO,
      payload: { itemId, purchaseInfo }
    };
  }

  public static replaceItemInPortfolio(itemId: string, purchaseInfo: ItemPurchaseInfo[]): PortfolioAction {
    return {
      type: PortfolioActionTypes.REPLACE_ITEM_IN_PORTFOLIO,
      payload: { itemId, purchaseInfo }
    };
  }

  public static clearPortfolio(): PortfolioAction {
    return {
      type: PortfolioActionTypes.CLEAR_PORTFOLIO,
    };
  }
}

export class PortfolioInternalActions {
  public static getUserPortfolioSuccess(portfolio: PortfolioModel): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_USER_PORTFOLIO_SUCCESS,
      payload: { portfolio }
    };
  }

  public static getUserPortfolioFailure(): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_USER_PORTFOLIO_FAILURE,
    };
  }

  public static getPortfolioByIdSuccess(portfolio: PortfolioModel): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_BY_ID_SUCCESS,
      payload: { portfolio },
    };
  }

  public static getPortfolioByIdFailure(): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_BY_ID_FAILURE,
    };
  }

  public static getPortfolioArtistsSuccess(artistList: ArtistModel[]): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_ARTISTS_SUCCESS,
      payload: { artistList },
    };
  }

  public static getPortfolioArtistsFailure(): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_ARTISTS_FAILURE,
    };
  }

  public static getPortfolioHistorySuccess(portfolioHistory: PortfolioHistoryModel): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_HISTORY_SUCCESS,
      payload: { portfolioHistory },
    };
  }

  public static getPortfolioHistoryFailure(): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_HISTORY_FAILURE
    };
  }

  public static getPortfolioItemsSuccess(items: DetailedPortfolioItemModel[]): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_ITEMS_SUCCESS,
      payload: { items },
    };
  }

  public static getPortfolioItemsFailure(): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_ITEMS_FAILURE,
    };
  }

  public static getPortfolioListingsSuccess(listings: PaginationModel<ListingModel>): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_LISTINGS_SUCCESS,
      payload: { listings },
    };
  }

  public static getPortfolioListingsFailure(): PortfolioAction {
    return {
      type: PortfolioActionTypes.GET_PORTFOLIO_LISTINGS_FAILURE,
    };
  }

  public static addItemToPortfolioSuccess(portfolioId: string): PortfolioAction {
    return {
      type: PortfolioActionTypes.ADD_ITEM_TO_PORTFOLIO_SUCCESS,
      payload: { portfolioId }
    };
  }

  public static addItemToPortfolioFailure(): PortfolioAction {
    return {
      type: PortfolioActionTypes.ADD_ITEM_TO_PORTFOLIO_FAILURE
    };
  }

  public static removeItemFromPortfolioSuccess(portfolioId: string): PortfolioAction {
    return {
      type: PortfolioActionTypes.REMOVE_ITEM_FROM_PORTFOLIO_SUCCESS,
      payload: { portfolioId }
    };
  }

  public static removeItemFromPortfolioFailure(): PortfolioAction {
    return {
      type: PortfolioActionTypes.REMOVE_ITEM_FROM_PORTFOLIO_FAILURE
    };
  }

  public static replaceItemInPortfolioSuccess(portfolioId: string): PortfolioAction {
    return {
      type: PortfolioActionTypes.REPLACE_ITEM_IN_PORTFOLIO_SUCCESS,
      payload: { portfolioId }
    };
  }

  public static replaceItemInPortfolioFailure(): PortfolioAction {
    return {
      type: PortfolioActionTypes.REPLACE_ITEM_IN_PORTFOLIO_FAILURE
    };
  }
}
