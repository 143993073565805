import { Component } from 'react';
import { Box, Heading, Text, Divider, VStack } from '@chakra-ui/react';
import { connect, Dispatch } from "react-redux";
import { RouterProps, withRouter } from "utils/route";
import { AppConfig } from 'config';
import { AppConstants } from '../../constants';

interface PrivacyContentViewProps extends RouterProps { }

class PrivacyContent extends Component<PrivacyContentViewProps, {}> {

	renderSection(header: string, content: string) {
		return (
			<VStack align="start" spacing={5} textAlign={{ base: 'center', md: 'left' }}>
				<Heading as="h4" size="md" alignSelf={{ base: 'center', md: 'start' }}>{header}</Heading>
				<Text>{content}</Text>
			</VStack>
		);
	}

	render() {
		return (
			<Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
				<VStack align="start" spacing={8}>
					<Heading as="h1" alignSelf={{ base: 'center', md: 'start' }}>Privacy Policy</Heading>

					{this.renderSection("Information Collection and Use", `${AppConfig.APP_NAME} may collect certain personal information, such as your name and email address, when you sign up or use our services. We use this information to provide and improve our services, respond to your inquiries, and personalize your experience.`)}

					<Divider borderColor="gray.200" />

					{this.renderSection("Log Data", `When you use ${AppConfig.APP_NAME}, our servers automatically record certain information, including your IP address, browser type, referring/exit pages, and other usage information. We use this information to analyze trends, administer the site, track users' movements around the site, and gather demographic information about our user base.`)}

					<Divider borderColor="gray.200" />

					{this.renderSection("Cookies", `We use cookies to personalize content, track usage patterns, and improve your experience on ${AppConfig.APP_NAME}. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent.`)}

					<Divider borderColor="gray.200" />

					{this.renderSection("Data Sharing and Disclosure", `${AppConfig.APP_NAME} will not sell, trade, or otherwise transfer your personal information to third parties without your consent, except as outlined in this privacy policy.`)}

					<Divider borderColor="gray.200" />

					{this.renderSection("Security", `The security of your personal information is important to us. We strive to implement and maintain reasonable security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.`)}

					<Divider borderColor="gray.200" />

					{this.renderSection("Changes to This Privacy Policy", `We may update our privacy policy from time to time. Any changes will be posted on this page, and the revised policy will be effective when posted. We encourage you to review this privacy policy periodically for any changes.`)}

					<Divider borderColor="gray.200" />

					{this.renderSection("Contact Us", `If you have any questions or suggestions about our privacy policy, please contact us at ${AppConfig.CONTACT_EMAIL}.`)}

				</VStack>
			</Box>
		);
	}
}

function mapStateToProps() {
	return {};
}

function mapDispatchToProps() {
	return {};
}

export const PrivacyContentView = connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(PrivacyContent));
