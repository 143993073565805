import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { UserSelectors } from 'reducers/User/selectors';
import { UserActions } from 'reducers/User/actions';
import {
	Box,
} from '@chakra-ui/react';
import { ChangePasswordForm, Content, Loader } from 'components';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';
import { ChangePasswordFormData } from 'components/Form/ChangePasswordForm/ChangePasswordForm';

interface UserForgotPasswordViewProps extends RouterProps {
	passwordActionLoading: boolean;
	changeForgottenPassword: (token: string, newPassword: string) => void;
}

class UserForgotPassword extends Component<UserForgotPasswordViewProps, {}> {
	getToken = () => {
		const { location } = this.props;
		const params = new URLSearchParams(location.search);
		const token = params.get('token');
		return token || '';
	}

	onPasswordUpdateFinish = () => {
		this.props.navigate(
			NavigationService.getAuthLoginPath()
		);
	}

	onChangePasswordFormSubmit = (formData: ChangePasswordFormData) => {
		const newPassword = formData.newPassword;
		const token = this.getToken();
		this.props.changeForgottenPassword(token, newPassword);
		this.onPasswordUpdateFinish();
	}

	renderContent() {
		const { passwordActionLoading } = this.props;
		return (
			<ChangePasswordForm onSubmit={this.onChangePasswordFormSubmit} submitText={'Update Password'} submitLoading={passwordActionLoading} forgotMode={true} />
    );
  }

	render() {
		return (
			<Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
				{this.renderContent()}
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		passwordActionLoading: UserSelectors.getUserPasswordActionLoading(state)
	}
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
	return bindActionCreators(
		{
			changeForgottenPassword: (token: string, newPassword: string) => UserActions.changeForgottenPassword(token, newPassword)
		},
		dispatch
	);
}

export const UserForgotPasswordView = connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(UserForgotPassword));
