import { useForm, useFieldArray } from 'react-hook-form';
import moment from 'moment';
import {
  Stack,
  Flex,
  FormControl,
  InputGroup,
  FormLabel,
  InputLeftElement,
  Input,
  Icon,
  Heading
} from '@chakra-ui/react';
import {
  AiFillPlusCircle,
  AiFillMinusCircle
} from 'react-icons/ai';
import {
  BsFillTrashFill
} from 'react-icons/bs';
import {
  RiErrorWarningFill
} from 'react-icons/ri';
import BasicItemCard from '../../Item/BasicItemCard/BasicItemCard';
import ActionButton from '../../Button/ActionButton';
import { ItemModel } from 'models';

export interface PortfolioItemManagementFormProps {
  item: ItemModel;
  defaultFormData?: PortfolioItemManagementFormData;
  onSubmit?: (data: PortfolioItemManagementFormData) => void;
  submitLoading?: boolean;
  removeItem?: boolean;
}

export interface PortfolioItemPurchaseInfo {
  date: string | Date | undefined;
  price: number;
  quantity: number;
}

export interface PortfolioItemManagementFormData {
  purchaseInfo: PortfolioItemPurchaseInfo[];
  numPurchases: number;
}

export default function PortfolioItemManagementForm({ item, defaultFormData, onSubmit, submitLoading, removeItem }: PortfolioItemManagementFormProps) {
  const {
    handleSubmit,
    register,
    control
  } = useForm<PortfolioItemManagementFormData>({ defaultValues: defaultFormData || { numPurchases: 1, purchaseInfo: [{ quantity: 1 }] } });
  const { fields, append, remove } = useFieldArray({ name: 'purchaseInfo', control });

  const onFormSubmit = (data: PortfolioItemManagementFormData) => {
    onSubmit?.(data);
  }

  function renderRemoveWarning() {
    if (fields.length == 0 || removeItem) {
      return (
        <Flex justifyContent={'center'} alignItems={'center'} direction={'row'} gap={1} color={'red.600'}>
          <Icon as={RiErrorWarningFill} />
          <Heading fontSize="sm">Are You Sure You Want To Remove Item?</Heading>
        </Flex>
      );
    }
  }

  function renderPurchaseInfoHeader() {
    return !removeItem && (
      <Flex direction={'row'} gap={2} alignItems={'center'}>
          <Heading fontSize="sm">Purchase Info </Heading>
          <Flex direction={'row'} gap={1}>
            <Icon as={AiFillPlusCircle} cursor={'pointer'} color={'green'} onClick={() => append({ quantity: 1 })} />
            {fields.length > 0 && <Icon as={AiFillMinusCircle} cursor={'pointer'} color={'red'} onClick={() => remove(-1)} />}
          </Flex>
        </Flex>
    );
  }

  function renderPurchaseInfo() {
    if (!removeItem) {
      return fields.map((_, index) => renderPurchaseRow(index));
    }
  }

  function renderPurchaseRow(index: number) {
    return (
      <Flex direction={'row'} gap={2} key={`portfolioPurchaseItem_${index}`}>
        <FormControl width={'60px'} isRequired>
          <FormLabel htmlFor={`purchaseInfo.${index}.quantity`} fontSize="xs">Qty</FormLabel>
          <InputGroup>
            <Input
              {...register(`purchaseInfo.${index}.quantity`, {
                required: 'Purchase Quantity is required',
                validate: {
                  isPositive: v => v && v > 0 || 'Purchase Quantity must be greater than 0',
                }
              })}
              fontSize="sm"
              type="number"
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel htmlFor={`purchaseInfo.${index}.quantity`} fontSize="xs">Price (USD)</FormLabel>
          <InputGroup>
            <InputLeftElement
              pointerEvents='none'
              color='gray.500'
              children='$'
            />
            <Input
              {...register(`purchaseInfo.${index}.price`, {
                required: 'Purchase Price is required',
                validate: {
                  isPositive: v => v && v > 0 || 'Purchase Price must be greater than 0',
                }
              })}
              fontSize="sm"
              type="number"
              placeholder='Amount (USD)'
            />
          </InputGroup>
        </FormControl>
        <FormControl isRequired>
          <FormLabel fontSize="xs" htmlFor="date">Date</FormLabel>
          <Input
            {...register(`purchaseInfo.${index}.date`, {
              required: 'Purchase Date is required',
              max: { value: moment.now(), message: 'Purchase Date must be before current date' },
              valueAsDate: true
            })}
            fontSize="sm"
            type="date"
          />
        </FormControl>
        <Flex alignItems={'flex-end'} marginBottom={3}>
          <Icon as={BsFillTrashFill} cursor={'pointer'} onClick={() => remove(index)} />
        </Flex>
      </Flex>
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={8}>
        <BasicItemCard item={item} />
        {renderPurchaseInfoHeader()}
        {renderRemoveWarning()}
        {renderPurchaseInfo()}
        <Stack alignItems="center">
          <ActionButton type='submit' text={'Submit'} loading={submitLoading} size={'md'} />
        </Stack>
      </Stack>
    </form>
  );
}
