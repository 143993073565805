import { useState } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  Icon,
  Text,
  Flex,
  Box
} from '@chakra-ui/react';
import {
  GiMaterialsScience
} from 'react-icons/gi';
import { MaterialModel } from 'models/Material/Material';
import { convertToArray } from 'utils/array';
import SearchBar from '../../SearchBar/SearchBar';

export interface MaterialDropdownProps {
  materials: MaterialModel[];
  onSelect: (materialIds: string[]) => void;
  selectedMaterialIds?: string[];
  disabled?: boolean;
  title?: string;
}

export default function MaterialDropdown({ materials, onSelect, disabled = false, title = 'Select Materials', selectedMaterialIds = [] }: MaterialDropdownProps) {
  const [selectedMaterials, setSelectedMaterials] = useState<MaterialModel[]>(materials.filter(material => selectedMaterialIds.includes(material.id)));
  const [filter, setFilter] = useState('');

  const onMaterialChange = (newMaterialSelection: string[] | string) => {
    const materialIds: string[] = convertToArray(newMaterialSelection);
    const newMaterials: MaterialModel[] = materials.filter(material => materialIds.includes(material.id));
    setSelectedMaterials(newMaterials);
    if (onSelect) {
      onSelect(materialIds);
    }
  }

  const filteredMaterials = materials.filter(material => material.name.toLowerCase().includes(filter.toLowerCase())); // <-- Filter materials based on filter value

  const menuItems = filteredMaterials.map((material, index) => (
    <MenuItemOption
      key={`MaterialDropdownMenuItem_${index}`}
      value={material.id}>
      {material.name}
    </MenuItemOption>
  ));

  function renderTitle() {
    let dropdownTitle = title;
    if (selectedMaterials.length) {
      dropdownTitle = selectedMaterials.map(material => material.name).join(', ');
    }

    return (
      <Text>{dropdownTitle}</Text>
    );
  }

  return (
    <Menu closeOnSelect={false}>
      <MenuButton as={Button} disabled={disabled} variant={'outline'} width={'100%'} textAlign={'left'} fontSize={'sm'} fontWeight={300} paddingLeft={3}>
        <Flex display={'flex'} flexDirection={'row'} gap={3}>
          <Icon w={4} h={4} color={'gray.500'} as={GiMaterialsScience} />
          {renderTitle()}
        </Flex>
      </MenuButton>
      <MenuList minWidth="240px" height="400px" overflow="scroll" zIndex={200} fontSize={'sm'}>
        <MenuOptionGroup title="Materials" type="checkbox" onChange={onMaterialChange}>
          <Box padding={2} marginBottom={2}>
            <SearchBar onChange={setFilter} showResultBox={false} />
          </Box>
          {menuItems}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
