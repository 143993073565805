import {
  Box,
  Avatar,
  Flex,
  Heading,
  Text,
  Button,
  Spacer,
  Spinner,
  useColorModeValue
} from '@chakra-ui/react';
import { ArtistModel } from 'models';

export interface BasicArtistCardProps {
  artist: ArtistModel;
  onClick?: () => void;
  onArtistTextClick?: () => void;
  avatarSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  titleSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl';
  boldTitle?: boolean;
  showAdditionalInfo?: boolean;
  showButton?: boolean;
  buttonText?: string;
  buttonIcon?: JSX.Element;
  onButtonClick?: () => void;
  buttonLoading?: boolean;
  showBorder?: boolean;
  disabled?: boolean;
}

export enum BasicArtistCardSizingConfig {
  CARD_HEIGHT = '60px',
  PANEL_MIN_HEIGHT = '80px'
}

export enum BasicArtistCardStylingConfig {
  BORDER_COLOR_LIGHT = '#f1f3f7',
  BORDER_COLOR_DARK = '#3f434c'
}

export default function BasicArtistCard({ artist, onClick, onArtistTextClick, onButtonClick, buttonIcon, avatarSize = 'sm', titleSize = 'md', buttonText = '', boldTitle = true, showAdditionalInfo = false , showButton = false, buttonLoading = false, showBorder = true, disabled = false }: BasicArtistCardProps) {
  function renderArtistAdditionalInfo() {
    let additionalData: string = '';
    const birthCountryName = artist.getBirthCountryName();
    const birthYear = artist.getBirthYear();
    if (birthCountryName && birthYear) {
      additionalData = `${birthCountryName}, ${birthYear}`
    } else if (birthCountryName) {
      additionalData = birthCountryName;
    } else if (birthYear) {
      additionalData = `${birthYear}`
    }

    if (showAdditionalInfo && additionalData) {
      return (
        <Text fontSize='xs' fontWeight='light'>
          {additionalData}
        </Text>
      );
    }
  }

  function renderArtistName() {
    const contentProps = {
      _hover: onArtistTextClick ? {color: 'blue.500'} : {},
      fontSize: titleSize,
      cursor: onArtistTextClick ? 'pointer' : '',
      onClick: onArtistTextClick
    }

    if (boldTitle) {
      return (
        <Heading {...contentProps}>
          {artist.name}
        </Heading>
      );
    } else {
      return (
        <Text {...contentProps}>
          {artist.name}
        </Text>
      );
    }
  }

  function renderArtistButton() {
    const buttonLeftIcon = buttonLoading ? <Spinner size="sm" /> : buttonIcon;
    if (showButton) {
      return (
        <Button leftIcon={buttonLeftIcon} border="1px" onClick={onButtonClick} maxWidth="33%">
          {buttonText}
        </Button>
      );
    }
  }

  return (
      <Flex 
        direction='row' 
        alignItems='center' 
        gap='8px' 
        minHeight={BasicArtistCardSizingConfig.CARD_HEIGHT} 
        width="100%" 
        overflow='hidden' 
        onClick={onClick} 
        cursor={onClick && !disabled ? 'pointer' : ''} 
        padding={showBorder ? '10px' : '10px 0px'} 
        border={showBorder ? "1px solid" : 'none'} 
        borderColor={useColorModeValue(BasicArtistCardStylingConfig.BORDER_COLOR_LIGHT, BasicArtistCardStylingConfig.BORDER_COLOR_DARK)}
        _hover={onClick && !disabled ? { 'backgroundColor': useColorModeValue('gray.200', 'gray.800') } : {}} 
      >
        <Avatar marginRight="16px" size={avatarSize} name={artist.name} src={artist.image} />
        <Flex direction="column" textAlign="left">
          {renderArtistName()}
          {renderArtistAdditionalInfo()}
        </Flex>
        <Spacer />
        {renderArtistButton()}
      </Flex>
  );
}
