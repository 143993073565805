import { Cmd, loop, Loop } from 'redux-loop';
import { ListingInternalActions } from './actions';
import { ListingState, ListingAction } from './types';
import { defaultState } from './consts';
import { ListingApiClient, UserApiClient } from 'api';

export enum ListingStateErrors {
  SEARCH = 'Unable to search listings'
}

export class ListingHandlers {
  public static handleSearchListingByQuery(state: ListingState, action: ListingAction): Loop<ListingState, ListingAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        search: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ListingApiClient.searchListingsByQuery, {
        args: [payload?.query || '', payload?.page || 1],
        successActionCreator: ListingInternalActions.searchListingByQuerySuccess,
        failActionCreator: ListingInternalActions.searchListingByQueryFailure,
      })
    );
  }

  public static handleSearchListingByQuerySuccess(state: ListingState, action: ListingAction): ListingState {
    const paginatedListings = action.payload?.paginatedListings || defaultState.paginatedListings;
    return {
      ...state,
      paginatedListings,
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleSearchListingByQueryFailure(state: ListingState): ListingState {
    return {
      ...state,
      error: new Error(ListingStateErrors.SEARCH),
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleSearchListings(state: ListingState, action: ListingAction): Loop<ListingState, ListingAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        search: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ListingApiClient.searchListings, {
        args: [payload?.searchQuery],
        successActionCreator: ListingInternalActions.searchListingsSuccess,
        failActionCreator: ListingInternalActions.searchListingsFailure,
      })
    );
  }

  public static handleSearchListingsSuccess(state: ListingState, action: ListingAction): ListingState {
    const paginatedListings = action.payload?.paginatedListings || defaultState.paginatedListings;
    return {
      ...state,
      paginatedListings,
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleSearchListingsFailure(state: ListingState): ListingState {
    return {
      ...state,
      error: new Error(ListingStateErrors.SEARCH),
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleGetUserFollowingArtistsListings(state: ListingState, action: ListingAction): Loop<ListingState, ListingAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        search: true,
      }
    };
    return loop(
      newState,
      Cmd.run(UserApiClient.getFollowingArtistListings, {
        args: [payload?.page || 1],
        successActionCreator: ListingInternalActions.getUserFollowingArtistsListingsSuccess,
        failActionCreator: ListingInternalActions.getUserFollowingArtistsListingsFailure,
      })
    );
  }

  public static handleGetUserFollowingArtistsListingsSuccess(state: ListingState, action: ListingAction): ListingState {
    const paginatedListings = action.payload?.paginatedListings || defaultState.paginatedListings;
    return {
      ...state,
      paginatedListings,
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleGetUserFollowingArtistsListingsFailure(state: ListingState): ListingState {
    return {
      ...state,
      error: new Error(ListingStateErrors.SEARCH),
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

}
