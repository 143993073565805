import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { ArtistSelectors } from 'reducers/Artist/selectors';
import { MaterialSelectors } from 'reducers/Material/selectors';
import {
  Box
} from '@chakra-ui/react';
import { ArtistModel, MaterialModel } from 'models';
import { ItemDataSuggestionForm } from 'components';
import { AppConstants } from '../../constants';

interface ItemSuggestionViewProps extends RouterProps {
  artistList: ArtistModel[];
  materialList: MaterialModel[];
  materialListLoading: boolean;
  artistListLoading: boolean;
}

class ItemSuggestion extends Component<ItemSuggestionViewProps> {
  renderArtistSuggestionForm() {
    const { artistList, materialList } = this.props;
    return (
      <Box p={{ base: '20px', md: '0px' }}>
        <ItemDataSuggestionForm artistList={artistList} materialList={materialList} displayOptions={['releasePrice', 'date', 'publisher', 'edition', 'dimensions', 'imageUrl', 'name', 'artistId', 'handTreated', 'authenticityInfo', 'materialIds']} requiredOptions={['name', 'imageUrl', 'artistId']} />
      </Box>
    );
  }

  render() {
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
        {this.renderArtistSuggestionForm()}
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    artistList: ArtistSelectors.getArtistList(state),
    artistListLoading: ArtistSelectors.getArtistListLoading(state),
    materialList: MaterialSelectors.getMaterialList(state),
    materialListLoading: MaterialSelectors.getMaterialListLoading(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {},
    dispatch
  );
}

export const ItemSuggestionView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ItemSuggestion));
