export function isValidHttpUrl(urlValue: string): boolean {
  let url;

  try {
    url = new URL(urlValue);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export function extractFirstNumber(value: string): number {
  const splitNumbers = value.replace(/[a-zA-Z!@#$%^&*()/]/g, "");
  return Number(splitNumbers);
}

export function hasNumbers(value: string): boolean {
  return /\d/.test(value);
}

export function hasSpecialCharacters(value: string): boolean {
  return /[~`!@#$%^&*()_+=\-\[\]\\';,./{}|\\":<>?]/g.test(value);
}

export function hasLowerCase(value: string): boolean {
  return /[a-z]/.test(value)
}

export function hasUpperCase(value: string): boolean {
  return /[A-Z]/.test(value)
}

export function isValidUsername(username: string): boolean {
  return /^(?=[a-zA-Z0-9._]{4,20}$)(?!.*[_.]{2})[^_.].*[^_.]$/.test(username)
}

export function isValidEmail(email: string): boolean {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email.toLowerCase());
};

export function capitalizeEachWord(str: string) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
