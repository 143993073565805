import { Loop, LoopReducer } from 'redux-loop';
import { MaterialHandlers } from './handlers';
import { MaterialState, MaterialAction, MaterialActionTypes } from './types';
import { defaultState } from './consts';

export const materialReducer: LoopReducer<MaterialState, MaterialAction> = (
  state: MaterialState = defaultState,
  action: MaterialAction
): MaterialState | Loop<MaterialState, MaterialAction> => {
  switch (action.type) {
    case MaterialActionTypes.GET_MATERIAL_BY_ID:
      return MaterialHandlers.handleGetMaterialById(state, action);
    case MaterialActionTypes.GET_MATERIAL_BY_ID_SUCCESS:
      return MaterialHandlers.handleGetMaterialByIdSuccess(state, action);
    case MaterialActionTypes.GET_MATERIAL_BY_ID_FAILURE:
      return MaterialHandlers.handleGetMaterialByIdFailure(state);
    case MaterialActionTypes.GET_ALL_MATERIALS:
      return MaterialHandlers.handleGetAllMaterials(state);
    case MaterialActionTypes.GET_ALL_MATERIALS_SUCCESS:
      return MaterialHandlers.handleGetAllMaterialsSuccess(state, action);
    case MaterialActionTypes.GET_ALL_MATERIALS_FAILURE:
      return MaterialHandlers.handleGetAllMaterialsFailure(state);
    default:
      return state;
  }
}
