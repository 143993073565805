import React, { useState } from 'react';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Checkbox,
  Flex,
	Box
} from '@chakra-ui/react';
import { MaterialModel } from 'models/Material/Material';
import SearchBar from '../../SearchBar/SearchBar';

export interface MaterialDropdownProps {
  materials: MaterialModel[];
  onSelect: (materialIds: string[]) => void;
  selectedMaterialIds?: string[];
  disabled?: boolean;
}

export default function MaterialTable({
  materials,
  onSelect,
  disabled = false,
  selectedMaterialIds = []
}: MaterialDropdownProps) {
  const [filter, setFilter] = useState('');
  const [selectedMaterials, setSelectedMaterials] = useState<string[]>(selectedMaterialIds);

  const filteredMaterials = materials.filter(material => 
    material.name.toLowerCase().includes(filter.toLowerCase())
  ).sort((a, b) => 
		Number(selectedMaterials.includes(b.id)) - Number(selectedMaterials.includes(a.id))
	);

  const toggleMaterialSelection = (materialId: string) => {
    if (disabled) return;

    let updatedMaterials;
    if (selectedMaterials.includes(materialId)) {
      updatedMaterials = selectedMaterials.filter(id => id !== materialId);
    } else {
      updatedMaterials = [...selectedMaterials, materialId];
    }

    setSelectedMaterials(updatedMaterials);
    onSelect(updatedMaterials);
};

  return (
    <Flex direction="column" width="100%">
      <Flex alignItems="center" marginBottom="4">
        <Box marginBottom={2}>
        	<SearchBar onChange={setFilter} showResultBox={false} disabled={disabled} />
        </Box>
      </Flex>
      <Table size="sm" width="100%">
        <Tbody height="200px" overflowY="scroll" display="block" width="100%">
          {filteredMaterials.map((material) => (
            <Tr key={material.id} onClick={() => toggleMaterialSelection(material.id)} cursor={disabled ? 'not-allowed' : 'pointer'}>
              <Td onClick={(e) => e.stopPropagation()}>
                <Checkbox 
                  isChecked={selectedMaterials.includes(material.id)}
                  onChange={() => toggleMaterialSelection(material.id)}
                  isDisabled={disabled}
									required={false}
                />
              </Td>
              <Td width={'100%'}>{material.name}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Flex>
  );
}
