import { useForm } from 'react-hook-form';
import {
  Box,
  Stack,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { RiLockPasswordFill } from 'react-icons/ri';
import { UserModel } from 'models';
import { PasswordInput } from '../../Input/PasswordInput/PasswordInput';
import UserProfileBadge from '../../User/UserProfileBadge/UserProfileBadge';
import ActionButton from '../../Button/ActionButton';
import { hasNumbers, hasSpecialCharacters } from 'utils/string';

export interface ChangePasswordFormData {
  oldPassword: '',
  newPassword: '',
  newPasswordConfirmation: ''
}

export interface ChangePasswordFormProps {
  onSubmit?: (data: ChangePasswordFormData) => void;
  user?: UserModel;
  submitText?: string;
  submitLoading?: boolean;
  forgotMode?: boolean;
}

export default function ChangePasswordForm({ onSubmit, user, submitLoading, submitText = 'Change Password', forgotMode = false }: ChangePasswordFormProps) {
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<ChangePasswordFormData>();

  const onFormSubmit = (data: ChangePasswordFormData) => {
    onSubmit?.(data);
  }

  function renderOldPasswordInput() {
    if (forgotMode) {
      return null;
    } else {
      return (
        <PasswordInput {...register('oldPassword', {
          required: 'Old password must be specified',
          validate: {
            isDefined: (v: string) => v.length > 0 || 'Old Password Must Be Specified'
          }
        })} />
      );
    }
  }

  function renderNewPasswordInput() {
    return (
      <PasswordInput {...register('newPassword', {
        required: 'New password must be specified',
        validate: {
          minLength: (v: string) => v && v.length >= 8 || 'Password Length Must Be Atleast 8',
          containsNumber: (v: string) => hasNumbers(v) || 'Password Must Contain Number',
          containsSpecialCharacter: (v: string) => hasSpecialCharacters(v) || 'Password Must Contain Special Character'
        }
      })} />
    );
  }

  function renderNewPasswordConfirmationInput() {
    return (
      <PasswordInput {...register('newPasswordConfirmation', {
        required: 'Confirm New Password',
        validate: {
          isEqualToNextPassword: (v: string) => v.length > 0 && v === watch('newPassword') || 'Passwords do not match'
        }
      })} />
    );
  }

  return (
    <Flex direction={'column'}>
      {!!user && <UserProfileBadge user={user} buttonText={'PASSWORD'} buttonIcon={RiLockPasswordFill} />}
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Stack spacing={8}>
            {!forgotMode && (
              <FormControl isRequired isInvalid={!!errors.oldPassword}>
              <FormLabel htmlFor="oldPassword" fontSize="sm">Old Password</FormLabel>
              <InputGroup>
                {renderOldPasswordInput()}
              </InputGroup>
              <FormErrorMessage>
                {errors.oldPassword && errors.oldPassword.message}
              </FormErrorMessage>
            </FormControl>
            )}
            <FormControl isRequired isInvalid={!!errors.newPassword}>
              <FormLabel htmlFor="newPassword" fontSize="sm">New Password</FormLabel>
              <InputGroup>
                {renderNewPasswordInput()}
              </InputGroup>
              <FormErrorMessage>
                {errors.newPassword && errors.newPassword.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isRequired isInvalid={!!errors.newPasswordConfirmation}>
              <FormLabel htmlFor="newPasswordConfirmation" fontSize="sm">New Password Confirmation</FormLabel>
              <InputGroup>
                {renderNewPasswordConfirmationInput()}
              </InputGroup>
              <FormErrorMessage>
                {errors.newPasswordConfirmation && errors.newPasswordConfirmation.message}
              </FormErrorMessage>
            </FormControl>
            <Stack alignItems="center">
              <ActionButton type='submit' text={submitText} loading={submitLoading} />
            </Stack>
          </Stack>
        </form>
      </Box>
    </Flex>
  );
}
