import { Button, Flex, Icon, Spinner, useColorModeValue } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import { useState, useCallback, useEffect } from 'react';

export interface ActionButtonProps {
  text: string;
  icon?: IconType;
  rightIcon?: IconType;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  size?: "md" | "lg" | "sm" | "xs" | undefined;
  inverse?: boolean;
  debounceInterval?: number;
  disableOnClick?: boolean;
}

export default function ActionButton({ text, icon, rightIcon, onClick, loading, disabled, inverse = false, size = 'lg', type = 'button', debounceInterval = 300, disableOnClick = false }: ActionButtonProps) {
  const [isClicked, setIsClicked] = useState(false);
  const [permanentlyDisabled, setPermanentlyDisabled] = useState(false);
  const hoverColor = inverse ? useColorModeValue('gray.200', 'gray.700') : useColorModeValue('gray.700', 'gray.200');
  const backgroundColor = inverse ? useColorModeValue("gray.100", "gray.900") : useColorModeValue("gray.900", "gray.100");
  const color = inverse ? useColorModeValue("black", "white") : useColorModeValue("white", "black");

  const debouncedOnClick = useCallback(() => {
    if (!isClicked && onClick) {
      onClick();
      setIsClicked(true);
      if (disableOnClick) {
        setPermanentlyDisabled(true);
      }
    }
  }, [onClick, isClicked, permanentlyDisabled, disableOnClick]);

  useEffect(() => {
    if (isClicked) {
      const timer = setTimeout(() => {
        setIsClicked(false);
      }, debounceInterval);
      return () => clearTimeout(timer);
    }
  }, [isClicked, debounceInterval, disableOnClick]);

  function renderContent() {
    if (loading) {
      return <Spinner size='sm' />
    } else {
      return (
        <Flex direction={'row'} gap={2}>
          {text}
        </Flex>
      );
    }
  }

  return (
    <Button
      size={size}
      type={type}
      width={{base: '160px', md: '200px'}}
      backgroundColor={backgroundColor}
      color={color}
      fontWeight="bold"
      mt={4}
      onClick={debouncedOnClick}
      disabled={loading || disabled || isClicked || permanentlyDisabled}
      leftIcon={icon && <Icon as={icon}/>}
      rightIcon={rightIcon && <Icon as={rightIcon} />}
      _hover={{ bg: hoverColor }}
      _active={{
        bg: hoverColor,
        transform: 'scale(0.95)',
      }}
    >
      {renderContent()}
    </Button>
  );
}
