import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';

export class ItemDataSuggestionSelectors {
  public static getError(state: ApplicationState): Error | undefined {
    return RootSelectors.getItemDataSuggestionState(state).error;
  }

  public static getItemDataSuggestionCreateLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemDataSuggestionState(state).loading.create;
  }
}
