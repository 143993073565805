import { RawCompositeMarketData } from './types';
import { toDollarFormat, toPercentageFormat } from 'utils/format';

export class CompositeMarketModel {
  averageGrowth: number;
  averageValue: number;
  maxValue: number;
  minValue: number;
  totalValue: number;

  constructor(rawMarketData?: RawCompositeMarketData) {
    this.averageGrowth = rawMarketData?.average_growth || 0;
    this.averageValue = rawMarketData?.average_value || 0;
    this.maxValue = rawMarketData?.max_value || 0;
    this.minValue = rawMarketData?.min_value || 0;
    this.totalValue = rawMarketData?.total_value || 0;
  }

  public getAverageGrowth(): number {
    return Math.round(this.averageGrowth);
  }

  public getAverageValue(): number {
    return Math.round(this.averageValue);
  }

  public getMinValue(): number {
    return Math.round(this.minValue);
  }

  public getMaxValue(): number {
    return Math.round(this.maxValue);
  }

  public getTotalValue(): number {
    return Math.round(this.totalValue);
  }

  public getAverageGrowthForDisplay(): string {
    return toPercentageFormat(this.getAverageGrowth());
  }

  public getAverageValueForDisplay(): string {
    return toDollarFormat(this.getAverageValue());
  }

  public getMinValueForDisplay(): string {
    return toDollarFormat(this.getMinValue());
  }

  public getMaxValueForDisplay(): string {
    return toDollarFormat(this.getMaxValue());
  }

  public getTotalValueForDisplay(): string {
    return toDollarFormat(this.getTotalValue());
  }
}
