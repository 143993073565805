import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { UserModel } from 'models';

export class UserSelectors {
  public static getUser(state: ApplicationState): UserModel {
    return RootSelectors.getUserState(state).user;
  }

  public static getError(state: ApplicationState): Error | undefined {
    return RootSelectors.getUserState(state).error;
  }

  public static getUserLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.user;
  }

  public static getUserPortfolioLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.portfolio;
  }

  public static getUserWishlistLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.wishlist;
  }

  public static getUserFollowingArtistsLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.followingArtists;
  }

  public static getUserWishlistActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.wishlistAction;
  }

  public static getUserArtistActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.artistAction;
  }

  public static getUserImageActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.imageAction;
  }

  public static getUserPasswordActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.passwordAction;
  }

  public static getUserConfirmationActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.confirmationAction;
  }

  public static getUserMailActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.mailAction;
  }

  public static getUserWaitlistActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.waitlistAction;
  }

  public static getUserUpdateActionLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getUserState(state).loading.updateAction;
  }
}
