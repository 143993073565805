import moment, { Moment } from 'moment';
import { Cloneable } from '../Utils/Cloneable';
import { ItemModel } from '../Item/Item';
import { ItemPurchaseInfo, RawDetailedPortfolioItemData } from './types';

export class DetailedPortfolioItemModel extends Cloneable {
  item: ItemModel;
  purchaseInfo: ItemPurchaseInfo[];

  constructor(rawPortfolioItemData?: RawDetailedPortfolioItemData) {
    super();
    this.item = new ItemModel(rawPortfolioItemData?.item);
    this.purchaseInfo = rawPortfolioItemData?.purchase_info.map(purchaseItem => ({
      price: purchaseItem.price,
      quantity: purchaseItem.quantity,
      date: moment(purchaseItem.date)
    })) || [];
  }

  public getTotalPurchaseCount(): number {
    return this.purchaseInfo.reduce((total, purchaseItem) => total + purchaseItem.quantity, 0);
  }
  
  public getPurchaseInfoForDate(targetDate: Moment): ItemPurchaseInfo | null {
    const foundPurchase = this.purchaseInfo.find(purchaseItem => {
      return purchaseItem.date.isSame(targetDate, 'day');
    });

    return foundPurchase || null;
  }

  public getPurchaseInfoForDateCombo(year: number, month?: number, day?: number): ItemPurchaseInfo[] {
    return this.purchaseInfo.filter(purchaseItem => {
      if (day) {
        return (
          purchaseItem.date.year() === year &&
          purchaseItem.date.month() + 1 === month &&
          purchaseItem.date.date() === day
        );
      } else if (month) {
        return (
          purchaseItem.date.year() === year &&
          purchaseItem.date.month() + 1 === month
        );
      } else {
        return purchaseItem.date.year() === year;
      }
    });
  }

  public getMeanPurchasePrice(): number {
    let totalPrice = 0;
    let totalQuantity = 0;
    this.purchaseInfo.forEach(purchaseItem => {
      totalPrice += (purchaseItem.price * purchaseItem.quantity);
      totalQuantity += purchaseItem.quantity;
    });

    if (totalPrice && totalQuantity) {
      return totalPrice / totalQuantity;
    } else {
      return 0;
    }
  }

  public getMeanPurchasePriceForDisplay(currency?: string): string {
    return (this.getMeanPurchasePrice()).toLocaleString('en-US', {
      style: 'currency',
      currency: currency || 'USD',
    });
  }

  public getEarliestPurchaseDate(): Moment {
    return this.purchaseInfo.map(infoItem => infoItem.date).sort((a, b) => a.valueOf() - b.valueOf())[0]
  }
}
