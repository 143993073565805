import { Cmd, loop, Loop } from 'redux-loop';
import { ItemActions, ItemInternalActions } from './actions';
import { ItemState, ItemAction } from './types';
import { defaultState } from './consts';
import { ItemApiClient, ArtistApiClient, UserApiClient } from 'api';
import { ItemModel, MarketModel, ListingModel, ArtistModel } from 'models';

export enum ItemStateErrors {
  ID_FETCH = 'Unable to fetch item',
  ID_LISTINGS_FETCH = 'Unable to fetch item listings',
  ID_MARKET_FETCH = 'Unable to fetch item market',
  ID_ARTIST_FETCH = 'Unable to fetch item artist',
  ID_SUGGESTIONS_FETCH = 'Unable to fetch item suggestions',
  SEARCH = 'Unable to search items',
}

export class ItemHandlers {
  public static handleGetItemById(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        item: true
      }
    };

    return loop(
      newState,
      Cmd.run(ItemApiClient.getItemById, {
        args: [payload?.id || ''],
        successActionCreator: ItemInternalActions.getItemByIdSuccess,
        failActionCreator: ItemInternalActions.getItemByIdFailure,
      })
    );
  }

  public static handleGetItemByIdSuccess(state: ItemState, action: ItemAction): ItemState | Loop<ItemState, ItemAction> {
    const item: ItemModel = action.payload?.item || defaultState.item;

    const newState = {
      ...state,
      item,
      loading: {
        ...state.loading,
        item: false
      }
    };

    return loop(
      newState,
      Cmd.list([
        Cmd.action(ItemActions.getItemMarket(item.id)),
      ])
    );
  }

  public static handleGetItemByIdFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ID_FETCH),
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetItemListings(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        listings: true
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.getItemListings, {
        args: [payload?.id || '', payload?.page || 0],
        successActionCreator: ItemInternalActions.getItemListingsSuccess,
        failActionCreator: ItemInternalActions.getItemListingsFailure,
      })
    );
  }

  public static handleGetItemListingsSuccess(state: ItemState, action: ItemAction): ItemState {
    const { item } = state;
    let listings: ListingModel[] = action.payload?.listings || [];
    const newItem = ItemModel.deepCopy(item);
    newItem.setListings(listings);
    return {
      ...state,
      item: newItem,
      loading: {
        ...state.loading,
        listings: false
      }
    };
  }

  public static handleGetItemListingsFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ID_LISTINGS_FETCH),
      loading: {
        ...state.loading,
        listings: false
      }
    };
  }

  public static handleGetItemMarket(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        market: true
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.getItemMarket, {
        args: [payload?.id || ''],
        successActionCreator: ItemInternalActions.getItemMarketSuccess,
        failActionCreator: ItemInternalActions.getItemMarketFailure,
      })
    );
  }

  public static handleGetItemMarketSuccess(state: ItemState, action: ItemAction): ItemState {
    const { item } = state;
    let market: MarketModel | undefined = action.payload?.market;
    const newItem = ItemModel.deepCopy(item);
    newItem.setItemMarket(market);
    return {
      ...state,
      item: newItem,
      loading: {
        ...state.loading,
        market: false
      }
    };
  }

  public static handleGetItemMarketFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ID_MARKET_FETCH),
      loading: {
        ...state.loading,
        market: false
      }
    };
  }

  public static handleGetItemArtist(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        artist: true
      }
    };
    return loop(
      newState,
      Cmd.run(ArtistApiClient.getArtistByItemId, {
        args: [payload?.id || ''],
        successActionCreator: ItemInternalActions.getItemArtistSuccess,
        failActionCreator: ItemInternalActions.getItemArtistFailure,
      })
    );
  }

  public static handleGetItemArtistSuccess(state: ItemState, action: ItemAction): ItemState {
    const { item } = state;
    let artist: ArtistModel | undefined = action.payload?.artist;
    const newItem = ItemModel.deepCopy(item);
    newItem.setArtist(artist);
    return {
      ...state,
      item: newItem,
      loading: {
        ...state.loading,
        artist: false
      }
    };
  }

  public static handleGetItemArtistFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ID_ARTIST_FETCH),
      loading: {
        ...state.loading,
        artist: false
      }
    };
  }

  public static handleSearchItemByQuery(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      paginatedItems: defaultState.paginatedItems,
      loading: {
        ...state.loading,
        search: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.searchItemsByQuery, {
        args: [payload?.query || '', payload?.page || 1],
        successActionCreator: ItemInternalActions.searchItemByQuerySuccess,
        failActionCreator: ItemInternalActions.searchItemByQueryFailure,
      })
    );
  }

  public static handleSearchItemByQuerySuccess(state: ItemState, action: ItemAction): ItemState {
    const paginatedItems = action.payload?.paginatedItems || defaultState.paginatedItems;
    return {
      ...state,
      paginatedItems,
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleSearchItemByQueryFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.SEARCH),
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }


  public static handleSearchItem(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      paginatedItems: defaultState.paginatedItems,
      loading: {
        ...state.loading,
        search: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.searchItems, {
        args: [payload?.searchQuery],
        successActionCreator: ItemInternalActions.searchItemSuccess,
        failActionCreator: ItemInternalActions.searchItemFailure,
      })
    );
  }

  public static handleSearchItemSuccess(state: ItemState, action: ItemAction): ItemState {
    const paginatedItems = action.payload?.paginatedItems || defaultState.paginatedItems;
    return {
      ...state,
      paginatedItems,
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleSearchItemFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.SEARCH),
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleGetUserFollowingArtistsItems(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        search: true,
      }
    };
    return loop(
      newState,
      Cmd.run(UserApiClient.getFollowingArtistItems, {
        args: [payload?.page || 1],
        successActionCreator: ItemInternalActions.getUserFollowingArtistsItemsSuccess,
        failActionCreator: ItemInternalActions.getUserFollowingArtistsItemsFailure,
      })
    );
  }

  public static handleGetUserFollowingArtistsItemsSuccess(state: ItemState, action: ItemAction): ItemState {
    const paginatedItems = action.payload?.paginatedItems || defaultState.paginatedItems;
    return {
      ...state,
      paginatedItems,
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleGetUserFollowingArtistsItemsFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.SEARCH),
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleGetItemSuggestions(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        items: true
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.getItemSuggestions, {
        args: [payload?.id || ''],
        successActionCreator: ItemInternalActions.getItemSuggestionsSuccess,
        failActionCreator: ItemInternalActions.getItemSuggestionsFailure,
      })
    );
  }

  public static handleGetItemSuggestionsSuccess(state: ItemState, action: ItemAction): ItemState {
    const items = action.payload?.items || [];
    return {
      ...state,
      items,
      loading: {
        ...state.loading,
        items: false
      }
    };
  }

  public static handleGetItemSuggestionsFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.ID_SUGGESTIONS_FETCH),
      loading: {
        ...state.loading,
        items: false
      }
    };
  }

  public static handleGetAllItems(state: ItemState, action: ItemAction): Loop<ItemState, ItemAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        search: true,
      }
    };
    return loop(
      newState,
      Cmd.run(ItemApiClient.getAllItems, {
        args: [payload?.page || 1],
        successActionCreator: ItemInternalActions.getAllItemsSuccess,
        failActionCreator: ItemInternalActions.getAllItemsFailure,
      })
    );
  }

  public static handleGetAllItemsSuccess(state: ItemState, action: ItemAction): ItemState {
    const paginatedItems = action.payload?.paginatedItems || defaultState.paginatedItems;
    return {
      ...state,
      paginatedItems,
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }

  public static handleGetAllItemsFailure(state: ItemState): ItemState {
    return {
      ...state,
      error: new Error(ItemStateErrors.SEARCH),
      loading: {
        ...state.loading,
        search: false,
      }
    };
  }
}
