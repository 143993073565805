import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Stack,
  HStack,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputGroup,
  Input,
  Button,
  Checkbox
} from '@chakra-ui/react';
import { PasswordInput } from '../../Input/PasswordInput/PasswordInput';
import ActionButton from '../../Button/ActionButton';
import { isValidEmail } from 'utils/string';

export interface LoginFormData {
  email: string;
  password: string;
}

export interface LoginFormProps {
  onSubmit?: (data: LoginFormData) => void;
  onForgotPasswordSubmit?: (email: string) => void;
  submitText?: string;
  submitLoading?: boolean;
}

export default function LoginForm({
  onSubmit,
  onForgotPasswordSubmit,
  submitLoading,
  submitText = 'Change Password'
}: LoginFormProps) {
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<LoginFormData>();

  const onFormSubmit = (data: LoginFormData) => {
    if (isForgotPassword) {
      onForgotPasswordSubmit?.(data.email);
    } else {
      onSubmit?.(data);
    }
  };

  function renderEmailInput() {
    return (
      <Input {...register('email', {
        required: 'Email must be specified',
        validate: {
          isDefined: (v: string) => v.length > 0 || 'Email must be specified',
          isValidEmail: (v: string) => isValidEmail(v) || 'Invalid Email Format Provided'
        }
      })} />
    );
  }

  function renderPasswordInput() {
    return (
      <PasswordInput {...register('password', {
        required: 'Password must be specified'
      })} />
    );
  }

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Stack spacing={8}>
        <FormControl isRequired isInvalid={!!errors.email}>
          <FormLabel htmlFor="email" fontSize="sm">Email</FormLabel>
          <InputGroup>
            {renderEmailInput()}
          </InputGroup>
          <FormErrorMessage>
            {errors.email && errors.email.message}
          </FormErrorMessage>
        </FormControl>

        {!isForgotPassword && (
          <FormControl isRequired isInvalid={!!errors.password}>
            <FormLabel htmlFor="password" fontSize="sm">Password</FormLabel>
            <InputGroup>
              {renderPasswordInput()}
            </InputGroup>
            <FormErrorMessage>
              {errors.password && errors.password.message}
            </FormErrorMessage>
          </FormControl>
        )}

        <HStack justify="space-between">
          {!isForgotPassword && (
            <Checkbox defaultChecked>Remember me</Checkbox>
          )}
          <Button
            variant="link"
            colorScheme="blue"
            size="sm"
            onClick={() => setIsForgotPassword(!isForgotPassword)}
          >
            {isForgotPassword ? 'Back to Login' : 'Forgot password?'}
          </Button>
        </HStack>
        <Stack alignItems="center">
          <ActionButton
            type='submit'
            text={isForgotPassword ? 'Request Password' : submitText}
            loading={submitLoading}
          />
        </Stack>
      </Stack>
    </form>
  );
}
