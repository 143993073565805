import { useState } from 'react';
import { AppConfig } from 'config';
import {
  Box,
  Container,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import UserDataForm, { UserDataFormData } from '../Form/UserDataForm/UserDataForm';
import Wizard from '../Wizard/Wizard';
import { getCountryNameFromCountryCode } from 'utils/country';

export interface UserOnboardingCardProps {
  onSubmit?: (data: UserDataFormData) => void;
  submitLoading?: boolean;
}

export default function UserOnboardingCard({ onSubmit, submitLoading = false }: UserOnboardingCardProps) {
  const boxShadow = useColorModeValue('md', 'md-dark');
  const background = useBreakpointValue({ base: 'transparent', sm: 'bg-surface' });
  const [onboardingData, setOnboardingData] = useState<UserDataFormData>({
    country: { code: AppConfig.DEFAULT_COUNTRY_CODE.toUpperCase(), name: getCountryNameFromCountryCode(AppConfig.DEFAULT_COUNTRY_CODE)}
  });

  const handleFormChange = (newData: Partial<UserDataFormData>) => {
    setOnboardingData(prevData => ({ ...prevData, ...newData }));
    console.log('on boarding on change is', onboardingData);
  };

  const onFormSubmit = () => {
    console.log('submission data is', onboardingData);
    onSubmit?.(onboardingData);
  }

  const getBasicFormDefaultData = (): UserDataFormData => {
    let basicFormDefaultData: UserDataFormData = {};
    if (onboardingData.country) {
      basicFormDefaultData.country = onboardingData.country;
    }

    if (onboardingData.birthYear) {
      basicFormDefaultData.birthYear = onboardingData.birthYear;
    }

    if (onboardingData.name) {
      basicFormDefaultData.name = onboardingData.name;
    }

    if (onboardingData.number) {
      basicFormDefaultData.number = onboardingData.number;
    }

    return basicFormDefaultData;
  }

  const getSocialMediaFormDefaultData = (): UserDataFormData => {
    let basicFormDefaultData: UserDataFormData = {};
    if (onboardingData.instagram) {
      basicFormDefaultData.instagram = onboardingData.instagram;
    }

    return basicFormDefaultData;
  }

  function renderWizard() {
    const wizardSteps = [
      { label: 'Basic Info', content: renderBasicInfoForm() },
      { label: 'Social Media', content: renderSocialMediaForm() },
      { label: 'Avatar', content: renderAvatarUploadForm() }
    ];
    return (
      <Wizard steps={wizardSteps} onSubmit={onFormSubmit} loading={submitLoading} />
    );
  }

  function renderBasicInfoForm() {
    return (
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={background}
        boxShadow={{ base: 'none', sm: boxShadow }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <UserDataForm defaultFormData={getBasicFormDefaultData()} displayOptions={['birthYear', 'country', 'name', 'number']} requiredOptions={['name', 'country']} showSubmit={false} onChange={handleFormChange} />
      </Box>
    );
  }

  function renderSocialMediaForm() {
    return (
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={background}
        boxShadow={{ base: 'none', sm: boxShadow }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <UserDataForm defaultFormData={getSocialMediaFormDefaultData()} displayOptions={['instagram']} requiredOptions={[]} showSubmit={false} onChange={handleFormChange} />
      </Box>
    );
  }

  function renderAvatarUploadForm() {
    return (
      <Box
      py={{ base: '0', sm: '8' }}
      px={{ base: '4', sm: '10' }}
      bg={background}
      boxShadow={{ base: 'none', sm: boxShadow }}
      borderRadius={{ base: 'none', sm: 'xl' }}
    >
      <UserDataForm displayOptions={['image']} requiredOptions={[]} showSubmit={false} onChange={handleFormChange} />
    </Box>
    );
  }

  return (
    <Container maxW="lg" py={{ base: '12', md: '24' }} px={{ base: '0', sm: '8' }}>
      {renderWizard()}
    </Container>
  );
}
