import { WishlistModel, ArtistModel, CompositeMarketModel, PaginationModel, ListingModel } from 'models';
import { WishlistActionTypes, WishlistAction } from './types';

export class WishlistActions {
  public static getUserWishlist(): WishlistAction {
    return {
      type: WishlistActionTypes.GET_USER_WISHLIST,
    };
  }

  public static getWishlistById(id: string): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_BY_ID,
      payload: { id },
    };
  }

  public static getWishlistArtists(id: string): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_ARTISTS,
      payload: { id },
    };
  }

  public static getWishlistMarket(id: string): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_MARKET,
      payload: { id },
    };
  }

  public static getWishlistListings(id: string, page?: number): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_LISTINGS,
      payload: { id, page },
    };
  }

  public static addItemToWishlist(itemId: string): WishlistAction {
    return {
      type: WishlistActionTypes.ADD_ITEM_TO_WISHLIST,
      payload: { itemId }
    };
  }

  public static removeItemFromWishlist(itemId: string): WishlistAction {
    return {
      type: WishlistActionTypes.REMOVE_ITEM_FROM_WISHLIST,
      payload: { itemId }
    };
  }

  public static clearWishlist(): WishlistAction {
    return {
      type: WishlistActionTypes.CLEAR_WISHLIST,
    };
  }
}

export class WishlistInternalActions {
  public static getUserWishlistSuccess(wishlist: WishlistModel): WishlistAction {
    return {
      type: WishlistActionTypes.GET_USER_WISHLIST_SUCCESS,
      payload: { wishlist }
    };
  }

  public static getUserWishlistFailure(): WishlistAction {
    return {
      type: WishlistActionTypes.GET_USER_WISHLIST_FAILURE,
    };
  }

  public static getWishlistByIdSuccess(wishlist: WishlistModel): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_BY_ID_SUCCESS,
      payload: { wishlist },
    };
  }

  public static getWishlistByIdFailure(): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_BY_ID_FAILURE,
    };
  }

  public static getWishlistArtistsSuccess(artistList: ArtistModel[]): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_ARTISTS_SUCCESS,
      payload: { artistList },
    };
  }

  public static getWishlistArtistsFailure(): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_ARTISTS_FAILURE,
    };
  }

  public static getWishlistMarketSuccess(market: CompositeMarketModel): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_MARKET_SUCCESS,
      payload: { market },
    };
  }

  public static getWishlistMarketFailure(): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_MARKET_FAILURE
    };
  }

  public static getWishlistListingsSuccess(listings: PaginationModel<ListingModel>): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_LISTINGS_SUCCESS,
      payload: { listings },
    };
  }

  public static getWishlistListingsFailure(): WishlistAction {
    return {
      type: WishlistActionTypes.GET_WISHLIST_LISTINGS_FAILURE,
    };
  }

  public static addItemToWishlistSuccess(wishlistId: string): WishlistAction {
    return {
      type: WishlistActionTypes.ADD_ITEM_TO_WISHLIST_SUCCESS,
      payload: { wishlistId }
    };
  }

  public static addItemToWishlistFailure(): WishlistAction {
    return {
      type: WishlistActionTypes.ADD_ITEM_TO_WISHLIST_FAILURE
    };
  }

  public static removeItemFromWishlistSuccess(wishlistId: string): WishlistAction {
    return {
      type: WishlistActionTypes.REMOVE_ITEM_FROM_WISHLIST_SUCCESS,
      payload: { wishlistId }
    };
  }

  public static removeItemFromWishlistFailure(): WishlistAction {
    return {
      type: WishlistActionTypes.REMOVE_ITEM_FROM_WISHLIST_FAILURE
    };
  }

}
