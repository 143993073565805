import { Component } from 'react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { RouterProps, withRouter } from "utils/route";
import { ApplicationState } from 'reducers/types';
import { ArtistSelectors } from 'reducers/Artist/selectors';
import { ArtistActions } from 'reducers/Artist/actions';
import {
  Box,
  Heading
} from '@chakra-ui/react';
import { ArtistGrid } from 'components';
import { ArtistModel } from 'models';
import { NavigationService } from 'services';
import { AppConstants } from '../../constants';

interface ArtistListViewProps extends RouterProps {
  artistList: ArtistModel[];
  artistListLoading: boolean;
  getAllArtists: () => void;
}

class ArtistList extends Component<ArtistListViewProps, {}> {
  componentDidMount(): void {
    this.props.getAllArtists();
  }

  onArtistCardClick = (artist: ArtistModel) => {
    this.props.navigate(NavigationService.getArtistDetailsPath(artist.id));
  };

  renderHeader() {
    return (
      <Box marginBottom={['40px', '20px', '20px']}>
        <Heading mb={4}>Artists</Heading>
      </Box>
    );
  }

  renderArtistGrid() {
    const { artistList, artistListLoading } = this.props;
    return (
      <ArtistGrid artists={artistList} isLoading={artistListLoading} onArtistCardClick={this.onArtistCardClick} />
    );
  }

  render() {
    return (
      <Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} minWidth={{base: 'auto', md: `${AppConstants.GRIDPAGE_WIDTH}px`}} paddingTop={['80px', '100px', '100px']} justifySelf="center">
        {this.renderArtistGrid()}
      </Box>
    );
  }
}

function mapStateToProps(state: ApplicationState) {
  return {
    artistList: ArtistSelectors.getArtistList(state),
    artistListLoading: ArtistSelectors.getArtistListLoading(state)
  }
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
  return bindActionCreators(
    {
      getAllArtists: ArtistActions.getAllArtists,
    },
    dispatch
  );
}

export const ArtistListView = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ArtistList));
