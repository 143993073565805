import { Component } from 'react';
import { AppConfig } from 'config';
import {
	Stack,
	Box,
	Heading,
	Flex,
	Text
} from '@chakra-ui/react';
import { connect, Dispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { UserSelectors } from 'reducers/User/selectors';
import { UserActions } from 'reducers/User/actions';
import { RouterProps, withRouter } from "utils/route";
import { WaitlistCard, Logo, HomeInfoBlock } from 'components';
import { UserModel } from 'models';
import { AppConstants } from '../../constants';
import { ApplicationState } from 'reducers/types';
import marketplaceImage from '../../assets/images/marketplace.webp';
import portfolioManagementImage from '../../assets/images/portfolio-management.png';
import priceAnalysisImage from '../../assets/images/price-analysis.jpeg';

interface JoinWaitlistViewProps extends RouterProps {
	user: UserModel;
	userWaitlistActionLoading: boolean;
	joinUserWaitlist: (email: string) => void;
}

class JoinWaitlist extends Component<JoinWaitlistViewProps, {}> {
	renderHomeInfoBlocks() {
		const content = [
			{ imageUrl: priceAnalysisImage, header: 'Realtime Price Data', content: 'We harness the power of AI to provide real-time market valuations, analysis, and sales history.' },
			{ imageUrl: portfolioManagementImage, header: 'Portfolio Management', content: 'Traditional inventory management systems can be a headache to handle. Our technology handles everything for you.' },
			{ imageUrl: marketplaceImage, header: 'Dedicated Marketplace', content: 'Coming Soon! An efficient, peer-to-peer marketplace with low fees, and the ability to use us as the middleman.' }
		]
		return (
			<Flex gap={{ base: 8, md: 20 }} direction={'column'} marginTop={20} marginBottom={20} textAlign={{ base: 'center', md: 'left' }} paddingTop={5} paddingBottom={5}>
				<Flex gap={{ base: 8, md: 20 }} direction={{ base: 'column', sm: 'column', md: 'row' }}>
					{content.map((infoContent, index) => <HomeInfoBlock key={`homePage_infoBlock_${index}`} header={infoContent.header} content={infoContent.content} imageUrl={infoContent.imageUrl} />)}
				</Flex>
			</Flex>
		);
	}

	render() {
		const { joinUserWaitlist, userWaitlistActionLoading } = this.props;
		return (
			<Box maxWidth={`${AppConstants.GRIDPAGE_WIDTH}px`} paddingTop={['80px', '100px', '100px']} justifySelf="center" minWidth={['100%', `${AppConstants.GRIDPAGE_WIDTH}px`]}>
				<Flex direction={'column'} alignItems={'center'}>
					<Logo height={'120px'} width={'120px'} />
					<Stack marginTop={10} marginBottom={20}>
						<Heading fontSize={'4xl'}>Join our waitlist into the next generation of art collecting</Heading>
						<Text>{AppConfig.APP_NAME} is currently on an invite-only mode. Sign up to get early access</Text>
					</Stack>
					<WaitlistCard onButtonClick={joinUserWaitlist} buttonLoading={userWaitlistActionLoading} />
					{this.renderHomeInfoBlocks()}
				</Flex>
			</Box>
		);
	}
}

function mapStateToProps(state: ApplicationState) {
	return {
		user: UserSelectors.getUser(state),
		userWaitlistActionLoading: UserSelectors.getUserWaitlistActionLoading(state),
	};
}

function mapDispatchToProps(dispatch: Dispatch<ApplicationState>) {
	return bindActionCreators(
		{
			joinUserWaitlist: (email: string) => UserActions.joinUserWaitlist(email)
		},
		dispatch
	);
}

export const JoinWaitlistView = connect(
	mapStateToProps,
	mapDispatchToProps
)(withRouter(JoinWaitlist));
