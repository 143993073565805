import { ReactNode } from 'react';
import { chakra, VisuallyHidden, useColorModeValue } from '@chakra-ui/react';

export interface SocialButtonProps {
  children: ReactNode;
  label: string;
}

export default function SocialButton({
  children,
  label
}: SocialButtonProps) {
  return (
    <chakra.button
      bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
      rounded={'full'}
      w={8}
      h={8}
      display={'inline-flex'}
      alignItems={'center'}
      justifyContent={'center'}
      transition={'background 0.3s ease'}
      _hover={{
        bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};
