import { UrlConfig } from 'config';
import { JSONObject } from 'models/Api/types';
import { ApiResponse } from 'models/Api/ApiResponse';
import { AjaxService } from 'services';

export class ListingDataSuggestionApiClient {
  public static async createListingDataSuggestion(listingId: string, data: JSONObject): Promise<string> {
    const url = UrlConfig.LISTING_DATA_SUGGESTION.LISTING_DATA_SUGGESTION_CREATE;
    const response: ApiResponse<string> = await AjaxService.postData(url, {
      listing_id: listingId,
      data: data
    });
    return response.data;
  }
}
