import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { SalesVenueModel } from 'models';

export class SalesVenueSelectors {
  public static getSalesVenue(state: ApplicationState): SalesVenueModel {
    return RootSelectors.getSalesVenueState(state).salesVenue;
  }

  public static getSalesVenueList(state: ApplicationState): SalesVenueModel[] {
    return RootSelectors.getSalesVenueState(state).salesVenueList;
  }

  public static getSalesVenueError(state: ApplicationState): Error | undefined {
    return RootSelectors.getSalesVenueState(state).error;
  }

  public static getSalesVenueLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getSalesVenueState(state).loading.item;
  }

  public static getSalesVenueListLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getSalesVenueState(state).loading.list;
  }
}
