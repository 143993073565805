import { Box, Heading, useColorModeValue, Stack, StackDivider, Text } from '@chakra-ui/react';
import { ItemType } from 'models/Item/types';

interface TypeDistributionTooltipProps {
  active?: boolean;
  payload?: Array<{
    name: ItemType | string;
    value: number;
    percentage: string;
  }> | any[];
  label?: string;
};

export default function TypeDistributionTooltip(props: TypeDistributionTooltipProps) {
  const { active, payload } = props;
  if (!active || !payload || payload.length === 0) return null;

	const itemName = payload[0].name;
  const itemCount = payload[0].value;
	const percentage = payload?.[0].payload?.percentage;

  return (
    <Box borderWidth="1px" borderRadius="lg" p={4} shadow="md" backgroundColor={useColorModeValue("white", "black")} zIndex={10}>
      <Stack divider={<StackDivider />} spacing='4' textAlign={'left'}>
        <Box mb={4}>
            <Heading fontSize='md'>{itemName}</Heading>
        </Box>
        <Box>
          <Text fontSize='xs' textTransform='uppercase'>
            Total Items
          </Text>
          <Heading pt='2' fontSize='sm'>
            {itemCount}
          </Heading>
        </Box>
        {!!percentage && (
          <Box>
            <Text fontSize='xs' textTransform='uppercase'>
              Percentage
            </Text>
            <Heading pt='2' fontSize='sm'>
              {percentage}%
            </Heading>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
