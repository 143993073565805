import {
  Box,
  Image,
  Heading,
  Flex,
  Spacer,
  Stack,
  Icon,
  Text,
  Tag,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  useColorModeValue
} from '@chakra-ui/react';
import { ListingModel, ItemModel } from 'models';
import {
  BsFillArrowRightCircleFill
} from 'react-icons/bs';
import {
  AiOutlinePlusCircle
} from 'react-icons/ai';
import {
  GiThorHammer
} from 'react-icons/gi';
import {
  MdPendingActions
} from 'react-icons/md';
import { IoPricetagOutline } from "react-icons/io5";
import { BiRuler } from 'react-icons/bi';
import GrowthBadge from '../../GrowthBadge/GrowthBadge';
import PricePerCmIcon from 'components/CustomIcons/PricePerCmIcon';

export interface ListingAccordionProps {
  listing: ListingModel;
  onClick?: () => void;
  onItemClick?: (id: string) => void;
  showAssociatedItem?: boolean;
  showAssociationAction?: boolean;
  onItemSuggestionClick?: (listing: ListingModel) => void;
  onTagListingClick?: (listing: ListingModel) => void;
}

export enum ListingAccordionSizingConfig {
  CARD_BORDER_WIDTH = '2px',
  IMAGE_WIDTH = '80px',
  IMAGE_HEIGHT = '80px',
  PANEL_MIN_HEIGHT = '80px'
}

export enum ListingAccordionStylingConfig {
  SIDE_BORDER_COLOR_LIGHT = '#f1f3f7',
  SIDE_BORDER_COLOR_DARK = '#3f434c'
}

export default function ListingAccordion({ listing, onClick, onItemClick, showAssociatedItem, showAssociationAction, onItemSuggestionClick, onTagListingClick }: ListingAccordionProps) {
  function renderListingLabels() {
    const labels = [];

    if (listing.isOriginal()) {
      labels.push(
        <Tag size={'sm'} key={'sm'} variant='solid' colorScheme={'pink'} width={'fit-content'}>
          {listing.type}
        </Tag>
      );
    }

    if (listing.isSet()) {
      labels.push(
        <Tag size={'sm'} key={'sm'} variant='solid' colorScheme={'purple'}>
          {listing.type}
        </Tag>
      );
    }

    if (!listing.isAuthentic()) {
      labels.push(
        <Tag size={'sm'} key={'sm'} variant='solid' colorScheme={'red'}>
          Fake
        </Tag>
      );
    }

    return (
      <Flex direction={'row'} gap={2} justifyContent={{ base: 'flex-start', md: 'center' }}>
        {labels}
      </Flex>
    )
  }

  function renderListingSizingInfo() {
    if (listing.isOriginal() && listing.hasDimensions()) {
      return (
        <Flex direction={'row'} gap={1}>
          <Icon as={BiRuler} size={'xs'} />
          <Text fontSize={'xs'}>{listing.getDimensionsForDisplay()}</Text>
        </Flex>
      );
    }
  }

  function renderPricePeCm2Badge() {
    if (listing.isOriginal() && listing.hasDimensions()) {
      const label = listing.getPricePerCm2ForDisplay();
      if (label) {
        return (
          <Tag fontSize={'xs'} fontWeight={'bold'}>
            <Icon as={PricePerCmIcon} />
            {label}
          </Tag>
        );
      }
    }
  }

  function renderGrowthBadge() {
    const item = listing.getAssociatedItem();
    if (showAssociatedItem && !!item) {
      return (
        <Flex justifyContent={{ base: "flex-start", md: "flex-end" }} marginTop={1}>
          <GrowthBadge starting={item.getEstimatedMarketPrice()} ending={listing.rawPrice} />
        </Flex>
      );
    }
  }

  function renderListingPrice() {
    if (!listing.price.toLowerCase().includes('usd')) {
      return (
        <Text fontSize="xs"> • {listing.price}</Text>
      );
    }
  }

  function renderListingBox() {
    return (
      <Box width="100%" overflow='hidden' onClick={onClick} cursor={onClick ? 'pointer' : ''}>
        <Flex direction="row" justifyContent="center" alignItems="center">
          <Image loading="lazy" fit={'contain'} src={listing.image} fallbackSrc={`https://via.placeholder.com/${ListingAccordionSizingConfig.IMAGE_HEIGHT}`} height={ListingAccordionSizingConfig.IMAGE_HEIGHT} width={ListingAccordionSizingConfig.IMAGE_WIDTH} />
          <Flex width="100%" direction={{ 'base': 'column', 'md': 'row' }} marginLeft="20px">
            <Box p={{ base: 1, md: 6 }} width={{ base: '100%', md: '40%' }} textAlign={'left'}>
              <Box lineHeight='tight'>
                <Flex alignItems="center">
                  <Heading fontSize="sm" width={'fit-content'} overflow={'nowrap'}>
                    {listing.name}
                  </Heading>
                </Flex>
                <Text fontSize="xs">{listing.artist}</Text>
                {renderListingSizingInfo()}
              </Box>
            </Box>
            <Box p={{ base: 1, md: 6 }} width={{ base: '100%', md: '30%' }} textAlign={{ base: 'left', md: 'center' }}>
              <Heading fontSize={{ base: 'xs', md: 'sm' }}>{listing.getFormattedDate()}</Heading>
              <Text fontSize="xs">{listing.source}</Text>
              {renderListingLabels()}
            </Box>
            <Box p={{ base: 1, md: 6 }} width={{ base: '100%', md: '30%' }} textAlign={{ base: 'left', md: 'right' }}>
              <Flex direction={'row'} wrap={'wrap'} gap={1} alignItems={'center'} justifyContent={{ base: 'flex-start', md: 'flex-end' }}>
                <Heading fontSize="sm">{listing.getRawPriceForDisplay()}</Heading>
                {renderListingPrice()}
              </Flex>
              <Flex direction='row' gap={1} alignItems={'center'} justifyContent={{ base: 'flex-start', md: 'flex-end' }}>
                <Text fontSize="xs"> Hammer Price </Text>
                <Icon as={GiThorHammer} w={3} h={3} />
              </Flex>
              {renderPricePeCm2Badge()}
              {renderGrowthBadge()}
            </Box>
          </Flex>
        </Flex>
      </Box>
    );
  }

  function renderAssociatedItem() {
    const item = listing.getAssociatedItem();
    if (item) {
      return (
        <Box minHeight={ListingAccordionSizingConfig.PANEL_MIN_HEIGHT} paddingTop={2}>
          <Stack gap={2}>
            <Flex direction={'row'} gap={2} alignItems={'center'}>
              <Heading fontSize="sm">Associated Item</Heading>
              <Spacer />
              <Flex cursor={'pointer'} gap={1} alignItems={'center'} _hover={{ color: "blue.500" }} onClick={() => onItemClick?.(item.id)}>
                <Heading fontSize="xs">See Item</Heading>
                <Icon as={BsFillArrowRightCircleFill} w={3} h={3} />
              </Flex>
            </Flex>
            {renderAssociatedItemData(item)}
          </Stack>
        </Box>
      );
    } else {
      return (
        <Box minHeight={ListingAccordionSizingConfig.PANEL_MIN_HEIGHT} paddingTop={2}>
          <Stack gap={4}>
            <Flex direction={'row'} gap={2} alignItems={'center'}>
              <Heading fontSize="sm">No Tagged Item</Heading>
            </Flex>
            {renderAssociatedItemEmptyContent()}
          </Stack>
        </Box>
      );
    }
  }

  function renderAssociatedItemEmptyContent() {
    if (listing.isGroupingPending()) {
      return (
        <Flex direction="row" alignItems="center" justifyContent="flex-start" color="gray.500">
          <Icon as={MdPendingActions} marginRight={2} width={8} height={8} />
          <Flex direction="column" textAlign="left" gap={2}>
            <Text fontSize="sm">Item Suggestion Pending Approval</Text>
          </Flex>
        </Flex>
      );
    } else {
      return (
        <Flex direction={'row'} gap={2}>
          <Flex direction="row" alignItems="center" justifyContent="flex-start" _hover={{ color: "blue.500", cursor: 'pointer' }} onClick={() => onItemSuggestionClick?.(listing)}>
            <Icon as={AiOutlinePlusCircle} marginRight={2} width={8} height={8} />
            <Flex direction="column" textAlign="left" gap={2}>
              <Heading fontSize="sm">Suggest Item</Heading>
            </Flex>
          </Flex>
          <Flex direction="row" alignItems="center" justifyContent="flex-start" _hover={{ color: "blue.500", cursor: 'pointer' }} onClick={() => onTagListingClick?.(listing)}>
            <Icon as={IoPricetagOutline} marginRight={2} width={8} height={8} />
            <Flex direction="column" textAlign="left" gap={2}>
              <Heading fontSize="sm">Tag Sale</Heading>
            </Flex>
          </Flex>
        </Flex>
      );
    }
  }

  function renderItemImage(item: ItemModel) {
    return <Image fit={'contain'} marginRight={4} src={item.image} alt={item.name} width={ListingAccordionSizingConfig.IMAGE_WIDTH} height={ListingAccordionSizingConfig.IMAGE_HEIGHT} />;
  }

  function renderPricingData(item: ItemModel) {
    if (item.hasMarketSummaryData()) {
      return (
        <Stack>
          <Flex direction="row" gap={2} alignItems="center">
            <Flex direction={'column'} textAlign={'right'}>
              <Heading fontSize="sm">{item.getEstimatedMarketPriceForDisplay()}</Heading>
              <Text fontSize={'10px'}>Market Price</Text>
            </Flex>
            <Flex direction="row" gap={2} alignItems="center" justifyContent="flex-end" fontSize={'xs'}>
              <Flex
                padding={1}
                align={'center'}
                justify={'center'}
                rounded={'full'}
                border="1px">
                <Icon as={GiThorHammer} w={3} h={3} />
              </Flex>
              {item.getSoldListingsQuantity()}
            </Flex>
          </Flex>
        </Stack>
      );
    }
  }

  function renderAssociatedItemData(item: ItemModel) {
    return (
      <Flex direction="row" alignItems="center" justifyContent="flex-start">
        {renderItemImage(item)}
        <Flex direction="column" textAlign="left" gap={2}>
          <Heading fontSize="sm" _hover={onItemClick ? { color: "blue.500", cursor: "pointer" } : {}} onClick={() => onItemClick?.(item.id)}>{item.name}</Heading>
          <Text fontSize="xs">{item.getArtistName()}</Text>
        </Flex>
        <Spacer />
        {renderPricingData(item)}
      </Flex>
    )
  }

  if (listing.isOriginal() || listing.isSet() || !showAssociatedItem || (!listing.getAssociatedItem() && !showAssociationAction)) {
    return (
      <Box
        paddingLeft={4}
        paddingTop={4}
        paddingBottom={4}
        paddingRight={8}
        border={ListingAccordionSizingConfig.CARD_BORDER_WIDTH}
        borderColor={useColorModeValue(ListingAccordionStylingConfig.SIDE_BORDER_COLOR_LIGHT, ListingAccordionStylingConfig.SIDE_BORDER_COLOR_DARK)}
      >
        {renderListingBox()}
      </Box>
    );
  } else {
    return (
      <Accordion
        allowToggle
        borderLeft={ListingAccordionSizingConfig.CARD_BORDER_WIDTH}
        borderLeftColor={useColorModeValue(ListingAccordionStylingConfig.SIDE_BORDER_COLOR_LIGHT, ListingAccordionStylingConfig.SIDE_BORDER_COLOR_DARK)}
        borderRight={ListingAccordionSizingConfig.CARD_BORDER_WIDTH}
        borderRightColor={useColorModeValue(ListingAccordionStylingConfig.SIDE_BORDER_COLOR_LIGHT, ListingAccordionStylingConfig.SIDE_BORDER_COLOR_DARK)}
      >
        <AccordionItem>
          <AccordionButton>
            {renderListingBox()}
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel
            borderTop={ListingAccordionSizingConfig.CARD_BORDER_WIDTH}
            borderTopColor={useColorModeValue(ListingAccordionStylingConfig.SIDE_BORDER_COLOR_LIGHT, ListingAccordionStylingConfig.SIDE_BORDER_COLOR_DARK)}
          >
            {renderAssociatedItem()}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }
}
