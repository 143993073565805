import { Loop, LoopReducer } from 'redux-loop';
import { ListingDataSuggestionHandlers } from './handlers';
import { ListingDataSuggestionState, ListingDataSuggestionAction, ListingDataSuggestionActionTypes } from './types';
import { defaultState } from './consts';

export const listingDataSuggestionReducer: LoopReducer<ListingDataSuggestionState, ListingDataSuggestionAction> = (
  state: ListingDataSuggestionState = defaultState,
  action: ListingDataSuggestionAction
): ListingDataSuggestionState | Loop<ListingDataSuggestionState, ListingDataSuggestionAction> => {
  switch (action.type) {
    case ListingDataSuggestionActionTypes.CREATE_LISTING_DATA_SUGGESTION:
      return ListingDataSuggestionHandlers.handleCreateListingDataSuggestion(state, action);
    case ListingDataSuggestionActionTypes.CREATE_LISTING_DATA_SUGGESTION_SUCCESS:
      return ListingDataSuggestionHandlers.handleCreateListingDataSuggestionSuccess(state);
    case ListingDataSuggestionActionTypes.CREATE_LISTING_DATA_SUGGESTION_FAILURE:
      return ListingDataSuggestionHandlers.handleCreateListingDataSuggestionFailure(state);
    default:
      return state;
  }
}
