import {
  useState
} from 'react';
import {
  Box,
  Button,
  Collapse
} from "@chakra-ui/react";

export interface CollapsableContentProps {
  text: JSX.Element | string;
  startingHeight?: number;
}

export default function CollapsableContent({
  text,
  startingHeight = 40
}: CollapsableContentProps) {
  const [show, setShow] = useState(false);

  const handleToggle = () => setShow(!show);

  return (
    <Box>
      <Collapse startingHeight={startingHeight} in={show}>
        {text}
      </Collapse>
      <Button size='sm' onClick={handleToggle} mt='1rem'>
        Show {show ? 'Less' : 'More'}
      </Button>
    </Box>
  );
}
