import { Cmd, loop, Loop } from 'redux-loop';
import { ListingDataSuggestionInternalActions } from './actions';
import { ListingDataSuggestionState, ListingDataSuggestionAction } from './types';
import { ListingDataSuggestionApiClient } from 'api';

export enum ListingDataSuggestionStateErrors {
  CREATE = 'Unable to create listing data suggstion',
}

export class ListingDataSuggestionHandlers {
  public static handleCreateListingDataSuggestion(state: ListingDataSuggestionState, action: ListingDataSuggestionAction): Loop<ListingDataSuggestionState, ListingDataSuggestionAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        create: true
      }
    };

    return loop(
      newState,
      Cmd.run(ListingDataSuggestionApiClient.createListingDataSuggestion, {
        args: [payload?.listingId || '', payload?.data || {}],
        successActionCreator: ListingDataSuggestionInternalActions.createListingDataSuggestionSuccess,
        failActionCreator: ListingDataSuggestionInternalActions.createListingDataSuggestionFailure,
      })
    );
  }

  public static handleCreateListingDataSuggestionSuccess(state: ListingDataSuggestionState): ListingDataSuggestionState {
    return {
      ...state,
      loading: {
        ...state.loading,
        create: false
      }
    };
  }

  public static handleCreateListingDataSuggestionFailure(state: ListingDataSuggestionState): ListingDataSuggestionState {
    return {
      ...state,
      error: new Error(ListingDataSuggestionStateErrors.CREATE),
      loading: {
        ...state.loading,
        create: false
      }
    };
  }
}
