import { Cmd, loop, Loop } from 'redux-loop';
import { ItemDataSuggestionInternalActions } from './actions';
import { ItemDataSuggestionState, ItemDataSuggestionAction } from './types';
import { ItemDataSuggestionApiClient } from 'api';

export enum ItemDataSuggestionStateErrors {
  CREATE = 'Unable to create item data suggstion',
}

export class ItemDataSuggestionHandlers {
  public static handleCreateItemDataSuggestion(state: ItemDataSuggestionState, action: ItemDataSuggestionAction): Loop<ItemDataSuggestionState, ItemDataSuggestionAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        create: true
      }
    };

    return loop(
      newState,
      Cmd.run(ItemDataSuggestionApiClient.createItemDataSuggestion, {
        args: [payload?.itemId || '', payload?.data || {}],
        successActionCreator: ItemDataSuggestionInternalActions.createItemDataSuggestionSuccess,
        failActionCreator: ItemDataSuggestionInternalActions.createItemDataSuggestionFailure,
      })
    );
  }

  public static handleCreateItemDataSuggestionSuccess(state: ItemDataSuggestionState): ItemDataSuggestionState {
    return {
      ...state,
      loading: {
        ...state.loading,
        create: false
      }
    };
  }

  public static handleCreateItemDataSuggestionFailure(state: ItemDataSuggestionState): ItemDataSuggestionState {
    return {
      ...state,
      error: new Error(ItemDataSuggestionStateErrors.CREATE),
      loading: {
        ...state.loading,
        create: false
      }
    };
  }
}
