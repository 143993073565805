import { Moment } from 'moment';

export interface CountryData {
  code: string;
  name: string;
}

export interface RawPhoneData {
  base_number: number;
  country_code: string;
}

export interface PhoneData {
  baseNumber: number;
  countryCode: string;
}

export interface MeasurementData {
  l?: number;
  w?: number;
  h?: number;
}

export enum MeasurementUnit {
  IN = 'in',
  CM = 'cm'
}

export type ToastStatus = 'info' | 'success' | 'warning' | 'error' | undefined;

export interface Toast {
  title: string;
  description?: string;
  status?: ToastStatus;
  duration?: number;
  id?: number;
}

export interface RawTimestampData {
  updated_at: string;
  created_at: string;
}

export interface TimestampData {
  updatedAt: Moment | null;
  createdAt: Moment | null;
}

export enum MarketDateFilter {
	MAX = 'MAX',
	FIVE_YEARS = '5Y',
	THREE_YEARS = '3Y',
	ONE_YEAR = '1Y',
	SIX_MONTHS = '6M'
}
