import { UrlConfig } from 'config';
import { JSONObject } from 'models/Api/types';
import { ApiResponse } from 'models/Api/ApiResponse';
import { AjaxService } from 'services';

export class ItemDataSuggestionApiClient {
  public static async createItemDataSuggestion(itemId: string, data: JSONObject): Promise<string> {
    const url = UrlConfig.ITEM_DATA_SUGGESTION.ITEM_DATA_SUGGESTION_CREATE;
    const response: ApiResponse<string> = await AjaxService.postData(url, {
      item_id: itemId,
      data: data
    });
    return response.data;
  }
}
