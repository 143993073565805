import { UrlConfig } from 'config';
import { WishlistModel, ArtistModel, CompositeMarketModel, PaginationModel, ListingModel } from 'models';
import { RawWishlistData } from 'models/Wishlist/Wishlist';
import { RawArtistData } from 'models/Artist/Artist';
import { RawListingData } from 'models/Listing/types';
import { RawPaginationData } from 'models/Pagination/Pagination';
import { RawCompositeMarketData } from 'models/Market/types';
import { ApiResponse } from 'models/Api/ApiResponse';
import { AjaxService } from 'services';

export class WishlistApiClient {
  public static async getWishlistById(id: string): Promise<WishlistModel> {
    const url = UrlConfig.WISHLIST.WISHLIST_BY_ID + `${id}`;
    const response: ApiResponse<RawWishlistData> = await AjaxService.getData(url);
    const wishlist = WishlistModel.fromRawWishlistData(response.data);
    return wishlist;
  }

  public static async getWishlistArtists(id: string): Promise<ArtistModel[]> {
    const url = UrlConfig.WISHLIST.WISHLIST_BY_ID + `${id}/artists`;
    const response: ApiResponse<RawArtistData[]> = await AjaxService.getData(url);
    const artistList = response.data.map((rawArtist: RawArtistData) => new ArtistModel(rawArtist));
    return artistList;
  }

  public static async getWishlistMarket(id: string): Promise<CompositeMarketModel> {
    const url = UrlConfig.WISHLIST.WISHLIST_BY_ID + `${id}/market`;
    const response: ApiResponse<RawCompositeMarketData> = await AjaxService.getData(url);
    const market = new CompositeMarketModel(response.data);
    return market;
  }

  public static async getWishlistListings(id: string, page: number = 1): Promise<PaginationModel<ListingModel>> {
    const url = UrlConfig.WISHLIST.WISHLIST_BY_ID + `${id}/listings?page=${page}`;
    const response: ApiResponse<RawPaginationData<RawListingData>> = await AjaxService.getData(url);
    const paginatedRespose: PaginationModel<RawListingData> = new PaginationModel(response.data);
    const formattedListings = paginatedRespose.data.map((rawListing: RawListingData) => new ListingModel(rawListing));
    const formattedPaginatedResponse: PaginationModel<ListingModel> = new PaginationModel({ ...response.data, data: formattedListings, fetched: true });
    return formattedPaginatedResponse;
  }

  public static async addItemToWishlist(item_id: string): Promise<string> {
    const url = UrlConfig.WISHLIST.WISHLIST_ADD_ITEM;
    const response: ApiResponse<string> = await AjaxService.postData(url, {
      item_id: item_id
    });
    return response.data;
  }

  public static async removeItemFromWishlist(item_id: string): Promise<string> {
    const url = UrlConfig.WISHLIST.WISHLIST_REMOVE_ITEM;
    const response: ApiResponse<string> = await AjaxService.postData(url, {
      item_id: item_id
    });
    return response.data;
  }
}
