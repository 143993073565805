import { Loop, LoopReducer } from 'redux-loop';
import { ItemListingGroupingHandlers } from './handlers';
import { ItemListingGroupingState, ItemListingGroupingAction, ItemListingGroupingActionTypes } from './types';
import { defaultState } from './consts';

export const itemListingGroupingReducer: LoopReducer<ItemListingGroupingState, ItemListingGroupingAction> = (
  state: ItemListingGroupingState = defaultState,
  action: ItemListingGroupingAction
): ItemListingGroupingState | Loop<ItemListingGroupingState, ItemListingGroupingAction> => {
  switch (action.type) {
    case ItemListingGroupingActionTypes.CREATE_ITEM_LISTING_GROUPING:
      return ItemListingGroupingHandlers.handleCreateItemListingGrouping(state, action);
    case ItemListingGroupingActionTypes.CREATE_ITEM_LISTING_GROUPING_SUCCESS:
      return ItemListingGroupingHandlers.handleCreateItemListingGroupingSuccess(state);
    case ItemListingGroupingActionTypes.CREATE_ITEM_LISTING_GROUPING_FAILURE:
      return ItemListingGroupingHandlers.handleCreateItemListingGroupingFailure(state);
    default:
      return state;
  }
}
