import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';

export class ItemListingGroupingSelectors {
  public static getError(state: ApplicationState): Error | undefined {
    return RootSelectors.getItemListingGroupingState(state).error;
  }

  public static getItemListingGroupingCreateLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getItemListingGroupingState(state).loading.create;
  }
}
