import { forwardRef } from 'react';
import { Flex, Icon, Spinner } from '@chakra-ui/react';
import { IconType } from 'react-icons';

export interface CircularIconButtonProps {
  icon: IconType
  onClick?: () => void;
  radius?: number;
  isLoading?: boolean;
}

const CircularIconButton = forwardRef<HTMLInputElement, CircularIconButtonProps>(({ icon, onClick, radius = 8, isLoading = false }, ref) => {
  function renderIcon() {
    return isLoading ? <Spinner size='sm' /> : <Icon as={icon} />;
  }

  return (
    <Flex
      ref={ref}
      w={radius}
      h={radius}
      align={'center'}
      justify={'center'}
      rounded={'full'}
      cursor={onClick ? "pointer" : ''}
      border="1px"
      onClick={onClick}
      _hover={onClick ? {color: 'blue.500'} : {}}>
      {renderIcon()}
    </Flex>
  );
});

CircularIconButton.displayName = 'CircularIconButton';

export default CircularIconButton;
