export enum ListingGridSortFilter {
  DEFAULT = 'DEFAULT',
  TITLE_ASC = 'TITLE_ASC',
  TITLE_DESC = 'TITLE_DESC',
  SALE_DATE_ASC = 'SALE_DATE_ASC',
  SALE_DATE_DESC = 'SALE_DATE_DESC',
  PRICE_ASC = 'PRICE_ASC',
  PRICE_DESC = 'PRICE_DESC'
}

export enum ListingSortFilter {
  DEFAULT = 'DEFAULT',
  TITLE = 'TITLE',
  SALE_DATE = 'SALE_DATE',
  PRICE = 'PRICE'
}
