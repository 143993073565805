import { PortfolioState } from './types';
import { PortfolioModel } from 'models';

export const defaultState: PortfolioState = {
  portfolio: new PortfolioModel(),
  error: undefined,
  loading: {
    item: false,
    action: false,
    artists: false,
    items: false,
    listings: false,
    history: false
  }
};
