import { Cmd, loop, Loop } from 'redux-loop';
import { ItemSaleSuggestionInternalActions } from './actions';
import { ItemSaleSuggestionState, ItemSaleSuggestionAction } from './types';
import { ItemSaleSuggestionApiClient } from 'api';

export enum ItemSaleSuggestionStateErrors {
  CREATE = 'Unable to create item sale suggstion',
}

export class ItemSaleSuggestionHandlers {
  public static handleCreateItemSaleSuggestion(state: ItemSaleSuggestionState, action: ItemSaleSuggestionAction): Loop<ItemSaleSuggestionState, ItemSaleSuggestionAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        create: true
      }
    };

    return loop(
      newState,
      Cmd.run(ItemSaleSuggestionApiClient.createItemSaleSuggestion, {
        args: [payload?.itemId || '', payload?.price || 0, payload?.link || '', payload?.date || '', payload?.sourceId || ''],
        successActionCreator: ItemSaleSuggestionInternalActions.createItemSaleSuggestionSuccess,
        failActionCreator: ItemSaleSuggestionInternalActions.createItemSaleSuggestionFailure,
      })
    );
  }

  public static handleCreateItemSaleSuggestionSuccess(state: ItemSaleSuggestionState): ItemSaleSuggestionState {
    return {
      ...state,
      loading: {
        ...state.loading,
        create: false
      }
    };
  }

  public static handleCreateItemSaleSuggestionFailure(state: ItemSaleSuggestionState): ItemSaleSuggestionState {
    return {
      ...state,
      error: new Error(ItemSaleSuggestionStateErrors.CREATE),
      loading: {
        ...state.loading,
        create: false
      }
    };
  }
}
