import {
  Box,
  Stack,
  Text,
  Heading,
  Icon,
  useColorModeValue
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

export interface ItemInfoBlockProps {
  label: string;
  value?: string;
  icon?: IconType;
  fallbackIcon?: IconType;
  onFallbackContentClick?: () => void;
}

export enum ItemInfoBlockSizingConfig {
  CARD_BORDER_INFO = '1px solid',
  BLOCK_MIN_HEIGHT = '100px',
  BLOCK_PADDING = '16px 8px',
  ICON_WIDTH = '20px',
  ICON_HEIGHT = '20px'
}

export enum ItemInfoBlockStylingConfig {
  BORDER_COLOR_LIGHT = 'black',
  BORDER_COLOR_DARK = '#3f434c'
}


export default function ItemInfoBlock({ label, value, icon = undefined, fallbackIcon = undefined, onFallbackContentClick }: ItemInfoBlockProps) {
  function renderIcon() {
    if (icon) {
      return (
        <Icon as={icon} w={ItemInfoBlockSizingConfig.ICON_WIDTH} h={ItemInfoBlockSizingConfig.ICON_HEIGHT} />
      );
    }
  }

  function renderInfoContent() {
    if (value) {
      return value;
    } else if (!!fallbackIcon) {
      return (
        <Icon
          as={fallbackIcon}
          onClick={onFallbackContentClick}
          w={ItemInfoBlockSizingConfig.ICON_WIDTH}
          h={ItemInfoBlockSizingConfig.ICON_HEIGHT}
          cursor={onFallbackContentClick ? 'pointer' : ''}
          _hover={onFallbackContentClick ? {color: 'blue.500'} : {}} />
      );
    } else {
      return 'N/A';
    }
  }

  return (
    <Stack
      alignItems="center"
      border={ItemInfoBlockSizingConfig.CARD_BORDER_INFO}
      borderColor={useColorModeValue(ItemInfoBlockStylingConfig.BORDER_COLOR_LIGHT, ItemInfoBlockStylingConfig.BORDER_COLOR_DARK)}
      minHeight={ItemInfoBlockSizingConfig.BLOCK_MIN_HEIGHT}
      padding={ItemInfoBlockSizingConfig.BLOCK_PADDING}
      height="100%"
    >
      {renderIcon()}
      <Heading fontSize="xs">
        {label}
      </Heading>
      <Text fontSize="sm">
        {renderInfoContent()}
      </Text>
    </Stack>
  )
}
