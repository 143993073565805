import { SalesVenueModel } from 'models';
import { SalesVenueActionTypes, SalesVenueAction } from './types';

export class SalesVenueActions {
  public static getSalesVenueById(id: string): SalesVenueAction {
    return {
      type: SalesVenueActionTypes.GET_SALES_VENUE_BY_ID,
      payload: { id },
    };
  }

  public static getAllSalesVenues(): SalesVenueAction {
    return {
      type: SalesVenueActionTypes.GET_ALL_SALES_VENUES
    }
  }
}

export class SalesVenueInternalActions {
  public static getSalesVenueByIdSuccess(salesVenue: SalesVenueModel): SalesVenueAction {
    return {
      type: SalesVenueActionTypes.GET_SALES_VENUE_BY_ID_SUCCESS,
      payload: { salesVenue },
    };
  }

  public static getSalesVenueByIdFailure(): SalesVenueAction {
    return {
      type: SalesVenueActionTypes.GET_SALES_VENUE_BY_ID_FAILURE,
    };
  }

  public static getAllSalesVenuesSuccess(salesVenueList: SalesVenueModel[]): SalesVenueAction {
    return {
      type: SalesVenueActionTypes.GET_ALL_SALES_VENUES_SUCCESS,
      payload: { salesVenueList }
    }
  }

  public static getAllSalesVenuesFailure(): SalesVenueAction {
    return {
      type: SalesVenueActionTypes.GET_ALL_SALES_VENUES_FAILURE
    }
  }
}
