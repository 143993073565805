import Promise from 'bluebird';
import _ from 'lodash';
import 'whatwg-fetch';
import { ApiResponse, ApiResponseModel } from 'models/Api/ApiResponse';
import { HttpRequestHeader, JSONObject, HttpRequestConfiguration } from 'models/Api/types';
import { CredentialService } from './Credential';


export class AjaxService {
  public static postData(url: string, data: JSONObject = {}, requireAuth: boolean = true, withCredentials: boolean = false, useRefreshToken: boolean = false, headerOverrides: HttpRequestHeader = {}): Promise<ApiResponse<any>> {
    const headers = this.fetchHeader(requireAuth, useRefreshToken);
    _.assign(headers, headerOverrides);

    return this.callAjax('POST', url, data, headers, withCredentials)
  }

  public static getData(url: string, data: JSONObject = {}, requireAuth: boolean = true, withCredentials: boolean = false, headerOverrides: HttpRequestHeader = {}): Promise<ApiResponse<any>> {
    const headers = this.fetchHeader(requireAuth);
    _.assign(headers, headerOverrides);

    return this.callAjax('GET', url, data, headers, withCredentials);
  }

  public static patchData(url: string, data: JSONObject = {}, requireAuth: boolean = true, withCredentials: boolean = false, headerOverrides: HttpRequestHeader = {}): Promise<ApiResponse<any>> {
    const headers = this.fetchHeader(requireAuth);
    _.assign(headers, headerOverrides);

    return this.callAjax('PATCH', url, data, headers, withCredentials);
  }

  public static putData(url: string, data: JSONObject = {}, requireAuth: boolean = true, withCredentials: boolean = false, headerOverrides: HttpRequestHeader = {}): Promise<ApiResponse<any>> {
    const headers = this.fetchHeader(requireAuth);
    _.assign(headers, headerOverrides);

    return this.callAjax('PUT', url, data, headers, withCredentials);
  }

  public static delData(url: string, data: JSONObject = {}, requireAuth: boolean = true, withCredentials: boolean = false, headerOverrides: HttpRequestHeader = {}): Promise<ApiResponse<any>> {
    const headers = this.fetchHeader(requireAuth);
    _.assign(headers, headerOverrides);

    return this.callAjax('DELETE', url, data, headers, withCredentials);
  }

  private static verifyParameters(...args: string[]): boolean {
    const invalidValues = [null, undefined, ''];
    return !args.filter(arg => invalidValues.map(val => val === arg).reduceRight((a, b) => a || b))
      .length;
  }

  private static fetchHeader(requireAuth: boolean = true, useRefreshToken: boolean = false): HttpRequestHeader {
    const header: HttpRequestHeader = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    if (requireAuth) {
      const token = useRefreshToken ? CredentialService.fetchRefreshToken() : CredentialService.fetchAuthToken();
      header['Authorization'] = `Bearer ${token}`;
    }

    return header;
  }

  private static callAjax(method: string, url: string, data: JSONObject = {}, headers: HttpRequestHeader = {}, withCredentials: boolean = false, request: any = fetch): Promise<ApiResponse<any>> {
    method = method ? method.toUpperCase() : 'GET';

    const cfg: HttpRequestConfiguration = {
      mode: 'cors',
      headers,
      method,
    };

    if (!_.isEmpty(data)) {
      if (method !== 'GET' && method !== 'HEAD') {
        cfg.body = JSON.stringify(data);
      } else {
        url += `?${this.serialize(data)}`;
      }
    }

    if (withCredentials) {
      cfg.credentials = 'include';
    }

    return this.retrieveData(request, url, cfg);
  }

  private static retrieveData(request: any, url: string, cfg: HttpRequestConfiguration): Promise<ApiResponse<any>> {
    return new Promise<ApiResponse<any>>((resolve, reject) => {
      request(url, cfg)
        .then((response: Response) => {
          const jsonRegExp: RegExp = /application\/json/i;
          const headers: Headers = response.headers || {};
          const isJson: boolean = jsonRegExp.test(headers.get('Content-Type') || '');

          if (!response.ok) {
            const err = new Error(response.statusText);
            reject(err);
            throw (err);
          }

          if (response.status === 204 || !isJson) {
            return {};
          }

          return response.json<ApiResponse<any>>();
        })
        .then((json: ApiResponse<any>) => {
          const apiResponse = new ApiResponseModel(json.data, json.status, json.ok, json.message);
          resolve(apiResponse)
        })
        .catch((error: any) => {
          const err = new Error(error.message);
          reject(err);
          throw (err);
        });
    });
  }

  private static serialize(obj: any): string {
    const query: string[] = [];

    for (const p in obj) {
      if (obj.hasOwnProperty(p)) {
        query.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]));
      }
    }

    return query.join('&');
  }
}
