import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Stack,
  useColorModeValue,
  useBreakpointValue,
  FormControl,
  FormErrorMessage
} from '@chakra-ui/react';
import { FaUserCog } from 'react-icons/fa';
import FilepondUploader from '../../Uploader/FilepondUploader/FilepondUploader';
import UserProfileBadge from '../../User/UserProfileBadge/UserProfileBadge';
import ActionButton from '../../Button/ActionButton';
import { UserModel } from 'models';

export interface UserAvatarUploadFormData {
  uploadedUrl: string;
}

export interface UserAvatarUploadFormProps {
  user?: UserModel;
  onSubmit?: (url: string) => void;
  submitText?: string
  submitLoading?: boolean;
}

export default function UserAvatarUploadForm({ user, onSubmit, submitLoading, submitText = 'Change Avatar' }: UserAvatarUploadFormProps) {
  const {
    control,
    handleSubmit,
    setValue,
    watch
  } = useForm<UserAvatarUploadFormData>({defaultValues: {uploadedUrl: ''}});

  const onFormSubmit = (data: UserAvatarUploadFormData) => {
    onSubmit?.(data.uploadedUrl);
  }

  const hasValidUploadedData = watch('uploadedUrl')?.length > 0;

  return (
    <Box>
      {!!user && <UserProfileBadge user={user} buttonText={'AVATAR'} buttonIcon={FaUserCog} />}
      <Box
        py={{ base: '0', sm: '8' }}
        px={{ base: '4', sm: '10' }}
        bg={useBreakpointValue({ base: 'transparent', sm: 'bg-surface' })}
        boxShadow={{ base: 'none', sm: useColorModeValue('md', 'md-dark') }}
        borderRadius={{ base: 'none', sm: 'xl' }}
      >
        <form onSubmit={handleSubmit(onFormSubmit)}>
          <Stack spacing="6">
            <Stack spacing="5">
              <FormControl isInvalid={!hasValidUploadedData}>
                <Controller
                  control={control}
                  name="uploadedUrl"
                  rules={{ required: true }}
                  render={() => (
                    <FilepondUploader
                      onFileProcess={(url) => setValue('uploadedUrl', url)}
                      onFileRevert={() => setValue('uploadedUrl', '')}
                      maxFiles={1}
                      label={'Drag & Drop your Image'} />
                  )} />
                <FormErrorMessage>
                  {!hasValidUploadedData && 'New Avatar Must Be Uploaded'}
                </FormErrorMessage>
              </FormControl>
            </Stack>
            <Stack alignItems="center">
              {hasValidUploadedData &&
                <ActionButton type='submit' text={submitText} loading={submitLoading} />
              }
            </Stack>
          </Stack>
        </form>
      </Box>
    </Box>
  );
}
