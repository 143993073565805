import { Box, Heading, Stack, Text, Image, useColorModeValue } from '@chakra-ui/react';
import Loader from '../Loader/Loader';
import ActionButton from '../Button/ActionButton';

export interface ContentProps {
  header?: string;
  description?: string;
  buttonText?: string;
  loading?: boolean;
  onButtonClick?: () => void;
  buttonLoading?: boolean;
  showButton?: boolean;
  imagePath?: string;
}


export default function Content({ header = 'No Data', description = '', buttonText = '', onButtonClick = () => {}, showButton = false, imagePath = '', buttonLoading = false, loading = false }: ContentProps) {
  function renderImage() {
    if (imagePath) {
      return (
        <Image src={imagePath} height="200px" marginBottom="24px" />
      );
    }
  }

  function renderDescription() {
    if (description) {
      return (
        <Text fontSize="md">{description}</Text>
      );
    }
  }

  function renderButton() {
    if (showButton) {
      return <ActionButton text={buttonText} loading={buttonLoading} onClick={onButtonClick} />;
    }
  }

  function renderContent() {
    return (
      <Stack alignItems="center" justifyContent="center" paddingLeft={16} paddingRight={16} paddingBottom={16} spacing={6}>
        <Stack
          spacing={2}
          borderRadius={8}
          alignItems="center"
          justifyContent="center"
        >
          {renderImage()}
          <Heading size="lg">{header}</Heading>
          {renderDescription()}
        </Stack>
        {renderButton()}
      </Stack>
    );
  }

  function renderLoader() {
    return (
      <Loader />
    );
  }

  return (
    <Box
      backgroundColor={useColorModeValue("white","black")}
      ml={0}
      mr={0}
      borderRadius={8}
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.05)"
    >
      <Box
        height="40px"
      />
      {loading ? renderLoader() : renderContent()}
    </Box>
  )
}
