import { UserModel } from 'models';
import { AuthData } from 'models/Auth/types';
import { AuthActionTypes, AuthAction } from './types';

export class AuthActions {
  public static registerUser(email: string, username: string, password: string): AuthAction {
    return {
      type: AuthActionTypes.REGISTER_USER,
      payload: { email, username, password }
    };
  }

  public static getAuthenticatedUser(): AuthAction {
    return {
      type: AuthActionTypes.GET_AUTHENTICATED_USER,
    };
  }

  public static loginByEmail(email: string, password: string): AuthAction {
    return {
      type: AuthActionTypes.LOGIN_BY_EMAIL,
      payload: { email, password }
    }
  }

  public static refreshAuthToken(): AuthAction {
    return {
      type: AuthActionTypes.REFRESH_AUTH_TOKEN,
    }
  }

  public static logout(): AuthAction {
    return {
      type: AuthActionTypes.LOGOUT,
    };
  }
}

export class AuthInternalActions {
  public static registerUserSuccess(email: string, password: string): AuthAction {
    return {
      type: AuthActionTypes.REGISTER_USER_SUCCESS,
      payload: { email, password }
    }
  }

  public static registerUserFailure(): AuthAction {
    return {
      type: AuthActionTypes.REGISTER_USER_FAILURE
    };
  }

  public static getAuthenticatedUserSuccess(user: UserModel): AuthAction {
    return {
      type: AuthActionTypes.GET_AUTHENTICATED_USER_SUCCESS,
      payload: { user }
    };
  }

  public static getAuthenticatedUserFailure(): AuthAction {
    return {
      type: AuthActionTypes.GET_AUTHENTICATED_USER_FAILURE,
    };
  }

  public static loginByEmailSuccess(authData: AuthData): AuthAction {
    return {
      type: AuthActionTypes.LOGIN_BY_EMAIL_SUCCESS,
      payload: { authData }
    }
  }

  public static loginByEmailFailure(): AuthAction {
    return {
      type: AuthActionTypes.LOGIN_BY_EMAIL_FAILURE,
    }
  }

  public static refreshAuthTokenSuccess(authData: AuthData): AuthAction {
    return {
      type: AuthActionTypes.REFRESH_AUTH_TOKEN_SUCCESS,
      payload: { authData }
    }
  }

  public static refreshAuthTokenFailure(): AuthAction {
    return {
      type: AuthActionTypes.REFRESH_AUTH_TOKEN_FAILURE,
    }
  }

  public static logoutSuccess(): AuthAction {
    return {
      type: AuthActionTypes.LOGOUT_SUCCESS,
    };
  }

  public static logoutFailure(): AuthAction {
    return {
      type: AuthActionTypes.LOGOUT_FAILURE,
    };
  }
}
