import { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Flex,
  SimpleGrid,
  Spacer,
  Square,
  Icon,
  useColorModeValue,
  Tooltip
} from '@chakra-ui/react';
import { ItemModel } from 'models';
import { ItemType } from 'models/Item/types';
import { processDateSort, processNumberSort } from 'utils/sort';
import { filterItemsByQuery } from 'utils/search';
import ItemCard from '../ItemCard/ItemCard';
import BasicItemCard from '../BasicItemCard/BasicItemCard';
import SearchBar from 'components/SearchBar/SearchBar';
import Loader from '../../Loader/Loader';
import EmptyState from '../../EmptyState/EmptyState'
import ItemGridFilters from '../ItemGridFilters/ItemGridFilters';
import SortDropdown from '../../SortDropdown/SortDropdown';
import PaginationFooter from '../../Pagination/PaginationFooter/PaginationFooter';
import { ItemSortFilter } from '../types'
import { AiOutlineFilter, AiFillFilter } from 'react-icons/ai';

export enum ItemGridSelectionType {
  NONE = 'None',
  SINGLE = 'Single',
  MULTI = 'Multi'
}

export interface ItemGridProps {
  items: ItemModel[];
  isLoading: boolean;
  showFilters?: boolean;
  showFiltersLabel?: boolean;
  onItemCardClick?: (item: ItemModel) => void;
  gridDisplay?: boolean;
  gridMaxHeight?: number;
  gridSelection?: ItemGridSelectionType;
  defaultSelectedItems?: ItemModel[];
  paginate?: boolean;
  perPage?: number;
}

export default function ItemGrid({ items, isLoading, showFilters = true, showFiltersLabel = true, onItemCardClick = () => { }, gridDisplay = false, gridMaxHeight = 600, gridSelection = ItemGridSelectionType.NONE, defaultSelectedItems = [], paginate = false, perPage = 40 }: ItemGridProps) {
  const [gridItems, setGridItems] = useState<ItemModel[]>(items);
  const [selectedItems, setSelectedItems] = useState<ItemModel[]>(defaultSelectedItems);
  const [itemsQuery, setItemsQuery] = useState<string>('');
  const [selectedTypes, setSelectedTypes] = useState<ItemType[]>([]);
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const [selectedEditionSizes, setSelectedEditionSizes] = useState<number[]>([]);
  const [selectedEstimatedValues, setSelectedEstimatedValues] = useState<number[]>([]);
  const [itemsSort, setItemsSort] = useState<ItemSortFilter>(ItemSortFilter.DEFAULT);
  const [sortType, setSortType] = useState<'asc' | 'desc'>('desc');
  const [displayFilters, setDisplayFilters] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  useEffect(() => {
    if (items.length) {
      setGridItems(items);
      applyFiltersAndSort();
    }
  }, [items]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [itemsQuery, selectedTypes, selectedYears, selectedEditionSizes, selectedEstimatedValues, itemsSort, sortType]);


  const isOnFilterMode = (): boolean => {
    return itemsSort !== ItemSortFilter.DEFAULT || itemsQuery.length > 0 || hasFiltersApplied();
  }

  const onClearFilters = () => {
    setSelectedTypes([]);
    setSelectedYears([]);
    setSelectedEditionSizes([]);
    setSelectedEstimatedValues([]);
    setDisplayFilters(false);
  }

  const hasFiltersApplied = (): boolean => {
    return selectedTypes.length > 0 || selectedYears.length > 0 || selectedEditionSizes.length > 0 || selectedEstimatedValues.length > 0;
  }

  const toggleFilterDisplay = (): void => {
    setDisplayFilters(!displayFilters);
  }

  const onGridItemCardClick = (item: ItemModel) => {
    if (gridSelection === ItemGridSelectionType.SINGLE) {
      setSelectedItems([item])
    } else if (gridSelection === ItemGridSelectionType.MULTI) {
      if (selectedItems.some(selectedItem => selectedItem.id === item.id)) {
        setSelectedItems(selectedItems.filter(selectedItem => selectedItem.id !== item.id));
      } else {
        setSelectedItems([...selectedItems, item]);
      }
    }
    onItemCardClick(item);
  }

  const applyFiltersAndSort = () => {
    let filteredItems = items;

    if (itemsQuery) {
      filteredItems = filterItemsByQuery(filteredItems, itemsQuery);
    }
    if (selectedTypes.length > 0) {
      filteredItems = filterItemsByTypes(filteredItems, selectedTypes);
    }
    if (selectedYears.length > 0) {
      filteredItems = filterItemsByDateRange(filteredItems, selectedYears);
    }
    if (selectedEditionSizes.length > 0) {
      filteredItems = filterItemsByEditionSizes(filteredItems, selectedEditionSizes);
    }
    if (selectedEstimatedValues.length > 0) {
      filteredItems = filterItemsByEstimatedValues(filteredItems, selectedEstimatedValues);
    }

    setGridItems(processSortKey(filteredItems, itemsSort));
    setCurrentPage(1);
    setLastPage(Math.ceil(filteredItems.length / perPage));
  };


  const onItemSearch = (query: string): void => {
    setItemsQuery(query);
  }

  const onItemDateRangeSelect = (minYear: number, maxYear: number): void => {
    const yearRange = [minYear, maxYear];
    setSelectedYears(yearRange);
  }

  const onItemTypeFilterSelect = (types: ItemType[]): void => {
    setSelectedTypes(types);
  }

  const onItemEditionSizeSelect = (minEditionSize: number, maxEditionSize: number) => {
    const editionSizeRange = [minEditionSize, maxEditionSize];
    setSelectedEditionSizes(editionSizeRange);
  }

  const onItemEstimatedValueSelect = (minEstimatedValue: number, maxEstimatedValue: number) => {
    const estimatedValueRange = [minEstimatedValue, maxEstimatedValue];
    setSelectedEstimatedValues(estimatedValueRange);
  }

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const getPaginatedItems = () => {
    const startIndex = (currentPage - 1) * perPage;
    return getItems().slice(startIndex, startIndex + perPage);
  };

  const filterItemsByTypes = (items: ItemModel[], types: ItemType[]): ItemModel[] => {
    return items.filter((item: ItemModel) => types.includes(item.type));
  }

  const filterItemsByDateRange = (items: ItemModel[], dateYearRanges: number[]): ItemModel[] => {
    return items.filter((item: ItemModel) => item.getReleaseYear() >= dateYearRanges[0] && item.getReleaseYear() <= dateYearRanges[1]);
  }

  const filterItemsByEditionSizes = (items: ItemModel[], editionSizeRanges: number[]): ItemModel[] => {
    return items.filter((item: ItemModel) => !item.isOpenEdition() && item.hasEditionSize() && item.getEditionSize() >= editionSizeRanges[0] && item.getEditionSize() <= editionSizeRanges[1]);
  }

  const filterItemsByEstimatedValues = (items: ItemModel[], estimatedValueRanges: number[]): ItemModel[] => {
    return items.filter((item: ItemModel) => item.hasEstimatedMarketPrice() && item.getEstimatedMarketPrice() >= estimatedValueRanges[0] && item.getEstimatedMarketPrice() <= estimatedValueRanges[1]);
  }

  const processSortKey = (items: ItemModel[], sortKey: ItemSortFilter): ItemModel[] => {
    let sortedItems = [...items];
    const isAscending: boolean = sortType === 'asc';

    if (sortType === 'asc') {
      if (sortKey === ItemSortFilter.TITLE) {
        sortedItems = sortedItems.sort((a, b) => (a.name > b.name ? 1 : -1));
      }
    } else {
      if (sortKey === ItemSortFilter.TITLE) {
        sortedItems = sortedItems.sort((a: ItemModel, b: ItemModel) => a.name > b.name ? -1 : 1);
      }
    }

    if (sortKey === ItemSortFilter.RELEASE_DATE) {
      sortedItems = sortedItems.sort((a, b) => processDateSort(a.date, b.date, !isAscending));
    } else if (sortKey === ItemSortFilter.LAST_SALE_DATE) {
      sortedItems = sortedItems.sort((a, b) => processDateSort(a.getLastSaleDate(), b.getLastSaleDate(), !isAscending));
    } else if (sortKey === ItemSortFilter.EDITION_SIZE) {
      sortedItems = sortedItems.sort((a, b) => processNumberSort(a.getEditionSize(), b.getEditionSize(), isAscending));
    } else if (sortKey === ItemSortFilter.ESTIMATED_PRICE) {
      sortedItems = sortedItems.sort((a, b) => processNumberSort(a.getEstimatedMarketPrice(), b.getEstimatedMarketPrice(), isAscending));
    } else if (sortKey === ItemSortFilter.MARKET_VOLUME) {
      sortedItems = sortedItems.sort((a, b) => processNumberSort(a.getSecondaryMarketVolume(), b.getSecondaryMarketVolume(), isAscending));
    } else if (sortKey === ItemSortFilter.BIGGEST_MARKET_CHANGE) {
      sortedItems = sortedItems.sort((a, b) => processNumberSort(a.getLastSalePercentageChange(), b.getLastSalePercentageChange(), isAscending));
    }

    return sortedItems;
  }

  const getItems = (): ItemModel[] => {
    if (isOnFilterMode()) {
      return gridItems;
    } else {
      return items;
    }
  }

  function renderFiltersSort() {
    return (
      <SortDropdown<ItemSortFilter>
        options={ItemSortFilter}
        selectedSortBy={itemsSort}
        sortType={sortType}
        onSortBySelect={setItemsSort}
        onSortTypeToggle={() => setSortType(sortType === 'asc' ? 'desc' : 'asc')}
      />
    );
  }

  function renderFilterButton() {
    return (
      <Tooltip label={'Filter Results'}>
        <Square size={10} border={'1px solid'} borderColor={'gray.300'} cursor={'pointer'} borderRadius={'md'} onClick={() => toggleFilterDisplay()}>
          <Icon as={displayFilters ? AiFillFilter : AiOutlineFilter} />
        </Square>
      </Tooltip>
    );
  }

  function renderItemsFilters() {
    if (showFilters) {
      return (
        <Flex minWidth='fit-content' alignItems={{ base: 'start', md: 'center' }} gap='2' marginBottom="40px" p={{ base: '20px', md: '8px 0px' }} direction={{ base: 'column', md: 'row' }}>
          <Box>
            {showFiltersLabel && <Text fontSize='xs'>Filters: </Text>}
          </Box>
          <Flex direction={{ base: 'column', md: 'row' }} width="100%" gap={2}>
            <SearchBar onChange={onItemSearch} />
            <Flex direction='row' gap={2} width={'100%'}>
              {renderFilterButton()}
              <Spacer />
              {renderFiltersSort()}
            </Flex>
          </Flex>
        </Flex>
      );
    }
  }

  function renderItemGridFilters() {
    if (displayFilters) {
      return (
        <ItemGridFilters
          items={items}
          onItemDateRangeSelect={onItemDateRangeSelect}
          onItemEditionRangeSelect={onItemEditionSizeSelect}
          onItemTypeFilterSelect={onItemTypeFilterSelect}
          onItemEstimatedValueRangeSelect={onItemEstimatedValueSelect}
          defaultDateRange={selectedYears}
          defaultEditionRange={selectedEditionSizes}
          defaultValueRange={selectedEstimatedValues}
          defaultTypes={selectedTypes}
          showClearOption={hasFiltersApplied()}
          onResetFilters={onClearFilters} />
      );
    }
  }

  function renderItems() {
    const itemData = paginate ? getPaginatedItems() : getItems();
    if (gridDisplay) {
      return (
        <Box backgroundColor={useColorModeValue("white", "black")} maxHeight={`${gridMaxHeight}px`} overflow="scroll" borderRadius={4}>
          <Flex direction='column'>
            {itemData.map((item: ItemModel, index: number) => <BasicItemCard selected={!!selectedItems.find((selectedItem) => selectedItem.id === item.id)} onClick={() => onGridItemCardClick(item)} key={`item_card_${index}`} item={item} />)}
          </Flex>
        </Box>
      );
    } else {
      return (
        <SimpleGrid columns={[2, 4, 4]} spacing={{ base: "0px", md: "20px" }}>
          {itemData.map((item: ItemModel, index: number) => <ItemCard onClick={() => onItemCardClick(item)} key={`item_card_${index}`} item={item} />)}
        </SimpleGrid>
      );
    }
  }

  function renderData() {
    const items = getItems();
    if (items.length) {
      return renderItems()
    } else if (isOnFilterMode()) {
      return (
        <EmptyState header="No Items" description="No Results Found" showButton={false} />
      );
    }
  }

  function renderLoader() {
    return isLoading ? (
      <Flex justifyContent="center">
        <Loader />
      </Flex>
    ) : null;
  }

  function renderPaginationFooter() {
    if (paginate && lastPage > 1) {
      return (
        <PaginationFooter currentPage={currentPage} lastPage={lastPage} siblingsCount={2} onPageChange={onPageChange} />
      );
    }
  }

  return (
    <Box>
      {renderItemsFilters()}
      {renderItemGridFilters()}
      {renderData()}
      {renderPaginationFooter()}
      {renderLoader()}
    </Box>
  );
}
