import { ListingModel } from 'models';
import { RawMetricData, MetricModel } from 'models/Market/Metric';

export function calculateMetricsForListings(listings: ListingModel[], key: number): MetricModel {
	const totalListings = listings.length;

	if (totalListings === 0) {
		return {
			key: key,
			mean: 0,
			open: 0,
			close: 0,
			max: 0,
			min: 0,
			growth: 0,
			totalListings: 0,
			totalSales: 0,
			variance: 0
		};
	}

	let sum = 0;
	let max = Number.MIN_SAFE_INTEGER;
	let min = Number.MAX_SAFE_INTEGER;

	for (const listing of listings) {
		sum += listing.rawPrice;
		if (listing.rawPrice > max) max = listing.rawPrice;
		if (listing.rawPrice < min) min = listing.rawPrice;
	}

	const mean = sum / totalListings;
	let varianceSum = 0;
	for (const listing of listings) {
		varianceSum += Math.pow(listing.rawPrice - mean, 2);
	}
	const variance = varianceSum / totalListings;
	const open = listings[0].rawPrice;
	const close = listings[totalListings - 1].rawPrice;
	const growth = ((close - open) / open) * 100;
	const totalSales = sum;

	const rawMetricData: RawMetricData = {
		close,
		growth,
		max,
		mean,
		min,
		open,
		total_listings: totalListings,
		total_sales: totalSales,
		variance,
		key: key
	};

	return new MetricModel(rawMetricData);
}

export function getListingsByDateCombo(
	listings: ListingModel[],
	year: number,
	month?: number,
	day?: number
): ListingModel[] {
	return listings.filter(listing => {
		if (listing.date) {
			if (day) {
				return (
					listing.date.year() === year &&
					listing.date.month() + 1 === month &&
					listing.date.date() === day
				);
			} else if (month) {
				return (
					listing.date.year() === year &&
					listing.date.month() + 1 === month
				);
			} else {
				return listing.date.year() === year;
			}
		}
		return false;
	});
};