import {
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  IconButton,
  Button,
  Tooltip
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa';
import { camelToSentenceCase } from 'utils/format';

export interface SortDropdownProps<T> {
  options: Record<string, T>;
  selectedSortBy: T | undefined;
  sortType: 'asc' | 'desc' | undefined;
  onSortBySelect: (value: T) => void;
  onSortTypeToggle: () => void;
}

export default function SortDropdown<T>({
  options,
  selectedSortBy,
  sortType,
  onSortBySelect,
  onSortTypeToggle,
}: SortDropdownProps<T>) {
  const selectedSortLabel = selectedSortBy
    ? (Object.entries(options).find(([_, value]) => value === selectedSortBy)?.[0] as string)
    : null;
  const isDefaultSelected: boolean = selectedSortLabel === 'DEFAULT';
  const sortLabelWithIcon = selectedSortLabel && !isDefaultSelected
    ? `${camelToSentenceCase(selectedSortLabel)} ${sortType === 'asc' ? '↑' : '↓'}`
    : 'Sort By';

  return (
    <Flex gap={4}>
      <Menu closeOnSelect={false}>
        <MenuButton as={Button} variant="outline" rightIcon={<ChevronDownIcon />} fontSize={'xs'}>
          {sortLabelWithIcon}
        </MenuButton>
        <MenuList minWidth="240px">
          <MenuOptionGroup
            defaultValue={selectedSortBy as unknown as string}
            type="radio"
            onChange={(value) => onSortBySelect(value as unknown as T)}
          >
            {Object.entries(options).map(([label, value]) => (
              <MenuItemOption fontSize="xs" key={value as unknown as string} value={value as unknown as string}>
                {camelToSentenceCase(label)} {selectedSortBy === value && !isDefaultSelected && (sortType === 'asc' ? '↑' : '↓')}
              </MenuItemOption>
            ))}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
      <Tooltip label={sortType === 'asc' ? 'Ascending' : 'Descending'}>
        <IconButton
          size={'md'}
          aria-label="Toggle Sort Order"
          icon={sortType === 'asc' ? <FaSortAmountUp /> : <FaSortAmountDown />}
          onClick={onSortTypeToggle}
          isDisabled={!selectedSortBy || isDefaultSelected}
        />
      </Tooltip>
    </Flex>
  );
}
