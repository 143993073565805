import { UrlConfig } from 'config';
import { ItemModel, ListingModel, MarketModel, PaginationModel } from 'models';
import { JSONObject } from 'models/Api/types';
import { RawItemData } from 'models/Item/types';
import { RawListingData } from 'models/Listing/types';
import { RawMarketData } from 'models/Market/types';
import { ApiResponse } from 'models/Api/ApiResponse';
import { RawPaginationData } from 'models/Pagination/Pagination';
import { AjaxService } from 'services';
import { ArtistConfig } from 'config';
import { filterEnabledArtists } from 'utils/artist';

export class ItemApiClient {
  public static async getItemById(id: string): Promise<ItemModel> {
    const url = UrlConfig.ITEM.ITEM_BY_ID + `${id}`;
    const response: ApiResponse<RawItemData> = await AjaxService.getData(url);
    const item = new ItemModel(response.data);
    return item;
  }

  public static async getItemMarket(id: string): Promise<MarketModel> {
    const url = UrlConfig.ITEM.ITEM_BY_ID + `${id}/market`;
    const response: ApiResponse<RawMarketData> = await AjaxService.getData(url);
    const market = new MarketModel(response.data);
    return market;
  }

  public static async getItemListings(id: string): Promise<ListingModel[]> {
    const url = UrlConfig.ITEM.ITEM_BY_ID + `${id}/listings`;
    const response: ApiResponse<RawListingData[]> = await AjaxService.getData(url);
    const listings = response.data.map(rawListing => new ListingModel(rawListing));
    return listings;
  }

  public static async getItemSuggestions(id: string): Promise<ItemModel[]> {
    const url = UrlConfig.ITEM.ITEM_BY_ID + `${id}/suggestions`;
    const response: ApiResponse<RawItemData[]> = await AjaxService.getData(url);
    const items = response.data.map(rawItem => new ItemModel(rawItem));
    return items;
  }


  public static async searchItemsByQuery(query: string, page: number = 1): Promise<PaginationModel<ItemModel>> {
    let url = UrlConfig.ITEM.ITEM_SEARCH;

    const response: ApiResponse<RawPaginationData<RawItemData>> = await AjaxService.postData(url, {
      "query": query,
      "page": page,
      "artist_id": ArtistConfig.ENABLED_ARTISTS
    });
    const paginatedRespose: PaginationModel<RawItemData> = new PaginationModel(response.data);
    const formattedItems = paginatedRespose.data.map((rawItem: RawItemData) => new ItemModel(rawItem));
    const formattedPaginatedResponse: PaginationModel<ItemModel> = new PaginationModel({ ...response.data, data: formattedItems, fetched: true });
    return formattedPaginatedResponse;
  }


  public static async searchItems(query: JSONObject): Promise<PaginationModel<ItemModel>> {
    let url = UrlConfig.ITEM.ITEM_SEARCH;
    if (query['artist_id']) {
      query['artist_id'] = filterEnabledArtists(query['artist_id'] as (string[] | string));
    } else {
      query['artist_id'] = ArtistConfig.ENABLED_ARTISTS;
    }
    const response: ApiResponse<RawPaginationData<RawItemData>> = await AjaxService.postData(url, query);
    const paginatedRespose: PaginationModel<RawItemData> = new PaginationModel(response.data);
    const formattedItems = paginatedRespose.data.map((rawItem: RawItemData) => new ItemModel(rawItem));
    const formattedPaginatedResponse: PaginationModel<ItemModel> = new PaginationModel({ ...response.data, data: formattedItems, fetched: true });
    return formattedPaginatedResponse;
  }

  public static async getAllItems(page: number = 1): Promise<PaginationModel<ItemModel>> {
    let url = UrlConfig.ITEM.ITEM_SEARCH;
    const response: ApiResponse<RawPaginationData<RawItemData>> = await AjaxService.postData(url, {
      "page": page,
      "artist_id": ArtistConfig.ENABLED_ARTISTS,
      sortBy: "market_summary.total_sales",
			sortType: 'descending'
    });
    const paginatedRespose: PaginationModel<RawItemData> = new PaginationModel(response.data);
    const formattedItems = paginatedRespose.data.map((rawItem: RawItemData) => new ItemModel(rawItem));
    const formattedPaginatedResponse: PaginationModel<ItemModel> = new PaginationModel({ ...response.data, data: formattedItems, fetched: true });
    return formattedPaginatedResponse;
  }
}
