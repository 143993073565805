import { ArtistState } from './types';
import { ArtistModel } from 'models';

export const defaultState: ArtistState = {
  artist: new ArtistModel(),
  artistList: [],
  error: undefined,
  loading: {
    item: false,
    list: false,
    itemList: false,
    listingList: false,
    editionIndex: false
  }
};
