import { Loop, LoopReducer } from 'redux-loop';
import { PortfolioState, PortfolioAction, PortfolioActionTypes } from './types';
import { defaultState } from './consts';
import { PortfolioHandlers } from './handlers';

export const portfolioReducer: LoopReducer<PortfolioState, PortfolioAction> = (
  state: PortfolioState = defaultState,
  action: PortfolioAction
): PortfolioState | Loop<PortfolioState, PortfolioAction> => {
  switch (action.type) {
    case PortfolioActionTypes.GET_USER_PORTFOLIO:
      return PortfolioHandlers.handleGetUserPortfolio(state);
    case PortfolioActionTypes.GET_USER_PORTFOLIO_SUCCESS:
      return PortfolioHandlers.handleGetUserPortfolioSuccess(state, action);
    case PortfolioActionTypes.GET_USER_PORTFOLIO_FAILURE:
      return PortfolioHandlers.handleGetUserPortfolioFailure(state);
    case PortfolioActionTypes.GET_PORTFOLIO_BY_ID:
      return PortfolioHandlers.handleGetPortfolioById(state, action);
    case PortfolioActionTypes.GET_PORTFOLIO_BY_ID_SUCCESS:
      return PortfolioHandlers.handleGetPortfolioByIdSuccess(state, action);
    case PortfolioActionTypes.GET_PORTFOLIO_BY_ID_FAILURE:
      return PortfolioHandlers.handleGetPortfolioByIdFailure(state);
    case PortfolioActionTypes.GET_PORTFOLIO_ARTISTS:
      return PortfolioHandlers.handleGetPortfolioArtists(state, action);
    case PortfolioActionTypes.GET_PORTFOLIO_ARTISTS_SUCCESS:
      return PortfolioHandlers.handleGetPortfolioArtistsSuccess(state, action);
    case PortfolioActionTypes.GET_PORTFOLIO_ARTISTS_FAILURE:
      return PortfolioHandlers.handleGetPortfolioArtistsFailure(state);
    case PortfolioActionTypes.GET_PORTFOLIO_HISTORY:
      return PortfolioHandlers.handleGetPortfolioHistory(state, action);
    case PortfolioActionTypes.GET_PORTFOLIO_HISTORY_SUCCESS:
      return PortfolioHandlers.handleGetPortfolioHistorySuccess(state, action);
    case PortfolioActionTypes.GET_PORTFOLIO_HISTORY_FAILURE:
      return PortfolioHandlers.handleGetPortfolioHistoryFailure(state);
    case PortfolioActionTypes.GET_PORTFOLIO_ITEMS:
      return PortfolioHandlers.handleGetPortfolioItems(state, action);
    case PortfolioActionTypes.GET_PORTFOLIO_ITEMS_SUCCESS:
      return PortfolioHandlers.handleGetPortfolioItemsSuccess(state, action);
    case PortfolioActionTypes.GET_PORTFOLIO_ITEMS_FAILURE:
      return PortfolioHandlers.handleGetPortfolioItemsFailure(state);
    case PortfolioActionTypes.GET_PORTFOLIO_LISTINGS:
      return PortfolioHandlers.handleGetPortfolioListings(state, action);
    case PortfolioActionTypes.GET_PORTFOLIO_LISTINGS_SUCCESS:
      return PortfolioHandlers.handleGetPortfolioListingsSuccess(state, action);
    case PortfolioActionTypes.GET_PORTFOLIO_LISTINGS_FAILURE:
      return PortfolioHandlers.handleGetPortfolioListingsFailure(state);
    case PortfolioActionTypes.ADD_ITEM_TO_PORTFOLIO:
      return PortfolioHandlers.handleAddItemToPortfolio(state, action);
    case PortfolioActionTypes.ADD_ITEM_TO_PORTFOLIO_SUCCESS:
      return PortfolioHandlers.handleAddItemToPortfolioSuccess(state, action);
    case PortfolioActionTypes.ADD_ITEM_TO_PORTFOLIO_FAILURE:
      return PortfolioHandlers.handleAddItemToPortfolioFailure(state);
    case PortfolioActionTypes.REMOVE_ITEM_FROM_PORTFOLIO:
      return PortfolioHandlers.handleRemoveItemFromPortfolio(state, action);
    case PortfolioActionTypes.REMOVE_ITEM_FROM_PORTFOLIO_SUCCESS:
      return PortfolioHandlers.handleRemoveItemFromPortfolioSuccess(state, action);
    case PortfolioActionTypes.REMOVE_ITEM_FROM_PORTFOLIO_FAILURE:
      return PortfolioHandlers.handleRemoveItemFromPortfolioFailure(state);
    case PortfolioActionTypes.REPLACE_ITEM_IN_PORTFOLIO:
      return PortfolioHandlers.handleReplaceItemInPortfolio(state, action);
    case PortfolioActionTypes.REPLACE_ITEM_IN_PORTFOLIO_SUCCESS:
      return PortfolioHandlers.handleReplaceItemInPortfolioSuccess(state, action);
    case PortfolioActionTypes.REPLACE_ITEM_IN_PORTFOLIO_FAILURE:
      return PortfolioHandlers.handleReplaceItemInPortfolioFailure(state);
    case PortfolioActionTypes.CLEAR_PORTFOLIO:
      return PortfolioHandlers.handleClearPortfolio(state);
    default:
      return state;
  }
}
