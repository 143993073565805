import { JSONObject } from 'models/Api/types';

export enum ItemDataSuggestionActionTypes {
  CREATE_ITEM_DATA_SUGGESTION = 'CREATE_ITEM_DATA_SUGGESTION',
  CREATE_ITEM_DATA_SUGGESTION_SUCCESS = 'CREATE_ITEM_DATA_SUGGESTION_SUCCESS',
  CREATE_ITEM_DATA_SUGGESTION_FAILURE = 'CREATE_ITEM_DATA_SUGGESTION_FAILURE',
};

export interface ItemDataSuggestionActionPayload {
  id?: string;
  itemId?: string
  data?: JSONObject;
};

export interface ItemDataSuggestionAction {
  type: ItemDataSuggestionActionTypes;
  payload?: ItemDataSuggestionActionPayload;
};

export interface ItemDataSuggestionStateLoading {
  create: boolean
};

export interface ItemDataSuggestionState {
  error: Error | undefined;
  loading: ItemDataSuggestionStateLoading;
};
