import moment, { Moment } from 'moment';
import { Cloneable } from '../Utils/Cloneable';
import { RawPortfolioHistory, PortfolioHistoryData, RawPortfolioHistoryData } from './types';
import { MarketDateFilter } from 'models/types';
import { getCurrentDate } from 'utils/date';

export class PortfolioHistoryModel extends Cloneable {
	id: string;
	portfolioId: string;
	historyData: PortfolioHistoryData[];
	backfilledData: PortfolioHistoryData[];
	recalculate: boolean;
	lastCalculationDate: Moment | null;

	constructor(portfolioHistoryData?: RawPortfolioHistory) {
		super();
		this.id = portfolioHistoryData?.id || '';
		this.portfolioId = portfolioHistoryData?.portfolio_id || '';
		this.historyData = (portfolioHistoryData?.history_data || []).map((rawData: RawPortfolioHistoryData): PortfolioHistoryData => {
			return {
				cost: rawData.cost,
				date: moment(rawData.date),
				value: rawData.value
			};
		});
		this.backfilledData = this.getBackfilledHistoryData();
		this.recalculate = !!portfolioHistoryData?.recalculate;
		this.lastCalculationDate = portfolioHistoryData?.last_calculation_date ? moment(portfolioHistoryData.last_calculation_date) : null;
	}

	public getHistoryData(): PortfolioHistoryData[] {
		return this.historyData || [];
	}

	public getDateSortedHistoryData(): PortfolioHistoryData[] {
		return this.historyData.filter(historyData => historyData.date != null)
			.sort((a, b) => {
				if (a.date && b.date) {
					return b.date.diff(a.date);
				}
				return 0;
			});
	}

	public getMostRecentHistoryData(): PortfolioHistoryData | null {
		if (this.historyData.length) {
			return this.historyData[this.historyData.length - 1];
		} else {
			return null;
		}
	}

	public getSecondMostRecentHistoryData(): PortfolioHistoryData | null {
		if (this.historyData.length > 1) {
			return this.historyData[this.historyData.length - 2];
		} else {
			return null;
		}
	}

	public getHistoryDataUniqueYears(): number[] {
		const years = this.historyData.map(historyData => historyData.date ? historyData.date.year() : null)
			.filter(year => year !== null);
		return Array.from(new Set(years)) as number[];
	}

	public filterDataByDateRange(startDate: Moment, endDate: Moment): PortfolioHistoryData[] {
		return this.historyData.filter(d => moment(d.date).isBetween(startDate, endDate, undefined, '[]'));
	}

	public filterDataByMarketDateFilter = (range: MarketDateFilter, useBackfilledData?: boolean) => {
		const endDate = moment();
		let startDate: Moment;
		const data = useBackfilledData ? this.backfilledData : this.historyData;
	
		switch (range) {
			case MarketDateFilter.FIVE_YEARS:
				startDate = endDate.clone().subtract(5, 'years');
				break;
			case MarketDateFilter.THREE_YEARS:
				startDate = endDate.clone().subtract(3, 'years');
				break;
			case MarketDateFilter.ONE_YEAR:
				startDate = endDate.clone().subtract(1, 'years');
				break;
			case MarketDateFilter.SIX_MONTHS:
				startDate = endDate.clone().subtract(6, 'months');
				break;
			default:
				return data;
		}
		return data.filter(d => moment(d.date).isBetween(startDate, endDate, undefined, '[]'));
	};

	private getBackfilledHistoryData(): PortfolioHistoryData[] {
		if (!this.historyData.length) return [];

		const sortedHistoryData = this.getDateSortedHistoryData().reverse();
		const startDate = sortedHistoryData[0].date!;
		const endDate = getCurrentDate();

		let currentDate = startDate.clone();
		let currentIndex = 0;
		const backfilledData: PortfolioHistoryData[] = [];
		let foundFirstNonZero = false;

		while (currentDate.isSameOrBefore(endDate, 'day')) {
			if (sortedHistoryData[currentIndex] && currentDate.isSame(sortedHistoryData[currentIndex].date, 'day')) {
				backfilledData.push({
					date: currentDate.clone(),
					cost: sortedHistoryData[currentIndex].cost,
					value: sortedHistoryData[currentIndex].value
				});
				if (sortedHistoryData[currentIndex].value !== 0) {
					foundFirstNonZero = true;
				}
				currentIndex++;
			} else {
				const previousValue = backfilledData.length > 0 ? backfilledData[backfilledData.length - 1] : null;
				if (foundFirstNonZero || previousValue?.value !== 0) {
					backfilledData.push({
						date: currentDate.clone(),
						cost: previousValue?.cost || 0,
						value: previousValue?.value || 0
					});
				}
			}
			currentDate.add(1, 'day');
		}

		return backfilledData;
	}
}
