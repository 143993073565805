export function toDollarFormat(value: number, includeCents: boolean = true, shorten: boolean = false): string {
  let absValue = Math.abs(value);
  let formattedValue: string;

  if (shorten) {
    if (absValue >= 1e9) {
      absValue = value / 1e9;
      formattedValue = `$${formatNumber(absValue, includeCents)}B`;
    } else if (absValue >= 1e6) {
      absValue = value / 1e6;
      formattedValue = `$${formatNumber(absValue, includeCents)}M`;
    } else if (absValue >= 1e3) {
      absValue = value / 1e3;
      formattedValue = `$${formatNumber(absValue, includeCents)}K`;
    } else {
      formattedValue = value.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: includeCents ? 2 : 0,
        maximumFractionDigits: includeCents ? 2 : 0
      });
    }
  } else {
    formattedValue = value.toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: includeCents ? 2 : 0,
      maximumFractionDigits: includeCents ? 2 : 0
    });
  }

  return formattedValue;
}

export function formatNumber(value: number, includeCents: boolean): string {
  if (includeCents) {
    return value.toFixed(2).replace(/\.00$/, ''); // Removes .00 if it's there
  } else {
    return value.toFixed(1).replace(/\.0$/, ''); // Keeps one decimal place, unless it's .0
  }
}

export function toPercentageFormat(value: number): string {
  return `${value.toFixed(2)}%`;
}

export function toCentimeters(valueInInches: number) : number {
  return valueInInches * 2.54;
}

export function arrayToCommaSeparatedString(arr: string[]): string {
  return arr.join(',');
}

export function formatNumberWithCountryCode(phone: string): string {
  return phone.startsWith('+') ? phone : `+${phone}`;
}

export function camelToSentenceCase(input: string): string {
  const words = input
    .replace(/_/g, ' ')
    .toLowerCase()                   
    .split(' ');                     

  const sentence = words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return sentence;
}
