import {
  Box,
  Spinner
} from '@chakra-ui/react';

export default function Loader() {
  return (
    <Box minHeight="400px" paddingTop="100px">
      <Spinner
        thickness='4px'
        speed='0.65s'
        emptyColor='gray.200'
        color='blue.500'
        size='xl'
      />
    </Box>
  );
}
