import { Cmd, loop, Loop } from 'redux-loop';
import { ArtistInternalActions } from './actions';
import { ArtistApiClient } from 'api';
import { ArtistModel , PaginationModel } from 'models';
import { ArtistState, ArtistAction } from './types';
import { defaultState } from './consts';

export enum ArtistStateErrors {
  ID_FETCH = 'Unable to fetch artist',
  ALL_FETCH = 'Unable to fetch all artists',
  ID_ITEMS_FETCH = 'Unable to fetch artist items',
  ID_LISTINGS_FETCH = 'Unable to fetch artist listings',
  ID_EDITION_INDEX_FETCH = 'Unable to fetch artist edition index'
}

export class ArtistHandlers {
  public static handleGetArtistById(state: ArtistState, action: ArtistAction): Loop<ArtistState, ArtistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        item: true
      }
    };

    return loop(
      newState,
      Cmd.run(ArtistApiClient.getArtistById, {
        args: [payload?.id || ''],
        successActionCreator: ArtistInternalActions.getArtistByIdSuccess,
        failActionCreator: ArtistInternalActions.getArtistByIdFailure,
      })
    );
  }

  public static handleGetArtistByIdSuccess(state: ArtistState, action: ArtistAction): ArtistState | Loop<ArtistState, ArtistAction> {
    const artist: ArtistModel = action.payload?.artist || defaultState.artist;

    return {
      ...state,
      artist,
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetArtistByIdFailure(state: ArtistState): ArtistState {
    return {
      ...state,
      error: new Error(ArtistStateErrors.ID_FETCH),
      loading: {
        ...state.loading,
        item: false
      }
    };
  }

  public static handleGetAllArtists(state: ArtistState): Loop<ArtistState, ArtistAction> {
    const newState: ArtistState = {
      ...state,
      loading: {
        ...state.loading,
        list: true
      }
    };

    return loop(
      newState,
      Cmd.run(ArtistApiClient.getAllArtists, {
        args: [],
        successActionCreator: ArtistInternalActions.getAllArtistsSuccess,
        failActionCreator: ArtistInternalActions.getAllArtistsFailure,
      })
    );
  }

  public static handleGetAllArtistsSuccess(state: ArtistState, action: ArtistAction): ArtistState {
    const artistList = action.payload?.artistList || defaultState.artistList;
    return {
      ...state,
      artistList,
      loading: {
        ...state.loading,
        list: false
      }
    };
  }

  public static handleGetAllArtistsFailure(state: ArtistState): ArtistState {
    return {
      ...state,
      error: new Error(ArtistStateErrors.ALL_FETCH),
      loading: {
        ...state.loading,
        list: false
      }
    };
  }

  public static handleGetArtistItems(state: ArtistState, action: ArtistAction): Loop<ArtistState, ArtistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        itemList: true
      }
    };
    return loop(
      newState,
      Cmd.run(ArtistApiClient.getArtistItems, {
        args: [payload?.id || ''],
        successActionCreator: ArtistInternalActions.getArtistItemsSuccess,
        failActionCreator: ArtistInternalActions.getArtistItemsFailure,
      })
    );
  }

  public static handleGetArtistItemsSuccess(state: ArtistState, action: ArtistAction): ArtistState {
    const { artist } = state;
    const itemsList = action.payload?.itemsList || [];
    const newArtist = ArtistModel.deepCopy(artist);
    newArtist.setItems(itemsList);
    return {
      ...state,
      artist: newArtist,
      loading: {
        ...state.loading,
        itemList: false
      }
    };
  }

  public static handleGetArtistItemsFailure(state: ArtistState): ArtistState {
    return {
      ...state,
      error: new Error(ArtistStateErrors.ID_ITEMS_FETCH),
      loading: {
        ...state.loading,
        itemList: false
      }
    };
  }

  public static handleGetArtistListings(state: ArtistState, action: ArtistAction): Loop<ArtistState, ArtistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        listingList: true
      }
    };
    return loop(
      newState,
      Cmd.run(ArtistApiClient.getArtistListings, {
        args: [payload?.id || '', payload?.page || 0],
        successActionCreator: ArtistInternalActions.getArtistListingsSuccess,
        failActionCreator: ArtistInternalActions.getArtistListingsFailure,
      })
    );
  }

  public static handleGetArtistListingsSuccess(state: ArtistState, action: ArtistAction): ArtistState {
    const { artist } = state;
    let paginatedListings = action.payload?.paginatedListings;
    if (artist.hasSoldListings() && paginatedListings) {
      paginatedListings = PaginationModel.deepCopy(paginatedListings);
      paginatedListings.setData([...artist.getSoldListings().data, ...paginatedListings?.data || []]);
    }

    const newArtist = ArtistModel.deepCopy(artist);
    newArtist.setSoldListings(paginatedListings);

    return {
      ...state,
      artist: newArtist,
      loading: {
        ...state.loading,
        listingList: false
      }
    };
  }

  public static handleGetArtistListingsFailure(state: ArtistState): ArtistState {
    return {
      ...state,
      error: new Error(ArtistStateErrors.ID_LISTINGS_FETCH),
      loading: {
        ...state.loading,
        listingList: false
      }
    };
  }

  public static handleGetArtistEditionIndex(state: ArtistState, action: ArtistAction): Loop<ArtistState, ArtistAction> {
    const { payload } = action;
    const newState = {
      ...state,
      loading: {
        ...state.loading,
        editionIndex: true
      }
    };
    return loop(
      newState,
      Cmd.run(ArtistApiClient.getArtistEditionIndex, {
        args: [payload?.id || ''],
        successActionCreator: ArtistInternalActions.getArtistEditionIndexSuccess,
        failActionCreator: ArtistInternalActions.getArtistEditionIndexFailure,
      })
    );
  }

  public static handleGetArtistEditionIndexSuccess(state: ArtistState, action: ArtistAction): ArtistState {
    const { artist } = state;
    let artistIndexData = action.payload?.artistIndexData;

    const newArtist = ArtistModel.deepCopy(artist);
    if (artistIndexData) {
      newArtist.setEditionIndex(artistIndexData);
    }

    return {
      ...state,
      artist: newArtist,
      loading: {
        ...state.loading,
        editionIndex: false
      }
    };
  }

  public static handleGetArtistEditionIndexFailure(state: ArtistState): ArtistState {
    return {
      ...state,
      error: new Error(ArtistStateErrors.ID_EDITION_INDEX_FETCH),
      loading: {
        ...state.loading,
        editionIndex: false
      }
    };
  }
}
