import { ItemModel, PaginationModel } from 'models';
import { ItemState } from './types';

export const defaultState: ItemState = {
  item: new ItemModel(),
  paginatedItems: new PaginationModel<ItemModel>(),
  items: [],
  error: undefined,
  loading: {
    item: false,
    items: false,
    listings: false,
    market: false,
    artist: false,
    search: false
  }
};
