import { WishlistState } from './types';
import { WishlistModel } from 'models';

export const defaultState: WishlistState = {
  wishlist: new WishlistModel(),
  error: undefined,
  loading: {
    item: false,
    action: false,
    artists: false,
    market: false,
    listings: false
  }
};
