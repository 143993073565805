import { ButtonGroup, Button, Icon } from '@chakra-ui/react';
import { BiStats } from 'react-icons/bi';
import { MarketDateFilter } from 'models/types';

interface MarketDateFiltersProps {
	selectedFilter: MarketDateFilter;
	onFilterSelect: (filter: MarketDateFilter) => void;
}

export default function MarketDateFilters({ selectedFilter, onFilterSelect }: MarketDateFiltersProps) {
	return (
		<ButtonGroup size="sm" isAttached variant="outline" mb={6}>
			<Button fontSize={'xs'} leftIcon={<Icon as={BiStats} />} onClick={() => onFilterSelect(MarketDateFilter.SIX_MONTHS)} isActive={selectedFilter === MarketDateFilter.SIX_MONTHS}>6M</Button>
			<Button fontSize={'xs'} leftIcon={<Icon as={BiStats} />} onClick={() => onFilterSelect(MarketDateFilter.ONE_YEAR)} isActive={selectedFilter === MarketDateFilter.ONE_YEAR}>1Y</Button>
			<Button fontSize={'xs'} leftIcon={<Icon as={BiStats} />} onClick={() => onFilterSelect(MarketDateFilter.THREE_YEARS)} isActive={selectedFilter === MarketDateFilter.THREE_YEARS}>3Y</Button>
			<Button fontSize={'xs'} leftIcon={<Icon as={BiStats} />} onClick={() => onFilterSelect(MarketDateFilter.FIVE_YEARS)} isActive={selectedFilter === MarketDateFilter.FIVE_YEARS}>5Y</Button>
			<Button fontSize={'xs'} leftIcon={<Icon as={BiStats} />} onClick={() => onFilterSelect(MarketDateFilter.MAX)} isActive={selectedFilter === MarketDateFilter.MAX}>Max</Button>
		</ButtonGroup>
	);
};
