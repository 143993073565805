import { ApplicationState } from '../types';
import { RootSelectors } from '../rootSelectors';
import { MaterialModel } from 'models';

export class MaterialSelectors {
  public static getMaterial(state: ApplicationState): MaterialModel {
    return RootSelectors.getMaterialState(state).material;
  }

  public static getMaterialList(state: ApplicationState): MaterialModel[] {
    return RootSelectors.getMaterialState(state).materialList;
  }

  public static getMaterialError(state: ApplicationState): Error | undefined {
    return RootSelectors.getMaterialState(state).error;
  }

  public static getMaterialLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getMaterialState(state).loading.item;
  }

  public static getMaterialListLoading(state: ApplicationState): boolean {
    return !!RootSelectors.getMaterialState(state).loading.list;
  }
}
