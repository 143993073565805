import { YearlySummaryItem } from 'models/Market/types';
import { Dot } from 'recharts';
import { ExtendedActiveDotProps } from '../types';

export interface YearDotProps extends ExtendedActiveDotProps {
	yearlyData: YearlySummaryItem[];
}

export default function YearDot(dotProps: YearDotProps) {
	const { payload, yearlyData } = dotProps;
    const actualData = yearlyData.find(y => y.overview?.key === payload?.key);
	if (actualData) {
		return <Dot {...dotProps} r={4} />;
	}
	return <Dot {...dotProps} r={0} opacity={0} style={{ pointerEvents: "none" }} />;
}
