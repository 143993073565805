import { ItemModel, ListingModel, MarketModel, ArtistModel, PaginationModel } from 'models';
import { ItemActionTypes, ItemAction } from './types';
import { JSONObject } from 'models/Api/types';


export class ItemActions {
  public static getItemById(id: string): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_BY_ID,
      payload: { id },
    };
  }

  public static getItemListings(id: string, page?: number): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_LISTINGS,
      payload: { id, page }
    }
  }

  public static getItemMarket(id: string): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_MARKET,
      payload: { id },
    }
  }

  public static getItemArtist(id: string): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_ARTIST,
      payload: { id }
    };
  }

  public static searchItemsByQuery(query: string, page?: number): ItemAction {
    return {
      type: ItemActionTypes.SEARCH_ITEM_BY_QUERY,
      payload: { query, page }
    }
  }


  public static searchItem(searchQuery: JSONObject): ItemAction {
    return {
      type: ItemActionTypes.SEARCH_ITEM,
      payload: { searchQuery }
    }
  }

  public static getUserFollowingArtistsItems(page?: number): ItemAction {
    return {
      type: ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS,
      payload: { page }
    }
  }

  public static getItemSuggestions(id: string): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_SUGGESTIONS,
      payload: { id }
    };
  }

  public static getAllItems(page?: number): ItemAction {
    return {
      type: ItemActionTypes.GET_ALL_ITEMS,
      payload: { page }
    }
  }
}

export class ItemInternalActions {
  public static getItemByIdSuccess(item: ItemModel): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_BY_ID_SUCCESS,
      payload: { item },
    };
  }

  public static getItemByIdFailure(): ItemAction {
    return { type: ItemActionTypes.GET_ITEM_BY_ID_FAILURE };
  }

  public static getItemListingsSuccess(listings: ListingModel[]): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_LISTINGS_SUCCESS,
      payload: { listings },
    };
  }

  public static getItemListingsFailure(): ItemAction {
    return { type: ItemActionTypes.GET_ITEM_LISTINGS_FAILURE };
  }

  public static getItemMarketSuccess(market: MarketModel): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_MARKET_SUCCESS,
      payload: { market },
    };
  }

  public static getItemMarketFailure(): ItemAction {
    return { type: ItemActionTypes.GET_ITEM_MARKET_FAILURE };
  }

  public static getItemArtistSuccess(artist: ArtistModel): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_ARTIST_SUCCESS,
      payload: { artist },
    };
  }

  public static getItemArtistFailure(): ItemAction {
    return { type: ItemActionTypes.GET_ITEM_ARTIST_FAILURE };
  }

  public static searchItemByQuerySuccess(paginatedItems: PaginationModel<ItemModel>) {
    return {
      type: ItemActionTypes.SEARCH_ITEM_BY_QUERY_SUCCESS,
      payload: { paginatedItems },
    };
  }

  public static searchItemByQueryFailure() {
    return {
      type: ItemActionTypes.SEARCH_ITEM_BY_QUERY_FAILURE
    };
  }

  public static searchItemSuccess(paginatedItems: PaginationModel<ItemModel>) {
    return {
      type: ItemActionTypes.SEARCH_ITEM_SUCCESS,
      payload: { paginatedItems },
    };
  }

  public static searchItemFailure() {
    return {
      type: ItemActionTypes.SEARCH_ITEM_FAILURE
    };
  }

  public static getUserFollowingArtistsItemsSuccess(paginatedItems: PaginationModel<ItemModel>) {
    return {
      type: ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS_SUCCESS,
      payload: { paginatedItems },
    };
  }

  public static getUserFollowingArtistsItemsFailure() {
    return {
      type: ItemActionTypes.GET_USER_FOLLOWING_ARTISTS_ITEMS_FAILURE
    };
  }

  public static getAllItemsSuccess(paginatedItems: PaginationModel<ItemModel>) {
    return {
      type: ItemActionTypes.GET_ALL_ITEMS_SUCCESS,
      payload: { paginatedItems },
    };
  }

  public static getAllItemsFailure() {
    return {
      type: ItemActionTypes.GET_ALL_ITEMS_FAILURE
    };
  }

  public static getItemSuggestionsSuccess(items: ItemModel[]): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_SUGGESTIONS_SUCCESS,
      payload: { items }
    };
  }

  public static getItemSuggestionsFailure(): ItemAction {
    return {
      type: ItemActionTypes.GET_ITEM_SUGGESTIONS_FAILURE,
    };
  }
}
