import { MaterialModel } from 'models';
import { MaterialState } from './types';

export const defaultState: MaterialState = {
  material: new MaterialModel(),
  materialList: [],
  error: undefined,
  loading: {
    item: false,
    list: false
  }
};
