import { ItemListingGroupingActionTypes, ItemListingGroupingAction } from './types';
import { Moment } from 'moment'

export class ItemListingGroupingActions {
  public static createItemListingGrouping(itemId: string, listingIds: string[]): ItemListingGroupingAction {
    return {
      type: ItemListingGroupingActionTypes.CREATE_ITEM_LISTING_GROUPING,
      payload: { itemId, listingIds },
    };
  }
}

export class ItemListingGroupingInternalActions {
  public static createItemListingGroupingSuccess(id: string): ItemListingGroupingAction {
    return {
      type: ItemListingGroupingActionTypes.CREATE_ITEM_LISTING_GROUPING_SUCCESS,
      payload: { id },
    };
  }

  public static createItemListingGroupingFailure(): ItemListingGroupingAction {
    return { type: ItemListingGroupingActionTypes.CREATE_ITEM_LISTING_GROUPING_FAILURE };
  }
}
