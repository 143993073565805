import { Sector } from 'recharts';
import { ArtistModel } from 'models';
import { toPercentageFormat } from 'utils/format';

interface ActiveShapeProps {
	cx: number;
	cy: number;
	midAngle: number;
	innerRadius: number;
	outerRadius: number;
	startAngle: number;
	endAngle: number;
	fill: string;
	name: string;
	payload: {
		data: ArtistModel;
	};
	percent: number;
}

export default function DistributionActiveShape(props: ActiveShapeProps) {
	const RADIAN = Math.PI / 180;
	const {
		cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle,
		fill, name, percent
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? -1 : 1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? 'end' : 'start';
	const textYOffset = 0;

	return (
		<g>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
			<circle cx={ex} cy={ey} r={4} fill={fill} stroke="none" />
			<text x={ex + (cos >= 0 ? -1 : 1) * 12} y={ey + textYOffset} textAnchor={textAnchor} fill="#333" fontSize={'10px'} fontWeight={'bold'}>
				{name}
			</text>
			<text x={ex + (cos >= 0 ? -1 : 1) * 12} y={ey + textYOffset + 18} textAnchor={textAnchor} fill="#999" fontSize={'10px'} fontWeight={'bold'}>
				{toPercentageFormat(percent * 100)}
			</text>
		</g>
	);
};
