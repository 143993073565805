import { ListingModel , PaginationModel } from 'models';
import { ListingState } from './types';

export const defaultState: ListingState = {
  paginatedListings: new PaginationModel<ListingModel>(),
  error: undefined,
  loading: {
    search: false
  }
};
