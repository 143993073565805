import { Loop, LoopReducer } from 'redux-loop';
import { AuthHandlers } from './handlers';
import { AuthState, AuthAction, AuthActionTypes } from './types';
import { defaultState } from './consts';

export const authReducer: LoopReducer<AuthState, AuthAction> = (
  state: AuthState = defaultState,
  action: AuthAction
): AuthState | Loop<AuthState, AuthAction> =>  {
  switch (action.type) {
    case AuthActionTypes.LOGIN_BY_EMAIL:
      return AuthHandlers.handleLoginByEmail(state, action);
    case AuthActionTypes.LOGIN_BY_EMAIL_SUCCESS:
      return AuthHandlers.handleLoginByEmailSuccess(state);
    case AuthActionTypes.LOGIN_BY_EMAIL_FAILURE:
      return AuthHandlers.handleLoginByEmailFailure(state);
    case AuthActionTypes.REGISTER_USER:
      return AuthHandlers.handleRegisterUser(state, action);
    case AuthActionTypes.REGISTER_USER_SUCCESS:
      return AuthHandlers.handleRegisterUserSuccess(state, action);
    case AuthActionTypes.REGISTER_USER_FAILURE:
      return AuthHandlers.handleRegisterUserFailure(state);
    case AuthActionTypes.REFRESH_AUTH_TOKEN:
      return AuthHandlers.handleRefreshAuthToken(state);
    case AuthActionTypes.REFRESH_AUTH_TOKEN_SUCCESS:
      return AuthHandlers.handleRefreshAuthTokenSuccess(state);
    case AuthActionTypes.REFRESH_AUTH_TOKEN_FAILURE:
      return AuthHandlers.handleRefreshAuthTokenFailure(state);
    case AuthActionTypes.LOGOUT:
      return AuthHandlers.handleLogout(state);
    case AuthActionTypes.LOGOUT_SUCCESS:
      return AuthHandlers.handleLogoutSuccess(state);
    case AuthActionTypes.LOGOUT_FAILURE:
      return AuthHandlers.handleLogoutFailure(state);
    case AuthActionTypes.GET_AUTHENTICATED_USER:
      return AuthHandlers.handleGetAuthenticatedUser(state);
    case AuthActionTypes.GET_AUTHENTICATED_USER_SUCCESS:
      return AuthHandlers.handleGetAuthenticatedUserSuccess(state, action);
    case AuthActionTypes.GET_AUTHENTICATED_USER_FAILURE:
      return AuthHandlers.handleGetAuthenticatedUserFailure(state);
    default:
      return state;
  }
}
