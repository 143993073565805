import { Stack, Text } from "@chakra-ui/react";
import PaginationItem from "../PaginationItem/PaginationItem";

type PaginationProps = {
  onPageChange: (page: number) => void;
  currentPage: number;
  lastPage: number;
  siblingsCount: number;
};

export default function PaginationFooter({
  currentPage,
  lastPage,
  siblingsCount,
  onPageChange,
}: PaginationProps) {
  let nextPages = [];
  let previousPages = [];

  let nextDelta = lastPage - currentPage;
  let prevDelta = currentPage - 0;

  let nextCounter = 1;
  while (nextDelta > 0 && nextCounter < 3) {
    nextPages.push(currentPage + nextCounter);
    nextCounter += 1;
    nextDelta -= 1;
  }

  let prevCounter = 1;
  while (prevDelta > 1 && prevCounter < 3) {
    previousPages.push(currentPage - prevCounter);
    prevDelta -= 1;
    prevCounter += 1;
  }


  return (
    <Stack direction="row" mt="8" justify="flex-end" align="center" spacing="6" paddingInline={{base: 3, md: 0}}>
      <Stack direction="row" spacing="4">
        {currentPage > 1 + siblingsCount ? (
          <>
            <PaginationItem
              onPageChange={onPageChange}
              page={1}
            />
            {currentPage > 2 + siblingsCount ? (
              <Text color="gray.300" w="8" textAlign="center">
                ...
              </Text>
            ) : null}
          </>
        ) : null}

        {previousPages.length > 0
          ? previousPages.reverse().map((page) => (
              <PaginationItem
                onPageChange={onPageChange}
                page={page}
                key={page}
              />
            ))
          : null}

        <PaginationItem
          onPageChange={onPageChange}
          page={currentPage}
          isCurrent
        />

        {nextPages.length > 0
          ? nextPages.map((page) => (
              <PaginationItem
                onPageChange={onPageChange}
                page={page}
                key={page}
              />
            ))
          : null}

        {currentPage + siblingsCount < lastPage ? (
          <>
            {currentPage + 1 + siblingsCount < lastPage ? (
              <Text color="gray.300" w="8" textAlign="center">
                ...
              </Text>
            ) : null}
            <PaginationItem
              onPageChange={onPageChange}
              page={lastPage}
            />
          </>
        ) : null}
      </Stack>
    </Stack>
  );
}
